import { EntityService } from './EntityService';
import { IEntityWithCustomer } from '../types';
import { AppGlobals } from '../AppGlobals';

export class RiskDiscussionForumService extends EntityService<IEntityWithCustomer> {
    constructor() {
        super(`/RiskDiscussionForums`);
    }

    public readAllForList(): Promise<IEntityWithCustomer[]> {
        return super.readAll(`?$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`);
    }

    public readAllForLookup(): Promise<IEntityWithCustomer[]> {
        return this.readAll(`?$select=ID,Title&$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`);
    }

    public async create(rdf: IEntityWithCustomer): Promise<IEntityWithCustomer> {
        rdf.CustomerID = AppGlobals.CustomerID;           
        return super.create(rdf);
    }
}