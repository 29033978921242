import { IColumn } from '@fluentui/react/lib/DetailsList';


export interface IUpdatesListColumn extends IColumn{
    columnDisplayType?: ColumnDisplayTypes;
}

export enum ColumnDisplayTypes {
    Hidden,
}

