import * as React from 'react';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import * as types from '../../types';
import * as services from '../../services';
import { IGenColumn, ColumnType, ColumnDisplayType } from '../../types/GenColumn';
import PeriodList from '../period/PeriodList';
import EntityListCat from '../entity/EntityListCat';
import withMobileDetector from '../withMobileDetector';
import { isMobile } from 'react-device-detect';

export interface IGoPropertiesClsProps extends types.IBaseComponentProps {
    isMobile: boolean;
}

export interface IGoPropertiesState {
}

export class GoPropertiesState implements IGoPropertiesState {
}


class GoPropertiesCls extends React.Component<IGoPropertiesClsProps, GoPropertiesState> {
    constructor(props: IGoPropertiesClsProps) {
        super(props);
        this.state = new GoPropertiesState();
    }

    public render(): React.ReactElement<IGoPropertiesClsProps> {
        return (
            <Pivot>
                <PivotItem headerText="Periods">
                    {this.renderCustomPeriodsList()}
                </PivotItem>
                <PivotItem headerText="Define Form">
                    {this.renderGoDefForms()}
                </PivotItem>
                <PivotItem headerText="Define Specific Areas">
                    {this.renderGoDefElements()}
                </PivotItem>
            </Pivot>
        );
    }

    private renderCustomPeriodsList() {
        const { isMobile } = this.props;
        const listColumns: IGenColumn[] = [
            {
                key: '1',
                columnType: ColumnType.TextBox,
                name: 'Name',
                fieldName: 'Title',
                minWidth: isMobile ? 55 : 80,
                maxWidth: isMobile ? 80 : 250,
                isResizable: true,
                isRequired: true,
                isMultiline: true,
                fieldMaxLength: 50,

            },
            {
                key: '2',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.ListOnly,
                name: isMobile ? 'Status' : 'Period Status',
                fieldName: 'PeriodStatus',
                minWidth: isMobile ? 55 : 75,
                maxWidth: isMobile ? 100 : 200,
                isResizable: true,
                isMultiline: true,
                fieldMaxLength: 50,
            },
            {
                key: '3',
                columnType: ColumnType.DatePicker,
                name: 'Start Date',
                fieldName: 'PeriodStartDate',
                minWidth: isMobile ? 65 : 85,
                maxWidth: isMobile ? 100 : 200,
                isResizable: true,
                isRequired: true,
                isCollapsible: true,
                fieldMaxLength: 50,
            },
            {
                key: '4',
                columnType: ColumnType.DatePicker,
                name: 'End Date',
                fieldName: 'PeriodEndDate',
                minWidth: isMobile ? 60 : 80,
                isResizable: true,
                isRequired: true,
                isCollapsible: true,
                fieldMaxLength: 50,
            },
        ];

        return (
            <React.Fragment>
                <PeriodList
                    allowAdd={this.allowAdd_Periods()}
                    columns={listColumns}
                    {...this.props}
                    errorHandling={this.props.errorHandling}
                    entityService={new services.GoPeriodService()}
                    entityNamePlural="Gov Periods"
                    entityNameSingular="Period"
                    childEntityNameApi=""
                    childEntityNamePlural=""
                    childEntityNameSingular=""
                />
            </React.Fragment>
        );
    }

    private renderGoDefForms() {
        const listColumns: IGenColumn[] = [
            {
                key: 'Title',
                columnType: ColumnType.TextBox,
                name: 'Welcome Text Heading',
                fieldName: 'Title',
                minWidth: 300,
                isResizable: true,
                isRequired: true,
                fieldMaxLength: 100
            },
            {
                key: 'Details',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Welcome Text',
                fieldName: 'Details',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 5000,
                numRows: 10
            },
            {
                key: 'Section1Title',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section 1 Title',
                fieldName: 'Section1Title',
                minWidth: 100,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 300
            },
            {
                key: 'Section1TitleMobile',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section 1 Title for Mobile',
                fieldName: 'Section1TitleMobile',
                minWidth: 100,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 300
            },
            {
                key: 'Section2Title',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section 2 Title',
                fieldName: 'Section2Title',
                minWidth: 100,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 300
            },
            {
                key: 'Section2TitleMobile',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section 2 Title for Mobile',
                fieldName: 'Section2TitleMobile',
                minWidth: 100,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 300
            },
            {
                key: 'Section3Title',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section 3 Title',
                fieldName: 'Section3Title',
                minWidth: 100,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 300
            },
            {
                key: 'Section3TitleMobile',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section 3 Title for Mobile',
                fieldName: 'Section3TitleMobile',
                minWidth: 100,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 300
            },
            {
                key: 'SummaryShortInstructions',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Summary Short Instructions',
                fieldName: 'SummaryShortInstructions',
                minWidth: 100,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 3
            },
            {
                key: 'SummaryFullInstructions',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Summary Full Instructions',
                fieldName: 'SummaryFullInstructions',
                minWidth: 100,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 5000,
                numRows: 6
            },
            {
                key: 'SummaryFormRatingText',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Summary Form Rating Text',
                fieldName: 'SummaryFormRatingText',
                minWidth: 100,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 2000,
                numRows: 3
            },
            {
                key: 'DGSignOffText',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'DG SignOff Text',
                fieldName: 'DGSignOffText',
                minWidth: 100,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 2000,
                numRows: 3
            },
        ];

        return (
            <React.Fragment>
                <EntityListCat
                    allowAdd={this.superUserPermission()}
                    columns={listColumns}
                    {...this.props}
                    errorHandling={this.props.errorHandling}
                    entityService={new services.GoDefFormService()}
                    entityNamePlural="Define Form"
                    entityNameSingular="Define Form"
                    childEntityNameApi=""
                    childEntityNamePlural=""
                    childEntityNameSingular=""
                />
            </React.Fragment>
        );
    }

    private renderGoDefElements() {
        const listColumns: IGenColumn[] = [
            {
                key: 'Title',
                columnType: ColumnType.TextBox,
                name: 'Title',
                fieldName: 'Title',
                minWidth: 300,
                isResizable: true,
                isRequired: true,
                fieldMaxLength: 100
            },
            {
                key: 'Instructions',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Instructions',
                fieldName: 'Instructions',
                minWidth: 100,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 3
            },
            {
                key: 'FullInstructions',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Full Instructions',
                fieldName: 'FullInstructions',
                minWidth: 100,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 5000,
                numRows: 6
            },
            {
                key: 'RagRatingStyle',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'RAG Rating Style (1 or 2)',
                fieldName: 'RagRatingStyle',
                minWidth: 100,
                isResizable: true,
                isRequired: true,
                fieldMaxLength: 1,
            },
        ];

        return (
            <React.Fragment>
                <EntityListCat
                    allowAdd={this.superUserPermission()}
                    columns={listColumns}
                    {...this.props}
                    errorHandling={this.props.errorHandling}
                    entityService={new services.GoDefElementService()}
                    entityNamePlural="Define Specific Areas"
                    entityNameSingular="Define Specific Area"
                    childEntityNameApi=""
                    childEntityNamePlural=""
                    childEntityNameSingular=""
                />
            </React.Fragment>
        );
    }

    private superUserPermission = (): boolean => {
        return this.props.userPermissions.UserIsSystemAdmin()
            || this.props.userPermissions.UserIsGovernanceSuperUser();
    }

    private allowAdd_Periods(): boolean {
        return this.superUserPermission();
    }
}

export default withMobileDetector(GoPropertiesCls);