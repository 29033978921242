import { EntityService } from './EntityService';
import { IAttribute } from '../types';
import axios from 'axios';

export class AttributeService extends EntityService<IAttribute> {
    constructor() {
        super(`/Attributes`);
    }

    public update(entityId: number, entity: Partial<IAttribute>): Promise<void> {
        const entityToPost = entity;
        delete entityToPost.AttributeType;
        return this.axiosPatch(`${this.entityUrl}(${entityId})`, entityToPost); 
    }

    public static attributesToBadgeStrings = (attributes: IAttribute[]): string[] => {
        return attributes?.filter(a => a.AttributeType?.Display).map(a => a.AttributeType?.Title ?? '');
      };
      
}