import { EntityService } from './EntityService';
import { IEntity, IEntityChildren } from '../types';
import { AppGlobals } from '../AppGlobals';

export class MilestoneTypeService extends EntityService<IEntity> {
    constructor() {
        super(`/MilestoneTypes`);
    }

    public async entityChildren(id: number): Promise<IEntityChildren[]> {
        return this.entityChildrenSingle(id, 'Milestones', 'Milestones', true);
    }

    public readAllForList(): Promise<IEntity[]> {
        return super.readAll(`?$orderby=Title`);
    }

    public readAllForLookup(): Promise<IEntity[]> {
        return this.readAll(`?$select=ID,Title&$orderby=Title`);
    }
}