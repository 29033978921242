import { EntityService } from './EntityService';
import { ContextService } from './ContextService';
import { IUserGroup} from '../types';
import { AppGlobals } from '../AppGlobals';

export class UserGroupService extends EntityService<IUserGroup> {
    constructor() {
        super(`/UserGroups`);
    }

    public readAllForList(): Promise<IUserGroup[]> {
        return this.readAll(`?$orderby=User/Title&$expand=User,OrgLevel1&$filter=User/CustomerID eq ${AppGlobals.CustomerID}`);
    }

    public readAllForLookup(): Promise<IUserGroup[]> {
        return this.readAll(`?$select=ID,Title,GroupID,UserID&$orderby=Title`);
    }

    public readMyGroups(): Promise<IUserGroup[]> {
        return this.readAll(`?$filter=User/Username eq '${encodeURIComponent(ContextService.Username())}'`);
    }

    public checkForDuplicates = (userId: number, groupId: number): Promise<IUserGroup[]> => {
        return this.readAll(`?$filter=UserID eq ${userId} and GroupID eq ${groupId}&$select=ID`);
    }
}