import React, { useContext, useState } from 'react';
import {
    ListDefaults as ld,
    IReportDueDates, IBaseComponentProps, IProgramme, IProgrammeUpdate, ProgrammeUpdate
} from '../../types';
import styles from '../../styles/cr.module.scss';
import { RagIndicator } from '../cr/RagIndicator';
import { IconButton } from '@fluentui/react/lib/Button';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { CrLastEdit } from '../cr/CrLastEdit';
import { OrbUserContext } from '../OrbUserContext';
import { ProgrammeProgressUpdateForm } from '../programme/ProgrammeProgressUpdateForm';

export interface IProgrammeProgressUpdateReviewProps extends IBaseComponentProps {
    programme?: IProgramme;
    programmeUpdate?: IProgrammeUpdate;
    readOnly?: boolean;
    onChange?: () => void;
    reportDates: IReportDueDates;
}

export const ProgrammeProgressUpdateReviewList = (props: IProgrammeProgressUpdateReviewProps): React.ReactElement => {
    const { userPermissions } = useContext(OrbUserContext);
    const programmeId = props.programme?.ID;
    const pu = props.programmeUpdate || new ProgrammeUpdate();
    const hideEdit = !!(props.readOnly);

    const [showForm, setShowForm] = useState(false);

    const closeEditUpdate = (reloadList: boolean): void => {
        setShowForm(false);
        if (reloadList) props.onChange?.();
    };

    return (
        <div className={styles.cr}>
            {programmeId && !hideEdit && userPermissions.UserCanSubmitProgrammeUpdates(programmeId) &&
                <IconButton iconProps={{ iconName: 'Edit' }} title='Edit' onClick={() => setShowForm(true)} />
            }
            <div className={styles.grid}>
                <div className={`${styles.gridRow} ${styles.signOffGridRow}`}>
                    <div className={`${styles.gridCol} ${styles.sm12}`}>
                        <RagIndicator rag={pu.OverallRagOptionID} />
                    </div>
                </div>
                <div className={`${styles.gridRow} ${styles.signOffGridRow}`}>
                    <div className={`${styles.gridCol} ${styles.sm12} ${styles.xl4}`}>
                        <div className={styles.content}>
                            <div className={styles.reviewListTitle}>Delivery confidence</div>
                            <p>{pu.ProgressUpdate || ld.placeholders.dataTBC}</p>
                        </div>
                    </div>
                    <div className={`${styles.gridCol} ${styles.sm12} ${styles.xl4}`}>
                        <div className={styles.content}>
                            <div className={styles.reviewListTitle}>Future actions</div>
                            <p>{pu.FutureActions || ld.placeholders.dataTBC}</p>
                        </div>
                    </div>
                    <div className={`${styles.gridCol} ${styles.sm12} ${styles.xl4}`}>
                        <div className={styles.content}>
                            <div className={styles.reviewListTitle}>Escalations for senior leader action</div>
                            <p>{pu.Escalations || ld.placeholders.dataTBC}</p>
                        </div>
                    </div>
                </div>
                <div className={`${styles.gridRow} ${styles.signOffGridRow}`}>
                    <div className={`${styles.gridCol} ${styles.sm12} ${styles.xl3}`}>
                        <div className={styles.content}>
                            <div className={styles.reviewListTitle}>Finance</div>
                            <RagIndicator rag={pu.FinanceRagOptionID} />
                            <p>{pu.FinanceComment || ld.placeholders.dataTBC}</p>
                        </div>
                    </div>
                    <div className={`${styles.gridCol} ${styles.sm12} ${styles.xl3}`}>
                        <div className={styles.content}>
                            <div className={styles.reviewListTitle}>People</div>
                            <RagIndicator rag={pu.PeopleRagOptionID} />
                            <p>{pu.PeopleComment || ld.placeholders.dataTBC}</p>
                        </div>
                    </div>
                    <div className={`${styles.gridCol} ${styles.sm12} ${styles.xl3}`}>
                        <div className={styles.content}>
                            <div className={styles.reviewListTitle}>Milestones</div>
                            <RagIndicator rag={pu.MilestonesRagOptionID} />
                            <p>{pu.MilestonesComment || ld.placeholders.dataTBC}</p>
                        </div>
                    </div>
                    <div className={`${styles.gridCol} ${styles.sm12} ${styles.xl3}`}>
                        <div className={styles.content}>
                            <div className={styles.reviewListTitle}>Benefits</div>
                            <RagIndicator rag={pu.BenefitsRagOptionID} />
                            <p>{pu.BenefitsComment || ld.placeholders.dataTBC}</p>
                        </div>
                    </div>
                </div>
                <div className={styles.gridRow}>
                    <div className={`${styles.gridCol} ${styles.sm12}`}>
                        <CrLastEdit author={pu.UpdateUser?.Title} editDate={pu.UpdateDate} />
                    </div>
                </div>
            </div>
            <Panel isOpen={showForm} headerText="Edit programme update" type={PanelType.medium} onDismiss={() => setShowForm(false)}>
                <ProgrammeProgressUpdateForm
                    {...props}
                    entityId={programmeId}
                    entityUpdateId={pu?.ID}
                    defaultShowForm={true}
                    onSaved={() => closeEditUpdate(true)}
                    onCancelled={() => closeEditUpdate(false)}
                />
            </Panel>
        </div>
    );
};
