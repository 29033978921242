import { useState } from "react";
import { IOrbProps } from "./App";
import { useApi } from './components/useApi';
import { useUserContext } from './components/useUserContext';
import { useDataContext } from './components/useDataContext';
import { useIntegration } from './components/useIntegration';
import React from "react";
import { HashRouter, Switch, Route } from 'react-router-dom';
import { ErrorBoundary } from "./components/ErrorBoundary";
import { IntegrationContext } from "./components/IntegrationContext";
import { DataContext } from "./components/DataContext";
import { OrbUserContext } from "./components/OrbUserContext";
import { CrLoadingOverlay } from './components/cr/CrLoadingOverlay';
import { AppGlobals, AppIDs } from "./AppGlobals";
import Logout from "./Logout";
import { withErrorHandling } from './components/withErrorHandling';
import { AdminRoute, NotAuthorised, NotFound } from "./Routes";
import { MenuToggle } from "./components/MenuToggle";
import LoadingWhite from "./LoadingWhite";
import { CorporateWorkflowsMenu } from "./components/CorporateWorkflowsMenu";
import App03WorkflowsAdmin from "./components/app03Workflows/App03WorkflowsAdmin";
import WorkflowTasks from "./components/app03Workflows/WorkflowTasks";
import { App03WorkflowsWelcome } from "./components/app03Workflows/App03WorkflowsWelcome";

const CorporateWorkflowsApp: React.FunctionComponent<IOrbProps> = (props: IOrbProps) => {

    const { dataServices, errorHandling } = props;
    const apiConnected = useApi(dataServices/*, errorHandling*/);
    const uc = useUserContext(apiConnected, dataServices, errorHandling);
    const ld = useDataContext(apiConnected, dataServices, errorHandling);
    const integration = useIntegration({
        disableDirectorateManagement: false,
        disableGroupManagement: false,
        disablePartnerOrganisationManagement: false,
        disableProjectManagement: false,
        disableUserManagement: false,
        dataSourceName: ''
    });
    const routeProps = { ...props, apiConnected: apiConnected, ...uc, ...ld };


    const [isNavigationVisible, setNavigationVisible] = useState(false);

    const toggleNavigation = () => {
        setNavigationVisible(!isNavigationVisible);
    };

    if (uc.userContext.UserId === null) return (<LoadingWhite />);

    const appName = props.menuData.filter(m => m.ID === AppIDs.CORWF)[0].AppName;

    return (

        <React.Fragment>
            <ErrorBoundary>
                <IntegrationContext.Provider value={integration}>
                    <DataContext.Provider value={ld}>
                        <OrbUserContext.Provider value={uc}>
                            <div className="main">
                                <div className="blue-banner">
                                    <MenuToggle onToggleNavigation={toggleNavigation} />
                                    <div className="blue-banner-white-bold">ZedX for {AppGlobals.CustomerSectorTitle} - {appName}</div>
                                    <div className="right-aligned-text"><Logout onLogout={props.onLogout} /></div>
                                </div>
                                <div className="container">
                                    <HashRouter>
                                        <div className={`navigation ${isNavigationVisible ? 'show' : ''}`}>
                                            {!uc.userContext.UserId &&
                                                <CrLoadingOverlay isLoading={true} />
                                                ||
                                                <CorporateWorkflowsMenu {...routeProps} onLinkClicked={toggleNavigation} />
                                            }
                                        </div>
                                        <div className="content">
                                            <Switch>
                                                <Route exact path="/corporate-workflows/welcome" {...routeProps}>
                                                    <App03WorkflowsWelcome />
                                                </Route>
                                                <Route path="/corporate-workflows/workflows" {...routeProps}>
                                                    <App03WorkflowsAdmin {...routeProps} entity="Workflows" />
                                                </Route>
                                                <Route path="/corporate-workflows/workflow-users/:workflowIdForWorkflowUsers" {...routeProps}>
                                                    <App03WorkflowsAdmin {...routeProps} entity="WorkflowUsers" />
                                                </Route>
                                                <Route path="/corporate-workflows/workflow-tasks/:workflowIdForTasks" {...routeProps}>
                                                    <WorkflowTasks {...routeProps} />
                                                </Route>
                                                <Route path="/not-authorised">
                                                    <NotAuthorised />
                                                </Route>
                                                <Route path="*">
                                                    <NotFound />
                                                </Route>
                                            </Switch>
                                        </div>
                                    </HashRouter>
                                </div>
                            </div>
                        </OrbUserContext.Provider>
                    </DataContext.Provider>
                </IntegrationContext.Provider>
            </ErrorBoundary>
        </React.Fragment>
    );
};

export const CorporateWorkflowsAppWithErrorHandling = withErrorHandling(CorporateWorkflowsApp);