import * as React from 'react';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import * as types from '../../types';
import GenExport from '../export/GenExport';

export interface IGiaaReportClsProps extends types.IBaseComponentProps {
}

export interface IGiaaReportClsState {
}
export class GiaaReportClsState implements IGiaaReportClsState {
}

export default class GiaaReportCls extends React.Component<IGiaaReportClsProps, GiaaReportClsState> {
    constructor(props: IGiaaReportClsProps, state: IGiaaReportClsState) {
        super(props);
        this.state = new GiaaReportClsState();
    }

    public render(): React.ReactElement<IGiaaReportClsProps> {
        return (
            <React.Fragment>
                <Pivot>
                    <PivotItem headerText="Export to Excel" itemKey="Export to Excel">
                        {this.renderGenExport()}
                    </PivotItem>
                </Pivot>
            </React.Fragment>
        );
    }

    private renderGenExport(): React.ReactElement<types.IWebPartComponentProps> {

        const isSuperUserPermission: boolean = this.isSuperUser();
        return (
            <div>
                <div style={{ paddingTop: "10px" }}>
                    {(isSuperUserPermission === true) &&
                        <GenExport
                            {...this.props}
                            moduleName="GIAA"

                        />
                    }
                    {
                        (isSuperUserPermission === false) &&
                        <div style={{ fontSize: '14px', color: 'navy', fontStyle: 'italic', paddingTop: '8px', paddingLeft: '5px' }}>
                            Export to Excel function is only available to the Super User.
                        </div>
                    }

                </div>
            </div>
        );

    }

    private isSuperUser(): boolean {
        return this.props.userPermissions.UserIsSystemAdmin()
            || this.props.userPermissions.UserIsGIAAActionsSuperUser();
    }
}