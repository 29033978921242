import { EntityWithStatusService } from './EntityWithStatusService';
import { ContextService } from './ContextService';
import { IWorkStream, IEntityChildren, IEntityDescription1 } from '../types';
import { EntityStatus } from '../refData/EntityStatus';
import axios from 'axios';
import { AppGlobals } from '../AppGlobals';

export class WorkStreamService extends EntityWithStatusService<IWorkStream> {
    public readonly parentEntities = ['Project', 'LeadUser'];
    protected childrenEntities = ['Contributors($expand=ContributorUser)', 'Attributes($expand=AttributeType)'];

    constructor() {
        super(`/WorkStreams`);
    }

    public readAllForList(includeClosedWorkStreams?: boolean, projectID?: number): Promise<IWorkStream[]> {
        return this.readAll(
            `?$select=ID,Title,WorkStreamCode`
            + `&$orderby=Title`
            + `&$expand=EntityStatus($select=Title),Project($select=Title),LeadUser($select=Title),Contributors($select=ContributorUser;$expand=ContributorUser($select=Title))`
            + `&$filter=Project/CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosedWorkStreams ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
            + (projectID ? ` and ProjectID eq ${projectID}` : '')
        );
    }

    public async readForView(workStreamID: number): Promise<IWorkStream> {
        return this.read(workStreamID, false, false, ['Project($select=Title)', 'LeadUser($select=Title,EmailAddress)', 'Contributors($select=ContributorUser;$expand=ContributorUser($select=Title,EmailAddress))', 'Attributes($expand=AttributeType($select=Title))']);
    }

    public readAllForLookup(includeClosedWorkStreams?: boolean): Promise<IWorkStream[]> {
        return this.readAll(
            `?$select=ID,Title,ProjectID`
            + `&$orderby=Title&$filter=Project/CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosedWorkStreams ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public readMyWorkStreams(): Promise<IWorkStream[]> {
        const username = encodeURIComponent(ContextService.Username());
        const filters = [
            `LeadUser/Username eq '${username}'`,
            `Contributors/any(c: c/ContributorUser/Username eq '${username}')`
        ];
        return this.readAll(`?$filter=EntityStatusID eq ${EntityStatus.Open} and (${filters.join(' or ')})`);
    }

    public readProjectWorkStreams(projectId: number): Promise<IWorkStream[]> {
        return this.readAll(
            `?$expand=LeadUser,Attributes($expand=AttributeType),RagOption,Contributors`
            + `&$filter=EntityStatusID eq ${EntityStatus.Open} and ProjectID eq ${projectId}`
            + `&$orderby=Title`
        );
    }

    public readProjectMilestones(projectId: number): Promise<IWorkStream[]> {
        return this.readAll(
            `?$filter=ProjectID eq ${projectId}`
            + `&$expand=Milestones($expand=WorkStream,LeadUser,Attributes($expand=AttributeType),Contributors;$filter=EntityStatusID eq ${EntityStatus.Open};$orderby=MilestoneCode,Title)`
        );
    }

    public async entityChildren(id: number): Promise<IEntityChildren[]> {
        const workStreamUrl = `${this.entityUrl}(${id})`;
        const milestones = (await this.axiosGet(`${workStreamUrl}/Milestones?$select=ID&$top=10`)).data.value;
        const updates = (await this.axiosGet(`${workStreamUrl}/WorkStreamUpdates?$select=ID&$top=10`)).data.value;
        return [
            { ChildType: 'Milestones', CanBeAdopted: true, ChildIDs: (await milestones).map((m: { ID: any; }) => m.ID) },
            { ChildType: 'Work stream updates', CanBeAdopted: false, ChildIDs: (await updates).map((w: { ID: any; }) => w.ID) }
        ];
    }

    public async countWorkStreamsForProject(projectID: number): Promise<number> {
        if (projectID) {
            return (await this.readAll(`?$select=ID&$filter=EntityStatusID eq ${EntityStatus.Open} and ProjectID eq ${projectID}`))?.length;
        }
        return Promise.resolve(0);
    }

    public async readTitlesForWorkStreamProjectDirectorateAndGroup(entityPlural: string, entitySingular: string, workStreamID: number): Promise<IEntityDescription1> {
        const entityDescription1: IEntityDescription1 = {
            Description1ForList: '',
            Description1ForForm: ''
        };
        const res = await this.readAll(`?$filter=ID eq ${workStreamID}&$expand=Project($select=Title;$expand=Directorate($select=Title;$expand=OrgLevel1($select=Title)))&$select=Title`);
        if (res.length > 0) {
            entityDescription1.Description1ForList = `${entityPlural} in work stream <span style='font-style: italic'>${res[0].Title}</span>, in project ${res[0].Project.Title}, ${res[0].Project.Directorate.Title}, ${res[0].Project.Directorate.OrgLevel1.Title}`;
            entityDescription1.Description1ForForm = `${entitySingular} in work stream <span style='font-style: italic'>${res[0].Title}</span>, in project ${res[0].Project.Title}, ${res[0].Project.Directorate.Title}, ${res[0].Project.Directorate.OrgLevel1.Title}`;
        }

        return entityDescription1;
    }
}