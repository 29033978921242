import * as React from 'react';
import * as types from '../../types';
import * as services from '../../services';
import { FilteredMainList } from './FilteredMainList';
import { IEntity } from '../../types';
import { IUpdatesListColumn, ColumnDisplayTypes } from '../../types/UpdatesListColumn';
import { CrLoadingOverlay } from '../cr/CrLoadingOverlay';
import { ConfirmDialog } from '../cr/ConfirmDialog';
import { IObjectWithKey, Selection } from '@fluentui/react/lib/DetailsList';
import styles from '../../styles/cr.module.scss';

export interface IMainListProps extends types.IBaseComponentProps {
    filterText?: string;
    onChangeFilterText: (value: string) => void;
    onItemTitleClick: (ID: number, title: string, filteredItems: any[]) => void;
    onMoveToLeaving?: (ID: number, caseId: number) => void;
    onCreateExtension?: (ID: number, caseId: number) => void;
    onAfterArchived?: () => void;
    currentUserId: number;
    superUserPermission: boolean;
    createPermission: boolean;
    caseType: string;
    listRefreshCounter?: number;
}

export interface IMainListState<T> {
    SelectedEntity: number;
    SelectedEntityTitle: string;
    SelectedEntityChildren: number;
    ShowForm: boolean;
    EnableEdit?: boolean;
    EnableDelete?: boolean;
    HideDeleteDialog: boolean;
    HideArchiveDialogue: boolean;
    HideDeleteDisallowed: boolean;
    ShowChildForm: boolean;
    CurrentPage?: number;
    NextPageAvailable?: boolean;
    Entities: T[];
    Loading: boolean;
    ListFilterText?: string;
    InitDataLoaded: boolean;
    MoveToLeavingPermission: boolean;
    CreateExtensionPermission: boolean;
    DeletePermission: boolean;
    ArchivedPermission: boolean;
}

export class MainListState<T> implements IMainListState<T> {
    public SelectedEntity = null;
    public SelectedEntityTitle: string = null;
    public SelectedEntityChildren = null;
    public ShowForm = false;
    public HideDeleteDialog = true;
    public HideArchiveDialogue = true;
    public HideDeleteDisallowed = true;
    public EnableEdit = false;
    public EnableDelete = false;
    public ShowChildForm = false;
    public CurrentPage = 1;
    public NextPageAvailable = false;
    public Entities: T[] = [];
    public Loading = false;
    public ListFilterText = null;
    public InitDataLoaded = false;
    public MoveToLeavingPermission = false;
    public CreateExtensionPermission = false;
    public DeletePermission = false;
    public ArchivedPermission = false;
}

export default class MainList extends React.Component<IMainListProps, IMainListState<IEntity>> {
    private _selection: Selection;
    private mainService: services.CLCaseService = new services.CLCaseService();
    private workerService: services.CLWorkerService = new services.CLWorkerService();
    private ChildEntityName: { Plural: string, Singular: string } = { Plural: 'Recommendations', Singular: 'Recommendation' };

    private listColumns: IUpdatesListColumn[] = [
        {
            key: 'ID',
            name: 'ID',
            fieldName: 'ID',
            minWidth: 1,
            isResizable: true,
            columnDisplayType: ColumnDisplayTypes.Hidden,
        },
        {
            key: 'CaseId',
            name: 'CaseId',
            fieldName: 'CaseId',
            minWidth: 1,
            isResizable: true,
            columnDisplayType: ColumnDisplayTypes.Hidden,
        },

        {
            key: 'CaseRef',
            name: 'Case Ref',
            fieldName: 'CaseRef',
            minWidth: 80,
            maxWidth: 80,
            isResizable: true,
            isMultiline: true,
            headerClassName: styles.bold,
        },
        {
            key: 'Dept',
            name: 'Dept',
            fieldName: 'DeptTransferringTo',
            minWidth: 50,
            maxWidth: 70,
            isResizable: true,
            isMultiline: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },
        {
            key: 'Title1',
            name: 'Title',
            fieldName: 'Title1',
            minWidth: 100,
            maxWidth: 160,
            isResizable: true,
            isMultiline: true,
            headerClassName: styles.bold,
        },
        {
            key: 'Title2',
            name: 'Title',
            fieldName: 'Title2',
            minWidth: 230,
            maxWidth: 230,
            isResizable: true,
            isMultiline: true,
            headerClassName: styles.bold,
            columnDisplayType: ColumnDisplayTypes.Hidden,
        },
        {
            key: 'Stage',
            name: 'Stage',
            fieldName: 'Stage',
            minWidth: 80,
            maxWidth: 80,
            isResizable: true,
            isMultiline: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },

        {
            key: 'StageActions1',
            name: 'Stage Actions',
            fieldName: 'StageActions1',
            minWidth: 110,
            maxWidth: 110,
            isResizable: true,
            isMultiline: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },

        {
            key: 'StageActions2',
            name: 'Stage Actions',
            fieldName: 'StageActions2',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            isMultiline: true,
            headerClassName: styles.bold,
            columnDisplayType: ColumnDisplayTypes.Hidden,
        },

        {
            key: 'Worker',
            name: 'Worker',
            fieldName: 'Worker',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            isMultiline: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },

        {
            key: 'CreatedOn',
            name: 'CreatedOn',
            fieldName: 'CreatedOn',
            minWidth: 85,
            maxWidth: 85,
            isResizable: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },
        {
            key: 'HiringManager',
            name: 'Hiring Manager',
            fieldName: 'HiringManager',
            minWidth: 100,
            maxWidth: 100,
            isMultiline: true,
            isResizable: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },
        {
            key: 'StartDate',
            name: 'StartDate',
            fieldName: 'StartDate',
            minWidth: 85,
            maxWidth: 85,
            isResizable: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },
        {
            key: 'EndDate',
            name: 'EndDate',
            fieldName: 'EndDate',
            minWidth: 85,
            maxWidth: 85,
            isResizable: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },
        {
            key: 'HiringManagerId',
            name: 'HiringManagerId',
            fieldName: 'HiringManagerId',
            minWidth: 1,
            maxWidth: 1,
            headerClassName: styles.bold,
            columnDisplayType: ColumnDisplayTypes.Hidden,
        },
        {
            key: 'EngagedChecksDone',
            name: 'EngagedChecksDone',
            fieldName: 'EngagedChecksDone',
            minWidth: 1,
            maxWidth: 1,
            headerClassName: styles.bold,
            columnDisplayType: ColumnDisplayTypes.Hidden,
        },
    ];


    constructor(props: IMainListProps, state: IMainListState<IEntity>) {
        super(props);
        this.state = new MainListState<IEntity>();

        this._selection = new Selection({
            onSelectionChanged: () => {
                if (this._selection.getSelectedCount() === 1) {

                    const sel = this._selection.getSelection()[0];
                    const key = Number(sel.key);
                    const title: string = sel["Title"];
                    const hiringMgrUserId: number = Number(sel["HiringManagerId"]);
                    const engagedChecksDone: boolean = (sel["EngagedChecksDone"] === '1');

                    let moveToLeavingPermission: boolean = false;
                    if (engagedChecksDone === true && (this.props.superUserPermission === true || (this.props.currentUserId === hiringMgrUserId))) {
                        moveToLeavingPermission = true;
                    }

                    let deletePermission: boolean;
                    const stage: string = sel["Stage"];
                    if (stage === "Draft" && (this.props.superUserPermission === true || (this.props.currentUserId === hiringMgrUserId))) {
                        deletePermission = true;
                    }

                    let archivedPermission: boolean;
                    if (stage !== "Draft" && (this.props.superUserPermission === true || (this.props.currentUserId === hiringMgrUserId))) {
                        archivedPermission = true;
                    }

                    this.setState({ SelectedEntity: key, SelectedEntityTitle: title, EnableEdit: true, EnableDelete: true, MoveToLeavingPermission: moveToLeavingPermission, CreateExtensionPermission: moveToLeavingPermission, DeletePermission: deletePermission, ArchivedPermission: archivedPermission });
                }
                else {
                    this.setState({ SelectedEntity: null, SelectedEntityTitle: null, EnableEdit: false, EnableDelete: false, MoveToLeavingPermission: false, CreateExtensionPermission: false, DeletePermission: false, ArchivedPermission: false, });
                }
            }
        });
    }

    public render(): React.ReactElement<IMainListProps> {

        return (
            <div className={`${styles.cr}`}>
                <div style={{ position: 'relative' }}>
                    <CrLoadingOverlay isLoading={this.state.Loading} />
                    {this.renderList()}
                    <ConfirmDialog hidden={this.state.HideDeleteDialog} title={`Are you sure you want to delete this case?`} content={`This action cannot be reversed.`} confirmButtonText="Delete" handleConfirm={this.onDelete} handleCancel={this.toggleDeleteConfirm} />
                    <ConfirmDialog hidden={this.state.HideArchiveDialogue} title={`Are you sure you want to archive this case?`} content={`This action cannot be reversed.`} confirmButtonText="Archive" handleConfirm={this.onArchive} handleCancel={this.toggleArchiveConfirm} />
                </div>
            </div>
        );
    }

    private renderList() {
        const listColumns = this.getColumns();
        const listColumnsForData = this.getColumnsForData();
        let items: IObjectWithKey[] = this.state.Entities.map((e) => { return this.makeItem(e, listColumnsForData); });

        return (
            <FilteredMainList
                onItemTitleClick={this.props.onItemTitleClick}
                columns={listColumns}
                items={items}
                filterText={this.props.filterText}
                onFilterChange={this.props.onChangeFilterText}
                selection={this._selection}
                onAdd={this.addItem}
                editDisabled={!this.state.EnableEdit}
                deleteDisabled={!this.state.EnableDelete}
                createPermission={true}
                caseType={this.props.caseType}
                moveToLeavingPermission={this.state.MoveToLeavingPermission}
                onMoveToLeaving={this.moveToLeaving}
                createExtensionPermission={this.state.CreateExtensionPermission}
                onCreateExtension={this.createExtension}
                deletePermission={this.state.DeletePermission}
                onDelete={this.toggleDeleteConfirm}
                archivedPermission={this.state.ArchivedPermission}
                onArchive={this.toggleArchiveConfirm}
            />
        );
    }

    private makeItem = (e: IEntity, listColumns: IUpdatesListColumn[]): any => {
        let item: any = { key: e["ID"] };
        listColumns.map((c) => {
            let fieldContent: string = String(e[c.fieldName]);
            item = {
                [c.fieldName]: fieldContent,
                ...item
            };
        });
        return item;
    }

    private getColumns(): IUpdatesListColumn[] {
        let listColumns: IUpdatesListColumn[];
        listColumns = this.listColumns.filter(c => c.columnDisplayType !== ColumnDisplayTypes.Hidden);
        return listColumns;
    }

    private getColumnsForData(): IUpdatesListColumn[] {
        const listColumns: IUpdatesListColumn[] = this.listColumns;
        return listColumns;
    }

    private loadData = (): void => {
        this.setState({ Loading: true });

        const read: Promise<IEntity[]> = this.mainService.readAllWithFilters(this.props.caseType);
        read.then((entities: any): void => {
            this.setState({
                Loading: false, Entities: entities,
            });

        }, (err) => this.errorLoadingData(err));
    }
    private errorLoadingData = (err: any, entityName?: string): void => {
        this.setState({ Loading: false });
        if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading ${entityName || 'items'}`, err.message);
    }
    public componentDidMount(): void {
        this.loadData();
    }

    public componentDidUpdate(prevProps: IMainListProps): void {
        if (prevProps.listRefreshCounter !== this.props.listRefreshCounter) {
            this._selection.setAllSelected(false);
            this.loadData();
        }
    }

    private addItem = (): void => {
        this.props.onItemTitleClick(0, null, null);
    }

    private onDelete = (): void => {
        this.setState({ HideDeleteDialog: true });
        if (this.state.SelectedEntity) {
            this.mainService.delete(this.state.SelectedEntity).then(() => {
                this.loadData();
                this.props.onAfterArchived();
            }, (err) => {
                if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error deleting item ${this.state.SelectedEntity}`, err.message);
            });
        }
    }

    private onArchive = (): void => {
        this.setState({ HideArchiveDialogue: true });
        if (this.state.SelectedEntity) {
            this.workerService.archive(this.state.SelectedEntity).then(() => {
                this.loadData();
                this.props.onAfterArchived();
            }, (err) => {
                if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error archiving item ${this.state.SelectedEntity}`, err.message);

            });
        }
    }

    private moveToLeaving = (): void => {
        const record = this.state.Entities.filter(x => x.ID === this.state.SelectedEntity)[0];
        this.props.onMoveToLeaving(this.state.SelectedEntity, Number(record['CaseId']));
    }

    private createExtension = (): void => {
        const record = this.state.Entities.filter(x => x.ID === this.state.SelectedEntity)[0];
        this.props.onCreateExtension(this.state.SelectedEntity, Number(record['CaseId']));
    }

    private toggleDeleteConfirm = (): void => {
        this.setState({ HideDeleteDialog: !this.state.HideDeleteDialog });
    }
    private toggleArchiveConfirm = (): void => {
        this.setState({ HideArchiveDialogue: !this.state.HideArchiveDialogue });
    }
}
