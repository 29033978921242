import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    IApp03Workflow, EntityValidations, ISpecificEntityFormProps, App03Workflow, App03WorkflowUser, IApp03WorkflowStage
} from '../../types';
import { LookupService } from '../../services';
import styles from '../../styles/cr.module.scss';
import { CrUserPicker } from '../cr/CrUserPicker';
import { CrTextField } from '../cr/CrTextField';
import { CrDropdown } from '../cr/CrDropdown';
import { EntityForm } from '../EntityForm';
import { DataContext } from '../DataContext';
import { IntegrationContext } from '../IntegrationContext';
import { WorkflowRefColumn, WorkflowType } from '../../refData/WorkflowType';

class WorkflowValidations extends EntityValidations {
    public Owners: string = null;
    public RefPrefix: string = null;
}

export const WorkflowForm = (props: ISpecificEntityFormProps): React.ReactElement => {
    const { disableGroupManagement } = useContext(IntegrationContext);
    const { dataServices, lookupData, loadLookupData: { entityStatuses, users: { all: allUsers } } } = useContext(DataContext);

    useMemo(() => entityStatuses(), [entityStatuses]);
    useMemo(() => allUsers(), [allUsers]);

    const [workflowStages, setWorkflowStages] = useState<IApp03WorkflowStage[]>([]);

    useEffect(() => {
        const fetchStages = async () => {
            if (props.entityId > 0) {
                try {
                    const stages = await dataServices.app03WorkflowStageService.readWorkflowStages(props.entityId);
                    setWorkflowStages(stages);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };

        fetchStages();
    }, [props.entityId]);


    const validateEntity = (workflow: IApp03Workflow): Promise<WorkflowValidations> => {
        const errors = new WorkflowValidations();

        if (workflow.Title === null || workflow.Title === '') {
            errors.Title = `Workflow name is required`;
            errors.Valid = false;
        }
        else {
            errors.Title = null;
        }
        if (workflow.App03WorkflowUsers === undefined || workflow.App03WorkflowUsers === null || workflow.App03WorkflowUsers.filter(x => x.IsAdmin === true).length < 1) {
            errors.Owners = `At least one owner is required`;
            errors.Valid = false;
        }
        else {
            errors.Owners = null;
        }
        if (workflow.RefColumnID === 1 && (workflow.RefPrefix === null || workflow.RefPrefix === '')) {
            errors.RefPrefix = "Ref Prefix is required"
            errors.Valid = false;
        }
        else {
            errors.RefPrefix = null;
        }


        return Promise.resolve(errors);
    };


    const workflowTypesArray = Object.keys(WorkflowType)
        .filter(key => !isNaN(Number(WorkflowType[key])))
        .map(key => ({ ID: WorkflowType[key], Title: key }));

    const workflowRefColumnArray = Object.keys(WorkflowRefColumn)
        .filter(key => !isNaN(Number(WorkflowRefColumn[key])))
        .map(key => ({ ID: WorkflowRefColumn[key], Title: key }));

    return (
        <EntityForm<IApp03Workflow, WorkflowValidations>
            {...props}
            entityName='Workflow'
            renderFormFields={(changeHandlers, formState) => {
                const { FormData: wf, ValidationErrors: errors } = formState;
                return (
                    <div>
                        <CrTextField
                            label="Name"
                            maxLength={100}
                            className={styles.formField}
                            required={true}
                            value={wf.Title}
                            onChange={(ev, newValue) => changeHandlers.changeTextField(newValue, 'Title')}
                            errorMessage={errors.Title}
                        />

                        <CrDropdown
                            label="Type"
                            className={styles.formField}
                            options={LookupService.entitiesToSelectableOptions(workflowTypesArray)}
                            selectedKey={wf.WorkflowTypeID}
                            onChange={(_, o) => changeHandlers.changeDropdown(o, 'WorkflowTypeID')}
                            disabled={true}
                        />

                        <CrTextField
                            label="Description"
                            multiline
                            rows={6}
                            maxLength={10000}
                            className={styles.formField}
                            value={wf.Description}
                            onChange={(ev, newValue) => changeHandlers.changeTextField(newValue, 'Description')}

                        />

                        <CrUserPicker
                            label="Owner(s)"
                            className={styles.formField}
                            users={lookupData.Users?.All}
                            itemLimit={5}
                            required={true}
                            errorMessage={errors.Owners}
                            selectedUsers={wf.App03WorkflowUsers?.filter(u => u.IsAdmin === true).map(u => u.UserID)}
                            onChange={v => changeHandlers.changeMultiUserPicker(v, 'App03WorkflowUsers', new App03WorkflowUser(true), 'UserID')}
                        />

                        {props.entityId > 0 && workflowStages.length > 0 &&
                            <CrDropdown
                                label="New task stage"
                                className={styles.formField}
                                options={LookupService.entitiesToSelectableOptions(workflowStages)}
                                selectedKey={wf.NewTaskDefaultStageID}
                                onChange={(_, o) => changeHandlers.changeDropdown(o, 'NewTaskDefaultStageID')}
                            />
                        }
                        <CrDropdown
                            label="Ref Column"
                            className={styles.formField}
                            options={LookupService.entitiesToSelectableOptions(workflowRefColumnArray)}
                            selectedKey={wf.RefColumnID}
                            onChange={(_, o) => changeHandlers.changeDropdown(o, 'RefColumnID')}
                            disabled={true}
                        />

                        <CrTextField
                            label="Ref Prefix"
                            maxLength={5}
                            className={styles.formField}
                            required={true}
                            value={wf.RefPrefix}
                            onChange={(ev, newValue) => changeHandlers.changeTextField(newValue, 'RefPrefix')}
                            errorMessage={errors.RefPrefix}
                        />

                        <CrDropdown
                            label="Status"
                            className={styles.formField}
                            disabled={disableGroupManagement}
                            options={LookupService.entitiesToSelectableOptions(lookupData.EntityStatuses)}
                            selectedKey={wf.EntityStatusID}
                            onChange={(_, o) => changeHandlers.changeStatusDropdown(o, 'EntityStatusID')}
                        />

                    </div>
                );
            }}
            loadEntity={id => dataServices.app03WorkflowService.read(id, true, true)}
            loadNewEntity={() => new App03Workflow()}
            loadEntityValidations={() => new WorkflowValidations()}
            onValidateEntity={validateEntity}
            onCreate={w => dataServices.app03WorkflowService.create(w)}
            onUpdate={g => dataServices.app03WorkflowService.update(g.ID, g)}
            parentEntities={dataServices.app03WorkflowService.parentEntities}
            childEntities={[
                {
                    ObjectParentProperty: 'App03WorkflowUsers',
                    ParentIdProperty: 'App03WorkflowID',
                    ChildIdProperty: 'UserID',
                    ChildService: dataServices.app03WorkflowUserService
                },
            ]}
        />
    );
};
