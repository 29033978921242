export enum Role {
    Admin = 1,
    RiskManager,
    PartnerOrganisationAdmin,
    FinancialRiskAdmin,
    CustomSectionsAdmin,
    ExternalUser,
    GIAAStaff,
    ControlsAssuranceSuperUser,
    GovernanceSuperUser,
    GIAAActionsSuperUser,
    NAOTrackerSuperUser,
    NAOStaff,
    PACStaff,
    MASuperUser,
    MAViewOtherActionOwners,
    CLSuperUser,
    CLViewer,
    CanSendEmails,
    CanManageUsers
}