import React, { useContext } from 'react';
import { ErrorBoundary } from '../ErrorBoundary';
import { IWithErrorHandlingProps, withErrorHandling } from '../withErrorHandling';
import { IUseApiProps } from '../useApi';
import GiaaPropertiesCls from './GiaaPropertiesCls';
import { OrbUserContext } from '../OrbUserContext';

export interface IGiaaPropertiesProps extends IUseApiProps, IWithErrorHandlingProps {
}

const GiaaProperties = (props: IGiaaPropertiesProps) => {
    const { userPermissions } = useContext(OrbUserContext);
    return (
        <ErrorBoundary>
            <GiaaPropertiesCls
                userPermissions={userPermissions}
                {...props}
            />
        </ErrorBoundary>
    );
};

export default withErrorHandling(GiaaProperties);
