import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IPanelHeaderRenderer, IPanelProps, Panel, PanelType } from '@fluentui/react/lib/Panel';
import { CrLoadingOverlay } from '../cr/CrLoadingOverlay';
import { ViewFormCommandBar } from '../cr/ViewFormCommandBar';
import { ICorporateRisk, IEntity, ILookupData } from '../../types';
import { renderDate } from '../cr/ListRenderers';
import styles from '../../styles/cr.module.scss';
import { OrbUserContext } from '../OrbUserContext';
import { DataContext } from '../DataContext';

interface ProjectRiskViewProps {
    entityName?: string;
    showForm?: boolean;
    entityId?: number;
    onClosed?: () => void;
    loadEntity: (entityId: number) => Promise<ICorporateRisk>;
}

export const ProjectRiskView: React.FC<ProjectRiskViewProps> = ({ showForm, entityName, entityId, loadEntity, onClosed }) => {
    const [formData, setFormData] = useState<ICorporateRisk>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const { orbConfig } = useContext(OrbUserContext);
    const { lookupData, loadLookupData } = useContext(DataContext);

    useEffect(() => {
        // Call loadLookupData.departmentalObjectives() when the component mounts
        loadLookupData.departmentalObjectives();
    }, []); // Empty dependency array ensures this runs only once

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const entity = await loadEntity(entityId);
                setFormData(entity);
                setLoading(false);
            } catch (error) {
                console.error('Error loading entity:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [loadEntity]);

    const riskStaff = useMemo(() => {
        if (!formData) return '';

        const staffRoles = [
            { role: 'Risk owner (approver)', users: formData.RiskOwnerUser ? [`${formData.RiskOwnerUser.Title} (${formData.RiskOwnerUser.EmailAddress})`] : [] },
            { role: 'Alternative risk approver', users: formData.ReportApproverUser ? [`${formData.ReportApproverUser.Title} (${formData.ReportApproverUser.EmailAddress})`] : [] },
            {
                role: 'Contributors',
                users: formData.Contributors && formData.Contributors.some(c => c.ContributorUser != null)
                    ? formData.Contributors.map(c => `${c.ContributorUser?.Title} (${c.ContributorUser?.EmailAddress})`)
                    : [],
            },
        ];

        const formattedStaffRoles = staffRoles
            .filter(role => role.users.length > 0)
            .map(role => `<div style='display:flex'><div style='font-weight:bold'>-&nbsp;</div><div>${role.role}: ${role.users.join(', ')}</div></div>`)
            .join('');

        return formattedStaffRoles;
    }, [formData]);



    const headerText = useMemo(() => {
        if (!formData) return entityName;

        return `${entityName} - ${formData.Title}`;
    }, [entityName, formData]);

    const formattedRiskEventDescription = useMemo(() => {
        if (!formData) return null;

        return formData.RiskEventDescription ? formData.RiskEventDescription.replace(/\n/g, '<br>') : null;
    }, [formData]);

    const formattedRiskCauseDescription = useMemo(() => {
        if (!formData) return null;

        return formData.RiskCauseDescription ? formData.RiskCauseDescription.replace(/\n/g, '<br>') : null;
    }, [formData]);

    const formattedRiskImpactDescription = useMemo(() => {
        if (!formData) return null;

        return formData.RiskImpactDescription ? formData.RiskImpactDescription.replace(/\n/g, '<br>') : null;
    }, [formData]);

    const titleForDepartmentalObjective = useMemo(() => {
        if (!formData || !formData.DepartmentalObjectiveID) {
            return 'N/A';
        }

        const departmentalObjective = lookupData.DepartmentalObjectives.find((x: IEntity) => x.ID === formData.DepartmentalObjectiveID);

        return departmentalObjective ? departmentalObjective.Title : 'N/A';
    }, [formData, lookupData.DepartmentalObjectives]);

    let owner: string = '';
    if (formData?.RiskRegisterID === 0)
        owner = 'Dept';
    else if (formData?.RiskRegisterID === 1)
        owner = `${orbConfig.OrgL1Label} - ${formData.OwnerL1?.Title}`;
    else if (formData?.RiskRegisterID === 2)
        owner = `${orbConfig.OrgL2Label} - ${formData.OwnerL2?.Title}`;
    else if (formData?.RiskRegisterID === 3)
        owner = `${orbConfig.OrgL3Label} - ${formData.OwnerL3?.Title}`;
    else
        owner = 'N/A';

    let managedBy: string = '';
    if (formData?.ManagedByTypeID === 2)
        managedBy = `${orbConfig.OrgL2Label} - ${formData.ManagedByL2?.Title}`;
    else if (formData?.ManagedByTypeID === 3)
        managedBy = `${orbConfig.OrgL3Label} - ${formData.ManagedByL3?.Title}`;
    else
        managedBy = 'N/A';


    return (
        <Panel
            className={styles.cr}
            isOpen={showForm}
            headerText={headerText}
            type={PanelType.medium}
            hasCloseButton={false}
            onRenderHeader={(props: IPanelProps, defaultRender: IPanelHeaderRenderer) =>
                <div style={{ marginBottom: '20px' }} className={styles.panelHeaderText}>{defaultRender(props)}</div>
            }
            onRenderNavigation={() =>
                <ViewFormCommandBar onClose={onClosed} />
            }
        >
            <div className={styles.cr}>
                <CrLoadingOverlay isLoading={loading} opaque={true} />
                {formData && (
                    <div>
                        <div className={styles.viewFormFieldLabel}>Register</div>
                        <div className={styles.viewFormFieldData}>
                            {owner}
                        </div>

                        <div className={styles.viewFormFieldLabel}>Managed By</div>
                        <div className={styles.viewFormFieldData}>{managedBy}</div>

                        <div className={styles.viewFormFieldLabel}>Staff</div>
                        <div className={styles.viewFormFieldData}>
                            {riskStaff ? (
                                <div dangerouslySetInnerHTML={{ __html: riskStaff }} />
                            ) : (
                                'N/A'
                            )}
                        </div>

                        <div className={styles.viewFormFieldLabel}>Risk type</div>
                        <div className={styles.viewFormFieldData}>
                            {formData.RiskRiskTypes && formData.RiskRiskTypes.length > 0 ? (
                                formData.RiskRiskTypes.map(rrt => rrt.RiskType?.Title).join(', ')
                            ) : (
                                'N/A'
                            )}
                        </div>

                        <div className={styles.viewFormFieldLabel}>Date risk created</div>
                        <div className={styles.viewFormFieldData}>
                            {renderDate(formData.CreatedDate)}
                        </div>

                        <div className={styles.viewFormFieldLabel}>Attributes</div>
                        <div className={styles.viewFormFieldData}>
                            {formData.Attributes && formData.Attributes.length > 0 ? (
                                formData.Attributes.map(a => a.AttributeType?.Title).join(', ')
                            ) : (
                                'N/A'
                            )}
                        </div>

                        <div className={styles.viewFormFieldLabel}>Risk event description</div>
                        <div className={styles.viewFormFieldData}>
                            {formattedRiskEventDescription ? (
                                <div dangerouslySetInnerHTML={{ __html: formattedRiskEventDescription }} />
                            ) : (
                                'N/A'
                            )}
                        </div>
                        <div className={styles.viewFormFieldLabel}>Risk cause description</div>
                        <div className={styles.viewFormFieldData}>
                            {formattedRiskCauseDescription ? (
                                <div dangerouslySetInnerHTML={{ __html: formattedRiskCauseDescription }} />
                            ) : (
                                'N/A'
                            )}
                        </div>
                        <div className={styles.viewFormFieldLabel}>Risk impact description</div>
                        <div className={styles.viewFormFieldData}>
                            {formattedRiskImpactDescription ? (
                                <div dangerouslySetInnerHTML={{ __html: formattedRiskImpactDescription }} />
                            ) : (
                                'N/A'
                            )}
                        </div>
                        <div className={styles.viewFormFieldLabel}>Unmitigated probability</div>
                        <div className={styles.viewFormFieldData}>{formData.UnmitigatedRiskProbability?.Title || 'N/A'}</div>

                        <div className={styles.viewFormFieldLabel}>Unmitigated impact</div>
                        <div className={styles.viewFormFieldData}>{formData.UnmitigatedRiskImpactLevel?.Title || 'N/A'}</div>

                        <div className={styles.viewFormFieldLabel}>Target probability</div>
                        <div className={styles.viewFormFieldData}>{formData.TargetRiskProbability?.Title || 'N/A'}</div>

                        <div className={styles.viewFormFieldLabel}>Target impact</div>
                        <div className={styles.viewFormFieldData}>{formData.TargetRiskImpactLevel?.Title || 'N/A'}</div>

                        <div className={styles.viewFormFieldLabel}>Is the risk ongoing</div>
                        <div className={styles.viewFormFieldData}>{formData.RiskIsOngoing === true ? 'Yes' : 'No'}</div>

                        <div className={styles.viewFormFieldLabel}>Proximity of this risk</div>
                        <div className={styles.viewFormFieldData}>
                            {renderDate(formData.RiskProximity)}
                        </div>

                        <div className={styles.viewFormFieldLabel}>Which Departmental Objective will this most impact</div>
                        <div className={styles.viewFormFieldData}>
                            {titleForDepartmentalObjective}
                        </div>




                    </div>
                )}
            </div>
        </Panel>
    );
};