import { EntityUpdateService } from './EntityUpdateService';
import { IKeyWorkAreaUpdate } from '../types';
import { DateService } from './DateService';
import { HealthCheckService } from './HealthCheckService';

export class KeyWorkAreaUpdateService extends EntityUpdateService<IKeyWorkAreaUpdate> {
    constructor() {
        super(`/KeyWorkAreaUpdates`);
    }

    public async readLatestUpdateForPeriod(keyWorkAreaId: number, period: Date): Promise<IKeyWorkAreaUpdate> {
        //period = DateService.removeTimezoneOffset(period);
        const kwa = await this.readAll(
            `?$top=1`
            + `&$expand=UpdateUser`
            + `&$filter=KeyWorkAreaID eq ${keyWorkAreaId} and UpdatePeriod eq ${period.toISOString()}`
            + `&$orderby=UpdateDate desc`
        );

        if (kwa.length > 0)
            return kwa[0];
        else
            throw new Error('No KeyWorkAreaUpdate found.');
    }

    public async readLastSignedOffUpdateForPeriod(keyWorkAreaId: number, period: Date): Promise<IKeyWorkAreaUpdate> {
        period = DateService.removeTimezoneOffset(period);
        const kwa = await this.readAll(
            `?$top=1`
            + `&$filter=KeyWorkAreaID eq ${keyWorkAreaId} and UpdatePeriod eq ${period.toISOString()} and SignOffID ne null`
            + `&$orderby=SignOffID desc`
        );
        if (kwa.length > 0)
            return kwa[0];
        else
            throw new Error('No KeyWorkAreaUpdate found.');
    }
}