import { EntityService } from './EntityService';
import { GIAAActionOwner } from '../types';

export class GIAAActionOwnerService extends EntityService<GIAAActionOwner> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/GIAAActionOwners`);
    }
}