import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IPanelHeaderRenderer, IPanelProps, Panel, PanelType } from '@fluentui/react/lib/Panel';
import { CrLoadingOverlay } from '../cr/CrLoadingOverlay';
import { ViewFormCommandBar } from '../cr/ViewFormCommandBar';
import { IProject } from '../../types';
import { renderDate } from '../cr/ListRenderers';
import styles from '../../styles/cr.module.scss';
import { OrbUserContext } from '../OrbUserContext';
import { IWithErrorHandlingProps, withErrorHandling } from '../withErrorHandling';
import { SignOffType } from '../../refData/SignOffType';
import { useParams } from 'react-router-dom';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { CrCommandBar } from '../cr/CrCommandBar';
import { DraftReportList } from '../draftReport/DraftReportList';

interface RiskUpdatesProps extends IWithErrorHandlingProps {
    lastProjectIdForChilds?: number;
    apiConnected: boolean;
    emphasiseProjectsWithAttribute: string;
    emphasisedProjectsHeaderText: string;
    otherProjectsHeaderText: string;
}


const RiskUpdates = (props: RiskUpdatesProps) => {

    const { riskIdForUpdates } = useParams<{ riskIdForUpdates: string }>();
    const history = useHistory();

    const commandBarItems: ICommandBarItemProps[] = [
        {
            key: 'risks',
            text: 'Risks',
            iconProps: { iconName: 'MiniExpandMirrored' },
            onClick: (ev, item) => {
                
                if (props.lastProjectIdForChilds != null) {
                    history.push(`/performance-reporting/risks-for-project/${props.lastProjectIdForChilds}`);
                }
                else {
                    //request was from risk register, so go back
                    history.push('/risk-reporting/risks');
                }
            },
        },
        {
            key: 'signoff',
            text: 'Review & Sign Off',
            iconProps: { iconName: 'MiniExpand' },
            onClick: (ev, item) => {
                history.push(`/performance-reporting/sign-off-for-risk/${riskIdForUpdates}`);
            },
        }
    ];


    return (

        <div className={`${styles.updatesContainer}`}>
            <CrCommandBar items={commandBarItems} />
            <div>
                <DraftReportList
                    defaultReportType={SignOffType.Risk}
                    defaultEntityId={Number(riskIdForUpdates)}
                    {...props}
                />
            </div>


        </div>

    );
};

export default withErrorHandling(RiskUpdates);