export interface IProgressUpdateValidations {
    Valid: boolean;
    RagOptionID: string | null;
    Comment: string | null;
}

export interface IProgressUpdateWithDeliveryDatesValidations extends IProgressUpdateValidations {
    ForecastDate: string | null;
    ActualDate: string | null;
}

export class ProgressUpdateValidations implements IProgressUpdateValidations {
    public Valid = true;
    public RagOptionID = null;
    public Comment = null;
}

export class ProgressUpdateWithDeliveryDatesValidations extends ProgressUpdateValidations implements IProgressUpdateWithDeliveryDatesValidations {
    public ForecastDate = null;
    public ActualDate = null;
}