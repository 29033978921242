import { EntityService } from './EntityService';
import { IProgrammePhase } from '../types/ProgrammePhase';
import { AppGlobals } from '../AppGlobals';

export class ProgrammePhaseService extends EntityService<IProgrammePhase> {
    constructor() {
        super(`/ProgrammePhases`);
    }

    public readAllForLookup(additionalFields?: string): Promise<IProgrammePhase[]> {
        return this.readAll(
            `?$select=ID,Title${additionalFields ? `,${additionalFields}` : ''}`
            + `&$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`
        );
    }
}