export enum WorkflowType {
    General = 1,
    Linked,
}

export enum WorkflowRefColumn {
    Ref = 1,
    ExternalRef,
}

export enum TaskPriority {
    Low = 1,
    Medium,
    Normal,
    High,
    Critical
}

export const TaskPriorityColors: { [key in TaskPriority]: string } = {
    [TaskPriority.Low]: '#008000', // Green
    [TaskPriority.Medium]: '#6495ED', // Cornflower Blue
    [TaskPriority.Normal]: '#00A2E8', // Blue
    [TaskPriority.High]: '#FFA500', // Orange
    [TaskPriority.Critical]: '#ED2931' // Crimson Red
};


export const getWorkflowTypeString = (workflowTypeID: number): string => {
    if (workflowTypeID === WorkflowType.General) {
        return WorkflowType[WorkflowType.General];
    } else if (workflowTypeID === WorkflowType.Linked) {
        return WorkflowType[WorkflowType.Linked];
    }
    return '';
};

export const getTaskPriorityString = (taskPriorityID: number): string => {
    if (taskPriorityID === TaskPriority.Low)
        return TaskPriority[TaskPriority.Low];
    else if (taskPriorityID === TaskPriority.Medium)
        return TaskPriority[TaskPriority.Medium];
    else if (taskPriorityID === TaskPriority.Normal)
        return TaskPriority[TaskPriority.Normal];
    else if (taskPriorityID === TaskPriority.High)
        return TaskPriority[TaskPriority.High];
    else if (taskPriorityID === TaskPriority.Critical)
        return TaskPriority[TaskPriority.Critical];
}