export interface IReportDueDates {
    Next: Date | null;
    Previous: Date | null;
}

export class ReportDueDates implements IReportDueDates {
    public Next: Date | null = null;
    public Previous: Date | null = null;
}

