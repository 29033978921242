import { IEntityRole } from "../types/EntityRole";
import { DirectorateService } from "./DirectorateService";
import { PartnerOrganisationService } from "./PartnerOrganisationService";
import { ProjectService } from "./ProjectService";
import { CorporateRiskService } from "./CorporateRiskService";
import { UserDirectorateService } from "./UserDirectorateService";
import { UserPartnerOrganisationService } from "./UserPartnerOrganisationService";
import { UserProjectService } from "./UserProjectService";
import { FinancialRiskService } from "./FinancialRiskService";
import { PortfolioService } from "./PortfolioService";
import { ProgrammeService } from "./ProgrammeService";

export class EntityPeopleService {


    public static GetProjectEntityPeople = async (
        { projectService, userProjectService, projectId }: { projectService: ProjectService, userProjectService: UserProjectService, projectId: number }
    ): Promise<IEntityRole[]> => {
        const people: IEntityRole[] = [];
        const promProjectPeople = projectService.readProjectApprovers(projectId);
        const promAdmins = userProjectService.readProjectAdmins(projectId);
        const projectPeople = await promProjectPeople;
        const admins = await promAdmins;

        if (projectPeople) {
            if (projectPeople.SeniorResponsibleOwnerUser)
                people.push({ role: 'Approver', names: [projectPeople.SeniorResponsibleOwnerUser.Title].filter(Boolean) as string[] });
            if (projectPeople.ReportApproverUser)
                people.push({ role: 'Alternative approver', names: [projectPeople.ReportApproverUser.Title].filter(Boolean) as string[] });
            if (projectPeople.ReportingLeadUser)
                people.push({ role: 'Project reporting lead', names: [projectPeople.ReportingLeadUser.Title].filter(Boolean) as string[] });
        }
        if (admins && admins.length > 0) {
            const adminNames = admins.map(a => a?.User?.Title).filter(Boolean) as string[];
            people.push({ role: 'Project admins', names: adminNames });
        }

        return people;
    }



    public static GetDirectorateEntityPeople = async (
        { directorateService, userDirectorateService, directorateId }: { directorateService: DirectorateService, userDirectorateService: UserDirectorateService, directorateId: number }
    ): Promise<IEntityRole[]> => {
        const people: IEntityRole[] = [];
        const promDirectoratePeople = directorateService.readDirectorateApprovers(directorateId);
        const promAdmins = userDirectorateService.readDirectorateAdmins(directorateId);
        const directoratePeople = await promDirectoratePeople;
        const admins = await promAdmins;

        if (directoratePeople) {
            if (directoratePeople.DirectorUser)
                people.push({ role: 'Approver', names: [directoratePeople.DirectorUser.Title].filter(Boolean) as string[] });
            if (directoratePeople.ReportApproverUser)
                people.push({ role: 'Alternative approver', names: [directoratePeople.ReportApproverUser.Title].filter(Boolean) as string[] });
            if (directoratePeople.ReportingLeadUser)
                people.push({ role: 'Directorate reporting lead', names: [directoratePeople.ReportingLeadUser.Title].filter(Boolean) as string[] });
        }
        if (admins && admins.length > 0) {
            people.push({ role: 'Directorate admins', names: admins.map(ud => ud.User && ud.User.Title).filter(Boolean) as string[] });
        }

        return people;
    }

    public static GetPortfolioEntityPeople = async (
        { portfolioService, portfolioId }: { portfolioService: PortfolioService, portfolioId: number }
    ): Promise<IEntityRole[]> => {
        const people: IEntityRole[] = [];
        const promPortfolioPeople = portfolioService.readPortfolioApprovers(portfolioId);
        //const promAdmins = userDirectorateService.readDirectorateAdmins(directorateId);
        const portfolioPeople = await promPortfolioPeople;
        //const admins = await promAdmins;

        if (portfolioPeople) {
            if (portfolioPeople.PortfolioManagerUser)
                people.push({ role: 'Manager', names: [portfolioPeople.PortfolioManagerUser.Title].filter(Boolean) as string[] });

            // if (directoratePeople.ReportApproverUser)
            //     people.push({ role: 'Alternative approver', names: [directoratePeople.ReportApproverUser.Title].filter(Boolean) as string[] });
            // if (directoratePeople.ReportingLeadUser)
            //     people.push({ role: 'Directorate reporting lead', names: [directoratePeople.ReportingLeadUser.Title].filter(Boolean) as string[] });
        }
        // if (admins && admins.length > 0) {
        //     people.push({ role: 'Directorate admins', names: admins.map(ud => ud.User && ud.User.Title).filter(Boolean) as string[] });
        // }

        return people;
    }

    public static GetProgrammeEntityPeople = async (
        { programmeService, programmeId }: { programmeService: ProgrammeService, programmeId: number }
    ): Promise<IEntityRole[]> => {
        const people: IEntityRole[] = [];
        const promProgrammePeople = programmeService.readProgrammeApprovers(programmeId);
        //const promAdmins = userDirectorateService.readDirectorateAdmins(directorateId);
        const programmePeople = await promProgrammePeople;
        //const admins = await promAdmins;

        if (programmePeople) {
            if (programmePeople.ProgrammeManagerUser)
                people.push({ role: 'Manager', names: [programmePeople.ProgrammeManagerUser.Title].filter(Boolean) as string[] });

            // if (directoratePeople.ReportApproverUser)
            //     people.push({ role: 'Alternative approver', names: [directoratePeople.ReportApproverUser.Title].filter(Boolean) as string[] });
            // if (directoratePeople.ReportingLeadUser)
            //     people.push({ role: 'Directorate reporting lead', names: [directoratePeople.ReportingLeadUser.Title].filter(Boolean) as string[] });
        }
        // if (admins && admins.length > 0) {
        //     people.push({ role: 'Directorate admins', names: admins.map(ud => ud.User && ud.User.Title).filter(Boolean) as string[] });
        // }

        return people;
    }


    public static GetPartnerOrganisationEntityPeople = async (
        { partnerOrganisationService, userPartnerOrganisationService, partnerOrganisationId }: { partnerOrganisationService: PartnerOrganisationService, userPartnerOrganisationService: UserPartnerOrganisationService, partnerOrganisationId: number }
    ): Promise<IEntityRole[]> => {
        const people = [];
        const promPartnerOrgPeople = partnerOrganisationService.readPartnerOrganisationApprovers(partnerOrganisationId);
        const promAdmins = userPartnerOrganisationService.readPartnerOrganisationAdmins(partnerOrganisationId);
        const partnerOrgPeople = await promPartnerOrgPeople;
        const admins = await promAdmins;

        if (partnerOrgPeople && partnerOrgPeople.LeadPolicySponsorUser)
            people.push({ role: 'Approver', names: [partnerOrgPeople.LeadPolicySponsorUser.Title].filter(Boolean) as string[] });
        if (partnerOrgPeople && partnerOrgPeople.ReportAuthorUser)
            people.push({ role: 'Alternative approver', names: [partnerOrgPeople.ReportAuthorUser.Title].filter(Boolean) as string[] });
        if (admins && admins.length > 0)
            people.push({ role: 'Partner organisation admins', names: admins.map(a => a.User && a.User.Title).filter(Boolean) as string[] });

        return people;
    }

    public static GetRiskEntityPeople = async (
        { riskService, riskId }: { riskService: CorporateRiskService | FinancialRiskService, riskId: number }
    ): Promise<IEntityRole[]> => {
        const people = [];
        const risk = await riskService.readRiskPeople(riskId);

        if (risk) {
            if (risk.RiskOwnerUser)
                people.push({ role: 'Risk owner (approver)', names: [risk.RiskOwnerUser.Title].filter(Boolean) as string[] });
            if (risk.ReportApproverUser)
                people.push({ role: 'Alternative risk approver', names: [risk.ReportApproverUser.Title].filter(Boolean) as string[] });
        }

        return people;
    }
}
