import React, { useContext } from 'react';
import { ErrorBoundary } from '../ErrorBoundary';
import { IWithErrorHandlingProps, withErrorHandling } from '../withErrorHandling';
import { IUseApiProps } from '../useApi';
import GiaaUpdatesCls from './GiaaUpdatesCls';
import { OrbUserContext } from '../OrbUserContext';


export interface IGiaaUpdatesProps extends IUseApiProps, IWithErrorHandlingProps {
}

const GiaaUpdates = (props: IGiaaUpdatesProps) => {
    const { userPermissions, userContext } = useContext(OrbUserContext);
    return (
        <ErrorBoundary>
            <GiaaUpdatesCls
                userPermissions={userPermissions}
                userContext={userContext}
                {...props}
            />
        </ErrorBoundary>
    );

};

export default withErrorHandling(GiaaUpdates);
