import { EntityWithStatusService } from './EntityWithStatusService';
import { ContextService } from './ContextService';
import { IKeyWorkArea, IEntityChildren, IEntityDescription1 } from '../types';
import { EntityStatus } from '../refData/EntityStatus';
import axios from 'axios';
import { AppGlobals } from '../AppGlobals';

export class KeyWorkAreaService extends EntityWithStatusService<IKeyWorkArea> {
    public readonly parentEntities = ['Directorate', 'LeadUser'];
    protected childrenEntities = ['Contributors($expand=ContributorUser)', 'Attributes($expand=AttributeType)'];

    constructor() {
        super(`/KeyWorkAreas`);
    }

    public readAllForList(includeClosedKeyWorkAreas?: boolean, directorateID?: number): Promise<IKeyWorkArea[]> {
        return this.readAll(
            `?$select=ID,Title`
            + `&$orderby=Title`
            + `&$expand=EntityStatus($select=Title),Directorate($select=Title),LeadUser($select=Title),Contributors($select=ContributorUser;$expand=ContributorUser($select=Title)),Attributes($expand=AttributeType($select=Title))`
            + `&$filter=Directorate/CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosedKeyWorkAreas ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
            + (directorateID ? ` and DirectorateID eq ${directorateID}` : '')
        );
    }

    public async readForView(workAreaID: number): Promise<IKeyWorkArea> {
        return this.read(workAreaID, false, false, ['Directorate($select=Title)', 'LeadUser($select=Title,EmailAddress)', 'Contributors($select=ContributorUser;$expand=ContributorUser($select=Title,EmailAddress))', 'Attributes($expand=AttributeType($select=Title))']);
    }

    public readAllForLookup(includeClosedKeyWorkAreas?: boolean): Promise<IKeyWorkArea[]> {
        return this.readAll(
            `?$select=ID,Title,DirectorateID`
            + `&$orderby=Title&$filter=Directorate/CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosedKeyWorkAreas ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public readMyKeyWorkAreas(): Promise<IKeyWorkArea[]> {
        const username = encodeURIComponent(ContextService.Username());
        const filters = [
            `LeadUser/Username eq '${username}'`,
            `Contributors/any(c: c/ContributorUser/Username eq '${username}')`
        ];
        return this.readAll(`?$filter=EntityStatusID eq ${EntityStatus.Open} and (${filters.join(' or ')})&$orderby=Directorate/Title,Title`);
    }

    public readDirectorateKeyWorkAreas(directorateId: number): Promise<IKeyWorkArea[]> {
        return this.readAll(`?$expand=LeadUser,Attributes($expand=AttributeType),Contributors`
            + `&$filter=EntityStatusID eq ${EntityStatus.Open} and DirectorateID eq ${directorateId}`
            + `&$orderby=Title`);
    }

    public readDirectorateMilestones(directorateId: number): Promise<IKeyWorkArea[]> {
        return this.readAll(
            `?$filter=DirectorateID eq ${directorateId}`
            + `&$expand=Milestones($expand=KeyWorkArea,LeadUser,Attributes($expand=AttributeType),Contributors;$filter=EntityStatusID eq ${EntityStatus.Open};$orderby=MilestoneCode,Title)`
        );
    }

    public async entityChildren(id: number): Promise<IEntityChildren[]> {
        const keyWorkAreaUrl = `${this.entityUrl}(${id})`;
        const milestones = (await this.axiosGet(`${keyWorkAreaUrl}/Milestones?$select=ID&$top=10`)).data.value;
        const updates = (await this.axiosGet(`${keyWorkAreaUrl}/KeyWorkAreaUpdates?$select=ID&$top=10`)).data.value;
        return [
            { ChildType: 'Milestones', CanBeAdopted: true, ChildIDs: (await milestones).map((m: { ID: any }) => m.ID) },
            { ChildType: 'Key work area updates', CanBeAdopted: false, ChildIDs: (await updates).map((w: { ID: any }) => w.ID) }
        ];
    }

    public async readTitlesForWorkAreaDirectorateAndGroup(entityPlural: string, entitySingular: string, workAreaID: number): Promise<IEntityDescription1> {
        const entityDescription1: IEntityDescription1 = {
            Description1ForList: '',
            Description1ForForm: ''
        };
        const res = await this.readAll(`?$filter=ID eq ${workAreaID}&$expand=Directorate($select=Title;$expand=OrgLevel1($select=Title))&$select=Title`);
        if (res.length > 0) {
            entityDescription1.Description1ForList = `${entityPlural} in work area <span style='font-style: italic'>${res[0].Title}</span>, in directorate ${res[0].Directorate.Title}, ${res[0].Directorate.OrgLevel1.Title}`;
            entityDescription1.Description1ForForm = `${entitySingular} in work area <span style='font-style: italic'>${res[0].Title}</span>, in directorate ${res[0].Directorate.Title}, ${res[0].Directorate.OrgLevel1.Title}`;
        }

        return entityDescription1;
    }

    public async countWorkAreasForDirectorate(directorateID: number): Promise<number> {
        if (directorateID) {
            return (await this.readAll(`?$select=ID&$filter=EntityStatusID eq ${EntityStatus.Open} and DirectorateID eq ${directorateID}`))?.length;
        }
        return Promise.resolve(0);
    }

}