import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { INavLink, Nav } from '@fluentui/react';
import { IWithErrorHandlingProps } from './withErrorHandling';
import { OrbUserContext } from './OrbUserContext';
import { ThemeProvider } from '@fluentui/react/lib/Theme';
import { darkMenuTheme } from '../AppGlobals';
import { LoggedUserInfoForMenu } from './LoggedUserInfoForMenu';

interface IAccountAdminMenu extends IWithErrorHandlingProps {
    onLinkClicked?: () => void;
}

export const AccountAdminMenu = ({ errorHandling: { clearErrors }, onLinkClicked }: IAccountAdminMenu): React.ReactElement => {
    const { userPermissions: up, orbConfig } = useContext(OrbUserContext);
    const history = useHistory();
    const { pathname } = useLocation();
    const [navs, setNavs] = useState([]);
    const [organisationExpanded, setOrganisationExpanded] = useState(false);
    const [manageusersExpanded, setManageUsersExpanded] = useState(false);
    const [settingsExpanded, setSettingsExpanded] = useState(false);
    const resetErrors = useCallback(clearErrors, [clearErrors]);

    useEffect(() => {
        const routerNav = (path: string): void => {
            history.push(path);
            if (onLinkClicked) onLinkClicked();
        };

        const NamePathToNavLink = ({ name, path }): INavLink => {
            return { key: path, name: name, url: `#${path}`, onClick: () => routerNav(path) };
        };

        const links: INavLink[] = [
            { name: 'Home', path: '/' },
            { name: 'Welcome', path: '/admin/welcome' },
        ].map(NamePathToNavLink);


        if (up.UserIsSystemAdmin()/* || up.UserIsCustomSectionsAdmin() || up.UserIsFinancialRiskAdmin()*/) {
            const organisationLinks = [];
            const manageUsersLinks = [];
            const settingsLinks = [];

            organisationLinks.push(
                { name: orbConfig.OrgL1LabelP, path: '/admin/groups' },
                { name: orbConfig.OrgL2LabelP, path: '/admin/directorates' },
                { name: orbConfig.OrgL3LabelP, path: '/admin/divisions' },
            );

            manageUsersLinks.push(
                { name: 'Users', path: '/admin/users' },
                { name: 'User groups', path: '/admin/user-groups' },
                { name: 'User Permissions', path: '/admin/administrators' },
            );

            settingsLinks.push(
                { name: 'Benefit types', path: '/admin/benefit-types' },
                { name: 'Attributes', path: '/admin/attributes' },
                { name: 'Reporting frequencies', path: '/admin/reporting-frequencies' },
                { name: 'Risk types', path: '/admin/risk-types' },
                { name: 'Risk discussion forums', path: '/admin/risk-discussion-forums' },
                { name: 'Units', path: '/admin/units' },
                { name: 'Thresholds', path: '/admin/thresholds' },
                { name: 'Threshold appetites', path: '/admin/threshold-appetites' },
            );

            links.push({
                key: 'organisation',
                name: 'Organisation',
                url: null,
                isExpanded: organisationExpanded,
                onClick: () => setOrganisationExpanded(oe => !oe),
                links: organisationLinks.map(NamePathToNavLink)
            });

            links.push({
                key: 'manageusers',
                name: 'Manage Users',
                url: null,
                isExpanded: manageusersExpanded,
                onClick: () => setManageUsersExpanded(mue => !mue),
                links: manageUsersLinks.map(NamePathToNavLink)
            });

            links.push({
                key: 'settings',
                name: 'Settings',
                url: null,
                isExpanded: settingsExpanded,
                onClick: () => setSettingsExpanded(se => !se),
                links: settingsLinks.map(NamePathToNavLink)
            });
        }
        setNavs(links);
    }, [up, history, organisationExpanded, manageusersExpanded, settingsExpanded, onLinkClicked, resetErrors]);

    return (
        <ThemeProvider theme={darkMenuTheme}>
            <LoggedUserInfoForMenu />
            <Nav
                selectedKey={pathname}
                groups={[{ links: navs }]}
            />
        </ThemeProvider>
    );
};
