import { useState } from "react";
import { IOrbProps } from "./App";
import { useApi } from './components/useApi';
import { useUserContext } from './components/useUserContext';
import { useDataContext } from './components/useDataContext';
import { useIntegration } from './components/useIntegration';
import React from "react";
import { HashRouter, Switch, Route } from 'react-router-dom';
import { ErrorBoundary } from "./components/ErrorBoundary";
import { IntegrationContext } from "./components/IntegrationContext";
import { DataContext } from "./components/DataContext";
import { OrbUserContext } from "./components/OrbUserContext";
import { CrLoadingOverlay } from './components/cr/CrLoadingOverlay';
import { AppGlobals, AppIDs } from "./AppGlobals";
import Logout from "./Logout";
import { withErrorHandling } from './components/withErrorHandling';
import { NotAuthorised, NotFound } from "./Routes";
import { MenuToggle } from "./components/MenuToggle";
import LoadingWhite from "./LoadingWhite";
import { CLMenu } from "./components/CLMenu";
import ClUpdates from "./components/CL/ClUpdates";
import ClProperties from "./components/CL/ClProperties";
import ClReport from "./components/CL/ClReport";
import { ClWelcome } from "./components/CL/ClWelcome";

const CLApp: React.FunctionComponent<IOrbProps> = (props: IOrbProps) => {

    const { dataServices, errorHandling } = props;
    const apiConnected = useApi(dataServices/*, errorHandling*/);
    const uc = useUserContext(apiConnected, dataServices, errorHandling);
    const ld = useDataContext(apiConnected, dataServices, errorHandling);
    const integration = useIntegration({
        disableDirectorateManagement: false,
        disableGroupManagement: false,
        disablePartnerOrganisationManagement: false,
        disableProjectManagement: false,
        disableUserManagement: false,
        dataSourceName: ''
    });
    const routeProps = { ...props, apiConnected: apiConnected, ...uc, ...ld };


    const [isNavigationVisible, setNavigationVisible] = useState(false);

    const toggleNavigation = () => {
        setNavigationVisible(!isNavigationVisible);
    };

    if (uc.userContext.UserId === null) return (<LoadingWhite />);

    const appName = props.menuData.filter(m => m.ID === AppIDs.CL)[0].AppName;

    return (

        <React.Fragment>
            <ErrorBoundary>
                <IntegrationContext.Provider value={integration}>
                    <DataContext.Provider value={ld}>
                        <OrbUserContext.Provider value={uc}>
                            <div className="main">
                                <div className="blue-banner">
                                    <MenuToggle onToggleNavigation={toggleNavigation} />
                                    <div className="blue-banner-white-bold">ZedX for {AppGlobals.CustomerSectorTitle} - {appName}</div>
                                    <div className="right-aligned-text"><Logout onLogout={props.onLogout} /></div>
                                </div>
                                <div className="container">
                                    <HashRouter>
                                        <div className={`navigation ${isNavigationVisible ? 'show' : ''}`}>
                                            {!uc.userContext.UserId &&
                                                <CrLoadingOverlay isLoading={true} />
                                                ||
                                                <CLMenu {...routeProps} onLinkClicked={toggleNavigation} />
                                            }
                                        </div>
                                        <div className="content">
                                            <Switch>
                                                <Route exact path="/contingent-labour-processes/welcome" {...routeProps}>
                                                    <ClWelcome />
                                                </Route>
                                                <Route path="/contingent-labour-processes/manage-cases" {...routeProps}>
                                                    <ClUpdates {...routeProps} />
                                                </Route>
                                                <Route path="/contingent-labour-processes/outputs" {...routeProps}>
                                                    <ClReport {...routeProps} />
                                                </Route>
                                                <Route path="/contingent-labour-processes/properties" {...routeProps}>
                                                    <ClProperties {...routeProps} />
                                                </Route>

                                                {/* <Route exact path="/nao-tracker/welcome" {...routeProps}>
                                                    <NaoWelcome />
                                                </Route>
                                                <Route path="/nao-tracker/updates" {...routeProps}>
                                                    <NaoUpdates {...routeProps} />
                                                </Route>
                                                <Route path="/nao-tracker/outputs" {...routeProps}>
                                                    <NaoReport {...routeProps} />
                                                </Route>
                                                <Route path="/nao-tracker/properties" {...routeProps}>
                                                    <NaoProperties {...routeProps} />
                                                </Route> */}
                                                <Route path="/not-authorised">
                                                    <NotAuthorised />
                                                </Route>
                                                <Route path="*">
                                                    <NotFound />
                                                </Route>
                                            </Switch>
                                        </div>
                                    </HashRouter>
                                </div>
                            </div>
                        </OrbUserContext.Provider>
                    </DataContext.Provider>
                </IntegrationContext.Provider>
            </ErrorBoundary>
        </React.Fragment>
    );
};

export const CLAppWithErrorHandling = withErrorHandling(CLApp);