import { EntityService } from './EntityService';
import { IIAPDefForm } from '../types';

export class IAPDefFormService extends EntityService<IIAPDefForm> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/IAPDefForms`);
    }

    public welcomeAccess(): Promise<string> {
        return super.readString(`?welcomeAccess=`).then((result: string): string => {
            return result;
        });
    }
}