import { EntityService, IEntityService } from './EntityService';
import { IAttributeType, IEntityChildren } from '../types';
import axios from 'axios';
import { AppGlobals } from '../AppGlobals';

export class AttributeTypeService extends EntityService<IAttributeType> implements IEntityService<IAttributeType> {
    constructor() {
        super(`/AttributeTypes`);
    }

    public readAllForLookup(): Promise<IAttributeType[]> {
        return this.readAll(`?$select=ID,Title,Display&$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`);
    }

    public readAllForList(): Promise<IAttributeType[]> {
        return super.readAll(`?$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`);
    }

    public async create(attributeType: IAttributeType): Promise<IAttributeType> {
        attributeType.CustomerID = AppGlobals.CustomerID;           
        return super.create(attributeType);
    }
    public async entityChildren(id: number): Promise<IEntityChildren[]> {
        const response = await this.axiosGet(`${this.entityUrl}(${id})/Attributes?$select=BenefitID,CommitmentID,DirectorateID,KeyWorkAreaID,MetricID,MilestoneID,ProjectID,RiskID,PartnerOrganisationRiskID,WorkStreamID&$top=10`);
        const attributes: IAttributeType[] = response.data.value;
        return [
            { ChildType: 'Benefits', CanBeAdopted: true, ChildIDs: attributes.filter((a: any) => a['BenefitID']).map((b: any) => b['BenefitID']) },
            { ChildType: 'Commitments', CanBeAdopted: true, ChildIDs: attributes.filter((a: any) => a['CommitmentID']).map((c: any) => c['CommitmentID']) },
            { ChildType: 'Directorates', CanBeAdopted: true, ChildIDs: attributes.filter((a: any) => a['DirectorateID']).map((d: any) => d['DirectorateID']) },
            { ChildType: 'Key work areas', CanBeAdopted: true, ChildIDs: attributes.filter((a: any) => a['KeyWorkAreaID']).map((k: any) => k['KeyWorkAreaID']) },
            { ChildType: 'Metrics', CanBeAdopted: true, ChildIDs: attributes.filter((a: any) => a['MetricID']).map((m: any) => m['MetricID']) },
            { ChildType: 'Milestones', CanBeAdopted: true, ChildIDs: attributes.filter((a: any) => a['MilestoneID']).map((m: any) => m['MilestoneID']) },
            { ChildType: 'Projects', CanBeAdopted: true, ChildIDs: attributes.filter((a: any) => a['ProjectID']).map((p: any) => p['ProjectID']) },
            { ChildType: 'Risks', CanBeAdopted: true, ChildIDs: attributes.filter((a: any) => a['RiskID']).map((r: any) => r['RiskID']) },
            { ChildType: 'Partner organisation risks', CanBeAdopted: true, ChildIDs: attributes.filter((a: any) => a['PartnerOrganisationRiskID']).map((p: any) => p['PartnerOrganisationRiskID']) },
            { ChildType: 'Work streams', CanBeAdopted: true, ChildIDs: attributes.filter((a: any) => a['WorkStreamID']).map((w: any) => w['WorkStreamID']) },
        ];
    }



}