import axios from 'axios';
import { Period } from '../refData/Period';
import { IReportDueDates, ReportDueDates } from '../types/ReportDueDates';
import { DateService } from './DateService';
import { EntityService } from './EntityService';
import { IEntity } from '../types';

export class ReportDueDatesService extends EntityService<IEntity> {
    private baseUrl: string;

    constructor() {
        super('');
        this.baseUrl = `${process.env.REACT_APP_ZedX_API!}/`;
    }

    public getBenefitReportDueDates = async (benefitId: number, atDate: Date, period?: Period): Promise<IReportDueDates> => {
        const url = `${this.baseUrl}GetBenefitReportDueDates`;
        if (benefitId) {
            const dates: IReportDueDates = (await this.axiosGet(`${url}(ID=${benefitId},AtDate=${atDate.toISOString()})`)).data;
            if (period && dates.Previous && period === Period.Previous) {
                const datesPrev: IReportDueDates = (await this.axiosGet(`${url}(ID=${benefitId},AtDate=${DateService.removeTimezoneOffset(dates.Previous).toISOString()})`)).data;
                return this.convertDates(datesPrev);
            }
            return this.convertDates(dates);
        }
        return Promise.resolve(new ReportDueDates());
    }

    public getDirectorateReportDueDates = async (directorateId: number, atDate: Date, period?: Period): Promise<IReportDueDates> => {
        const url = `${this.baseUrl}GetDirectorateReportDueDates`;
        if (directorateId) {
            const dates: IReportDueDates = (await this.axiosGet(`${url}(ID=${directorateId},AtDate=${atDate.toISOString()})`)).data;
            if (period && dates.Previous && period === Period.Previous) {
                const datesPrev: IReportDueDates = (await this.axiosGet(`${url}(ID=${directorateId},AtDate=${DateService.removeTimezoneOffset(dates.Previous).toISOString()})`)).data;
                return this.convertDates(datesPrev);
            }
            return this.convertDates(dates);
        }
        return Promise.resolve(new ReportDueDates());
    }

    public getMetricReportDueDates = async (metricId: number, atDate: Date, period?: Period): Promise<IReportDueDates> => {
        const url = `${this.baseUrl}GetMetricReportDueDates`;
        if (metricId) {
            const dates: IReportDueDates = (await this.axiosGet(`${url}(ID=${metricId},AtDate=${atDate.toISOString()})`)).data;
            if (period && dates.Previous && period === Period.Previous) {
                const datesPrev: IReportDueDates = (await this.axiosGet(`${url}(ID=${metricId},AtDate=${DateService.removeTimezoneOffset(dates.Previous).toISOString()})`)).data;
                return this.convertDates(datesPrev);
            }
            return this.convertDates(dates);
        }
        return Promise.resolve(new ReportDueDates());
    }

    public getPartnerOrganisationReportDueDates = async (partnerOrganisationId: number, atDate: Date, period?: Period): Promise<IReportDueDates> => {
        const url = `${this.baseUrl}GetPartnerOrganisationReportDueDates`;
        if (partnerOrganisationId) {
            const dates: IReportDueDates = (await this.axiosGet(`${url}(ID=${partnerOrganisationId},AtDate=${atDate.toISOString()})`)).data;
            if (period && dates.Previous && period === Period.Previous) {
                const datesPrev: IReportDueDates = (await this.axiosGet(`${url}(ID=${partnerOrganisationId},AtDate=${DateService.removeTimezoneOffset(dates.Previous).toISOString()})`)).data;
                return this.convertDates(datesPrev);
            }
            return this.convertDates(dates);
        }
        return Promise.resolve(new ReportDueDates());
    }

    public getProjectReportDueDates = async (projectId: number, atDate: Date, period?: Period): Promise<IReportDueDates> => {
        const url = `${this.baseUrl}GetProjectReportDueDates`;
        if (projectId) {
            const dates: IReportDueDates = (await this.axiosGet(`${url}(ID=${projectId},AtDate=${atDate.toISOString()})`)).data;
            if (period && dates.Previous && period === Period.Previous) {
                const datesPrev: IReportDueDates = (await this.axiosGet(`${url}(ID=${projectId},AtDate=${DateService.removeTimezoneOffset(dates.Previous).toISOString()})`)).data;
                return this.convertDates(datesPrev);
            }
            return this.convertDates(dates);
        }
        return Promise.resolve(new ReportDueDates());
    }

    public getPortfolioReportDueDates = async (portfolioId: number, atDate: Date, period?: Period): Promise<IReportDueDates> => {
        const url = `${this.baseUrl}GetPortfolioReportDueDates`;
        if (portfolioId) {
            const dates: IReportDueDates = (await this.axiosGet(`${url}(ID=${portfolioId},AtDate=${atDate.toISOString()})`)).data;
            if (period && dates.Previous && period === Period.Previous) {
                const datesPrev: IReportDueDates = (await this.axiosGet(`${url}(ID=${portfolioId},AtDate=${DateService.removeTimezoneOffset(dates.Previous).toISOString()})`)).data;
                return this.convertDates(datesPrev);
            }
            return this.convertDates(dates);
        }
        return Promise.resolve(new ReportDueDates());
    }

    public getProgrammeReportDueDates = async (programmeId: number, atDate: Date, period?: Period): Promise<IReportDueDates> => {
        const url = `${this.baseUrl}GetProgrammeReportDueDates`;
        if (programmeId) {
            const dates: IReportDueDates = (await this.axiosGet(`${url}(ID=${programmeId},AtDate=${atDate.toISOString()})`)).data;
            if (period && dates.Previous && period === Period.Previous) {
                const datesPrev: IReportDueDates = (await this.axiosGet(`${url}(ID=${programmeId},AtDate=${DateService.removeTimezoneOffset(dates.Previous).toISOString()})`)).data;
                return this.convertDates(datesPrev);
            }
            return this.convertDates(dates);
        }
        return Promise.resolve(new ReportDueDates());
    }

    public getCorporateRiskReportDueDates = async (riskId: number, atDate: Date, period?: Period): Promise<IReportDueDates> => {
        const url = `${this.baseUrl}GetCorporateRiskReportDueDates`;
        if (riskId) {
            const dates: IReportDueDates = (await this.axiosGet(`${url}(ID=${riskId},AtDate=${atDate.toISOString()})`)).data;
            if (period && dates.Previous && period === Period.Previous) {
                const datesPrev: IReportDueDates = (await this.axiosGet(`${url}(ID=${riskId},AtDate=${DateService.removeTimezoneOffset(dates.Previous).toISOString()})`)).data;
                return this.convertDates(datesPrev);
            }
            return this.convertDates(dates);
        }
        return Promise.resolve(new ReportDueDates());
    }

    public getFinancialRiskReportDueDates = async (riskId: number, atDate: Date, period?: Period): Promise<IReportDueDates> => {
        const url = `${this.baseUrl}GetFinancialRiskReportDueDates`;
        if (riskId) {
            const dates: IReportDueDates = (await this.axiosGet(`${url}(ID=${riskId},AtDate=${atDate.toISOString()})`)).data;
            if (period && dates.Previous && period === Period.Previous) {
                const datesPrev: IReportDueDates = (await this.axiosGet(`${url}(ID=${riskId},AtDate=${DateService.removeTimezoneOffset(dates.Previous).toISOString()})`)).data;
                return this.convertDates(datesPrev);
            }
            return this.convertDates(dates);
        }
        return Promise.resolve(new ReportDueDates());
    }

    private convertDates(dates: IReportDueDates): IReportDueDates {
        dates.Next = DateService.removeTimezoneOffset(dates.Next);
        dates.Previous = DateService.removeTimezoneOffset(dates.Previous);
        return dates;
    }


    public getProjectUpdateUniqueDates = async (projectId: number): Promise<Date[]> => {
        const url = `${this.baseUrl}GetProjectUpdateUniqueDates`;
        const dates: Date[] = (await this.axiosGet(`${url}(ProjectID=${projectId})`)).data.value;
        const newDates = dates.map(d => DateService.removeTimezoneOffset(d));
        return newDates;
    }
}
