import { EntityService } from './EntityService';
import { IAPAssignment } from '../types';

export class IAPAssignmentService extends EntityService<IAPAssignment> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/IAPAssignments`);
    }

    public create(entity: IAPAssignment): Promise<IAPAssignment> {
        return super.create(entity);
    }

    public readAllAssignmentsForParentAction(parentIAPActionId: number): Promise<IAPAssignment[]> {
        return this.readAll(`?parentIAPActionId=${parentIAPActionId}&getAllAssignmentsForParentAction=`);
    }
}