import { EntityWithStatusService } from './EntityWithStatusService';
import { IOrgLevel3, IEntityChildren } from '../types';
import { EntityStatus } from '../refData/EntityStatus';
import { AppGlobals } from '../AppGlobals';

export class OrgLevel3Service extends EntityWithStatusService<IOrgLevel3> {
    constructor() {
        super(`/OrgLevel3s`);
    }

    public readAllForList(includeClosed?: boolean): Promise<IOrgLevel3[]> {
        return this.readAll(
            `?$select=ID,Title`
            + `&$orderby=Title`
            + `&$expand=Directorate($select=Title),DeputyDirectorUser($select=Title),EntityStatus($select=Title)&$filter=CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosed ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public readAllForLookup(includeClosedEntities?: boolean, additionalFields?: string): Promise<IOrgLevel3[]> {
        return this.readAll(
            `?$select=ID,Title${additionalFields ? `,${additionalFields}` : ''}`
            + `&$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosedEntities ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public readAllOpenTeamsForUser_ControlsAssurance(): Promise<IOrgLevel3[]> {
        return this.readAll(`?user=&openTeams=&forControlAssurance=&$orderby=Title`);
    }

    public async entityChildren(id: number): Promise<IEntityChildren[]> {
        //return this.entityChildrenSingle(id, 'Directorates', 'Directorates', true);
        //TODO with actual children
        return [];
    }

    public async create(orgLevel3: IOrgLevel3): Promise<IOrgLevel3> {
        orgLevel3.CustomerID = AppGlobals.CustomerID;
        return super.create(orgLevel3);
    }
}