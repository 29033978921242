import { EntityService } from './EntityService';
import { IEntity } from '../types';

export class NAOOutput2Service extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/NAOOutput2`);
    }

    public createPDF(publicationIds: string): Promise<string> {

        return super.readString(`?publicationIds=${publicationIds}&createPdf=&spSiteUrl=`).then((result: string): string => {
            return result;
        });
    }

    public getPDFStatus(): Promise<any> {
        return super.readEntity(`?getPDFStatus=`).then((result: any): any => {
            return result;
        });
    }

    public deletePDFInfo(): Promise<string> {

        return super.readString(`?deletePdfInfo=true`).then((result: string): string => {
            return result;
        });
    }

}