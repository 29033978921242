import { EntityService } from './EntityService';
import { IEntity } from '../types';

export class GoElementActionService extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/GoElementActions`);
    }
    
    public readAllWithArgs(goElementId: number): Promise<IEntity[]> {
        return this.readAll(`?$orderby=EntityPriorityId&$expand=EntityPriority&$filter=GoElementId eq ${goElementId}`);
    }
}