import { EntityService } from './EntityService';
import { IEntity } from '../types';

export class NAOOutputService extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/NAOOutputs`);
    }

    public readAllReport1(): Promise<IEntity[]> {
        return this.readAll(`?report1=`);
    }

    public createPDF(outputId: number): Promise<string> {

        return super.readString(`?key=${outputId}&createPdf=&spSiteUrl=`).then((result: string): string => {
            return result;
        });
    }

    public deletePDFInfo(outputId: number): Promise<string> {

        return super.readString(`?key=${outputId}&deletePdfInfo=true`).then((result: string): string => {
            return result;
        });
    }
}