import * as React from 'react';
import * as types from '../../types';
import * as services from '../../services';
import RecommendationSaveForm from './RecommendationSaveForm';
import RecommendationAssignForm from './RecommendationAssignForm';
import { FilteredRecList } from './FilteredRecList';
import { IEntity } from '../../types';
import { IUpdatesListColumn, ColumnDisplayTypes } from '../../types/UpdatesListColumn';
import { CrLoadingOverlay } from '../cr/CrLoadingOverlay';
import { IObjectWithKey, Selection } from '@fluentui/react/lib/DetailsList';
import { ConfirmDialog } from '../cr/ConfirmDialog';
import styles from '../../styles/cr.module.scss';

export interface IRecommendationsListProps extends types.IBaseComponentProps {
    onItemTitleClick: (ID: number, title: string, filteredItems: any[]) => void;
    naoPublicationId: number | string;
    periodId: number | string;
    incompleteOnly: boolean;
    onChangeIncompleteOnly: (value: boolean) => void;
    justMine: boolean;
    onChangeJustMine: (value: boolean) => void;
    filterText?: string;
    onChangeFilterText: (value: string) => void;
    superUserPermission: boolean;
    dgOrDGMemberPermission: boolean;
}

export interface IRecommendationsListState<T> {
    SelectedEntity: number;
    SelectedEntityTitle: string;
    SelectedEntityChildren: number;
    ShowForm: boolean;
    ShowAssignForm: boolean;
    EnableEdit?: boolean;
    EnableDelete?: boolean;
    HideDeleteDialog: boolean;
    ShowChildForm: boolean;
    CurrentPage?: number;
    NextPageAvailable?: boolean;
    Entities: T[];
    Loading: boolean;
    ListFilterText?: string;
    InitDataLoaded: boolean;
}

export class RecommendationsListState<T> implements IRecommendationsListState<T> {
    public SelectedEntity = null;
    public SelectedEntityTitle: string = null;
    public SelectedEntityChildren = null;
    public ShowForm = false;
    public ShowAssignForm = false;
    public HideDeleteDialog = true;
    public EnableEdit = false;
    public EnableDelete = false;
    public ShowChildForm = false;
    public CurrentPage = 1;
    public NextPageAvailable = false;
    public Entities: T[] = [];
    public Loading = false;
    public ListFilterText = null;
    public InitDataLoaded = false;
}

export default class RecommendationsList extends React.Component<IRecommendationsListProps, IRecommendationsListState<IEntity>> {
    private _selection: Selection;
    private recService: services.NAORecommendationService = new services.NAORecommendationService();

    private listColumns: IUpdatesListColumn[] = [
        {
            key: 'ID',
            name: 'ID',
            fieldName: 'ID',
            minWidth: 1,
            isResizable: true,
            columnDisplayType: ColumnDisplayTypes.Hidden,
        },
        {
            key: 'Title',
            name: 'Rec Ref',
            fieldName: 'Title',
            minWidth: 60,
            maxWidth: 80,
            isResizable: true,
            headerClassName: styles.bold,
        },
        {
            key: 'RecommendationDetails',
            name: 'Recommendation',
            fieldName: 'RecommendationDetails',
            minWidth: 200,
            maxWidth: 400,
            isResizable: true,
            isMultiline: true,
            //isCollapsible: true,
            headerClassName: styles.bold,
        },
        {
            key: 'TargetDate',
            name: 'Target Date',
            fieldName: 'TargetDate',
            minWidth: 85,
            maxWidth: 85,
            isResizable: true,
            isMultiline: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },
        {
            key: 'RecStatus',
            name: 'Rec Status',
            fieldName: 'RecStatus',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },
        {
            key: 'AssignedTo',
            name: 'Assigned To',
            fieldName: 'AssignedTo',
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            isResizable: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },
        {
            key: 'UpdateStatus',
            name: 'Period Update Status',
            fieldName: 'UpdateStatus',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },
        {
            key: 'AssignedToIds',
            name: 'AssignedToIds',
            fieldName: 'AssignedToIds',
            minWidth: 1,
            maxWidth: 1,
            headerClassName: styles.bold,
            columnDisplayType: ColumnDisplayTypes.Hidden,
        },
    ];


    constructor(props: IRecommendationsListProps, state: IRecommendationsListState<IEntity>) {
        super(props);
        this.state = new RecommendationsListState<IEntity>();

        this._selection = new Selection({
            onSelectionChanged: () => {
                if (this._selection.getSelectedCount() === 1) {

                    const sel = this._selection.getSelection()[0];
                    console.log(sel);
                    const key = Number(sel.key);
                    const title: string = sel["Title"];

                    this.setState({ SelectedEntity: key, SelectedEntityTitle: title, EnableEdit: true, EnableDelete: true });
                }
                else {
                    this.setState({ SelectedEntity: null, SelectedEntityTitle: null, EnableEdit: false, EnableDelete: false });
                }
            }
        });
    }

    public render(): React.ReactElement<IRecommendationsListProps> {

        return (
            <div className={`${styles.cr}`}>
                <div style={{ position: 'relative' }}>
                    <CrLoadingOverlay isLoading={this.state.Loading} />
                    {this.renderList()}
                    {this.state.ShowForm && this.renderForm()}
                    {this.state.ShowAssignForm && this.renderAssignForm()}
                    <ConfirmDialog hidden={this.state.HideDeleteDialog} title={`Are you sure you want to delete ${this.getSelectedEntityName()}?`} content={`A deleted record cannot be un-deleted.`} confirmButtonText="Delete" handleConfirm={this.deleteRecord} handleCancel={this.toggleDeleteConfirm} />
                </div>
            </div>
        );
    }

    private renderList() {
        const listColumns = this.getColumns();
        const listColumnsForData = this.getColumnsForData();
        let items: IObjectWithKey[] = this.state.Entities.map((e) => { return this.makeItem(e, listColumnsForData); });

        return (
            <FilteredRecList
                onItemTitleClick={this.props.onItemTitleClick}
                columns={listColumns}
                items={items}
                incompleteOnly={this.props.incompleteOnly}
                onChangeIncompleteOnly={this.props.onChangeIncompleteOnly}
                justMine={this.props.justMine}
                onChangeJustMine={this.props.onChangeJustMine}
                filterText={this.props.filterText}
                onFilterChange={this.props.onChangeFilterText}
                selection={this._selection}
                onAdd={this.addItem}
                onEdit={this.editItem}
                onAssign={this.handleAssign}
                editDisabled={!this.state.EnableEdit}
                deleteDisabled={!this.state.EnableDelete}
                superUserPermission={this.props.superUserPermission}
                dgOrDGMemberPermission={this.props.dgOrDGMemberPermission}
            />
        );
    }

    private renderForm() {
        return (
            <RecommendationSaveForm
                naoPublicationId={this.props.naoPublicationId}
                showForm={this.state.ShowForm}
                entityId={this.state.SelectedEntity}
                onSaved={this.formSaved}
                onCancelled={this.closePanel}
                {...this.props}
            />
        );
    }

    private renderAssignForm() {
        return (
            <RecommendationAssignForm
                showForm={this.state.ShowAssignForm}
                recId={this.state.SelectedEntity}
                onSaved={this.formSaved}
                onCancelled={this.closePanel}
                {...this.props}
            />
        );
    }

    private makeItem = (e: IEntity, listColumns: IUpdatesListColumn[]): any => {
        let item: any = { key: e["ID"] };
        listColumns.map((c) => {
            let fieldContent: string = String(e[c.fieldName]);
            item = {
                [c.fieldName]: fieldContent,
                ...item
            };
        });
        return item;
    }

    private getColumns(): IUpdatesListColumn[] {
        let listColumns: IUpdatesListColumn[];
        listColumns = this.listColumns.filter(c => c.columnDisplayType !== ColumnDisplayTypes.Hidden);
        return listColumns;
    }

    private getColumnsForData(): IUpdatesListColumn[] {
        const listColumns: IUpdatesListColumn[] = this.listColumns;
        return listColumns;
    }

    private closePanel = (): void => {
        this.setState({ ShowForm: false, ShowAssignForm: false });
    }

    private formSaved = (): void => {
        this.loadData();
        this.closePanel();
    }

    private getSelectedEntityName = (): string => {
        let entity = this.state.Entities.filter((e) => { return e.ID === this.state.SelectedEntity; });
        return entity[0] ? entity[0].Title : null;
    }

    private toggleDeleteConfirm = (): void => {
        this.setState({ HideDeleteDialog: !this.state.HideDeleteDialog });
    }

    private deleteRecord = (): void => {

    }

    private loadData = (): void => {
        this.setState({ Loading: true });
        const read: Promise<IEntity[]> = this.recService.readAllWithFilters(this.props.naoPublicationId, this.props.periodId, this.props.incompleteOnly, this.props.justMine);
        read.then((entities: any): void => {
            this.setState({
                Loading: false, Entities: entities,
            });

        }, (err) => this.errorLoadingData(err));
    }

    private errorLoadingData = (err: any, entityName?: string): void => {
        this.setState({ Loading: false });
        if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading ${entityName || 'items'}`, err.message);
    }
    public componentDidMount(): void {
        this.loadData();
    }

    public componentDidUpdate(prevProps: IRecommendationsListProps): void {
        if (prevProps.incompleteOnly !== this.props.incompleteOnly || prevProps.justMine !== this.props.justMine) {
            this._selection.setAllSelected(false);
            this.loadData();
        }
    }

    private addItem = (): void => {
        if (this.state.SelectedEntity)
            this._selection.setKeySelected(this.state.SelectedEntity.toString(), false, false);
        this.setState({ SelectedEntity: null, ShowForm: true });
    }

    private editItem = (): void => {
        this.setState({ ShowForm: true });
    }

    private handleAssign = (): void => {
        this.setState({ ShowAssignForm: true });
    }

}
