import React, { useContext, useMemo } from 'react';
import { ISpecificEntityFormProps, EntityValidations, IApp03WorkflowUser, App03WorkflowUser } from '../../types';
import styles from '../../styles/cr.module.scss';
import { CrUserPicker } from '../cr/CrUserPicker';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { EntityForm } from '../EntityForm';
import { DataContext } from '../DataContext';

export class UserWorkflowValidations extends EntityValidations {
    public UserID: string = null;
}

export const UserWorkflowForm = (props: ISpecificEntityFormProps): React.ReactElement => {
    const { dataServices, lookupData, loadLookupData: { users: { enabled: enabledUsers } } } = useContext(DataContext);

    useMemo(() => enabledUsers(), [enabledUsers]);

    const validateEntity = async (userWorkflow: IApp03WorkflowUser): Promise<UserWorkflowValidations> => {
        const errors = new UserWorkflowValidations();

        if (userWorkflow.UserID === null) {
            errors.UserID = 'User is required';
            errors.Valid = false;
        }
        else
            errors.UserID = null;

        if (userWorkflow.App03WorkflowID !== null && userWorkflow.UserID !== null) {
            const dupes = await dataServices.app03WorkflowUserService.checkForDuplicates(userWorkflow.UserID, userWorkflow.App03WorkflowID);
            if (dupes.length > 0 && dupes[0].ID !== userWorkflow.ID) {
                errors.UserID = 'User is already assigned to the workflow';
                errors.Valid = false;
            }
        }

        return Promise.resolve(errors);
    };
    return (
        <EntityForm<IApp03WorkflowUser, UserWorkflowValidations>
            {...props}
            entityName="Workflow User"
            renderFormFields={(changeHandlers, formState) => {
                const { FormData: userWF, ValidationErrors: errors } = formState;
                return (
                    <div>
                        <CrUserPicker
                            label="User"
                            className={styles.formField}
                            required={true}
                            users={lookupData.Users && lookupData.Users.Enabled}
                            selectedUsers={userWF.UserID && [userWF.UserID]}
                            onChange={v => changeHandlers.changeUserPicker(v, 'UserID')}
                            errorMessage={errors.UserID} />
                        <Checkbox
                            className={`${styles.formField} ${styles.formField}`}
                            label="Workflow owner?"
                            checked={userWF.IsAdmin}
                            onChange={(_, isChecked) => changeHandlers.changeCheckbox(isChecked, 'IsAdmin')} />
                    </div>
                );
            }}
            loadEntity={id => dataServices.app03WorkflowUserService.read(id, true, true)}
            loadNewEntity={() => new App03WorkflowUser(false)}
            loadEntityValidations={() => new UserWorkflowValidations()}
            onValidateEntity={validateEntity}
            onCreate={uw => dataServices.app03WorkflowUserService.create(uw)}
            onUpdate={uw => dataServices.app03WorkflowUserService.update(uw.ID, uw)}
        />
    );
};
