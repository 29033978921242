import React from 'react';
import useMobileDetector from './useMobileDetector';

const withMobileDetector = (WrappedComponent: React.ComponentType<any>) => {
    return (props: any) => {
        const isMobile = useMobileDetector();
        return <WrappedComponent {...props} isMobile={isMobile} />;
    };
};

export default withMobileDetector;
