import axios from "axios";
import { IEntity } from "../types";
import { EntityService } from "./EntityService";

export class HealthCheckService extends EntityService<IEntity>{
    constructor() {
        super('/HealthCheck');
    }

    public async firstRequestToAPI(): Promise<string> {
        console.log('in HealthCheckService.firstRequestToAPI');
        const response = this.readString(`?firstRequest=&checkDb=&checkCurrentUser`);
        return response;
    }
}
