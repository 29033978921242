import { EntityService } from './EntityService';
import { IEntity } from '../types';

export class GoAssignmentService extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];
    
    constructor() {
        super(`/GoAssignments`);
    }
}