import { IEntity, Entity } from "./Entity";
import { IUser } from "./User";
import { IOrgLevel1 } from "./OrgLevel1";

export interface IUserGroup extends IEntity {
    UserID: number | null;
    GroupID: number | null;
    IsRiskAdmin: boolean;
    User?: IUser;
    OrgLevel1?: IOrgLevel1;
}

export class UserGroup extends Entity implements IUserGroup {
    public UserID = null;
    public GroupID = null;
    public IsRiskAdmin = false;
}