import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    EntityValidations, ISpecificEntityFormProps, IApp03WorkflowStage, App03WorkflowStage
} from '../../types';
import { LookupService } from '../../services';
import styles from '../../styles/cr.module.scss';
import { CrTextField } from '../cr/CrTextField';
import { CrDropdown } from '../cr/CrDropdown';
import { EntityForm } from '../EntityForm';
import { DataContext } from '../DataContext';
import { CrChoiceGroup } from '../cr/CrChoiceGroup';
import { CrCheckbox } from '../cr/CrCheckbox';


interface IStageFormProps extends ISpecificEntityFormProps {
    requestStage: IApp03WorkflowStage;
    workflowID: number;
}
class StageFormValidations extends EntityValidations {
    public ReqStageID: string = null;
    public ReqDisplayOrder: string = null;
}

export const StageForm = (props: IStageFormProps): React.ReactElement => {

    const { dataServices, loadLookupData: { entityStatuses, users: { all: allUsers } } } = useContext(DataContext);

    useMemo(() => entityStatuses(), [entityStatuses]);
    useMemo(() => allUsers(), [allUsers]);


    const [workflowStages, setWorkflowStages] = useState<IApp03WorkflowStage[]>([]);

    useEffect(() => {
        const fetchStages = async () => {
            if (props.entityId > 0) {
                try {
                    const stages = await dataServices.app03WorkflowStageService.readWorkflowStages(props.workflowID);
                    setWorkflowStages(stages);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };

        fetchStages();
    }, [props.entityId]);



    const validateEntity = (stage: IApp03WorkflowStage): Promise<StageFormValidations> => {
        const errors = new StageFormValidations();

        if (stage.Title === null || stage.Title === '') {
            errors.Title = `Stage name is required`;
            errors.Valid = false;
        }
        else {
            errors.Title = null;
        }
        if (props.entityId > 0 && stage.ChangeDisplayOrder === true) {
            if (stage.ReqStageID === null) {
                errors.ReqStageID = "Stage selection is required to change display order";
                errors.Valid = false;
            }
            else {
                errors.ReqStageID = null;
            }
            if (stage.ReqDisplayOrder === null) {
                errors.ReqDisplayOrder = "Where to move is required to change display order";
                errors.Valid = false;
            }
            else {
                errors.ReqDisplayOrder = null;
            }
        }
        return Promise.resolve(errors);
    };

    return (
        <EntityForm<IApp03WorkflowStage, StageFormValidations>
            {...props}
            entityName='Stage'
            renderFormFields={(changeHandlers, formState) => {
                const { FormData: stage, ValidationErrors: errors } = formState;

                return (
                    <div>
                        <CrTextField
                            label="Name"
                            maxLength={100}
                            className={styles.formField}
                            required={true}
                            value={stage.Title}
                            onChange={(ev, newValue) => changeHandlers.changeTextField(newValue, 'Title')}
                            errorMessage={errors.Title}
                        />
                        {props.entityId > 0 && <CrCheckbox
                            className={`${styles.formField} ${styles.checkbox}`}
                            label="Change Display Order?"
                            checked={stage.ChangeDisplayOrder}
                            onChange={(_, isChecked) => changeHandlers.changeCheckbox(isChecked, 'ChangeDisplayOrder')}
                        />}
                        {props.entityId > 0 && stage.ChangeDisplayOrder && workflowStages.length > 0 &&
                            <>
                                <CrDropdown
                                    label="Select Stage"
                                    className={styles.formField}
                                    options={LookupService.entitiesToSelectableOptions(workflowStages.filter(x => x.ID !== props.entityId))}
                                    selectedKey={stage.ReqStageID}
                                    onChange={(_, o) => changeHandlers.changeDropdown(o, 'ReqStageID')}
                                    required={true}
                                    errorMessage={errors.ReqStageID}
                                />

                                <CrChoiceGroup
                                    label="Where to move?"
                                    className={styles.formField}
                                    options={[
                                        { key: 'before_stage', text: `Before above stage` },
                                        { key: 'after_stage', text: `After above stage` },
                                    ]}
                                    selectedKey={stage.ReqDisplayOrder}
                                    onChange={(_, option) => changeHandlers.changeChoiceGroup(option, 'ReqDisplayOrder')}
                                    required={true}
                                    errorMessage={errors.ReqDisplayOrder}
                                />
                            </>
                        }
                        {props.entityId == null && <CrChoiceGroup
                            label="Where to add?"
                            className={styles.formField}
                            options={[
                                { key: 'before_stage', text: `Before Stage ${props.requestStage?.Title}` },
                                { key: 'after_stage', text: `After Stage ${props.requestStage?.Title}` },
                            ]}
                            selectedKey={stage.ReqDisplayOrder}
                            onChange={(_, option) => changeHandlers.changeChoiceGroup(option, 'ReqDisplayOrder')}
                        />}
                    </div>
                );
            }}
            loadEntity={id => dataServices.app03WorkflowStageService.read(id, true, true)}
            loadNewEntity={() => new App03WorkflowStage(props.workflowID, props.requestStage?.ID, 'after_stage')}
            loadEntityValidations={() => new StageFormValidations()}
            onValidateEntity={validateEntity}
            onCreate={s => dataServices.app03WorkflowStageService.create(s)}
            onUpdate={s => dataServices.app03WorkflowStageService.update(s.ID, s)}
            parentEntities={dataServices.app03WorkflowStageService.parentEntities}
        />
    );
};
