import { IPartnerOrganisationRiskMitigationActionUpdate } from '../types';
import { EntityUpdateService } from './EntityUpdateService';

export class PartnerOrganisationRiskMitigationActionUpdateService extends EntityUpdateService<IPartnerOrganisationRiskMitigationActionUpdate> {
    constructor() {
        super(`/PartnerOrganisationRiskMitigationActionUpdates`);
    }

    public async readLatestUpdateForPeriod(riskMitigationActionId: number, period: Date): Promise<IPartnerOrganisationRiskMitigationActionUpdate> {
        const ru = await this.readAll(
            `?$top=1`
            + `&$filter=PartnerOrganisationRiskMitigationActionID eq ${riskMitigationActionId} and UpdatePeriod eq ${period.toISOString()}`
            + `&$expand=UpdateUser($select=ID,Title)`
            + `&$orderby=UpdateDate desc`
        );
        if (ru.length > 0)
            return ru[0];
        else
            throw new Error('No PartnerOrganisationRiskMitigationActionUpdate found.');
    }

    public async readLastSignedOffUpdateForPeriod(riskMitigationActionId: number, period: Date): Promise<IPartnerOrganisationRiskMitigationActionUpdate> {
        const ru = await this.readAll(
            `?$top=1`
            + `&$filter=PartnerOrganisationRiskMitigationActionID eq ${riskMitigationActionId} and UpdatePeriod eq ${period.toISOString()} and SignOffID ne null`
            + `&$orderby=UpdateDate desc`
        );
        if (ru.length > 0)
            return ru[0];
        else
            throw new Error('No PartnerOrganisationRiskMitigationActionUpdate found.');
    }
}