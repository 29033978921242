import { Entity, IEntity } from "./Entity";
import { IApp03Workflow } from "./App03Workflow";

export interface IApp03WorkflowStage extends IEntity {
    DisplayOrder?: number;
    ReqStageID?: number;
    ReqDisplayOrder?: string;
    ChangeDisplayOrder?: boolean;
    App03WorkflowID?: number;
    App03Workflow?: IApp03Workflow;
}

export class App03WorkflowStage extends Entity implements IApp03WorkflowStage {
    public DisplayOrder = null;
    public App03WorkflowID = null;
    public ReqStageID = null;
    public ReqDisplayOrder = null;
    public ChangeDisplayOrder = null;

    constructor(workflowID: number, reqStageID: number, reqDisplayOrder: string) {
        super();
        this.App03WorkflowID = workflowID;
        this.ReqStageID = reqStageID;
        this.ReqDisplayOrder = reqDisplayOrder;
    }
}