import { EntityWithStatusService } from './EntityWithStatusService';
import { ContextService } from './ContextService';
import { IMilestone, IEntityChildren } from '../types';
import { EntityStatus } from '../refData/EntityStatus';
import { MilestoneType } from '../refData/MilestoneType';
import { AppGlobals } from '../AppGlobals';

export class MilestoneService extends EntityWithStatusService<IMilestone> {
    public readonly parentEntities = ['LeadUser', 'WorkStream($expand=Project)', 'KeyWorkArea($expand=Directorate)', 'PartnerOrganisation'];
    protected childrenEntities = ['Contributors($expand=ContributorUser)', 'Attributes($expand=AttributeType)'];

    constructor() {
        super(`/Milestones`);
    }

    public readWithContributors(entityId: number): Promise<IMilestone> {
        return this.read(entityId, false, true);
    }

    public readAllForLookup(includeClosedEntities?: boolean, additionalFields?: string): Promise<IMilestone[]> {
        return this.readAll(
            `?$select=ID,Title,KeyWorkAreaID,WorkStreamID,PartnerOrganisationID${additionalFields ? `,${additionalFields}` : ''}`
            + `&$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosedEntities ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public readAllExpandAll(): Promise<IMilestone[]> {
        return this.readAll(
            `?$select=ID,Title,MilestoneCode`
            + `&$orderby=Title`
            + `&$expand=EntityStatus,LeadUser,KeyWorkArea($select=Title;$expand=Directorate($select=Title)),`
            + `WorkStream($select=Title;$expand=Project($select=Title)),PartnerOrganisation($select=Title),MilestoneType($select=Title)`
        );
    }

    public readAllDirectorateAndProjectMilestonesForList(includeClosedMilestones?: boolean): Promise<IMilestone[]> {
        return this.readAll(
            `?$select=ID,Title,MilestoneCode`
            + `&$orderby=Title`
            + `&$expand=EntityStatus,LeadUser,KeyWorkArea($select=Title;$expand=Directorate($select=Title))`
            + `,WorkStream($select=Title;$expand=Project($select=Title)),MilestoneType($select=Title),Contributors($expand=ContributorUser($select=Title))`
            + `&$filter=CustomerID eq ${AppGlobals.CustomerID} and (MilestoneTypeID eq ${MilestoneType.Directorate} or MilestoneTypeID eq ${MilestoneType.Project})${includeClosedMilestones ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`}`
        );
    }

    public readAllProjectMilestonesForList(includeClosedMilestones?: boolean, workStreamID?: number): Promise<IMilestone[]> {
        return this.readAll(
            `?$select=ID,Title,MilestoneCode`
            + `&$orderby=Title`
            + `&$expand=EntityStatus,LeadUser,KeyWorkArea($select=Title;$expand=Directorate($select=Title))`
            + `,WorkStream($select=Title;$expand=Project($select=Title)),MilestoneType($select=Title),Contributors($expand=ContributorUser($select=Title))`
            + `&$filter=CustomerID eq ${AppGlobals.CustomerID} and (MilestoneTypeID eq ${MilestoneType.Directorate} or MilestoneTypeID eq ${MilestoneType.Project})${includeClosedMilestones ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`}`
            + (workStreamID ? ` and WorkStreamID eq ${workStreamID}` : '')
        );
    }

    public readAllDirectorateMilestonesForList(includeClosedMilestones?: boolean, workAreaID?: number): Promise<IMilestone[]> {
        return this.readAll(
            `?$select=ID,Title,MilestoneCode`
            + `&$orderby=Title`
            + `&$expand=EntityStatus,LeadUser,KeyWorkArea($select=Title;$expand=Directorate($select=Title))`
            + `,WorkStream($select=Title;$expand=Project($select=Title)),MilestoneType($select=Title),Contributors($expand=ContributorUser($select=Title))`
            + `&$filter=CustomerID eq ${AppGlobals.CustomerID} and (MilestoneTypeID eq ${MilestoneType.Directorate} or MilestoneTypeID eq ${MilestoneType.Project})${includeClosedMilestones ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`}`
            + (workAreaID ? ` and KeyWorkAreaID eq ${workAreaID}` : '')
        );
    }

    public async readForView(milestoneID: number): Promise<IMilestone> {
        return this.read(milestoneID, false, false, ['LeadUser($select=Title,EmailAddress)', 'Contributors($select=IsReadOnly,ContributorUser;$expand=ContributorUser($select=Title,EmailAddress))', 'Attributes($expand=AttributeType($select=Title))']);
    }

    public readAllPartnerOrganisationMilestonesForList(includeClosedMilestones?: boolean): Promise<IMilestone[]> {
        return this.readAll(
            `?$select=ID,Title,MilestoneCode`
            + `&$orderby=Title`
            + `&$expand=EntityStatus,LeadUser,PartnerOrganisation($select=Title),MilestoneType($select=Title)`
            + `&$filter=MilestoneTypeID eq ${MilestoneType.PartnerOrganisation}${includeClosedMilestones ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`}`
        );
    }


    public async readMyMilestones(): Promise<IMilestone[]> {
        const username = encodeURIComponent(ContextService.Username());
        const filters = [
            `LeadUser/Username eq '${username}'`,
            `Contributors/any(c: c/ContributorUser/Username eq '${username}')`
        ];
        return this.readAll(
            `?$select=ID,Title,KeyWorkAreaID,WorkStreamID,PartnerOrganisationID`
            + `&$expand=KeyWorkArea($select=Title,DirectorateID),WorkStream($select=Title,ProjectID)`
            + `&$filter=EntityStatusID eq ${EntityStatus.Open} and (${filters.join(' or ')})`
            + `&$orderby=MilestoneCode,Title`
        );
    }

    public readPartnerOrganisationMilestones(partnerOrganisationId: number): Promise<IMilestone[]> {
        return this.readAll(
            `?$filter=PartnerOrganisationID eq ${partnerOrganisationId} and EntityStatusID eq ${EntityStatus.Open}`
            + `&$expand=LeadUser,Attributes($expand=AttributeType),Contributors`
            + `&$orderby=MilestoneCode,Title`
        );
    }

    public async entityChildren(id: number): Promise<IEntityChildren[]> {
        return this.entityChildrenSingle(id, 'Milestone updates', 'MilestoneUpdates', false);
    }

    public async countMilestonesForWorkStream(workStreamID: number): Promise<number> {
        if (workStreamID) {
            return (await this.readAll(`?$select=ID&$filter=EntityStatusID eq ${EntityStatus.Open} and WorkStreamID eq ${workStreamID}`))?.length;
        }
        return Promise.resolve(0);
    }
    
    public async countMilestonesForWorkArea(workAreaID: number): Promise<number> {
        if (workAreaID) {
            return (await this.readAll(`?$select=ID&$filter=EntityStatusID eq ${EntityStatus.Open} and KeyWorkAreaID eq ${workAreaID}`))?.length;
        }
        return Promise.resolve(0);
    }

    public async create(milestone: IMilestone): Promise<IMilestone> {
        milestone.CustomerID = AppGlobals.CustomerID;           
        return super.create(milestone);
    }
}