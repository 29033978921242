import { EntityService } from './EntityService';
import { ICADefElement, IEntity } from '../types';

export class CADefElementService extends EntityService<ICADefElement> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/CADefElements`);
    }

    public readAllDefElement(defElementGroupId: number): Promise<ICADefElement[]> {
        return this.readAll(`?$filter=DefElementGroupId eq ${defElementGroupId}`);
    }

    public readAllWithArgs(periodId: number): Promise<ICADefElement[]> {
        return this.readAll(`?$filter=PeriodId eq ${periodId}`);
    }

    public readAllForList2(periodId: number | string, formId: number): Promise<IEntity[]> {
        return this.readAll(`?periodId=${periodId}&formId=${formId}`);
    }
}