import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { PrimaryButton } from "@fluentui/react";
import { AppGlobals } from "./AppGlobals";
import { IDataServices, IUser } from "./types";
import { msalInstance } from ".";
import { UserService } from "./services";
import { CrLoadingOverlay } from "./components/cr/CrLoadingOverlay";

interface AfterSignInProps {
  userService?: UserService;
  onAccountSelection: () => void;
}

export const AfterSignIn = (props: AfterSignInProps): React.ReactElement => {
  const { instance } = useMsal();
  const [user, setUser] = useState<IUser[]>([]);
  const [username, setUsername] = useState<string>('');
  const [personName, setPersonName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const checkEmail = async (): Promise<void> => {
      try {
        const uname = msalInstance.getAllAccounts()[0].username;
        setUsername(uname);
        props.userService?.readActiveUsersByEmailAddress(uname).then(u => {
          console.log('u', u);          
          if (u.length > 0) {            
            //setPersonName(u[0].Title ?? '');
            //setUser(u);
            handleLoginSelection(u[0]);
          }
          else
            setLoading(false);
        });

      } catch (err) {
        const error = err as Error;
        //logError(`Error loading user context`, error.message);
        setLoading(false);
      }
      finally{
        
      }
    };

    checkEmail();
  }, []);

  const handleLoginSelection = (uo: IUser): void => {
    //console.log('handleLoginSelection', uo);
    AppGlobals.UserID = uo.ID;
    AppGlobals.Username = uo.Username;
    AppGlobals.CustomerID = uo['Customer']['ID'];
    AppGlobals.CustomerTitle = uo['Customer']['Title'];
    AppGlobals.UserTitle = uo.Title;
    props.onAccountSelection();
  };

  return (
    <div className="main">
      <div className="blue-banner" style={{ marginLeft: '0', paddingLeft: '0' }}>
        <div className="blue-banner-white-bold">ZedX Apps</div>
      </div>
      <div className="container">
        <div className="content" style={{ width: 'calc(100% - 38px)' }}>
          <div>
            <h2 style={{ color: 'rgb(112,146,190)', fontWeight: 'normal' }}>
              Welcome to ZedX Apps
            </h2>
            <div style={{ position: 'relative' }}>
              <CrLoadingOverlay isLoading={loading} opaque={true} />
              {user.length > 0 ? (
                <div>
                  <div style={{ paddingTop: '5px' }}>
                    As {personName}, you have access to the following accounts. Please select one to continue.
                    <br /><br />
                  </div>
                  {user.map(uo => (
                    <div key={uo.ID} style={{ display: 'flex', marginBottom: '10px' }}>
                      <div style={{ width: '250px' }}>- {uo['Customer']['Title']}</div>
                      <div>
                        <PrimaryButton text={'Select'} onClick={() => handleLoginSelection(uo)} />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  Your login details were validated with Microsoft authentication system, but we could not find a record in our database for the email address '{username}'. Please contact your administrator for assistance.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};
