import { IEntity, Entity } from "./Entity";
import { IUser } from "./User";

export interface IProgressUpdate extends IEntity {
    UpdatePeriod?: Date | null;
    UpdateDate: Date | null;
    UpdateUserID: number | null;
    RagOptionID: number | null;
    Comment: string;
    ToBeClosed: boolean | null;
    ReportingStageID: number | null;
    UpdateUser?: IUser | null;
}

export interface IProgressUpdateWithDeliveryDates extends IProgressUpdate {
    ForecastDate: Date | null;
    ActualDate: Date | null;
}

export class ProgressUpdate extends Entity implements IProgressUpdate {
    public UpdatePeriod : Date | null = null;
    public UpdateDate = null;
    public UpdateUserID = null;
    public RagOptionID = null;
    public Comment = '';
    ReportingStageID = null;
    public ToBeClosed = null;

    constructor(period?: Date | null) {
        super();
        if(period)
            this.UpdatePeriod = period;
    }
}

export class ProgressUpdateWithDeliveryDates extends ProgressUpdate implements IProgressUpdateWithDeliveryDates {
    public ForecastDate = null;
    public ActualDate = null;
}
