import * as React from 'react';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import * as types from '../../types';
import * as services from '../../services';
import EntityListCat from '../entity/EntityListCat';
import { ColumnDisplayType, ColumnType, IGenColumn } from '../../types/GenColumn';

export interface IGiaaPropertiesClsProps extends types.IBaseComponentProps {
}

export interface IGiaaPropertiesClsState {
}
export class GiaaPropertiesClsState implements IGiaaPropertiesClsState {
}

export default class GiaaPropertiesCls extends React.Component<IGiaaPropertiesClsProps, GiaaPropertiesClsState> {
    constructor(props: IGiaaPropertiesClsProps, state: IGiaaPropertiesClsState) {
        super(props);
        this.state = new GiaaPropertiesClsState();
    }

    public render(): React.ReactElement<IGiaaPropertiesClsProps> {
        return (
            <Pivot>
                <PivotItem headerText="Define Form">
                    {this.renderDefForms()}
                </PivotItem>

            </Pivot>
        );
    }

    private renderDefForms() {

        const listColumns: IGenColumn[] = [

            {
                key: 'Title',
                columnType: ColumnType.TextBox,
                name: 'Welcome Text Heading',
                fieldName: 'Title',
                minWidth: 200,
                isResizable: true,
                isRequired: true,
                fieldMaxLength: 100
            },
            {
                key: 'Details',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Welcome Text',
                fieldName: 'Details',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 5000,
                numRows: 10
            },

        ];

        return (
            <React.Fragment>
                <EntityListCat
                    allowAdd={this.isSuperUser()}
                    columns={listColumns}
                    {...this.props}
                    entityService={new services.GIAADefFormService()}
                    entityNamePlural="Define Form"
                    entityNameSingular="Define Form"
                    childEntityNameApi=""
                    childEntityNamePlural=""
                    childEntityNameSingular=""
                />
            </React.Fragment>
        );
    }

    private isSuperUser(): boolean {
        return this.props.userPermissions.UserIsSystemAdmin()
            || this.props.userPermissions.UserIsGIAAActionsSuperUser();
    }
}