import { IEntity, IEntityChildren } from '../types';
import axios, { AxiosRequestConfig } from 'axios';
import { AppGlobals } from '../AppGlobals';


export interface IEntityService<T> {
    parentEntities: string[];

    read: (
        id: number,
        includeParents?: boolean,
        includeChildren?: boolean,
        customExpandEntities?: string[]
    ) => Promise<T>;
    readAll: (querystring?: string) => Promise<T[]>;
    readAllForList: () => Promise<T[]>;
    readAllForLookup: () => Promise<T[]>;
    create: (entity: T) => Promise<T>;
    update: (entityId: number, entity: Partial<T>) => Promise<void>;
    delete: (entityId: number) => Promise<void>;
    entityChildren: (
        id: number,
        childEntityName?: string,
        childEntityUrl?: string,
        canBeAdopted?: boolean
    ) => Promise<IEntityChildren[]>;
}

export class EntityService<T extends IEntity> implements IEntityService<T> {
    protected entityUrl: string;
    public readonly parentEntities: string[] = [];
    protected childrenEntities: string[] = [];

    constructor(entityUrl: string) {
        const apiURL = process.env.REACT_APP_ZedX_API!;
        this.entityUrl = `${apiURL}${entityUrl}`;
    }

    private async getRequestConfig(): Promise<AxiosRequestConfig> {
        const accessToken = await AppGlobals.acquireAccessToken();
        return {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                UserId: AppGlobals.UserID
            },
        };
    }

    public async readEntity(querystring?: string): Promise<T> {
        const requestConfig = await this.getRequestConfig();
        return axios.get(this.entityUrl + querystring, requestConfig).then((data: any): T => {
            return data.data;
        });
    }

    public async read(id: number, includeParents?: boolean, includeChildren?: boolean, customExpandEntities?: string[], customUrl?: string): Promise<T> {
        const entitiesToExpand: string[] = [];
        if (includeParents)
            entitiesToExpand.push(...this.parentEntities);
        if (includeChildren)
            entitiesToExpand.push(...this.childrenEntities);
        if (customExpandEntities)
            entitiesToExpand.push(...customExpandEntities);
        const expand: string = entitiesToExpand.length > 0 ? `?$expand=${entitiesToExpand.join(',')}` : '';
        const requestConfig = await this.getRequestConfig();
        return axios.get(`${this.entityUrl}${customUrl ? customUrl : `(${id})`}${expand}`, requestConfig).then((data: any): T => {
            return data.data;
        });
    }

    public async readAll(querystring?: string): Promise<T[]> {
        const requestConfig = await this.getRequestConfig();
        return axios.get(this.entityUrl + (querystring || '?$orderby=Title'), requestConfig).then((data: any): T[] => {
            return data.data.value;
        });
    }

    public async readString(querystring?: string): Promise<string> {
        const requestConfig = await this.getRequestConfig();
        return axios.get(this.entityUrl + (querystring || ''), requestConfig).then((response) => {
            console.log('read string result', response.data.value);
            return response.data.value;
        });
    }

    public readAllWithArgs(arg1?: any, arg2?: any, arg3?: any): Promise<T[]> {
        return this.readAll();
    }

    public readAllExpandAll(querystring?: string): Promise<T[]> {
        return this.readAll(querystring);
    }

    public readAllForUser(querystring?: string): Promise<T[]> {
        return this.readAll(querystring);
    }

    public async readServerDate(): Promise<Date> {
        const apiURL = process.env.REACT_APP_ZedX_API!;
        this.entityUrl = `${apiURL}/HealthCheck?a=&b=true`;
        const requestConfig = await this.getRequestConfig();
        return axios.get(this.entityUrl, requestConfig).then((data: any): Date => {
            return data.data.value;
        });
    }

    public async readAllQuery(query: string): Promise<T[]> {
        const requestConfig = await this.getRequestConfig();
        requestConfig.headers['Content-Type'] = 'text/plain';

        // const config = {
        //     headers: {
        //         'Content-Type': 'text/plain',
        //     },
        // };

        return await axios.post(`${this.entityUrl}/$query`, query, requestConfig).then((data: any): T[] => {
            return data.data.value;
        });
    }


    public async readForLookup(id: number): Promise<T> {
        return this.read(id, false, false, [], `(${id})?$select=ID,Title`);
    }

    public readAllForLookup(): Promise<T[]> {
        return this.readAll(`?$select=ID,Title&$orderby=Title`);
    }

    public readAllForList(): Promise<T[]> {
        return this.readAll(`?$orderby=Title`);
    }

    public async readAnyEndPointValue(endPoint: string): Promise<any> {
        const requestConfig = await this.getRequestConfig();
        return axios.get(endPoint, requestConfig).then((data: any): any => {
            return data.data;
        });
    }

    public async readAnyEndPointValueWithPost(endPoint: string, requestBody): Promise<any> {
        const requestConfig = await this.getRequestConfig();
        return axios.post(endPoint, requestBody, requestConfig).then((data: any): any => {
            return data.data;
        });
    }

    public async create(entity: T): Promise<T> {
        const requestConfig = await this.getRequestConfig();
        const response = await axios.post(this.entityUrl, entity, requestConfig);
        return response.data;
    }

    // public update(entityId: number, entity: Partial<T>): Promise<void> {
    //     return axios.patch(`${this.entityUrl}(${entityId})`, entity);   
    // }
    public async update(entityId: number, entity: Partial<T>): Promise<void> {
        const requestConfig = await this.getRequestConfig();
        await axios.patch(`${this.entityUrl}(${entityId})`, entity, requestConfig);
    }

    public async updatePut(entityId: number, entity: T): Promise<void> {
        const requestConfig = await this.getRequestConfig();
        return axios.put(`${this.entityUrl}(${entityId})`, entity, requestConfig);
    }

    public async delete(entityId: number): Promise<void> {
        const requestConfig = await this.getRequestConfig();
        return await axios.delete(`${this.entityUrl}(${entityId})`, requestConfig);
    }


    protected async entityChildrenSingle(id: number, childEntityName: string, childEntityUrl: string, canBeAdopted: boolean): Promise<IEntityChildren[]> {
        const requestConfig = await this.getRequestConfig();
        return [
            { ChildType: childEntityName, CanBeAdopted: canBeAdopted, ChildIDs: (await axios.get(`${this.entityUrl}(${id})/${childEntityUrl}?$select=ID&$top=10`, requestConfig)).data.value.map((d: { ID: any; }) => d.ID) }
        ];
    }

    public async numberOfChildren(id: number, childEntity?: string): Promise<number> {
        const requestConfig = await this.getRequestConfig();
        return axios.get(`${this.entityUrl}(${id})/${childEntity}?$select=ID`, requestConfig).then((data: any): number => {
            return data.data.value.length;
        });
    }

    public async entityChildren(
        id: number,
        childEntityName?: string,
        childEntityUrl?: string,
        canBeAdopted?: boolean
    ): Promise<IEntityChildren[]> {
        console.log(id, childEntityName, childEntityUrl);
        return this.entityChildrenSingle(id, childEntityName!, childEntityUrl!, canBeAdopted!);
    }


    protected deduplicate(resultSets: T[][]): T[] {
        const distinctTs: T[] = [];
        resultSets.forEach(resultSet =>
            resultSet.forEach(result =>
                !distinctTs.some(e => e.ID === result.ID) && distinctTs.push(result)
            )
        );
        return distinctTs;
    }

    protected async axiosGet(url: string): Promise<any> {
        const requestConfig = await this.getRequestConfig();

        return axios.get(url, requestConfig);
    }

    protected async axiosPatch(url: string, data: any): Promise<void> {
        const requestConfig = await this.getRequestConfig();

        return axios.patch(url, data, requestConfig);
    }

    protected async axiosPost(url: string, data: any): Promise<any> {
        const requestConfig = await this.getRequestConfig();

        return axios.post(url, data, requestConfig);
    }
}