import { Entity, IEntity } from "./Entity";
import { IRisk } from "./Risk";
import { IUser } from "./User";

export interface IContributor extends IEntity {
    ContributorUserID: number | null;
    BenefitID: number | null;
    CommitmentID: number | null;
    DependencyID: number | null;
    KeyWorkAreaID: number | null;
    MetricID: number | null;
    MilestoneID: number | null;
    WorkStreamID: number | null;
    RiskID: number | null;
    RiskMitigationActionID: number | null;
    PartnerOrganisationID: number | null;
    PartnerOrganisationRiskID: number | null;
    PartnerOrganisationRiskMitigationActionID: number | null;
    Risk?: IRisk;
    ContributorUser?: IUser;
    IsReadOnly?: boolean;
}

export class Contributor extends Entity implements IContributor {
    public ContributorUserID = null;
    public BenefitID = null;
    public CommitmentID = null;
    public DependencyID = null;
    public KeyWorkAreaID = null;
    public MetricID = null;
    public MilestoneID = null;
    public WorkStreamID = null;
    public RiskID = null;
    public RiskMitigationActionID = null;
    public PartnerOrganisationID = null;
    public PartnerOrganisationRiskID = null;
    public PartnerOrganisationRiskMitigationActionID = null;
    public IsReadOnly = false;
}