import * as React from 'react';
import { IEntityFormProps2 } from '../../types';
import styles from '../../styles/cr.module.scss';
import { UpdateHeader2 } from '.././cr/UpdateHeader2';
import MiscFilesList from './miscFiles/MicsFilesList';

export interface ISection4UpdateProps extends IEntityFormProps2 {
}

export class Section4UpdateState {
    public ShowForm: boolean = false;
    public ListFilterText: string = null;
}

export default class Section4Update extends React.Component<ISection4UpdateProps, Section4UpdateState> {
    constructor(props: ISection4UpdateProps, state: Section4UpdateState) {
        super(props);
        this.state = new Section4UpdateState();
    }

    public render(): React.ReactElement<ISection4UpdateProps> {
        const { ShowForm } = this.state;
        return (
            <div className={styles.cr} style={{ overflowX: 'hidden' }}>
                <UpdateHeader2 title="Miscellaneous Files" isOpen={ShowForm}
                    leadUser=""
                    rag={this.getRag()}
                    hideRagIndicator={true}
                    onClick={this.toggleProgressUpdateForm} />
                {ShowForm && <div style={{ overflowX: 'hidden' }}
                >
                    <div style={{ width: 'auto', minHeight: '120px', marginTop: '10px', marginLeft: 'auto', marginRight: 'auto', overflowX: 'hidden' }}>
                        <MiscFilesList
                            {...this.props}
                            errorHandling={this.props.errorHandling}
                            filterText={this.state.ListFilterText}
                            onChangeFilterText={this.handleChangeFilterText}
                        />
                    </div>
                </div>}
            </div>
        );
    }

    private getRag(): number {
        return null;
    }

    public componentDidMount(): void {
    }

    public componentDidUpdate(prevProps: ISection4UpdateProps): void {
    }

    protected validateEntityUpdate = (): boolean => {
        return true;
    }

    protected cloneObject(obj, changeProp?, changeValue?) {
        if (changeProp)
            return { ...obj, [changeProp]: changeValue };
        return { ...obj };
    }

    private handleChangeFilterText = (value: string): void => {
        this.setState({ ListFilterText: value });
    }

    protected toggleProgressUpdateForm = (): void => {
        this.setState({ ShowForm: !this.state.ShowForm });
    }

}