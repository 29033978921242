import { IRiskType } from "./RiskType";
import { IThresholdAppetite } from "./ThresholdAppetite";
import { IEntity, Entity, IEntityWithCustomer, EntityWithCustomer } from "./Entity";

export interface IThreshold extends IEntityWithCustomer {
    Priority: number | null;

    RiskTypes?: IRiskType[];
    ThresholdAppetites?: IThresholdAppetite[];
}

export class Threshold extends EntityWithCustomer implements IThreshold {
    public Priority = null;
}