import { ICorporateRiskMitigationAction, IEntityChildren, IUserDirectorate } from '../types';
import { RiskMitigationActionService } from './RiskMitigationActionService';
import { ContextService } from './ContextService';
import { EntityStatus } from '../refData/EntityStatus';
import { AppGlobals } from '../AppGlobals';

export class CorporateRiskMitigationActionService extends RiskMitigationActionService<ICorporateRiskMitigationAction> {
    public readonly parentEntities = ['Risk($expand=Directorate,Attributes)', 'OwnerUser', 'CorporateRiskRiskMitigationActions($expand=Risk)'];

    constructor() {
        super(`/CorporateRiskMitigationActions`);
    }

    public readAllForList(includeClosedRiskActions?: boolean, riskID?: number): Promise<ICorporateRiskMitigationAction[]> {
        return this.readAll(
            `?$select=ID,RiskMitigationActionCode,Title`
            + `&$orderby=Title`
            + `&$expand=Risk($select=RiskCode,Title;$expand=OwnerL1($select=Title))`
            + `,OwnerUser($select=Title),EntityStatus($select=Title),Contributors($select=ContributorUser;$expand=ContributorUser($select=Title))`
            + `&$filter=Risk/CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosedRiskActions ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
            + (riskID ? ` and RiskID eq ${riskID}` : '')
        );
    }

    public readAllForList_RiskRegister(includeClosedRiskActions?: boolean, riskID?: number): Promise<ICorporateRiskMitigationAction[]> {
        return this.readAll(
            `?$select=ID,RiskMitigationActionCode,Title`
            + `&$orderby=Title`
            + `&$expand=Risk($select=RiskCode,RiskRegisterID,Title;$expand=OwnerL1($select=Title),OwnerL2($select=Title),OwnerL3($select=Title))`
            + `,OwnerUser($select=Title),EntityStatus($select=Title),Contributors($select=ContributorUser;$expand=ContributorUser($select=Title))`
            + `&$filter=Risk/CustomerID eq ${AppGlobals.CustomerID} and Risk/IsProjectRisk ne true`
            + (includeClosedRiskActions ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
            + (riskID ? ` and RiskID eq ${riskID}` : '')
        );
    }

    public async readForView(riskMitigationActionID: number): Promise<ICorporateRiskMitigationAction> {
        return this.read(riskMitigationActionID, false, false, ['Risk($select=RiskCode,Title)', 'OwnerUser($select=Title,EmailAddress)', 'Contributors($select=ContributorUser;$expand=ContributorUser($select=Title,EmailAddress))', 'CorporateRiskRiskMitigationActions($select=Risk;$expand=Risk($select=Title))']);
    }

    public readAllForLookup(includeClosedEntities?: boolean, additionalFields?: string): Promise<ICorporateRiskMitigationAction[]> {
        return this.readAll(
            `?$select=ID,Title${additionalFields ? `,${additionalFields}` : ''}`
            + `&$orderby=Title&$filter=Risk/Directorate/CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosedEntities ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public readMyActions(userDirectorates?: IUserDirectorate[]): Promise<ICorporateRiskMitigationAction[]> {
        const username = encodeURIComponent(ContextService.Username());
        let filters = [
            `OwnerUser/Username eq '${username}'`,
            `Contributors/any(c: c/ContributorUser/Username eq '${username}')`
        ];
        if (userDirectorates) {
            filters = filters.concat(userDirectorates?.filter(ud => ud.IsRiskAdmin).map(ud => `Risk/DirectorateID eq ${ud.DirectorateID}`));
        }
        return this.readAll(
            `?$select=ID,Title,RiskID`
            + `&$expand=Risk($select=Title,DirectorateID),CorporateRiskRiskMitigationActions($expand=Risk($select=Title,DirectorateID))`
            + `&$filter=EntityStatusID eq ${EntityStatus.Open} and (${filters.join(' or ')})`
            + `&$orderby=RiskID`
        );
    }

    public readMitigationActionsForRisk(riskId: number): Promise<ICorporateRiskMitigationAction[]> {
        return this.readAll(
            `?$filter=RiskID eq ${riskId} or CorporateRiskRiskMitigationActions/any(a: a/RiskID eq 171)`
            + `&$expand=OwnerUser($select=Title),EntityStatus($select=Title),Contributors`
            + `&$orderby=RiskMitigationActionCode,Title`
        );
    }

    public async entityChildren(id: number): Promise<IEntityChildren[]> {
        return this.entityChildrenSingle(id, 'Risk mitigating action updates', 'RiskMitigationActionUpdates', false);
    }
}