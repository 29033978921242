import * as React from 'react';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import { CrDropdown } from '../../components/cr/CrDropdown';
import Section from './Section';
import UpdatesTab from './UpdatesTab';
import SignOffList from './SignOffList';
import * as services from '../../services';
import * as types from '../../types';
import { IEntity, ICADefElementGroup, ICADefForm, ICAForm, CAForm, ICAPeriod, IUserContext } from '../../types';
import { CrLoadingOverlayWelcomeCat } from '../cr/CrLoadingOverlayWelcomeCat';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';

export interface ICaUpdatesClsProps extends types.IBaseComponentProps {
    userContext: IUserContext;
}

export interface ILookupData {
    DefForm: ICADefForm;
    DefElementGroups: ICADefElementGroup[];
    Periods: IEntity[];
    Teams: IEntity[];
}

export class LookupData implements ILookupData {
    public DefForm: ICADefForm;
    public DefElementGroups: ICADefElementGroup[] = [];
    public Periods: ICAPeriod[] = [];
    public Teams: IEntity[] = [];
}

export interface ICarUpdatesState<T> {
    Loading: boolean;
    LookupData: ILookupData;
    FormData: T;
    FormId: number;
    IsArchivedPeriod: boolean;
    Section1_IsOpen: boolean;
    Section1_MainList_ListFilterText: string;
    SelectedPivotKey: string;
    Section_MainList_SelectedId: number;
    Section_MainList_SelectedTitle: string;
    Section_MainList_FilteredItems: any[];
    Section1UpdateStatus: string;
}

export class CarUpdatesState<T> implements ICarUpdatesState<T> {
    public Loading = false;
    public SignOffPeriod = null;
    public FormData: T;
    public FormId: number = 0;
    public IsArchivedPeriod: boolean = false;
    public LookupData = new LookupData();
    public Section1_IsOpen: boolean = false;
    public Section1_MainList_ListFilterText: string = null;
    public SelectedPivotKey = "Controls Assurance"; //default, 1st tab selected
    public Section_MainList_SelectedId: number = 0;
    public Section_MainList_SelectedTitle: string = null;
    public Section_MainList_FilteredItems = [];
    public Section1UpdateStatus: string = null;

    constructor(formData: T) {
        this.FormData = formData;
    }
}


export default class CaUpdatesCls extends React.Component<ICaUpdatesClsProps, CarUpdatesState<ICAForm>> {

    private defFormService: services.CADefFormService = new services.CADefFormService();
    private defElementGroupService: services.CADefElementGroupService = new services.CADefElementGroupService();
    private periodService: services.CAPeriodService = new services.CAPeriodService();
    private teamService: services.OrgLevel3Service = new services.OrgLevel3Service();
    private formService: services.CAFormService = new services.CAFormService();

    private readonly headerTxt_MainTab: string = "Controls Assurance";
    private readonly headerTxt_UpdatesTab: string = "Updates";

    constructor(props: ICaUpdatesClsProps) {
        super(props);
        this.state = new CarUpdatesState(new CAForm());
    }

    public render(): React.ReactElement<ICaUpdatesClsProps> {
        return (
            <Pivot onLinkClick={this.handlePivotClick} selectedKey={`${this.state.SelectedPivotKey}`}>
                <PivotItem headerText={this.headerTxt_MainTab} itemKey={this.headerTxt_MainTab}>
                    {this.renderMainTab()}
                </PivotItem>
                {this.renderUpdatesTab()}

            </Pivot>
        );
    }

    private renderMainTab(): React.ReactElement<types.IWebPartComponentProps> {
        const { LookupData: lookups, FormId } = this.state;
        return (
            <div>
                <CrLoadingOverlayWelcomeCat isLoading={this.state.Loading} />
                <div style={{ paddingTop: "10px" }}>
                    <CrDropdown
                        placeholder="Select an Option"
                        label="Which period do you want to view or report on?"
                        options={lookups.Periods.map((p) => { return { key: p.ID, text: p.Title }; })}
                        onChange={(_, v) => this.changeDropdown(v, 'PeriodId')}
                        selectedKey={this.state.FormData.PeriodId}
                    />
                    <CrDropdown
                        placeholder="Select an Option"
                        label="Which Division/Team?"
                        options={lookups.Teams.map((t) => { return { key: t.ID, text: t.Title }; })}
                        onChange={(_, v) => this.changeDropdown(v, 'TeamId')}
                        selectedKey={this.state.FormData.TeamId}
                    />
                    <br />
                    {FormId > 0 &&
                        <Section
                            periodId={this.state.FormData.PeriodId}
                            formId={this.state.FormId}
                            sectionUpdateStatus={this.state.Section1UpdateStatus}
                            onItemTitleClick={this.handleSection_MainListItemTitleClick}
                            section_IsOpen={this.state.Section1_IsOpen}
                            onSection_toggleOpen={this.handleSection1_toggleOpen}
                            listFilterText={this.state.Section1_MainList_ListFilterText}
                            onChangeFilterText={this.handleSection1_ChangeFilterText}
                            {...this.props}
                        />
                    }
                    {FormId > 0 &&
                        <SignOffList showCancelSignOffs={this.showCancelSignOffs()} onSignOff={this.updateFormInState} canSignOffDDSection={this.canSignOff_DDSection()} canSignOffDirSection={this.canSignOff_DirSection()} formId={FormId} form={this.state.FormData} defForm={lookups.DefForm} {...this.props} />
                    }
                </div>
            </div>
        );
    }

    private renderUpdatesTab() {
        if (this.state.SelectedPivotKey === this.headerTxt_UpdatesTab) {
            return (
                <PivotItem headerText={this.headerTxt_UpdatesTab} itemKey={this.headerTxt_UpdatesTab}>
                    {this.renderUpdates()}
                </PivotItem>
            );
        }
        else
            return <React.Fragment></React.Fragment>;
    }

    private renderUpdates(): React.ReactElement<types.IWebPartComponentProps> {
        return (
            <UpdatesTab
                defElementId={this.state.Section_MainList_SelectedId}
                formId={this.state.FormId}
                form={this.state.FormData}
                filteredItemsMainList={this.state.Section_MainList_FilteredItems}
                onShowList={this.handleShowMainTab}
                externalUserLoggedIn={this.externalUserLoggedIn()}
                isArchivedPeriod={this.state.IsArchivedPeriod}
                onChangeMainListID={this.handleSection_MainListChangeSelectedID}
                {...this.props}
            />
        );
    }


    private changeDropdown = (option: IDropdownOption, f: string, index?: number): void => {
        if (f === "PeriodId") {
            if (option.key !== this.state.FormData.PeriodId) {
                const pArrTemp: ICAPeriod[] = this.state.LookupData.Periods.filter(p => p.ID === option.key);
                let isArchivedPeriod: boolean = false;
                if (pArrTemp.length > 0) {
                    if (pArrTemp[0].PeriodStatus === "Archived Period") {
                        isArchivedPeriod = true;
                    }
                }
                this.setState({ FormData: this.cloneObject(this.state.FormData, f, option.key), IsArchivedPeriod: isArchivedPeriod },
                    this.loadDefForm //load DefForm then DefElementGroups and then createFormInDb
                );
            }
        }
        else {
            //f === "TeamId"
            this.setState({ FormData: this.cloneObject(this.state.FormData, f, option.key) }, this.createFormInDb);
        }
    }

    private createFormInDb = (): void => {
        if (this.state.FormData.PeriodId > 0 && this.state.FormData.TeamId > 0) {
            const form = this.state.FormData;
            //we only want to send form.PeriodId and form.TeamId, so delete rest of the fields
            delete form.ID;
            delete form['Id'];
            delete form.DefFormId;
            delete form.Title;
            delete form.DDSignOffStatus;
            delete form.DDSignOffUserId;
            delete form.DDSignOffDate;
            delete form.DirSignOffStatus;
            delete form.DirSignOffUserId;
            delete form.DirSignOffDate;
            delete form.LastSignOffFor;
            delete form.FirstSignedOff;

            //following service only adds form in db if its needed
            this.formService.create(form).then((newForm: ICAForm): void => {
                this.setState({ FormData: newForm, FormId: newForm.ID }, this.loadFormUpdateStatuses);
            }, (err) => { });
        }

    }

    private loadPeriods = (): Promise<void | ICAPeriod[]> => {
        return this.periodService.readAll().then((pArr: ICAPeriod[]): ICAPeriod[] => {
            //get the current period
            let currentPeriodId: number = 0;
            const currentPeriod = pArr.filter(p => p.PeriodStatus === "Current Period");
            if (currentPeriod && currentPeriod.length > 0) {
                currentPeriodId = currentPeriod[0].ID;
            }

            //show status like Qtr 2 2019 ( Current Period ) in Title
            for (let i = 0; i < pArr.length; i++) {
                let p: ICAPeriod = pArr[i];
                pArr[i].Title = `${p.Title} ( ${p.PeriodStatus} )`;
            }

            //check user permissions
            if (this.superUserLoggedIn() === true) {
            }
            else {
                //dont show design periods
                pArr = pArr.filter(p => p.PeriodStatus !== "Design Period");
            }

            this.setState({
                LookupData: this.cloneObject(this.state.LookupData, 'Periods', pArr),
                FormData: this.cloneObject(this.state.FormData, "PeriodId", currentPeriodId)
            }, this.loadDefForm);
            return pArr;
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading Periods lookup data`, err.message); });
    }

    private loadTeams = (): Promise<void | IEntity[]> => {
        return this.teamService.readAllOpenTeamsForUser_ControlsAssurance().then((t: IEntity[]): IEntity[] => {
            this.setState({ LookupData: this.cloneObject(this.state.LookupData, 'Teams', t) });
            return t;
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading Teams lookup data`, err.message); });
    }

    private loadDefForm = (): void => {
        this.defFormService.readAll(`?$filter=PeriodId eq ${this.state.FormData.PeriodId}`).then((dfArr: ICADefForm[]): void => {
            if (dfArr.length > 0) {
                const df: ICADefForm = dfArr[0];
                this.setState({ LookupData: this.cloneObject(this.state.LookupData, 'DefForm', df) }, this.loadDefElementGroups);
            }
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading DefForm lookup data`, err.message); });
    }

    private loadDefElementGroups = (): void => {
        this.defElementGroupService.readAllDefElementGroups(this.state.LookupData.DefForm.ID).then((deg: ICADefElementGroup[]): void => {
            this.setState({ LookupData: this.cloneObject(this.state.LookupData, 'DefElementGroups', deg) }, this.createFormInDb);
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading DefElementGroups lookup data`, err.message); });
    }

    private loadLookups(): Promise<any> {
        return Promise.all([
            this.loadPeriods(),
            this.loadTeams(),
        ]).then(() => {
            this.setState({ Loading: false })
        });
    }

    public componentDidMount(): void {
        this.setState({ Loading: true });
        this.loadLookups();
    }

    private updateFormInState = () => {
        this.formService.read(this.state.FormId).then((form: ICAForm): void => {
            this.setState({ FormData: form });

        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading Form data`, err.message); });
    }

    private loadFormUpdateStatuses() {
        this.formService.readFormUpdateStatus(Number(this.state.FormData.PeriodId), Number(this.state.FormId)).then((res: string): void => {
            this.setState({ Section1UpdateStatus: res });

        }, (err) => { });
    }

    private handleSection_MainListItemTitleClick = (ID: number, title: string, filteredItems: any[]): void => {
        this.setState({
            SelectedPivotKey: this.headerTxt_UpdatesTab,
            Section_MainList_SelectedId: ID,
            Section_MainList_SelectedTitle: title,
            Section_MainList_FilteredItems: filteredItems
        });
    }

    private handleSection1_toggleOpen = (): void => {
        this.setState({ Section1_IsOpen: !this.state.Section1_IsOpen });
    }

    private handleSection1_ChangeFilterText = (value: string): void => {
        this.setState({ Section1_MainList_ListFilterText: value });
    }

    private handlePivotClick = (item: PivotItem): void => {
        this.props.errorHandling.clearErrors();
        this.setState({ SelectedPivotKey: item.props.headerText });
    }

    private handleShowMainTab = (): void => {
        this.props.errorHandling.clearErrors();
        this.setState({ SelectedPivotKey: this.headerTxt_MainTab });
    }

    private handleSection_MainListChangeSelectedID = (ID: number): void => {
        this.setState({
            Section_MainList_SelectedId: ID,
        });
    }

    private canSignOff_DDSection(): boolean {

        //Archived Period check - dont allow if period is archived
        if (this.state.IsArchivedPeriod === true)
            return false;

        // //Directorate check
        if (this.props.userContext.DirectorOf.length > 0) {
            return true;
        }

        //ToDo (if we do members/delegates)
        // //Directorate member check
        // let dms = this.state.DirectorateMembers;
        // for (let i = 0; i < dms.length; i++) {
        //     let dm: types.IDirectorateMember = dms[i];
        //     if (dm.CanSignOff === true)
        //         return true;
        // }

        //Teams check
        if (this.props.userContext.DeputyDirectorOf.length > 0) {
            return true;
        }

        //ToDo (if we do members/delegates)
        // //Team members check
        // let tms = this.state.TeamMembers;
        // for (let i = 0; i < tms.length; i++) {
        //     let tm: types.ITeamMember = tms[i];
        //     if (tm.CanSignOff === true)
        //         return true;
        // }

        return false;
    }

    private canSignOff_DirSection(): boolean {

        //Archived Period check - dont allow if period is archived
        if (this.state.IsArchivedPeriod === true)
            return false;

        //Directorate check
        if (this.props.userContext.DirectorOf.length > 0) {
            return true;
        }

        //ToDo (if we do members/delegates)
        // //Directorate member check
        // let dms = this.state.DirectorateMembers;
        // for (let i = 0; i < dms.length; i++) {
        //     let dm: types.IDirectorateMember = dms[i];
        //     if (dm.CanSignOff === true)
        //         return true;
        // }

        return false;
    }

    private superUserLoggedIn(): boolean {
        return this.props.userPermissions.UserIsSystemAdmin()
            || this.props.userPermissions.UserIsControlsAssuranceSuperUser();
    }

    private showCancelSignOffs(): boolean {
        //Archived Period check - dont allow if period is archived
        if (this.state.IsArchivedPeriod === true)
            return false;

        return this.superUserLoggedIn();
    }

    private cloneObject(obj, changeProp?, changeValue?) {
        if (changeProp)
            return { ...obj, [changeProp]: changeValue };
        return { ...obj };
    }

    private externalUserLoggedIn(): boolean {
        return this.props.userPermissions.UserIsExternalUser();
    }
}
