import { EntityService } from './EntityService';
import { IEntity, IPartnerOrganisationRiskRiskType, IRisk, IRiskRiskType, IRiskUpdate } from '../types';
import { IRiskType } from '../types/RiskType';
import { IThreshold } from '../types/Threshold';

export class RiskAppetiteService extends EntityService<IEntity> {
    constructor() {
        super(`/RiskAppetites`);
    }

    public readAllForLookup(): Promise<IEntity[]> {
        return this.readAll(`?$select=ID,Title&$orderby=ID`);
    }

    public static getRiskAppetite = (riskRiskTypes: IRiskRiskType[], riskTypes: IRiskType[]): string | null => {
        if (riskRiskTypes && riskRiskTypes.length > 0) {
            const riskTypeIDs = riskRiskTypes.map(rrt => rrt.RiskTypeID);
            const filteredRiskTypes = riskTypes.filter(rt => riskTypeIDs && riskTypeIDs.indexOf(rt.ID) > -1);
            const thresholds = filteredRiskTypes.map(rt => rt.Threshold).filter(th => th);
            if (thresholds && thresholds.length > 0) {
                const orderedThreshold = thresholds.sort((th1, th2) => {
                    if (th1?.Priority != null && th2?.Priority != null) {
                        return th1.Priority - th2.Priority;
                    }
                    return 0;
                }).reverse();
                return orderedThreshold[0]?.Title ?? null;
            }
        }
        return null;
    }


    public static getPartnerOrganisationRiskAppetite = (riskRiskTypes: IPartnerOrganisationRiskRiskType[], riskTypes: IRiskType[]): string | null => {
        if (riskRiskTypes && riskRiskTypes.length > 0) {
            const riskTypeIDs = riskRiskTypes.map(rrt => rrt.RiskTypeID);
            const filteredRiskTypes = riskTypes.filter(rt => riskTypeIDs && riskTypeIDs.indexOf(rt.ID) > -1);
            const thresholds = filteredRiskTypes.map(rt => rt.Threshold).filter(th => th);
            if (thresholds && thresholds.length > 0) {
                const orderedThreshold = thresholds.sort((th1, th2) => {
                    if (th1?.Priority != null && th2?.Priority != null) {
                        return th1.Priority - th2.Priority;
                    }
                    return 0;
                }).reverse();
                return orderedThreshold[0]?.Title ?? null;
            }
        }
        return null;
    }


    public static riskWithinBoundary = (riskTypes: IRiskType[], ru: IRiskUpdate, r: IRisk): boolean => {
        if (ru.RiskImpactLevelID && ru.RiskProbabilityID && r.RiskRiskTypes) {
            const riskTypeIDs = r.RiskRiskTypes.map(rrt => rrt.RiskTypeID);

            const filteredRiskTypes: IRiskType[] = riskTypes.filter(rt => riskTypeIDs && riskTypeIDs.indexOf(rt.ID) > -1);
            const thresholds: IThreshold[] = filteredRiskTypes.map(rt => rt.Threshold).filter(th => th !== undefined) as IThreshold[];

            if (thresholds && thresholds.length > 0) {
                const orderedThreshold = thresholds.sort((th1, th2) => {
                    if (th1?.Priority != null && th2?.Priority != null) {
                        return th1.Priority - th2.Priority;
                    }
                    return 0;
                });
                const priorityThresholdAppetites = orderedThreshold[0];

                if (priorityThresholdAppetites && priorityThresholdAppetites.ThresholdAppetites) {
                    return !priorityThresholdAppetites.ThresholdAppetites
                        .some(ta =>
                            ta?.RiskImpactLevelID === ru.RiskImpactLevelID
                            && ta?.RiskProbabilityID === ru.RiskProbabilityID
                            && ta.Acceptable === false);
                }
            }
        }
        return true;
    }



}