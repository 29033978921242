import React, { useContext, useMemo } from 'react';
import { IProject, Project, Attribute, EntityValidations, ISpecificEntityFormProps, IUser, Contributor } from '../../types';
import { DateService, LookupService } from '../../services';
import styles from '../../styles/cr.module.scss';
import { CrDatePicker } from '../cr/CrDatePicker';
import { CrUserPicker } from '../cr/CrUserPicker';
import { CrDropdown } from '../cr/CrDropdown';
import { CrTextField } from '../cr/CrTextField';
import { CrEntityPicker } from '../cr/CrEntityPicker';
import { EntityForm } from '../EntityForm';
import { CrReportingCyclePicker } from '../cr/CrReportingCyclePicker';
import { ReportingCycleService } from '../../services/ReportingCycleService';
import { CrCheckbox } from '../cr/CrCheckbox';
import { DataContext } from '../DataContext';
import { OrbUserContext } from '../OrbUserContext';
import { IntegrationContext } from '../IntegrationContext';
import { CrLabel } from '../cr/CrLabel';

export class ProjectValidations extends EntityValidations {
    //public DirectorateID: string = null;
    public ParentType: string = null;
    public PortfolioID: string = null;
    public ProgrammeID: string = null;
    public EndDate: string = null;
    public EntityStatusID: string = null;
    public ReportingCycle: string = null;
    public CorporateProjectID: string = null;
    public OwnerL1: string = null;
    public OwnerL2: string = null;
    public OwnerL3: string = null;
    public ManagedByL2: string = null;
    public ManagedByL3: string = null;
}

export const ProjectForm = (props: ISpecificEntityFormProps): React.ReactElement => {
    const { dataSourceName, disableProjectManagement } = useContext(IntegrationContext);
    const { userPermissions, orbConfig } = useContext(OrbUserContext);
    const { dataServices: { attributeService, contributorService, projectService }, lookupData,
        loadLookupData: { portfolios, programmes, projectStages, attributeTypes, groups, directorates, orgLevel3s, entityStatuses, userProjects, users: { all: allUsers } } } = useContext(DataContext);
    const orbOnlyProjectFlag = 'Local';

    useMemo(() => portfolios(), [portfolios]);
    useMemo(() => programmes(), [programmes]);
    useMemo(() => projectStages(), [projectStages]);
    useMemo(() => attributeTypes(), [attributeTypes]);
    useMemo(() => groups(), [groups]);
    useMemo(() => directorates(), [directorates]);
    useMemo(() => orgLevel3s(), [orgLevel3s]);
    useMemo(() => entityStatuses(), [entityStatuses]);
    //useMemo(() => projects(), [projects]);
    useMemo(() => userProjects(), [userProjects]);
    useMemo(() => allUsers(), [allUsers]);

    const validateEntity = async (project: IProject): Promise<ProjectValidations> => {
        const errors = new ProjectValidations();

        if (project.Title === null || project.Title === '') {
            errors.Title = 'Project name is required..';
            errors.Valid = false;
        }
        else {
            errors.Title = null;
        }

        if (project.ParentTypeID === null) {
            errors.ParentType = `Parent Type is required`;
            errors.Valid = false;
        }
        else {
            errors.ParentType = null;
        }

        if (project.ParentTypeID === 1 && (project.PortfolioID === null || isNaN(project.PortfolioID))) {
            errors.PortfolioID = `Parent Portfolio is required`;
            errors.Valid = false;
        }
        else {
            errors.PortfolioID = null;
        }

        if (project.ParentTypeID === 2 && (project.ProgrammeID === null || isNaN(project.ProgrammeID))) {
            errors.ProgrammeID = `Parent Programme is required`;
            errors.Valid = false;
        }
        else {
            errors.ProgrammeID = null;
        }

        if (project.OwnerTypeID === 1 && project.OwnerL1ID === null) {
            errors.OwnerL1 = `Owner ${orbConfig.OrgL1Label} is required`;
            errors.Valid = false;
        }
        else {
            errors.OwnerL1 = null;
        }
        if (project.OwnerTypeID === 2 && project.OwnerL2ID === null) {
            errors.OwnerL2 = `Owner ${orbConfig.OrgL2Label} is required`;
            errors.Valid = false;
        }
        else {
            errors.OwnerL2 = null;
        }
        if (project.OwnerTypeID === 3 && project.OwnerL3ID === null) {
            errors.OwnerL3 = `Owner ${orbConfig.OrgL3Label} is required`;
            errors.Valid = false;
        }
        else {
            errors.OwnerL3 = null;
        }
        if (project.ManagedByTypeID === 2 && project.ManagedByL2ID === null) {
            errors.ManagedByL2 = `Managed By ${orbConfig.OrgL2Label} is required`;
            errors.Valid = false;
        }
        else {
            errors.ManagedByL2 = null;
        }
        if (project.ManagedByTypeID === 3 && project.ManagedByL3ID === null) {
            errors.ManagedByL3 = `Managed By ${orbConfig.OrgL3Label} is required`;
            errors.Valid = false;
        }
        else {
            errors.ManagedByL3 = null;
        }

        if (project.EndDate !== null && project.EndDate <= project.StartDate) {
            errors.EndDate = 'Project end date must be after the project start date';
            errors.Valid = false;
        }
        else {
            errors.EndDate = null;
        }

        if (!ReportingCycleService.reportingCycleIsValid(project)) {
            errors.ReportingCycle = 'Please select all values for the reporting cycle';
            errors.Valid = false;
        }
        else {
            errors.ReportingCycle = null;
        }

        if (project.CorporateProjectID && project.CorporateProjectID !== orbOnlyProjectFlag) {
            const existingProjectsWithCorporateId = await projectService.readProjectByCorporateId(project.CorporateProjectID);
            if (existingProjectsWithCorporateId.length > 1
                || (existingProjectsWithCorporateId.length === 1 && existingProjectsWithCorporateId[0].ID !== project.ID)) {
                errors.CorporateProjectID = 'An existing project has this identifier';
                errors.Valid = false;
            } else {
                errors.CorporateProjectID = null;
            }
        }

        return Promise.resolve(errors);
    };

    const projectUsers = (project: IProject): IUser[] => {
        if (project.ID && lookupData.Users && lookupData.Users.All) {
            return lookupData.Users.All.filter(u => LookupService.userIsInProject(lookupData, u.ID, project.ID)
                || LookupService.userIsContributor(project, u.ID)
                || u.ID === project.SeniorResponsibleOwnerUserID
                || u.ID === project.ReportApproverUserID
                || u.ID === project.ProjectManagerUserID
                || u.ID === project.ReportingLeadUserID
            );
        }
        return [];
    };

    return (
        <EntityForm<IProject, ProjectValidations>
            {...props}
            entityName="Project"
            renderFormFields={({ changeTextField, changeDropdown, changeReportingCycle, changeUserPicker, changeMultiUserPicker,
                changeDatePicker, changeMultiDropdown, changeCheckbox, changeStatusDropdown }, formState) => {
                const { FormData: project, ValidationErrors: errors } = formState;
                const disableEdit = project.CorporateProjectID !== orbOnlyProjectFlag && disableProjectManagement;
                const externalDataSource = project.DataSource && project.DataSource.toLowerCase() === 'verto' ? true : false;
                const mappedUsers = projectUsers(project);
                const users = project.ID ? mappedUsers : lookupData.Users && lookupData.Users.All;
                const suggestions = project.ID ? {
                    initialSuggestionsHeaderText: 'Project users',
                    initialSuggestions: mappedUsers,
                    noResultsFoundText: mappedUsers.length === 0 ? 'No users are mapped to this project' : null
                } : {};

                return (
                    <div>
                        {/* <CrTextField
                            label="Data Source"
                            disabled={true}
                            maxLength={255}
                            className={styles.formField}
                            value={project.DataSource}
                            onChange={(ev, newValue) => changeTextField(newValue, 'DataSource')}
                        /> */}
                        <CrTextField
                            label="Name"
                            required={true}
                            disabled={externalDataSource || disableEdit || !userPermissions.UserIsSystemAdmin()}
                            maxLength={255}
                            className={styles.formField}
                            value={project.Title}
                            onChange={(ev, newValue) => changeTextField(newValue, 'Title')}
                            errorMessage={errors.Title}
                        />
                        <CrTextField
                            label="Project ref"
                            disabled={externalDataSource || disableProjectManagement}
                            maxLength={100}
                            labelIcon="Dynamics365Logo"
                            placeholder={disableProjectManagement ? "" : "Enter external reference or id if available"}
                            className={styles.formField}
                            value={project.CorporateProjectID}
                            onChange={(ev, newValue) => changeTextField(newValue, 'CorporateProjectID')}
                            errorMessage={errors.CorporateProjectID}
                        />
                        {!(props.showEntityDescription1 === true) &&
                            <CrDropdown
                                label='Parent Type'
                                required={true}
                                className={styles.formField}
                                options={LookupService.entitiesToSelectableOptions([
                                    { ID: 1, Title: 'Portfolio' },
                                    { ID: 2, Title: 'Programme' },
                                ])}
                                selectedKey={project.ParentTypeID}
                                onChange={(_, v) => changeDropdown(v, 'ParentTypeID')}
                                errorMessage={errors.ParentType}
                            />
                        }
                        {!(props.showEntityDescription1 === true) && project.ParentTypeID === 1 &&
                            <CrDropdown
                                label='Parent Portfolio'
                                required={true}
                                className={styles.formField}
                                options={LookupService.entitiesToSelectableOptions(lookupData.Portfolios.filter(x => x.ContainsValue === 'Projects'))}
                                selectedKey={project.PortfolioID}
                                onChange={(_, v) => changeDropdown(v, 'PortfolioID')}
                                errorMessage={errors.PortfolioID}
                            />
                        }
                        {!(props.showEntityDescription1 === true) && project.ParentTypeID === 2 &&
                            <CrDropdown
                                label='Parent Programme'
                                required={true}
                                className={styles.formField}
                                options={LookupService.entitiesToSelectableOptions(lookupData.Programmes)}
                                selectedKey={project.ProgrammeID}
                                onChange={(_, v) => changeDropdown(v, 'ProgrammeID')}
                                errorMessage={errors.ProgrammeID}
                            />
                        }
                        <CrDropdown
                            label='Stage'
                            required={true}
                            className={styles.formField}
                            options={LookupService.entitiesToSelectableOptions(lookupData.ProjectStages)}
                            selectedKey={project.ProjectStageID}
                            onChange={(_, v) => changeDropdown(v, 'ProjectStageID')}
                        //errorMessage={errors.PortfolioID}
                        />
                        {/* {!(props.showEntityDescription1 === true) && <CrDropdown
                            label={orbConfig.OrgL2Label}
                            required={true}
                            disabled={disableEdit || !userPermissions.UserIsSystemAdmin()}
                            className={styles.formField}
                            options={LookupService.entitiesToSelectableOptions(lookupData.Directorates)}
                            selectedKey={project.DirectorateID}
                            onChange={(_, v) => changeDropdown(v, 'DirectorateID')}
                            errorMessage={errors.DirectorateID}
                        />
                        } */}

                        <CrReportingCyclePicker
                            label="Reports due:"
                            required={true}
                            className={styles.formField}
                            cycle={{ frequency: project.ReportingFrequency, dueDay: project.ReportingDueDay, startDate: project.ReportingStartDate }}
                            onChange={cycle => changeReportingCycle(cycle)}
                            errorMessage={errors.ReportingCycle}
                        />
                        <CrDropdown
                            label="Owner Type"
                            className={styles.formField}
                            options={LookupService.entitiesToSelectableOptions([
                                { ID: 0, Title: 'Dept' },
                                { ID: 1, Title: orbConfig.OrgL1Label },
                                { ID: 2, Title: orbConfig.OrgL2Label },
                                { ID: 3, Title: orbConfig.OrgL3Label },
                            ])}
                            selectedKey={project.OwnerTypeID}
                            onChange={(_, o) => changeDropdown(o, 'OwnerTypeID')}
                        />
                        {project.OwnerTypeID === 1 &&
                            <CrDropdown
                                label={`Owner ${orbConfig.OrgL1Label}`}
                                className={styles.formField}
                                options={LookupService.entitiesToSelectableOptions(lookupData.Groups)}
                                selectedKey={project.OwnerL1ID}
                                onChange={(_, option, index) => changeDropdown(option, 'OwnerL1ID', index)}
                                errorMessage={errors.OwnerL1}
                            />
                        }
                        {project.OwnerTypeID === 2 &&
                            <CrDropdown
                                label={`Owner ${orbConfig.OrgL2Label}`}
                                className={styles.formField}
                                options={LookupService.entitiesToSelectableOptions(lookupData.Directorates)}
                                selectedKey={project.OwnerL2ID}
                                onChange={(_, option, index) => changeDropdown(option, 'OwnerL2ID', index)}
                                errorMessage={errors.OwnerL2}
                            />
                        }
                        {project.OwnerTypeID === 3 &&
                            <CrDropdown
                                label={`Owner ${orbConfig.OrgL3Label}`}
                                className={styles.formField}
                                options={LookupService.entitiesToSelectableOptions(lookupData.OrgLevel3s)}
                                selectedKey={project.OwnerL3ID}
                                onChange={(_, option, index) => changeDropdown(option, 'OwnerL3ID', index)}
                                errorMessage={errors.OwnerL3}
                            />
                        }
                        <CrDropdown
                            label="Managed By Type"
                            className={styles.formField}
                            options={LookupService.entitiesToSelectableOptions([
                                { ID: 2, Title: orbConfig.OrgL2Label },
                                { ID: 3, Title: orbConfig.OrgL3Label },
                            ])}
                            selectedKey={project.ManagedByTypeID}
                            onChange={(_, o) => changeDropdown(o, 'ManagedByTypeID')}
                        />
                        {project.ManagedByTypeID === 2 &&
                            <CrDropdown
                                label={`Managed By ${orbConfig.OrgL2Label}`}
                                className={styles.formField}
                                options={LookupService.entitiesToSelectableOptions(lookupData.Directorates)}
                                selectedKey={project.ManagedByL2ID}
                                onChange={(_, option, index) => changeDropdown(option, 'ManagedByL2ID', index)}
                                errorMessage={errors.ManagedByL2}
                            />
                        }
                        {project.ManagedByTypeID === 3 &&
                            <CrDropdown
                                label={`Managed By ${orbConfig.OrgL3Label}`}
                                className={styles.formField}
                                options={LookupService.entitiesToSelectableOptions(lookupData.OrgLevel3s)}
                                selectedKey={project.ManagedByL3ID}
                                onChange={(_, option, index) => changeDropdown(option, 'ManagedByL3ID', index)}
                                errorMessage={errors.ManagedByL3}
                            />
                        }

                        <CrUserPicker
                            label="Senior responsible owner (SRO)"
                            className={styles.formField}
                            disabled={disableEdit}
                            users={users}
                            selectedUsers={project.SeniorResponsibleOwnerUserID && [project.SeniorResponsibleOwnerUserID]}
                            onChange={v => changeUserPicker(v, 'SeniorResponsibleOwnerUserID')}
                            {...suggestions}
                        />
                        <CrUserPicker
                            label="Alternative report approver (ARA)"
                            className={styles.formField}
                            users={users}
                            selectedUsers={project.ReportApproverUserID && [project.ReportApproverUserID]}
                            onChange={v => changeUserPicker(v, 'ReportApproverUserID')}
                            {...suggestions}
                        />
                        <CrUserPicker
                            label="Project manager (PM)"
                            className={styles.formField}
                            users={users}
                            selectedUsers={project.ProjectManagerUserID && [project.ProjectManagerUserID]}
                            onChange={v => changeUserPicker(v, 'ProjectManagerUserID')}
                            {...suggestions}
                        />
                        <CrUserPicker
                            label="Reporting lead (RL)"
                            className={styles.formField}
                            users={users}
                            selectedUsers={project.ReportingLeadUserID && [project.ReportingLeadUserID]}
                            onChange={u => changeUserPicker(u, 'ReportingLeadUserID')}
                            {...suggestions}
                        />
                        <CrUserPicker
                            label="Headline update contributors (HUC)"
                            className={styles.formField}
                            users={users}
                            itemLimit={3}
                            selectedUsers={project.Contributors?.map(c => c.ContributorUserID)}
                            onChange={v => changeMultiUserPicker(v, 'Contributors', new Contributor(), 'ContributorUserID')}
                            {...suggestions}
                        />
                        <CrTextField
                            label="Objectives"
                            multiline
                            rows={6}
                            maxLength={10000}
                            className={styles.formField}
                            value={project.Objectives}
                            onChange={(ev, newValue) => changeTextField(newValue, 'Objectives')}
                        />
                        <CrDatePicker
                            label="Start date"
                            className={styles.formField}
                            disabled={disableEdit}
                            value={DateService.removeTimezoneOffset(project.StartDate)}
                            onSelectDate={date => changeDatePicker(date, 'StartDate')}
                        />
                        <CrDatePicker
                            label="End date"
                            className={styles.formField}
                            disabled={disableEdit}
                            value={DateService.removeTimezoneOffset(project.EndDate)}
                            minDate={DateService.removeTimezoneOffset(project.StartDate)}
                            onSelectDate={date => changeDatePicker(date, 'EndDate')}
                            errorMessage={errors.EndDate}
                        />
                        <CrDropdown
                            label="Attributes"
                            className={styles.formField}
                            multiSelect
                            options={LookupService.entitiesToSelectableOptions2(lookupData.AttributeTypes)}
                            selectedKeys={project.Attributes?.map(p => p.AttributeTypeID)}
                            onChange={(_, v) => changeMultiDropdown(v, 'Attributes', new Attribute(), 'AttributeTypeID')}
                        />
                        <CrCheckbox
                            className={`${styles.formField} ${styles.checkbox}`}
                            label="Show on directorate report?"
                            checked={project.ShowOnDirectorateReport}
                            onChange={(_, isChecked) => changeCheckbox(isChecked, 'ShowOnDirectorateReport')}
                        />
                        <CrDropdown
                            label="Status"
                            required={true}
                            className={styles.formField}
                            options={LookupService.entitiesToSelectableOptions(lookupData.EntityStatuses)}
                            selectedKey={project.EntityStatusID}
                            onChange={(_, v) => changeStatusDropdown(v, 'EntityStatusID')}
                            errorMessage={errors.EntityStatusID}
                        />
                        {disableProjectManagement && project.CorporateProjectID !== orbOnlyProjectFlag &&
                            <>
                                <div>
                                    <CrLabel>{dataSourceName || 'Integration'} ID</CrLabel>
                                    <p className={styles.formText}>{project.IntegrationID}</p>
                                </div>
                                <div>
                                    <CrLabel>{dataSourceName || 'Integration'} last modified</CrLabel>
                                    <p className={styles.formText}>{DateService.dateToUkDateTime(project.IntegrationLastModified)}</p>
                                </div>
                            </>
                        }
                    </div>
                );
            }}
            loadEntity={projectId => projectService.read(projectId, true, true)}
            loadNewEntity={() => {
                const proj = new Project();
                if (disableProjectManagement) proj.CorporateProjectID = orbOnlyProjectFlag;
                return proj;
            }}
            loadEntityValidations={() => new ProjectValidations()}
            onValidateEntity={validateEntity}
            onCreate={p => projectService.create(p)}
            onUpdate={p => projectService.update(p.ID, p)}
            parentEntities={projectService.parentEntities}
            childEntities={[
                {
                    ObjectParentProperty: 'Contributors',
                    ParentIdProperty: 'ProjectID',
                    ChildIdProperty: 'ContributorUserID',
                    ChildService: contributorService
                },
                {
                    ObjectParentProperty: 'Attributes',
                    ParentIdProperty: 'ProjectID',
                    ChildIdProperty: 'AttributeTypeID',
                    ChildService: attributeService
                }
            ]}
        />
    );
};
