import React, { useContext, useMemo } from 'react';
import {
    IOrgLevel3, OrgLevel3, IEntityFormValidations, EntityFormValidations,
    IEntityValidations, EntityValidations, ISpecificEntityFormProps
} from '../../types';
import { LookupService } from '../../services';
import styles from '../../styles/cr.module.scss';
import { CrUserPicker } from '../cr/CrUserPicker';
import { CrTextField } from '../cr/CrTextField';
import { CrDropdown } from '../cr/CrDropdown';
import { EntityForm } from '../EntityForm';
import { DataContext } from '../DataContext';
import { IntegrationContext } from '../IntegrationContext';
import { OrbUserContext } from '../OrbUserContext';

class OrgLevel3Validations extends EntityValidations {
    public DirectorateID: string = null;
}

export const OrgLevel3Form = (props: ISpecificEntityFormProps): React.ReactElement => {
    const { userPermissions, orbConfig } = useContext(OrbUserContext);
    const { disableGroupManagement } = useContext(IntegrationContext);
    const { dataServices, lookupData, loadLookupData: { entityStatuses, directorates, users: { all: allUsers } } } = useContext(DataContext);

    useMemo(() => entityStatuses(), [entityStatuses]);
    useMemo(() => allUsers(), [allUsers]);
    useMemo(() => directorates(), [directorates]);


    const validateEntity = (orgLevel3: IOrgLevel3): Promise<OrgLevel3Validations> => {
        const errors = new OrgLevel3Validations();

        if (orgLevel3.Title === null || orgLevel3.Title === '') {
            errors.Title = `${orbConfig.OrgL3Label} name is required`;
            errors.Valid = false;
        }
        else {
            errors.Title = null;
        }

        if (orgLevel3.DirectorateID === null) {
            errors.DirectorateID = `${orbConfig.OrgL2Label} is required`;
            errors.Valid = false;
        }
        else {
            errors.DirectorateID = null;
        }

        return Promise.resolve(errors);
    };


    return (
        <EntityForm<IOrgLevel3, OrgLevel3Validations>
            {...props}
            entityName={orbConfig.OrgL3Label}
            renderFormFields={(changeHandlers, formState) => {
                const { FormData: orgLevel3, ValidationErrors: errors } = formState;
                return (
                    <div>
                        <CrTextField
                            label="Name"
                            maxLength={100}
                            className={styles.formField}
                            required={true}
                            value={orgLevel3.Title}
                            onChange={(ev, newValue) => changeHandlers.changeTextField(newValue, 'Title')}
                            errorMessage={errors.Title} />
                        <CrDropdown
                            label={orbConfig.OrgL2Label}
                            required={true}
                            className={styles.formField}
                            options={LookupService.entitiesToSelectableOptions(lookupData.Directorates)}
                            selectedKey={orgLevel3.DirectorateID}
                            onChange={(_, option, index) => changeHandlers.changeDropdown(option, 'DirectorateID', index)}
                            errorMessage={errors.DirectorateID}
                        />
                        <CrUserPicker
                            label={orbConfig.OrgMgrL3Lable}
                            className={styles.formField}
                            users={lookupData.Users?.All}
                            selectedUsers={orgLevel3.DeputyDirectorUserID && [orgLevel3.DeputyDirectorUserID]}
                            onChange={v => changeHandlers.changeUserPicker(v, 'DeputyDirectorUserID')} />
                        <CrDropdown
                            label="Status"
                            className={styles.formField}
                            disabled={disableGroupManagement}
                            options={LookupService.entitiesToSelectableOptions(lookupData.EntityStatuses)}
                            selectedKey={orgLevel3.EntityStatusID}
                            onChange={(_, o) => changeHandlers.changeStatusDropdown(o, 'EntityStatusID')} />
                    </div>
                );
            }}
            loadEntity={id => dataServices.orgLevel3Service.read(id, true, true)}
            loadNewEntity={() => new OrgLevel3()}
            loadEntityValidations={() => new OrgLevel3Validations()}
            onValidateEntity={validateEntity}
            onCreate={g => dataServices.orgLevel3Service.create(g)}
            onUpdate={g => dataServices.orgLevel3Service.update(g.ID, g)}
        />
    );
};
