import { EntityService } from './EntityService';
import { AppGlobals } from '../AppGlobals';
import { IProjectStage } from '../types';

export class ProjectStageService extends EntityService<IProjectStage> {
    constructor() {
        super(`/ProjectStages`);
    }

    public readAllForLookup(additionalFields?: string): Promise<IProjectStage[]> {
        return this.readAll(
            `?$select=ID,Title${additionalFields ? `,${additionalFields}` : ''}`
            + `&$orderby=ID&$filter=CustomerID eq ${AppGlobals.CustomerID}`
        );
    }
}