import React from 'react';
import { IPanelHeaderRenderer, IPanelProps, Panel, PanelType } from '@fluentui/react/lib/Panel';
import { ViewFormCommandBar } from '../cr/ViewFormCommandBar';
import styles from '../../styles/cr.module.scss';
import { CrChoiceGroup } from '../cr/CrChoiceGroup';
import { Period } from '../../refData/Period';
import { CrDropdown } from '../cr/CrDropdown';
import { ISelectableOption } from '@fluentui/react/lib/SelectableOption';
import { DateService } from '../../services';

interface SignOffPageSettingsProps {
    entityName?: string;
    showPeriodSelection: boolean;
    showForm?: boolean;
    entityId?: number;
    onClosed?: () => void;
    showPreviousUpdate: string;
    onChangeShowPreviousUpdate: (string) => void;
    reportPeriod: Period;
    onChangePeriod: (period: Period) => void;
    uniqueUpdatePeriods: Date[];
    updatePeriod: Date;
    onChangeUpdatePeriod: (dateValue: Date) => void;
}

export const SignOffPageSettings: React.FC<SignOffPageSettingsProps> = ({ showPeriodSelection, showForm, showPreviousUpdate, onChangeShowPreviousUpdate, reportPeriod, onChangePeriod, uniqueUpdatePeriods, updatePeriod, onChangeUpdatePeriod, onClosed }) => {

    let uniquePeriodOptions: ISelectableOption[] = [];
    if (uniqueUpdatePeriods != null) {
        uniquePeriodOptions = uniqueUpdatePeriods.map(d => ({
            key: d.toString(),
            text: DateService.dateToUkLongDate(d)
        }));
    }

    return (
        <Panel
            className={styles.cr}
            isOpen={showForm}
            headerText='Options & Settings'
            type={PanelType.medium}
            hasCloseButton={false}
            onRenderHeader={(props: IPanelProps, defaultRender: IPanelHeaderRenderer) =>
                <div style={{ marginBottom: '20px' }} className={styles.panelHeaderText}>{defaultRender(props)}</div>
            }
            onRenderNavigation={() =>
                <ViewFormCommandBar onClose={onClosed} />
            }
        >
            <div className={styles.cr}>

                <div>
                    <CrChoiceGroup
                        label="Previous period's comments"
                        className={styles.formField}
                        options={[
                            { key: 'false', text: 'Hidden' }, { key: 'true', text: 'Shown' },
                        ]}
                        selectedKey={showPreviousUpdate}
                        onChange={(_, option) => onChangeShowPreviousUpdate(option.key)}
                    />

                    {
                        showPeriodSelection && <CrChoiceGroup
                            label="Period"
                            className={styles.formField}
                            options={[
                                { key: Period.Current.toString(), text: `Current` },
                                { key: Period.Previous.toString(), text: `Last` },
                                { key: Period.Specific.toString(), text: `Specific` },
                            ]}
                            selectedKey={reportPeriod.toString()}
                            onChange={(_, p) => onChangePeriod(Number(p.key))}
                        />
                    }

                    {
                        reportPeriod === Period.Specific && (
                            <CrDropdown
                                label='Select Update Period'
                                className={styles.formField}
                                options={uniquePeriodOptions}
                                selectedKey={updatePeriod && updatePeriod.toString()}
                                onChange={(_, p) => onChangeUpdatePeriod(new Date(p.key))}
                            />
                        )
                    }

                </div>

            </div>
        </Panel>
    );
};