import { IThreshold } from "./Threshold";
import { IEntity, Entity, IEntityWithCustomer, EntityWithCustomer } from "./Entity";

export interface IRiskType extends IEntityWithCustomer {
    ThresholdID: number | null;
    Threshold?: IThreshold;
}

export class RiskType extends EntityWithCustomer implements IRiskType {
    public ThresholdID = null;
}