import { EntityService } from './EntityService';
import { IThresholdAppetite } from '../types/ThresholdAppetite';
import { AppGlobals } from '../AppGlobals';

export class ThresholdAppetiteService extends EntityService<IThresholdAppetite> {
    constructor() {
        super(`/ThresholdAppetites`);
    }

    public readAllForList(): Promise<IThresholdAppetite[]> {
        return this.readAll(`?$expand=Threshold($select=Title),RiskImpactLevel($select=Title),RiskProbability($select=Title)`
            + `&$orderby=ThresholdID,RiskImpactLevelID,RiskProbabilityID&$filter=CustomerID eq ${AppGlobals.CustomerID}`);
    }

    public readAllForLookup(): Promise<IThresholdAppetite[]> {
        return this.readAll(`?$select=ID,Title&$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`);
    }

    public async create(thresholdAppetite: IThresholdAppetite): Promise<IThresholdAppetite> {
        thresholdAppetite.CustomerID = AppGlobals.CustomerID;           
        return super.create(thresholdAppetite);
    }
}