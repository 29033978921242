import React, { useEffect, useState } from 'react';
import { initializeIcons } from '@fluentui/react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import './App.css';
import { IWithErrorHandlingProps } from './components/withErrorHandling';
import { IDataServices } from './types';
import { AppGlobals } from './AppGlobals';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { MsSignIn } from './MsSignIn';
import { AfterSignIn } from './AfterSignIn';
import { ZedXMainApp } from './ZedXMainApp';
import { CustomSignIn } from './CustomSignIn';
import { ManageProjectsAppWithErrorHandling } from './ManageProjectsApp';
import { TrackRisksAppWithErrorHandling } from './TrackRisksApp';
import { AccountAdminAppWithErrorHandling } from './AccountAdminApp';
import { ProgresUpdatesAppWithErrorHandling } from './ProgresUpdatesApp';
import { ButtonCommandBarExample } from './test/ButtonCommandBarExample';
import { CommandBarBasicExample } from './test/CommandBarBasicExample';
import MobileDetector from './test/MobileDetector';
import MobileDetector2 from './test/MobileDetector2';
import MobileDetectorUsingHook from './test/MobileDetectorUsingHook';
import { CorporateWorkflowsAppWithErrorHandling } from './CorporateWorkflowsApp';
import { GIAAActionsAppWithErrorHandling } from './GIAAActionsApp';
import BlobFileDownloadTest from './test/BlobFileDownloadTest';
import FileUploadTest from './test/FileUploadTest';
import { NAOTrackerAppWithErrorHandling } from './NAOTrackerApp';
import { CLAppWithErrorHandling } from './CLApp';
import { GoAppWithErrorHandling } from './GoApp';
import { IAPAppWithErrorHandling } from './IAPApp';
import { CAAppWithErrorHandling } from './CAApp';
import axios from 'axios';
import { AgileSprintsAppWithErrorHandling } from './AgileSprintsApp';
import { KnowledgeHubAppWithErrorHandling } from './KnowledgeHubApp';
import { GroupNotificationsAppWithErrorHandling } from './GroupNotificationsApp';

initializeIcons();
Intl.DateTimeFormat().resolvedOptions().timeZone = 'UTC'; // Set timezone


export interface IOrbProps extends IWithErrorHandlingProps {
  dataServices: IDataServices;
  authType: string;
  menuData?: any[];
  onLogout?: () => void;
}

const App: React.FunctionComponent<IOrbProps> = (props: IOrbProps) => {

  const [userLoggedInAndSelected, setUserLoggedInAndSelected] = useState<boolean>(false);
  const { instance } = useMsal();
  const [appsMenuData, SetAppsMenuData] = useState([]);

  useEffect(() => {
    SetAppsMenuData(props.menuData);
  }, [props.menuData]);

  const handleAccountSelection = () => {
    //console.log('in handleAccountSelection', AppGlobals.Username);
    //console.log('customer id', AppGlobals.CustomerID);
    setUserLoggedInAndSelected(true);
  };

  const handleLogout = () => {
    //console.log('in handleLogout');
    if (AppGlobals.AuthType === "CustomJwt") {
      AppGlobals.UserID = null;
      AppGlobals.Username = null;
      AppGlobals.UserTitle = null;
      AppGlobals.CustomJwtToken = null;
      AppGlobals.CustomerID = null;
      AppGlobals.CustomerTitle = null;
      window.location.href = AppGlobals.ZedxNetSiteUrl;
    }
    else {
      //AzureAd Logout
      sessionStorage.clear();
      instance.logoutRedirect({
        postLogoutRedirectUri: "/"
      });

    }
    setUserLoggedInAndSelected(false);
  };

  const { authType } = props;
  //console.log('authType', authType);

  const renderApps = (
    <HashRouter>
      <Switch>
        <Route
          exact
          path={["/", "/account/:any*"]} // Specify multiple paths
          render={({ match }) => {
            return (
              <ZedXMainApp
                authType={authType}
                onLogout={handleLogout}
                menuData={appsMenuData}
                {...props}
              />
            );
          }}
        />
        <Route
          path="/progress-updates/:any*"
          render={({ match }) => {
            return <ProgresUpdatesAppWithErrorHandling onLogout={handleLogout} menuData={appsMenuData} {...props} />;
          }}
        />
        <Route
          path="/performance-reporting/:any*"
          render={({ match }) => {
            return <ManageProjectsAppWithErrorHandling onLogout={handleLogout} menuData={appsMenuData} {...props} />;
          }}
        />
        <Route
          path="/corporate-workflows/:any*"
          render={({ match }) => {
            return <CorporateWorkflowsAppWithErrorHandling onLogout={handleLogout} menuData={appsMenuData} {...props} />;
          }}
        />
        <Route
          path="/giaa-actions/:any*"
          render={({ match }) => {
            return <GIAAActionsAppWithErrorHandling onLogout={handleLogout} menuData={appsMenuData} {...props} />;
          }}
        />
        <Route
          path="/nao-tracker/:any*"
          render={({ match }) => {
            return <NAOTrackerAppWithErrorHandling onLogout={handleLogout} menuData={appsMenuData} {...props} />;
          }}
        />
        <Route
          path="/contingent-labour-processes/:any*"
          render={({ match }) => {
            return <CLAppWithErrorHandling onLogout={handleLogout} menuData={appsMenuData} {...props} />;
          }}
        />
        <Route
          path="/management-actions/:any*"
          render={({ match }) => {
            return <IAPAppWithErrorHandling onLogout={handleLogout} menuData={appsMenuData} {...props} />;
          }}
        />
        <Route
          path="/governance/:any*"
          render={({ match }) => {
            return <GoAppWithErrorHandling onLogout={handleLogout} menuData={appsMenuData} {...props} />;
          }}
        />
        <Route
          path="/controls-assurance/:any*"
          render={({ match }) => {
            return <CAAppWithErrorHandling onLogout={handleLogout} menuData={appsMenuData} {...props} />;
          }}
        />
        <Route
          path="/risk-reporting/:any*"
          render={({ match }) => {
            return <TrackRisksAppWithErrorHandling onLogout={handleLogout} menuData={appsMenuData} {...props} />;
          }}
        />
        <Route
          path="/agile-sprints/:any*"
          render={({ match }) => {
            return <AgileSprintsAppWithErrorHandling onLogout={handleLogout} menuData={appsMenuData} {...props} />;
          }}
        />
        <Route
          path="/knowledge-hub/:any*"
          render={({ match }) => {
            return <KnowledgeHubAppWithErrorHandling onLogout={handleLogout} menuData={appsMenuData} {...props} />;
          }}
        />
        <Route
          path="/group-notifications/:any*"
          render={({ match }) => {
            return <GroupNotificationsAppWithErrorHandling onLogout={handleLogout} menuData={appsMenuData} {...props} />;
          }}
        />
        <Route
          path="/admin/:any*"
          render={({ match }) => {
            return <AccountAdminAppWithErrorHandling onLogout={handleLogout} {...props} />;
          }}
        />
      </Switch>
    </HashRouter>
  );

  // const renderAppTest = (
  //   <FileUploadTest
  //     authType={authType}
  //     onLogout={handleLogout}
  //     {...props} />
  // );

  if (authType === 'CustomJwt') {
    return (
      <React.Fragment>
        {userLoggedInAndSelected ? (
          renderApps
        ) : (
          <CustomSignIn
            onAccountSelection={handleAccountSelection}
          />
        )}
      </React.Fragment>
    );
  }
  else {
    //Auth type is AzureAd
    return (
      <React.Fragment>
        <UnauthenticatedTemplate>
          <MsSignIn />
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          {userLoggedInAndSelected ? (
            renderApps
          ) : (
            <AfterSignIn
              userService={props.dataServices.userService}
              onAccountSelection={handleAccountSelection}
            />
          )}
        </AuthenticatedTemplate>
      </React.Fragment>
    );
  }
};
export default App;