import { IEntityWithStatus, EntityWithStatus } from "./EntityWithStatus";
import { IUser } from "./User";
import { IDirectorate } from "./Directorate";

export interface IOrgLevel3 extends IEntityWithStatus {
    CustomerID?: number;
    DirectorateID: number | null;
    DeputyDirectorUserID: number | null;
    DeputyDirectorUser?: IUser;
    Directorate?: IDirectorate;
}

export class OrgLevel3 extends EntityWithStatus implements IOrgLevel3 {
    public CustomerID = null;
    public DirectorateID = null;
    public DeputyDirectorUserID = null;
}