import React from "react";
import { AppGlobals } from "./AppGlobals";

interface LogoutProps {
  onLogout: () => void;
}

const Logout: React.FC<LogoutProps> = ({ onLogout }) => {

  return (
    <>
      {AppGlobals.UserTitle} ( {AppGlobals.CustomerTitle} )&nbsp;
      <span
        onClick={onLogout}
        style={{ textDecoration: 'underline', cursor: 'pointer' }}
      >
        Logout
      </span>
    </>
  );
};

export default Logout;
