import * as React from 'react';
import { DetailsList, SelectionMode, IColumn, ISelection } from '@fluentui/react/lib/DetailsList';
import { SearchObjectService } from '../../services';
import { IEntity } from '../../types';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import { CrDropdown } from '../cr/CrDropdown';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { ICommandBarItemProps, ThemeProvider } from '@fluentui/react';
import styles from '../../styles/cr.module.scss';
import '../../styles/CustomFabric2.scss';
import { CrCommandBar } from '../cr/CrCommandBar';

const controlStyles = {
    root: {
        margin: '5px -10px 0 0', //top, right, bottom, left
        maxWidth: '301px',
    }
};

const filterDrpsStyle = {
    width: "120px",
};

export interface IFilteredRecListProps {
    className?: string;
    columns: IColumn[];
    items: any[];
    filterText?: string;
    incompleteOnly: boolean;
    onChangeIncompleteOnly: (value: boolean) => void;
    justMine: boolean;
    onChangeJustMine: (value: boolean) => void;
    actionStatusTypeId: number;
    onChangeActionStatusType: (option: IDropdownOption) => void;
    onFilterChange: (value: string) => void;
    onItemTitleClick: (ID: number, title: string, filteredItems: any[]) => void;
    actionStatusTypes: IEntity[];
    selection?: ISelection;
    onAdd: () => void;
    onEdit: () => void;
    onDelete: () => void;
    editDisabled: boolean;
    deleteDisabled: boolean;
    superUserPermission: boolean;
}

export interface IFilteredRecListState {
    Columns: IColumn[];
    FilteredItems: any[];
}

export class FilteredRecList extends React.Component<IFilteredRecListProps, IFilteredRecListState> {

    constructor(props: IFilteredRecListProps) {
        super(props);

        props.columns.forEach((c) => { c.onColumnClick = this._onColumnClick; });
        this.state = {
            Columns: props.columns,
            FilteredItems: props.items,
        };
    }

    public render(): JSX.Element {
        const { props, state } = this;
        let dgActionStatusTypeOptions = this.props.actionStatusTypes.map((x) => { return { key: x.ID, text: x.Title }; });
        dgActionStatusTypeOptions = [{ key: 0, text: "All Statuses" }, ...dgActionStatusTypeOptions];

        const commandBarItems: ICommandBarItemProps[] = [];
        const farCommandBarItems: ICommandBarItemProps[] = [];

        const incompleteOnlyItem: ICommandBarItemProps = {
            key: 'incompleteOnly',
            onRender: function renderIncompleteOnly() {
                return (
                    <div className={styles.crCommandBarContainer}>
                        <div className={styles.crCommandBarPrimaryButton}>
                            <Toggle
                                onText="Incomplete Only"
                                offText="Incomplete Only"
                                styles={controlStyles}
                                checked={props.incompleteOnly}
                                onChange={(_, isChecked) => props.onChangeIncompleteOnly(isChecked)}
                            />
                        </div>
                    </div>
                );
            }
        };

        const justMineItem: ICommandBarItemProps = {
            key: 'justMine',
            onRender: function renderIncompleteOnly() {
                return (
                    <div className={styles.crCommandBarContainer}>
                        <div className={styles.crCommandBarPrimaryButton}>
                            <Toggle
                                onText="Just Mine"
                                offText="Just Mine"
                                styles={controlStyles}
                                checked={props.justMine}
                                onChange={(_, isChecked) => props.onChangeJustMine(isChecked)}
                            />
                        </div>
                    </div>
                );
            }
        };

        const statusItem: ICommandBarItemProps = {
            key: 'statusFilter',
            onRender: function renderIncompleteOnly() {
                return (
                    <div className={styles.crCommandBarContainer}>
                        <div className={styles.crCommandBarPrimaryButton}>
                            <CrDropdown
                                options={dgActionStatusTypeOptions}
                                selectedKey={props.actionStatusTypeId}
                                onChange={(_, v) => props.onChangeActionStatusType(v)}
                                styles={controlStyles}
                                style={filterDrpsStyle}
                            />
                        </div>
                    </div>
                );
            }
        };

        const addItem: ICommandBarItemProps = {
            key: 'new',
            text: 'New',
            iconProps: { iconName: 'Add' },
            onClick: props.onAdd,
        };

        const editItem: ICommandBarItemProps = {
            key: 'edit',
            text: 'Edit',
            iconProps: { iconName: 'Edit' },
            onClick: props.onEdit,
            disabled: props.editDisabled
        };

        const deleteItem = {
            key: 'delete',
            text: 'Delete',
            iconProps: { iconName: 'Delete' },
            onClick: props.onDelete,
            disabled: props.deleteDisabled
        };


        if (props.superUserPermission && props.editDisabled && props.deleteDisabled) {
            commandBarItems.push(addItem);
        }

        if (props.superUserPermission && !props.editDisabled) {
            commandBarItems.push(editItem);
        }

        if (props.superUserPermission && !props.deleteDisabled) {
            commandBarItems.push(deleteItem);
        }

        commandBarItems.push(incompleteOnlyItem);
        commandBarItems.push(justMineItem);
        commandBarItems.push(statusItem);

        if (props.onFilterChange) {
            farCommandBarItems.push(
                {
                    key: 'filter',
                    text: 'List filter',
                    inActive: true,
                    onRender: function renderFilter() {
                        return (
                            <div className={styles.crCommandBarContainer}>
                                <SearchBox placeholder="Filter items" value={props.filterText ?? ''} onChange={(_, v) => props.onFilterChange(v)} className={styles.listFilterBox} />
                            </div>
                        );
                    }
                }
            );
        }

        return (
            <ThemeProvider>
                <div>
                    <CrCommandBar className={props.className} items={commandBarItems} farItems={farCommandBarItems} />
                </div>

                <DetailsList
                    setKey={"state.FilteredItems"}
                    selectionMode={SelectionMode.single}
                    selection={props.selection}
                    columns={state.Columns}
                    items={state.FilteredItems}
                    onRenderItemColumn={this.renderItemColumn}
                />
            </ThemeProvider>
        );
    }

    public componentDidMount(): void {
        this.setState({ FilteredItems: SearchObjectService.filterEntities(this.props.items, this.props.filterText) });
    }

    public componentDidUpdate(prevProps: IFilteredRecListProps): void {

        if (prevProps.columns !== this.props.columns) {
            this.props.columns.forEach((c) => { c.onColumnClick = this._onColumnClick; });
            this.setState({ Columns: this.props.columns, FilteredItems: SearchObjectService.filterEntities(this.props.items, this.props.filterText) });
        }
        else if (prevProps.items !== this.props.items || prevProps.filterText !== this.props.filterText) {

            this.setState({ FilteredItems: SearchObjectService.filterEntities(this.props.items, this.props.filterText) });
        }
    }

    private renderItemColumn = (item: IEntity, index: number, column: IColumn) => {

        let fieldContent = item[column.fieldName as keyof IEntity] as string;

        if (column.key === "DGArea") {
            const directorate = item["Directorate"];
            return <span>{fieldContent} - {directorate}</span>;
        }
        else if (column.key === "Title") {

            const id: number = item["ID"];

            return (
                <span><a className="titleLnk" onClick={(ev) => this.props.onItemTitleClick(id, fieldContent, this.state.FilteredItems)} > {fieldContent}</a> </span>
            );
        }
        else {
            return <span>{fieldContent}</span>;
        }
    }

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { Columns, FilteredItems } = this.state;
        let newItems: any[] = FilteredItems.slice();
        const newColumns: IColumn[] = Columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol: IColumn, idx: number) => {
            return column.key === currCol.key;
        })[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        newItems = this._sortItems(newItems, currColumn.fieldName || '', currColumn.isSortedDescending);
        this.setState({
            Columns: newColumns,
            FilteredItems: newItems
        });
    }

    private _sortItems = (items: any[], sortBy: string, descending = false): any[] => {
        return items.sort((a, b) => {
            if (!a.hasOwnProperty(sortBy) || !b.hasOwnProperty(sortBy)) {
                // property doesn't exist on either object
                return 0;
            }

            const varA = (typeof a[sortBy] === 'string') ? a[sortBy].toLowerCase() : a[sortBy];
            const varB = (typeof b[sortBy] === 'string') ? b[sortBy].toLowerCase() : b[sortBy];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return ((descending) ? (comparison * -1) : comparison);
        });
    }
}
