import { Entity, IEntity } from "./Entity";
import { IUser } from "./User";
import { IApp03Workflow } from "./App03Workflow";

export interface IApp03WorkflowUser extends IEntity {
    UserID?: number;
    IsAdmin?: boolean;
    App03WorkflowID?: number;
    User?: IUser;
    App03Workflow?: IApp03Workflow;
}

export class App03WorkflowUser extends Entity implements IApp03WorkflowUser {
    public UserID = null;
    public App03WorkflowID = null;
    public IsAdmin = null;

    constructor(isAdmin: boolean) {
        super();
        this.IsAdmin = isAdmin;
    }
}