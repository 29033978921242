import { IEntityWithStatus, EntityWithStatus } from "./EntityWithStatus";
import { IUser } from "./User";
import { IDirectorate } from "./Directorate";

export interface IOrgLevel1 extends IEntityWithStatus {
    CustomerID?: number;
    DirectorGeneralUserID: number | null;
    RiskChampionDeputyDirectorUserID: number | null;
    BusinessPartnerUserID: number | null;
    DirectorGeneralUser?: IUser;
    RiskChampionUser?: IUser;
    BusinessPartnerUser?: IUser;
    Directorates?: IDirectorate[];
}

export class OrgLevel1 extends EntityWithStatus implements IOrgLevel1 {
    public DirectorGeneralUserID = null;
    public RiskChampionDeputyDirectorUserID = null;
    public BusinessPartnerUserID = null;
}