import { EntityService } from './EntityService';
import { ICLDefForm } from '../types';

export class CLDefFormService extends EntityService<ICLDefForm> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/CLDefForms`);
    }

    public welcomeAccess(): Promise<string> {
        return super.readString(`?welcomeAccess=`).then((result: string): string => {
            return result;
        });
    }

    public readDefForm(): Promise<ICLDefForm[]> {
        return this.readAll().then((e: ICLDefForm[]): ICLDefForm[] => {
            return e;
        });
    }
}