import React, { useContext } from 'react';
import { ErrorBoundary } from '../ErrorBoundary';
import { IWithErrorHandlingProps, withErrorHandling } from '../withErrorHandling';
import { IUseApiProps } from '../useApi';
import IapUpdatesCls from './IapUpdatesCls';
import { OrbUserContext } from '../OrbUserContext';

export interface IIapUpdatesProps extends IUseApiProps, IWithErrorHandlingProps {
}

const IapUpdates = (props: IIapUpdatesProps) => {
    const { userPermissions } = useContext(OrbUserContext);
    return (
        <ErrorBoundary>
            <IapUpdatesCls
                userPermissions={userPermissions}
                {...props}
            />
        </ErrorBoundary>
    );
};

export default withErrorHandling(IapUpdates);
