import React from 'react';
import styles from '../../styles/cr.module.scss';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { SaveIndicator } from './SaveIndicator';
import { SaveStatus } from '../../types';

export interface IFormButtonsProps {
    primaryText?: string;
    secondaryText?: string;
    primaryStatus?: SaveStatus;
    primaryDisabled?: boolean;
    secondaryDisabled?: boolean;
    onPrimaryClick: () => void;
    onSecondaryClick?: () => void;
    onPrimary2Click?: () => void;
    primary2Text?: string;
    primary2Disabled?: boolean;
}

export const FormButtons = (
    { primaryText, secondaryText, onPrimaryClick, onSecondaryClick, onPrimary2Click, primary2Text, primary2Disabled, primaryDisabled, secondaryDisabled, primaryStatus }: IFormButtonsProps): React.ReactElement => {
    return (
        <div>
            <PrimaryButton text={primaryText || 'Save'} className={styles.formButton} onClick={onPrimaryClick} disabled={primaryDisabled} style={{ marginRight: '5px' }} />
            {onPrimary2Click &&
                <PrimaryButton text={primary2Text || 'Save2'} className={styles.formButton}
                    onClick={onPrimary2Click}
                    disabled={primary2Disabled} style={{ marginRight: '5px' }} />
            }
            {onSecondaryClick && <DefaultButton text={secondaryText || 'Cancel'} className={styles.formButton} onClick={onSecondaryClick} disabled={secondaryDisabled} />}
            <SaveIndicator saveStatus={primaryStatus} />
        </div>
    );
};
