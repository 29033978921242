import { EntityService } from './EntityService';
import { IDivisionStat } from '../types';

export class DivisionStatService extends EntityService<IDivisionStat> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/DivisionStats`);
    }

    public readAllWithArgs(periodId: number, SPDivisionStat2: boolean): Promise<IDivisionStat[]> {
        if (SPDivisionStat2 === true)
            return this.readAll(`?periodId=${periodId}&SPDivisionStat2=`);
        else
            return this.readAll(`?periodId=${periodId}`);
    }
}