import React from 'react';

interface IHomeTilesProps {
    menuData: any[];
}

export const HomeTiles = ({ menuData }: IHomeTilesProps): React.ReactElement => {
    const imagesRoot: string = process.env.REACT_APP_ZedXImagesRoot!;
    const progressUpdatesIcon: string = `${imagesRoot}progress-updates.png`;
    const manageProjectsIcon: string = `${imagesRoot}manage_projects.png`;
    const trackRisksIcon: string = `${imagesRoot}track-risks.png`;
    const giaaactionsIcon: string = `${imagesRoot}giaaactions-apphome.png`;
    const naotrackerIcon: string = `${imagesRoot}naotracker-apphome.png`;
    const contingentLabourIcon: string = `${imagesRoot}contingent-labour-apphome.png`;
    const governanceIcon: string = `${imagesRoot}governance-apphome.png`;
    const controlsAssuranceIcon: string = `${imagesRoot}controls-assurance-apphome.png`;
    const individualActionsIcon: string = `${imagesRoot}individual-actions-apphome.png`;
    const corporateWorkflowsIcon: string = `${imagesRoot}corporate-workflows.png`;
    const agileSprintsIcon: string = `${imagesRoot}agile-sprints.png`;
    const knowledgeHubIcon: string = `${imagesRoot}knowledge-hub.png`;
    const groupNotificationsIcon: string = `${imagesRoot}group-notifications.png`;

    // - Corporate Workflows
    // - Project Portfolios
    // - Risk Registers
    // - GIAA Actions
    // - NAO/PAC Tracker
    // - Contingent Labour
    // - Governance Statements
    // - Controls and Assurance
    // - Management Actions
    // - Agile Sprints
    // - Knowledge Hub
    // - Group Notifications

    //console.log('home tiles menu', menuData);

    return (
        <div className="apps-home">
            {menuData && menuData.map((m, index) => (
                <div key={`homeTile_${index}`} className="rectangle">
                    <a href={`#${m?.AppLink}/welcome`}>
                        <img src={`${imagesRoot}${m?.AppImage}`} /*alt="Corporate Workflows"*/ />
                        <div className="rectangle-title">{m?.AppName}</div>
                    </a>
                </div>
            ))}
            {/* <div className="rectangle">
                <a href="#/corporate-workflows/welcome">
                    <img src={corporateWorkflowsIcon} alt="Corporate Workflows" />
                    <div className="rectangle-title">Corporate Workflows</div>
                </a>
            </div>
            <div className="rectangle">
                <a href="#/performance-reporting/welcome">
                    <img src={manageProjectsIcon} alt="Project Portfolios" />
                    <div className="rectangle-title">Project Portfolios</div>
                </a>
            </div>
            <div className="rectangle">
                <a href="#/risk-reporting/welcome">
                    <img src={trackRisksIcon} alt="Risk Registers" />
                    <div className="rectangle-title">Risk Registers</div>
                </a>
            </div>
            <div className="rectangle">
                <a href="#/giaa-actions/welcome">
                    <img src={giaaactionsIcon} alt="GIAA Actions" />
                    <div className="rectangle-title">GIAA Actions</div>
                </a>
            </div>
            <div className="rectangle">
                <a href="#/nao-tracker/welcome">
                    <img src={naotrackerIcon} alt="NAO/PAC Tracker" />
                    <div className="rectangle-title">NAO/PAC Tracker</div>
                </a>
            </div>
            <div className="rectangle">
                <a href="#/contingent-labour-processes/welcome">
                    <img src={contingentLabourIcon} alt="Contingent Labour" />
                    <div className="rectangle-title">Contingent Labour</div>
                </a>
            </div>
            <div className="rectangle">
                <a href="#/governance/welcome">
                    <img src={governanceIcon} alt="Governance Statements" />
                    <div className="rectangle-title">Governance Statements</div>
                </a>
            </div>
            <div className="rectangle">
                <a href="#/controls-assurance/welcome">
                    <img src={controlsAssuranceIcon} alt="Controls and Assurance" />
                    <div className="rectangle-title">Controls and Assurance</div>
                </a>
            </div>
            <div className="rectangle">
                <a href="#/management-actions/welcome">
                    <img src={individualActionsIcon} alt="Management Actions" />
                    <div className="rectangle-title">Management Actions</div>
                </a>
            </div>
            <div className="rectangle">
                <a href="/#/">
                    <img src={agileSprintsIcon} alt="Agile Sprints" />
                    <div className="rectangle-title">Agile Sprints</div>
                </a>
            </div>
            <div className="rectangle">
                <a href="/#/">
                    <img src={knowledgeHubIcon} alt="Knowledge Hub" />
                    <div className="rectangle-title">Knowledge Hub</div>
                </a>
            </div>
            <div className="rectangle">
                <a href="/#/">
                    <img src={groupNotificationsIcon} alt="Group Notifications" />
                    <div className="rectangle-title">Group Notifications</div>
                </a>
            </div> */}
        </div>
    );
};

export default HomeTiles;
