import { IReportingEntity, ReportingEntity } from "./ReportingEntity";
import { IUser } from "./User";
import { IOrgLevel1 } from "./OrgLevel1";
import { IDirectorate } from "./Directorate";
import { IOrgLevel3 } from "./OrgLevel3";
import { IPortfolio } from "./Portfolio";
import { IProgrammeStage } from "./ProgrammeStage";
import { IProgrammeUpdate } from "./ProgrammeUpdate";
import { IProject } from "./Project";

export interface IProgramme extends IReportingEntity {
    CustomerID: number | null;
    PortfolioID?: number;
    OwnerTypeID?: number;
    OwnerL0ID?: number;
    OwnerL1ID?: number;
    OwnerL2ID?: number;
    OwnerL3ID?: number;
    ManagedByTypeID?: number;
    ManagedByL2ID?: number;
    ManagedByL3ID?: number;
    ProgrammeStageID?: number;
    ProgrammeManagerUserID?: number;
    StartDate?: Date | null;
    EndDate?: Date | null;
    Objectives?: string;
    ManagedByL2?: IDirectorate
    ManagedByL3?: IOrgLevel3;
    OwnerL1?: IOrgLevel1;
    OwnerL2?: IDirectorate;
    OwnerL3?: IOrgLevel3;
    Portfolio?: IPortfolio
    ProgrammeManagerUser?: IUser;
    ProgrammeStage?: IProgrammeStage;
    ProgrammeUpdates?: IProgrammeUpdate[];
    Projects?: IProject[];
}

export class Programme extends ReportingEntity implements IProgramme {
    public CustomerID = null;
    public PortfolioID = null;
    public OwnerTypeID = null;
    public OwnerL0ID = null;
    public OwnerL1ID = null;
    public OwnerL2ID = null;
    public OwnerL3ID = null;
    public ManagedByTypeID = null;
    public ManagedByL2ID = null;
    public ManagedByL3ID = null;
    public ProgrammeStageID = null;
    public ProgrammeManagerUserID = null;
    public StartDate = null;
    public EndDate = null;
    public Objectives = null;
}