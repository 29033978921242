import { EntityService } from './EntityService';
import { IEntity, IIAPAction } from '../types';

export class IAPActionService extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/IAPActions`);
    }

    public readAllWithFilters(userIds: string, isArchive: boolean): Promise<IEntity[]> {
        return this.readAll(`?userIds=${userIds}&isArchive=${isArchive}`);
    }

    public readAllGroups(parentActionId: number): Promise<IEntity[]> {
        return this.readAll(`?parentActionId=${parentActionId}&getGroups=`);
    }

    public readWithExpandAssignments(ID: number): Promise<IIAPAction> {
        let entitiesToExpand: string[] = [];
        entitiesToExpand.push("IAPAssignments");
        entitiesToExpand.push("IAPActionDirectorates");

        return this.read(ID, false, false, entitiesToExpand).then((e: IIAPAction): IIAPAction => {
            return e;
        });
    }

    public countUpdatesForAction(actionId: number): Promise<string> {
        return super.readString(`?actionId=${actionId}&countUpdatesForAction=&extraP=`).then((result: string): string => {
            return result;
        });
    }
}