import React from 'react';
import { AppGlobals } from '../AppGlobals';

export const LoggedUserInfoForMenu = (): React.ReactElement => {
    return (
        <div className="menu-logged-user-info" >
            Logged in as {AppGlobals.UserTitle} ({AppGlobals.CustomerTitle})
        </div>
    );
};
