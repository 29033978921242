import { EntityService } from './EntityService';
import { ICADefForm } from '../types';

export class CADefFormService extends EntityService<ICADefForm> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/CADefForms`);
    }
    public readAllWithArgs(periodId: number): Promise<ICADefForm[]> {
        return this.readAll(`?$filter=PeriodId eq ${periodId}`);
    }
}