import { EntityService } from './EntityService';
import { IEntity } from '../types';

export class CLIR35ScopeService extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/CLIR35Scopes`);
    }
}