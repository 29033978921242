import { IEntity } from "./Entity";
import { IProgramme } from "./Programme";
import { IRagOption } from "./RagOption";
import { IReportingProgressUpdate, ReportingProgressUpdate } from "./ReportingProgressUpdate";

export interface IProgrammeUpdate extends IReportingProgressUpdate {
    ProgrammeID: number | null;
    OverallRagOptionID: number | null;
    FinanceRagOptionID: number | null;
    FinanceComment: string;
    PeopleRagOptionID: number | null;
    PeopleComment: string;
    MilestonesRagOptionID: number | null;
    MilestonesComment: string;
    BenefitsRagOptionID: number | null;
    BenefitsComment: string;
    ProgressUpdate: string;
    FutureActions: string;
    Escalations: string;

    ProgrammePhaseID?: number;
    WholeLifeCost: number | string;
    WholeLifeBenefit: number | string;
    NetPresentValue: number | string;

    Programme?: IProgramme | null;
    ProgrammePhase?: IEntity;
    OverallRagOption?: IRagOption;
    FinanceRagOption?: IRagOption;
    PeopleRagOption?: IRagOption;
    MilestonesRagOption?: IRagOption;
    BenefitsRagOption?: IRagOption;
}

export class ProgrammeUpdate extends ReportingProgressUpdate implements IProgrammeUpdate {
    public ProgrammeID: number | null = null;
    public OverallRagOptionID = null;
    public FinanceRagOptionID = null;
    public FinanceComment = '';
    public PeopleRagOptionID = null;
    public PeopleComment = '';
    public MilestonesRagOptionID = null;
    public MilestonesComment = '';
    public BenefitsRagOptionID = null;
    public BenefitsComment = '';
    public ProgressUpdate = '';
    public FutureActions = '';
    public Escalations = '';

    public ProgrammePhaseID = null;
    public WholeLifeCost = '';
    public WholeLifeBenefit = '';
    public NetPresentValue = '';
    
    public UpdateUser = null;
    public Programme: IProgramme | null = null;

    constructor(programmeId?: number, period?: Date) {
        super(period);
        if (programmeId)
            this.ProgrammeID = programmeId;
    }
}