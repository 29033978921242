import * as React from 'react';
import * as types from '../../types';
import * as services from '../../services';
import { IIAPActionUpdate, IAPActionUpdate, IEntity, Entity } from '../../types';
import { CrTextField } from '../cr/CrTextField';
import { CrDropdown } from '../cr/CrDropdown';
import { CrCheckbox } from '../cr/CrCheckbox';
import { FormButtons } from '../cr/FormButtons';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { FormCommandBar } from '../cr/FormCommandBar';
import { CrDatePicker } from '../cr/CrDatePicker';
import { FieldErrorMessage } from '../cr/FieldDecorators';
import { IAPActionUpdateTypes } from '../../types/AppGlobals';
import styles from '../../styles/cr.module.scss';
import { changeDatePicker } from '../../types/AppGlobals';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';

export interface IUpdatesSaveFormProps extends types.IBaseComponentProps {
    iapUpdateId: number | string;
    iapTypeId: number;
    updateType: string;
    defaultActionStatusTypeId: number;
    defaultRevDate: Date;
    entityId: number;
    showForm: boolean;
    onSaved?: (defaultIAPStatusTypeId: number, defaultRevisedDate: Date) => void;
    onCancelled?: () => void;
}

export interface ILookupData {
    IAPStatusTypes: IEntity[];
}

export class LookupData implements ILookupData {
    public IAPStatusTypes = null;
}

export interface IErrorMessage {

    Details: string;
    RevisedDate: string;
    ActionStatus: string;
    FileUpload: string;
}

export class ErrorMessage implements IErrorMessage {
    public Details = null;
    public RevisedDate = null;
    public ActionStatus = null;
    public FileUpload = null;
}

export interface IUpdatesSaveFormState {
    Loading: boolean;
    LookupData: ILookupData;
    FormData: IIAPActionUpdate;
    FormDataBeforeChanges: IIAPActionUpdate;
    FormIsDirty: boolean;
    ErrMessages: IErrorMessage;
    UploadStatus: string;
    UploadProgress: number;
    ShowUploadProgress: boolean;
}

export class UpdatesSaveFormState implements IUpdatesSaveFormState {
    public Loading = false;
    public LookupData = new LookupData();
    public FormData;
    public FormDataBeforeChanges;
    public FormIsDirty = false;
    public ErrMessages = new ErrorMessage();
    public UploadStatus = "";
    public UploadProgress: number = 0;
    public ShowUploadProgress = false;

    constructor(iapUpdateId: number, updateType: string) {
        this.FormData = new IAPActionUpdate(iapUpdateId, updateType);
        this.FormDataBeforeChanges = new IAPActionUpdate(iapUpdateId, updateType);
    }
}

export default class UpdatesSaveForm extends React.Component<IUpdatesSaveFormProps, IUpdatesSaveFormState> {
    private iapStatusTypeService: services.IAPStatusTypeService = new services.IAPStatusTypeService();
    private iapActionUpdateService: services.IAPActionUpdateService = new services.IAPActionUpdateService();
    private zFileService: services.ZFileService = new services.ZFileService();

    constructor(props: IUpdatesSaveFormProps, state: IUpdatesSaveFormState) {
        super(props);
        this.state = new UpdatesSaveFormState(Number(props.iapUpdateId), props.updateType);
    }

    public render(): React.ReactElement<IUpdatesSaveFormProps> {
        return (
            <Panel isOpen={this.props.showForm} headerText={this.getHeaderText()} type={PanelType.medium} onRenderNavigation={() => <FormCommandBar onSave={this.saveData} onCancel={this.props.onCancelled} /*saveDisabled={this.state.ShowUploadProgress}*/ />}>
                <div className={styles.cr}>
                    {this.renderFormFields()}
                    <FormButtons
                        primaryText={"Save"}
                        onPrimaryClick={() => this.saveData()}
                        primaryDisabled={this.state.ShowUploadProgress}
                        onSecondaryClick={this.props.onCancelled}
                    />
                </div>
            </Panel>
        );
    }

    public renderFormFields() {
        return (
            <React.Fragment>
                {this.renderIAPStatusTypes()}
                {this.renderRevisedDate()}
                {this.renderUpdateDetails()}
                {this.renderEvLabel()}
                {this.renderEvCheckBox()}
                {this.renderEvLinkBox()}
                {this.renderFileUpload()}

            </React.Fragment>
        );
    }

    private renderIAPStatusTypes() {
        if (this.props.updateType !== IAPActionUpdateTypes.ActionUpdate) return null;

        const giaaActionStatusTypes = this.state.LookupData.IAPStatusTypes;
        if (giaaActionStatusTypes) {
            return (
                <CrDropdown
                    label="Status"
                    placeholder="Select an Option"
                    required={true}
                    className={styles.formField}
                    options={services.LookupService.entitiesToSelectableOptions(giaaActionStatusTypes)}
                    selectedKey={this.state.FormData.IAPStatusTypeId}
                    onChanged={(v) => this.changeDropdown(v, "IAPStatusTypeId")}
                    errorMessage={this.state.ErrMessages.ActionStatus}
                />
            );
        }
        else
            return null;
    }

    private renderRevisedDate() {
        if (this.props.updateType !== IAPActionUpdateTypes.RevisedDate) return null;
        return (
            <CrDatePicker
                label="Revised Date"
                className={styles.formField}
                required={true}
                value={services.DateService.removeTimezoneOffset(this.state.FormData.RevisedDate)}
                onSelectDate={(v) => changeDatePicker(this, v, "RevisedDate")}
                errorMessage={this.state.ErrMessages.RevisedDate}
            />
        );
    }

    private renderUpdateDetails() {

        let lbl: string = "";
        if (this.props.updateType === IAPActionUpdateTypes.ActionUpdate)
            lbl = "Action Update Details";
        else if (this.props.updateType === IAPActionUpdateTypes.RevisedDate)
            lbl = "Reason for Revision";
        else if (this.props.updateType === IAPActionUpdateTypes.GIAAComment)
            lbl = "GIAA Comment";
        else
            lbl = "Add Comment or Feedback";

        return (
            <CrTextField
                label={lbl}
                className={styles.formField}
                value={this.state.FormData.UpdateDetails}
                onChange={(_, v) => this.changeTextField(v, "UpdateDetails")}
                multiline={true}
                required={true}
                errorMessage={this.state.ErrMessages.Details}
                rows={3}
            />
        );
    }

    private renderEvLabel() {
        let lbl: string = "";
        if (this.props.updateType === IAPActionUpdateTypes.ActionUpdate)
            lbl = "Optional link or evidence upload";
        else if (this.props.updateType === IAPActionUpdateTypes.RevisedDate)
            lbl = "Provide evidence of authorisation for revision";
        else if (this.props.updateType === IAPActionUpdateTypes.GIAAComment)
            lbl = "Optional link or supporting pdf upload";
        else
            lbl = "Optional link or supporting pdf upload";

        return (
            <div>{lbl}</div>
        );
    }

    private renderEvCheckBox() {
        if (this.props.updateType === IAPActionUpdateTypes.RevisedDate) return null;

        return (
            <React.Fragment>
                <CrCheckbox
                    className={`${styles.formField} ${styles.checkbox}`}
                    label="Provide a link instead of uploading a file"
                    checked={this.state.FormData.EvIsLink}
                    onChange={(ev, isChecked) => this.changeCheckboxIsLink(isChecked, "EvIsLink")}
                />
            </React.Fragment>
        );
    }

    private renderEvLinkBox() {
        if (this.state.FormData.EvIsLink === true) {
            return (
                <CrTextField
                    label="Link"
                    className={styles.formField}
                    value={this.state.FormData.EvFileName}
                    onChange={(_, v) => this.changeTextField(v, "EvFileName")}
                />
            );
        }
        else
            return false;
    }

    private renderFileUpload() {
        if (this.state.FormData.EvIsLink === true)
            return null;

        return (
            <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <div>
                    <input type="file" name="fileUpload" id="fileUpload" accept=".pdf,.msg"></input>
                    {this.state.ErrMessages.FileUpload && <FieldErrorMessage value={this.state.ErrMessages.FileUpload} />}
                    <div style={{ paddingTop: '10px' }}>
                        Please upload documents as Outlook mail .msg or .pdf. For guidelines on saving documents as .msg or .pdf, please click <span onClick={this.viewHelpPDF} style={{ textDecoration: 'underline', cursor: 'pointer' }}>here</span>.
                    </div>
                </div>
                {this.state.ShowUploadProgress && <div style={{ minHeight: '80px', marginTop: '15px' }}>
                    <div>
                        {this.state.UploadStatus}
                    </div>
                    <div>
                        {this.state.UploadProgress} %
                    </div>
                </div>}
            </div>
        );
    }

    private viewHelpPDF = () => {
        console.log('help pdf');
    }

    private getHeaderText = (): string => {
        if (this.props.updateType === IAPActionUpdateTypes.ActionUpdate)
            return "Add new action update";
        else if (this.props.updateType === IAPActionUpdateTypes.RevisedDate)
            return "Revise Implemention Date";
        else if (this.props.updateType === IAPActionUpdateTypes.GIAAComment)
            return "Add GIAA Comments";
        else
            return "Add Comment or Feedback";
    }

    private saveData = (): void => {
        if (this.validateEntity()) {
            if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(null);

            let f: IIAPActionUpdate = { ...this.state.FormData };
            if (f.ID === 0) {
                let myfile = (document.querySelector("#fileUpload") as HTMLInputElement);
                //firts create record in the db, so we can get the ID, then use the ID to append in the file name to make file name unique
                this.iapActionUpdateService.create(f).then(x => {
                    if ((f.EvIsLink === false) && myfile.files[0]) {
                        this.uploadFile(x.ID, x.UpdatedById);
                    }
                    else {
                        //its a link instead of the file, so close the form
                        this.props.onSaved(f.IAPStatusTypeId, f.RevisedDate);
                    }
                });
            }
            else {
                this.iapActionUpdateService.updatePut(f.ID, f).then(() => this.props.onSaved(f.IAPStatusTypeId, f.RevisedDate), (err) => {
                    if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error updating item`, err.message);
                });
            }
        }
    }

    private uploadFile = async (updateId: number, uploadedByUserId: number) => {
        this.setState({
            UploadStatus: "Uploading file ...",
            ShowUploadProgress: true
        });

        let myfile = (document.querySelector("#fileUpload") as HTMLInputElement).files[0];
        let fileName: string = `${updateId}_${myfile.name}`;

        try {
            const response = await this.zFileService.uploadFile('IAP', myfile, (progressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({ UploadProgress: progress });
            });

            if (response.status === true) {
                this.afterFileUpload(String(response.fileID), updateId, fileName, uploadedByUserId);
            }
            else {
                console.log('error in uploading file');
            }
        } catch (error) {
            console.error('Error uploading file', error);
        }
    }

    private afterFileUpload = (fileUniqueID: string, updateId: number, fileName: string, uploadedByUserId: number): void => {
        const fdata = { ...this.state.FormData, "FileUniqueID": fileUniqueID, "EvFileName": fileName, "ID": updateId, "UpdatedById": uploadedByUserId };
        this.setState({
            FormData: fdata
        }, this.saveData);
    }

    public componentDidMount(): void {
        this.setState({ Loading: true });
        let loadingPromises = [this.loadLookups()];
        Promise.all(loadingPromises).then(p => this.onAfterLoad(p[1])).then(p => this.setState({ Loading: false })).catch(err => this.setState({ Loading: false }));
    }

    private loadLookups(): Promise<any> {
        let proms: any[] = [];
        proms.push(this.loadIAPStatusTypes());
        return Promise.all(proms);
    }

    private loadIAPStatusTypes = (): void => {
        this.iapStatusTypeService.readAll().then((data: IEntity[]): IEntity[] => {
            let iapStatusTypes = data;
            if (this.props.iapTypeId === 6) {
                iapStatusTypes = data.filter(x => x.ID > 1);
                iapStatusTypes.forEach(x => {
                    x.Title = x['Title2'];
                });
            }
            this.setState({ LookupData: this.cloneObject(this.state.LookupData, "IAPStatusTypes", iapStatusTypes) });
            return data;
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading IAPStatusTypes lookup data`, err.message); });
    }

    private onAfterLoad = (entity: types.IEntity): void => {
        if (this.props.updateType === IAPActionUpdateTypes.ActionUpdate) {
            this.setState({ FormData: this.cloneObject(this.state.FormData, "IAPStatusTypeId", this.props.defaultActionStatusTypeId), FormIsDirty: true });
        }
        else if (this.props.updateType === IAPActionUpdateTypes.RevisedDate) {
            this.setState({ FormData: this.cloneObject(this.state.FormData, "RevisedDate", this.props.defaultRevDate), FormIsDirty: true });
        }
    }

    private validateEntity = (): boolean => {
        let returnVal: boolean = true;
        let errMsg: IErrorMessage = { ...this.state.ErrMessages };

        if ((this.state.FormData.UpdateDetails === null) || (this.state.FormData.UpdateDetails === '')) {
            errMsg.Details = "Details required";
            returnVal = false;
        }
        else {
            errMsg.Details = null;
        }

        if (this.props.updateType === IAPActionUpdateTypes.ActionUpdate && this.state.FormData.IAPStatusTypeId === null) {
            errMsg.ActionStatus = "Proposed Recommendation Status required";
            returnVal = false;
        }
        else {
            errMsg.ActionStatus = null;
        }

        if (this.props.updateType === IAPActionUpdateTypes.RevisedDate && this.state.FormData.RevisedDate === null) {
            errMsg.RevisedDate = "Revised Date required";
            returnVal = false;
        }
        else {
            errMsg.RevisedDate = null;
        }

        if (this.props.updateType === IAPActionUpdateTypes.RevisedDate) {
            const file = (document.querySelector("#fileUpload") as HTMLInputElement).files[0];

            if (file == null) {
                errMsg.FileUpload = "PDF file required";
                returnVal = false;
            }
            else {
                const fileName = file.name;
                const ext = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();

                if (ext === "pdf") {
                    errMsg.FileUpload = null;
                }
                else {
                    errMsg.FileUpload = "PDF file required";
                    returnVal = false;
                }
            }
        }
        else {
            errMsg.FileUpload = null;
        }

        //at the end set state
        this.setState({ ErrMessages: errMsg });
        return returnVal;
    }

    private cloneObject(obj, changeProp?, changeValue?) {
        if (changeProp)
            return { ...obj, [changeProp]: changeValue };
        return { ...obj };
    }

    private changeTextField = (value: string, f: string): void => {
        this.setState({ FormData: this.cloneObject(this.state.FormData, f, value), FormIsDirty: true });
    }

    private changeDropdown = (option: IDropdownOption, f: string, index?: number): void => {
        this.setState({ FormData: this.cloneObject(this.state.FormData, f, option.key), FormIsDirty: true });
    }

    protected changeCheckboxIsLink = (value: boolean, f: string): void => {
        this.setState({ FormData: this.cloneObject(this.state.FormData, f, value), /*ShowFileUpload: !value , FormIsDirty: true*/ });
    }
}