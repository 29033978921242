import { EntityService } from './EntityService';
import { IEntity } from '../types';

export class GoElementFeedbackService extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/GoElementFeedbacks`);
    }

    public readAllWithArgs(goElementId: number): Promise<IEntity[]> {
        return this.readAll(`?$filter=GoElementId eq ${goElementId}&$expand=CommentBy`);
    }
}