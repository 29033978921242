import React, { useContext, useMemo } from 'react';
import {
    IProgramme, IEntityFormValidations, EntityFormValidations,
    IEntityValidations, EntityValidations, ISpecificEntityFormProps, Contributor, Attribute, Programme
} from '../../types';
import { DateService, LookupService, ReportingCycleService } from '../../services';
import styles from '../../styles/cr.module.scss';
import { CrUserPicker } from '../cr/CrUserPicker';
import { CrTextField } from '../cr/CrTextField';
import { CrDropdown } from '../cr/CrDropdown';
import { EntityForm } from '../EntityForm';
import { DataContext } from '../DataContext';
import { IntegrationContext } from '../IntegrationContext';
import { OrbUserContext } from '../OrbUserContext';
import { CrChoiceGroup } from '../cr/CrChoiceGroup';
import { CrEntityPicker } from '../cr/CrEntityPicker';
import { CrDatePicker } from '../cr/CrDatePicker';
import { CrMultiDropdownWithText } from '../cr/CrMultiDropdownWithText';
import { error } from 'console';
import { CrReportingCyclePicker } from '../cr/CrReportingCyclePicker';

class ProgrammeValidations extends EntityValidations {
    public PortfolioID: string = null;
    public EndDate: string = null;
    public OwnerL1: string = null;
    public OwnerL2: string = null;
    public OwnerL3: string = null;
    public ManagedByL2: string = null;
    public ManagedByL3: string = null;
    public ReportingCycle: string = null;
}

export const ProgrammeForm = (props: ISpecificEntityFormProps): React.ReactElement => {
    const { userPermissions, orbConfig } = useContext(OrbUserContext);
    const { disableGroupManagement } = useContext(IntegrationContext);
    const { dataServices, lookupData, loadLookupData: { programmeStages, entityStatuses, groups, directorates, orgLevel3s, portfolios, attributeTypes, users: { all: allUsers } } } = useContext(DataContext);

    useMemo(() => entityStatuses(), [entityStatuses]);
    useMemo(() => programmeStages(), [programmeStages]);
    useMemo(() => portfolios(), [portfolios]);
    useMemo(() => allUsers(), [allUsers]);
    useMemo(() => groups(), [groups]);
    useMemo(() => directorates(), [directorates]);
    useMemo(() => orgLevel3s(), [orgLevel3s]);
    useMemo(() => attributeTypes(), [attributeTypes]);


    const validateEntity = (programme: IProgramme): Promise<ProgrammeValidations> => {
        const errors = new ProgrammeValidations();

        if (programme.Title === null || programme.Title === '') {
            errors.Title = `Programme name is required`;
            errors.Valid = false;
        }
        else {
            errors.Title = null;
        }
        if (programme.PortfolioID === null || isNaN(programme.PortfolioID)) {
            errors.PortfolioID = `Parent Portfolio is required`;
            errors.Valid = false;
        }
        else {
            errors.PortfolioID = null;
        }
        if (programme.OwnerTypeID === 1 && programme.OwnerL1ID === null) {
            errors.OwnerL1 = `Owner ${orbConfig.OrgL1Label} is required`;
            errors.Valid = false;
        }
        else {
            errors.OwnerL1 = null;
        }
        if (programme.OwnerTypeID === 2 && programme.OwnerL2ID === null) {
            errors.OwnerL2 = `Owner ${orbConfig.OrgL2Label} is required`;
            errors.Valid = false;
        }
        else {
            errors.OwnerL2 = null;
        }
        if (programme.OwnerTypeID === 3 && programme.OwnerL3ID === null) {
            errors.OwnerL3 = `Owner ${orbConfig.OrgL3Label} is required`;
            errors.Valid = false;
        }
        else {
            errors.OwnerL3 = null;
        }
        if (programme.ManagedByTypeID === 2 && programme.ManagedByL2ID === null) {
            errors.ManagedByL2 = `Managed By ${orbConfig.OrgL2Label} is required`;
            errors.Valid = false;
        }
        else {
            errors.ManagedByL2 = null;
        }
        if (programme.ManagedByTypeID === 3 && programme.ManagedByL3ID === null) {
            errors.ManagedByL3 = `Managed By ${orbConfig.OrgL3Label} is required`;
            errors.Valid = false;
        }
        else {
            errors.ManagedByL3 = null;
        }
        if (programme.EndDate !== null && programme.EndDate <= programme.StartDate) {
            errors.EndDate = 'End date must be after the programme start date';
            errors.Valid = false;
        }
        else {
            errors.EndDate = null;
        }
        if (!ReportingCycleService.reportingCycleIsValid(programme)) {
            errors.ReportingCycle = 'Please select all values for the reporting cycle';
            errors.Valid = false;
        }
        else {
            errors.ReportingCycle = null;
        }

        return Promise.resolve(errors);
    };


    return (
        <EntityForm<IProgramme, ProgrammeValidations>
            {...props}
            entityName='Programme'
            renderFormFields={(changeHandlers, formState) => {
                const { FormData: programme, ValidationErrors: errors } = formState;
                return (
                    <div>
                        <CrTextField
                            label="Name"
                            maxLength={100}
                            className={styles.formField}
                            required={true}
                            value={programme.Title}
                            onChange={(ev, newValue) => changeHandlers.changeTextField(newValue, 'Title')}
                            errorMessage={errors.Title} />

                        {!(props.showEntityDescription1 === true) &&
                            <CrDropdown
                                label='Parent Portfolio'
                                required={true}
                                className={styles.formField}
                                options={LookupService.entitiesToSelectableOptions(lookupData.Portfolios.filter(x => x.ContainsValue === 'Projects'))}
                                selectedKey={programme.PortfolioID}
                                onChange={(_, v) => changeHandlers.changeDropdown(v, 'PortfolioID')}
                                errorMessage={errors.PortfolioID}
                            />
                        }
                        <CrDropdown
                            label='Stage'
                            required={true}
                            className={styles.formField}
                            options={LookupService.entitiesToSelectableOptions(lookupData.ProgrammeStages)}
                            selectedKey={programme.ProgrammeStageID}
                            onChange={(_, v) => changeHandlers.changeDropdown(v, 'ProgrammeStageID')}
                        />

                        <CrReportingCyclePicker
                            label="Reports due:"
                            required={true}
                            className={styles.formField}
                            cycle={{ frequency: programme.ReportingFrequency, dueDay: programme.ReportingDueDay, startDate: programme.ReportingStartDate }}
                            onChange={cycle => changeHandlers.changeReportingCycle(cycle)}
                            errorMessage={errors.ReportingCycle}
                        />

                        <CrDropdown
                            label="Owner Type"
                            className={styles.formField}
                            options={LookupService.entitiesToSelectableOptions([
                                { ID: 0, Title: 'Dept' },
                                { ID: 1, Title: orbConfig.OrgL1Label },
                                { ID: 2, Title: orbConfig.OrgL2Label },
                                { ID: 3, Title: orbConfig.OrgL3Label },
                            ])}
                            selectedKey={programme.OwnerTypeID}
                            onChange={(_, o) => changeHandlers.changeDropdown(o, 'OwnerTypeID')}
                        />
                        {programme.OwnerTypeID === 1 &&
                            <CrDropdown
                                label={`Owner ${orbConfig.OrgL1Label}`}
                                className={styles.formField}
                                options={LookupService.entitiesToSelectableOptions(lookupData.Groups)}
                                selectedKey={programme.OwnerL1ID}
                                onChange={(_, option, index) => changeHandlers.changeDropdown(option, 'OwnerL1ID', index)}
                                errorMessage={errors.OwnerL1}
                            />
                        }
                        {programme.OwnerTypeID === 2 &&
                            <CrDropdown
                                label={`Owner ${orbConfig.OrgL2Label}`}
                                className={styles.formField}
                                options={LookupService.entitiesToSelectableOptions(lookupData.Directorates)}
                                selectedKey={programme.OwnerL2ID}
                                onChange={(_, option, index) => changeHandlers.changeDropdown(option, 'OwnerL2ID', index)}
                                errorMessage={errors.OwnerL2}
                            />
                        }
                        {programme.OwnerTypeID === 3 &&
                            <CrDropdown
                                label={`Owner ${orbConfig.OrgL3Label}`}
                                className={styles.formField}
                                options={LookupService.entitiesToSelectableOptions(lookupData.OrgLevel3s)}
                                selectedKey={programme.OwnerL3ID}
                                onChange={(_, option, index) => changeHandlers.changeDropdown(option, 'OwnerL3ID', index)}
                                errorMessage={errors.OwnerL3}
                            />
                        }
                        <CrDropdown
                            label="Managed By Type"
                            className={styles.formField}
                            options={LookupService.entitiesToSelectableOptions([
                                { ID: 2, Title: orbConfig.OrgL2Label },
                                { ID: 3, Title: orbConfig.OrgL3Label },
                            ])}
                            selectedKey={programme.ManagedByTypeID}
                            onChange={(_, o) => changeHandlers.changeDropdown(o, 'ManagedByTypeID')}
                        />
                        {programme.ManagedByTypeID === 2 &&
                            <CrDropdown
                                label={`Managed By ${orbConfig.OrgL2Label}`}
                                className={styles.formField}
                                options={LookupService.entitiesToSelectableOptions(lookupData.Directorates)}
                                selectedKey={programme.ManagedByL2ID}
                                onChange={(_, option, index) => changeHandlers.changeDropdown(option, 'ManagedByL2ID', index)}
                                errorMessage={errors.ManagedByL2}
                            />
                        }
                        {programme.ManagedByTypeID === 3 &&
                            <CrDropdown
                                label={`Managed By ${orbConfig.OrgL3Label}`}
                                className={styles.formField}
                                options={LookupService.entitiesToSelectableOptions(lookupData.OrgLevel3s)}
                                selectedKey={programme.ManagedByL3ID}
                                onChange={(_, option, index) => changeHandlers.changeDropdown(option, 'ManagedByL3ID', index)}
                                errorMessage={errors.ManagedByL3}
                            />
                        }
                        <CrUserPicker
                            label="Programme manager (PM)"
                            className={styles.formField}
                            users={lookupData.Users?.All}
                            selectedUsers={programme.ProgrammeManagerUserID && [programme.ProgrammeManagerUserID]}
                            onChange={v => changeHandlers.changeUserPicker(v, 'ProgrammeManagerUserID')}
                        //{...suggestions}
                        />
                        <CrUserPicker
                            label="Contributors (C)"
                            className={styles.formField}
                            users={lookupData.Users?.All}
                            itemLimit={3}
                            selectedUsers={programme.Contributors?.map(c => c.ContributorUserID)}
                            onChange={v => changeHandlers.changeMultiUserPicker(v, 'Contributors', new Contributor(), 'ContributorUserID')}
                        //{...suggestions}
                        />
                        <CrTextField
                            label="Objectives"
                            multiline
                            rows={6}
                            maxLength={10000}
                            className={styles.formField}
                            value={programme.Objectives}
                            onChange={(ev, newValue) => changeHandlers.changeTextField(newValue, 'Objectives')}
                        />
                        <CrDatePicker
                            label="Start date"
                            className={styles.formField}
                            value={DateService.removeTimezoneOffset(programme.StartDate)}
                            onSelectDate={date => changeHandlers.changeDatePicker(date, 'StartDate')}
                        />
                        <CrDatePicker
                            label="End date"
                            className={styles.formField}
                            value={DateService.removeTimezoneOffset(programme.EndDate)}
                            minDate={DateService.removeTimezoneOffset(programme.StartDate)}
                            onSelectDate={date => changeHandlers.changeDatePicker(date, 'EndDate')}
                            errorMessage={errors.EndDate}
                        />

                        <CrMultiDropdownWithText
                            label="Attributes"
                            className={styles.formField}
                            textMaxLength={255}
                            options={lookupData.AttributeTypes.map(a => ({ key: a.ID, text: a.Title, textRequired: false }))}
                            selectedItems={LookupService.attributesToDropdownWithText(programme.Attributes)}
                            onChange={v => changeHandlers.changeMultiDropdownWithText(v, 'Attributes', new Attribute(), 'AttributeTypeID', 'AttributeValue')}
                        />
                        <CrDropdown
                            label="Status"
                            className={styles.formField}
                            disabled={disableGroupManagement}
                            options={LookupService.entitiesToSelectableOptions(lookupData.EntityStatuses)}
                            selectedKey={programme.EntityStatusID}
                            onChange={(_, o) => changeHandlers.changeStatusDropdown(o, 'EntityStatusID')}
                        />

                    </div>
                );
            }}
            loadEntity={id => dataServices.programmeService.read(id, true, true)}
            loadNewEntity={() => new Programme()}
            loadEntityValidations={() => new ProgrammeValidations()}
            onValidateEntity={validateEntity}
            onCreate={p => dataServices.programmeService.create(p)}
            onUpdate={p => dataServices.programmeService.update(p.ID, p)}
            parentEntities={dataServices.programmeService.parentEntities}
            childEntities={[
                {
                    ObjectParentProperty: 'Contributors',
                    ParentIdProperty: 'ProgrammeID',
                    ChildIdProperty: 'ContributorUserID',
                    ChildService: dataServices.contributorService
                },
                {
                    ObjectParentProperty: 'Attributes',
                    ParentIdProperty: 'ProgrammeID',
                    ChildIdProperty: 'AttributeTypeID',
                    ChildService: dataServices.attributeService
                }
            ]}
        />
    );
};
