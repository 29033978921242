import { EntityService } from './EntityService';
import { IEntity, IEntityChildren, IEntityWithCustomer } from '../types';
import { AppGlobals } from '../AppGlobals';

export class BenefitTypeService extends EntityService<IEntityWithCustomer> {
    constructor() {
        super(`/BenefitTypes`);
    }

    public async entityChildren(id: number): Promise<IEntityChildren[]> {
        return this.entityChildrenSingle(id, 'Benefits', 'Benefits', true);
    }

    public readAllForList(): Promise<IEntityWithCustomer[]> {
        return super.readAll(`?$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`);
    }

    public readAllForLookup(): Promise<IEntityWithCustomer[]> {
        return this.readAll(`?$select=ID,Title&$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`);
    }

    public async create(milestoneType: IEntityWithCustomer): Promise<IEntityWithCustomer> {
        milestoneType.CustomerID = AppGlobals.CustomerID;           
        return super.create(milestoneType);
    }
}