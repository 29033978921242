import * as React from 'react';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import styles from '../../styles/cr.module.scss';

export interface IPeriodListCommandBarProps {
    onAdd: () => void;
    onEdit: () => void;
    onDelete: () => void;
    onMakeCurrent: () => void;
    onFilterChange: (filterText: string) => void;
    editDisabled?: boolean;
    deleteDisabled?: boolean;
    makeCurrentDisabled?: boolean;
    onAddChild?: () => void;
    addChildName?: string;
    allowAdd?: boolean;
}

export class PeriodListCommandBar extends React.Component<IPeriodListCommandBarProps, any> {
    public render(): JSX.Element {
        const { props } = this;

        const commandBarItems: ICommandBarItemProps[] = [];
        const farCommandBarItems: ICommandBarItemProps[] = [];

        const addItem: ICommandBarItemProps = {
            key: 'Add',
            text: 'New',
            iconProps: { iconName: 'Add' },
            onClick: props.onAdd,
        };

        const editItem: ICommandBarItemProps = {
            key: 'Edit',
            text: 'Edit',
            iconProps: { iconName: 'Edit' },
            onClick: props.onEdit,
            disabled: props.editDisabled
        };

        const deleteItem: ICommandBarItemProps = {
            key: 'Delete',
            text: 'Delete',
            iconProps: { iconName: 'Delete' },
            onClick: props.onDelete,
            disabled: props.deleteDisabled
        };

        const makeCurrentItem: ICommandBarItemProps = {
            key: 'MakeCurrent',
            text: 'Make Current',
            iconProps: { iconName: 'RenewalCurrent' },
            onClick: props.onMakeCurrent,
            disabled: this.props.makeCurrentDisabled
        };

        if (props.allowAdd === true) {
            if (props.editDisabled && props.deleteDisabled) {
                commandBarItems.push(addItem);
            }
            else {
                commandBarItems.push(makeCurrentItem);
                commandBarItems.push(editItem);
                commandBarItems.push(deleteItem);
            }
        }

        if (props.onFilterChange) {
            farCommandBarItems.push(
                {
                    key: 'filter',
                    text: 'List filter',
                    inActive: true,
                    onRender: function renderFilter() {
                        return (
                            <div className={styles.crCommandBarContainer}>
                                <SearchBox placeholder="Filter items" onChange={(_, v) => props.onFilterChange(v)} className={styles.listFilterBox} />
                            </div>
                        );
                    }
                }
            );
        }

        return (
            <CommandBar
                items={commandBarItems}
                farItems={farCommandBarItems}
            />
        );
    }
}
