import { EntityService } from './EntityService';
import { IEntity } from '../types';

export class GoDefElementService extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/GoDefElements`);
    }

    public readAllWithFilters(goFormId: number | string, incompleteOnly: boolean, justMine: boolean): Promise<IEntity[]> {
        return this.readAll(`?goFormId=${goFormId}&incompleteOnly=${incompleteOnly}&justMine=${justMine}`);
    }
}