export enum UpdateReportingStages {
    UpdatesRequired = 0,
    UpdatesInProgress = 1,
    ApprovalRequired = 2,
    Approved = 3
}

export const getUpdateReportingStage = (reportingStageID?: number): string => {
    if (reportingStageID === UpdateReportingStages.UpdatesInProgress) {
        return 'In Progress';
    } else if (reportingStageID === UpdateReportingStages.ApprovalRequired) {
        return 'Approval Required';
    }
    else if (reportingStageID === UpdateReportingStages.Approved) {
        return 'Approved';
    }

    return 'Updates Required';
};
