import React, { useState } from 'react';
import { ISignOff, IReportDueDates, IReportingEntity, IBaseComponentProps, IBenefitUpdate } from '../../types';
import { ProjectProgressUpdateReviewList } from '../project/ProjectProgressUpdateReviewList';
import { CrExpandableTextDisplay } from '../cr/CrExpandableTextDisplay';
import { Label } from '@fluentui/react/lib/Label';
import { WorkStreamProgressUpdateReviewList } from '../workStream/WorkStreamProgressUpdateReviewList';
import { MilestoneProgressUpdateReviewList } from '../milestone/MilestoneProgressUpdateReviewList';
import { BenefitProgressUpdateReviewList } from '../benefit/BenefitProgressUpdateReviewList';
import { DependencyProgressUpdateReviewList } from '../dependency/DependencyProgressUpdateReviewList';
import { ProjectProgressUpdateSummaryReviewList } from '../project/ProjectProgressUpdateSummaryReviewList';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { EntityStatus } from '../../refData/EntityStatus';
import { ReportingEntityProgressUpdateReviewList } from '../reportingEntities/ReportingEntityProgressUpdateReviewList';
import { ReportTypes } from '../../refData/ReportTypes';
import { DraftReportHeader } from './DraftReportHeader';
import { PortfolioProgressUpdateReviewList } from '../portfolio/PortfolioProgressUpdateReviewList';

interface IDraftReportPortfolioProps extends IBaseComponentProps {
    reportDates: IReportDueDates;
    report: Partial<ISignOff>;
    previousReport: ISignOff;
    //previousBenefitUpdates: IBenefitUpdate[];
    loadReport: () => void;
}

export const DraftReportPortfolio = (props: IDraftReportPortfolioProps): React.ReactElement => {
    const { report, reportDates, previousReport, loadReport } = props;
    const [showPreviousProjectReport, setShowPreviousProjectReport] = useState(false);

    const isOpen = (entity: IReportingEntity, updatesProp: string): boolean => entity.EntityStatusID === EntityStatus.Open || entity[updatesProp]?.length > 0;

    console.log('in DraftReportPortfolio');

    return (

        <div>
            <DraftReportHeader
                title={report?.Portfolio?.Title}
                reportDate={reportDates?.Next}
            //attributes={report?.Portfolio?.Attributes?.filter(a => a.AttributeType?.Display).map(a => a.AttributeType)}
            />
            {/* <Label>Show previous period&apos;s comments</Label> */}
            {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                <Toggle
                    checked={showPreviousProjectReport}
                    onChange={(_, checked) => setShowPreviousProjectReport(checked)}
                    style={{ marginBottom: '0px' }}
                />
            </div> */}
            <CrExpandableTextDisplay
                label="Portfolio objectives"
                largeLabel={true}
                shortenedCharCount={250}
                text={report.Portfolio?.Objectives}
            />
            <PortfolioProgressUpdateReviewList
                {...props}
                portfolio={report?.Portfolio}
                portfolioUpdate={report?.Portfolio?.PortfolioUpdates?.[0]}
                onChange={loadReport}
            />
            {/* <ProjectProgressUpdateReviewList
                {...props}
                project={report.Project}
                projectUpdate={report.Project?.ProjectUpdates?.[0]}
                previousProjectUpdate={previousReport?.Project?.ProjectUpdates?.[0]}
                showPreviousUpdate={showPreviousProjectReport}
                onChange={loadReport}
            />
            {report?.ReportingEntityTypes?.filter(ret => ret.IsHeadlineSection).map(ret => ret?.ReportingEntities?.length > 0 &&
                <ReportingEntityProgressUpdateReviewList
                    key={ret.ID}
                    {...props}
                    entities={ret?.ReportingEntities}
                    previousEntities={previousReport?.ReportingEntityTypes?.find(t => t.ID === ret.ID)?.ReportingEntities}
                    onChange={loadReport}
                    entityType={ret}
                />
            )} */}

        </div>
    );
};
