import React from 'react';
import { CommandBar, ICommandBarProps, ICommandBarStyles } from '@fluentui/react/lib/CommandBar';
import styles from '../../styles/cr.module.scss';

export const CrCommandBar = ({ className, ...props }: ICommandBarProps): React.ReactElement => {

    const commandBarStyles: ICommandBarStyles = {
        root: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    };
    
    return (
        <div className={`${className} ${styles.crCommandBar}`}><CommandBar styles={commandBarStyles} {...props} /></div>
    );
};

