import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IPanelHeaderRenderer, IPanelProps, Panel, PanelType } from '@fluentui/react/lib/Panel';
import { CrLoadingOverlay } from '../cr/CrLoadingOverlay';
import { ViewFormCommandBar } from '../cr/ViewFormCommandBar';
import { IDirectorate } from '../../types';
import styles from '../../styles/cr.module.scss';
import { OrbUserContext } from '../OrbUserContext';

interface DirectorateViewProps {
    entityName?: string;
    showForm?: boolean;
    entityId?: number;
    onClosed?: () => void;
    loadEntity: (entityId: number) => Promise<IDirectorate>;
}

export const DirectorateView: React.FC<DirectorateViewProps> = ({ showForm, entityName, entityId, loadEntity, onClosed }) => {
    const [formData, setFormData] = useState<IDirectorate>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const { orbConfig } = useContext(OrbUserContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const entity = await loadEntity(entityId);
                setFormData(entity);
                setLoading(false);
            } catch (error) {
                console.error('Error loading entity:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [loadEntity]);

    const directorateStaff = useMemo(() => {
        if (!formData) return '';

        const staffRoles = [
            { role: 'Alternative report approver', users: formData.ReportApproverUser ? [`${formData.ReportApproverUser.Title} (${formData.ReportApproverUser.EmailAddress})`] : [] },
            { role: 'Reporting lead', users: formData.ReportingLeadUser ? [`${formData.ReportingLeadUser.Title} (${formData.ReportingLeadUser.EmailAddress})`] : [] },
            {
                role: 'Headline update contributors',
                users: formData.Contributors && formData.Contributors.some(c => c.ContributorUser != null)
                    ? formData.Contributors.map(dc => `${dc.ContributorUser?.Title} (${dc.ContributorUser?.EmailAddress})`)
                    : [],
            },
        ];

        const formattedStaffRoles = staffRoles
            .filter(role => role.users.length > 0)
            .map(role => `<div style='display:flex'><div style='font-weight:bold'>-&nbsp;</div><div>${role.role}: ${role.users.join(', ')}</div></div>`)
            .join(''); //no need to specify a join string like '<br/>' because we are already using div which makes a new line


        return formattedStaffRoles;
    }, [formData]);

    const headerText = useMemo(() => {
        if (!formData) return entityName;

        return `${entityName} - ${formData.Title}`;
    }, [entityName, formData]);

    const formattedObjectives = useMemo(() => {
        if (!formData) return null;

        return formData.Objectives ? formData.Objectives.replace(/\n/g, '<br>') : null;
    }, [formData]);

    return (
        <Panel
            className={styles.cr}
            isOpen={showForm}
            headerText={headerText}
            type={PanelType.medium}
            hasCloseButton={false}
            onRenderHeader={(props: IPanelProps, defaultRender: IPanelHeaderRenderer) =>
                <div style={{ marginBottom: '20px' }} className={styles.panelHeaderText}>{defaultRender(props)}</div>
            }
            onRenderNavigation={() =>
                <ViewFormCommandBar onClose={onClosed} />
            }
        >
            <div className={styles.cr}>
                <CrLoadingOverlay isLoading={loading} opaque={true} />
                {formData && (
                    <div>
                        <div className={styles.viewFormFieldLabel}>{orbConfig.OrgL1Label}</div>
                        <div className={styles.viewFormFieldData}>{formData.OrgLevel1?.Title}</div>

                        <div className={styles.viewFormFieldLabel}>{orbConfig.OrgMgrL2Lable}</div>
                        <div className={styles.viewFormFieldData}>
                            {formData.DirectorUser ? (
                                `${formData.DirectorUser.Title} (${formData.DirectorUser.EmailAddress})`
                            ) : (
                                'N/A'
                            )}
                        </div>

                        <div className={styles.viewFormFieldLabel}>Staff</div>
                        <div className={styles.viewFormFieldData}>
                            {directorateStaff ? (
                                <div dangerouslySetInnerHTML={{ __html: directorateStaff }} />
                            ) : (
                                'N/A'
                            )}
                        </div>

                        <div className={styles.viewFormFieldLabel}>Attributes</div>
                        <div className={styles.viewFormFieldData}>
                            {formData.Attributes && formData.Attributes.length > 0 ? (
                                formData.Attributes.map(a => a.AttributeType?.Title).join(', ')
                            ) : (
                                'N/A'
                            )}
                        </div>

                        <div className={styles.viewFormFieldLabel}>Objectives</div>
                        <div className={styles.viewFormFieldData}>
                            {formattedObjectives ? (
                                <div dangerouslySetInnerHTML={{ __html: formattedObjectives }} />
                            ) : (
                                'N/A'
                            )}
                        </div>
                    </div>
                )}
            </div>
        </Panel>
    );
};