import { AppGlobals } from '../AppGlobals';
import { IApp03WorkflowUser } from '../types';
import { EntityService } from './EntityService';

export class App03WorkflowUserService extends EntityService<IApp03WorkflowUser> {
    constructor() {
        super(`/App03WorkflowUsers`);
    }

    public readAllForList(workflowID?: number): Promise<IApp03WorkflowUser[]> {
        return this.readAll(
            `?$orderby=User/Title&$expand=User,App03Workflow&$filter=User/CustomerID eq ${AppGlobals.CustomerID}`
            + (workflowID ? ` and App03WorkflowID eq ${workflowID}` : '')
        );
    }

    public checkForDuplicates = (userId: number, workflowID: number): Promise<IApp03WorkflowUser[]> => {
        return this.readAll(`?$filter=UserID eq ${userId} and App03WorkflowID eq ${workflowID}&$select=ID`);
    }

    public readWorkflowUsers(workflowID: number): Promise<IApp03WorkflowUser[]> {
        return this.readAll(
            `?$orderby=User/Title&$expand=User&$filter=User/CustomerID eq ${AppGlobals.CustomerID}`
            + (workflowID ? ` and App03WorkflowID eq ${workflowID}` : '')
        );
    }

}