import React, { useContext } from 'react';
import { ErrorBoundary } from '../ErrorBoundary';
import { IWithErrorHandlingProps, withErrorHandling } from '../withErrorHandling';
import { IUseApiProps } from '../useApi';
import NaoReportCls from './NaoReportCls';
import { OrbUserContext } from '../OrbUserContext';


export interface INaoReportProps extends IUseApiProps, IWithErrorHandlingProps {
}

const NaoReport = (props: INaoReportProps) => {
    const { userPermissions } = useContext(OrbUserContext);
    return (
        <ErrorBoundary>
            <NaoReportCls
                userPermissions={userPermissions}
                {...props}
            />
        </ErrorBoundary>
    );
};

export default withErrorHandling(NaoReport);
