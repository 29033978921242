import * as React from 'react';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import * as types from '../../types';
import * as services from '../../services';
import { IGenColumn, ColumnType, ColumnDisplayType } from '../../types/GenColumn';
import EntityListCat from '../entity/EntityListCat';

export interface IIapPropertiesClsProps extends types.IBaseComponentProps {
}

export interface IIapPropertiesState {
}
export class IapPropertiesState implements IIapPropertiesState {
}

export default class IapPropertiesCls extends React.Component<IIapPropertiesClsProps, IapPropertiesState> {
    constructor(props: IIapPropertiesClsProps) {
        super(props);
        this.state = new IapPropertiesState();
    }

    public render(): React.ReactElement<IIapPropertiesClsProps> {
        return (
            <Pivot onLinkClick={(ev) => this.props.errorHandling.clearErrors()}>
                <PivotItem headerText="Define Form">
                    {this.renderDefForms()}
                </PivotItem>
            </Pivot>
        );
    }

    private renderDefForms() {
        const listColumns: IGenColumn[] = [
            {
                key: 'Title',
                columnType: ColumnType.TextBox,
                name: 'Welcome Text Heading',
                fieldName: 'Title',
                minWidth: 300,
                isResizable: true,
                isRequired: true,
                fieldMaxLength: 100
            },
            {
                key: 'Details',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Welcome Text',
                fieldName: 'Details',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 5000,
                numRows: 10
            },
        ];

        return (
            <React.Fragment>
                <EntityListCat
                    allowAdd={this.superUserPermission()}
                    columns={listColumns}
                    {...this.props}
                    errorHandling={this.props.errorHandling}
                    entityService={new services.IAPDefFormService()}
                    entityNamePlural="Define Form"
                    entityNameSingular="Define Form"
                    childEntityNameApi=""
                    childEntityNamePlural=""
                    childEntityNameSingular=""
                />
            </React.Fragment>
        );
    }

    private superUserPermission(): boolean {
        return this.props.userPermissions.UserIsSystemAdmin()
            || this.props.userPermissions.UserIsMASuperUser();
    }

}
