import { EntityService } from './EntityService';
import { IGIAAAuditReportDirectorate } from '../types';

export class GIAAAuditReportDirectorateService extends EntityService<IGIAAAuditReportDirectorate> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/GIAAAuditReportDirectorates`);
    }

}