export interface IEntity {
    ID: number;
    Title: string | null;
    Description?: string | null;
}

export class Entity implements IEntity {
    public ID = 0;
    public Title = '';
}

export interface IEntityWithCustomer extends IEntity {
    CustomerID: number;
}
export class EntityWithCustomer extends Entity implements IEntityWithCustomer {
    public CustomerID = null;
}