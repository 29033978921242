import { IPortfolio } from "./Portfolio";
import { IRagOption } from "./RagOption";
import { IReportingProgressUpdate, ReportingProgressUpdate } from "./ReportingProgressUpdate";

export interface IPortfolioUpdate extends IReportingProgressUpdate {
    PortfolioID: number | null;
    OverallRagOptionID: number | null;
    FinanceRagOptionID: number | null;
    FinanceComment: string;
    PeopleRagOptionID: number | null;
    PeopleComment: string;
    MilestonesRagOptionID: number | null;
    MilestonesComment: string;
    BenefitsRagOptionID: number | null;
    BenefitsComment: string;
    ProgressUpdate: string;
    FutureActions: string;
    Escalations: string;

    Portfolio?: IPortfolio | null;
    OverallRagOption?: IRagOption;
    FinanceRagOption?: IRagOption;
    PeopleRagOption?: IRagOption;
    MilestonesRagOption?: IRagOption;
    BenefitsRagOption?: IRagOption;
}

export class PortfolioUpdate extends ReportingProgressUpdate implements IPortfolioUpdate {
    public PortfolioID: number | null = null;
    public OverallRagOptionID = null;
    public FinanceRagOptionID = null;
    public FinanceComment = '';
    public PeopleRagOptionID = null;
    public PeopleComment = '';
    public MilestonesRagOptionID = null;
    public MilestonesComment = '';
    public BenefitsRagOptionID = null;
    public BenefitsComment = '';
    public ProgressUpdate = '';
    public FutureActions = '';
    public Escalations = '';

    public UpdateUser = null;
    public Portfolio: IPortfolio | null = null;

    constructor(portfolioId?: number, period?: Date) {
        super(period);
        if (portfolioId)
            this.PortfolioID = portfolioId;
    }
}