import { EntityWithStatusService } from './EntityWithStatusService';
import { ContextService } from './ContextService';
import { IMetric, IEntityChildren } from '../types';
import { EntityStatus } from '../refData/EntityStatus';
import { Period } from '../refData/Period';
import { AppGlobals } from '../AppGlobals';

export class MetricService extends EntityWithStatusService<IMetric> {
    public readonly parentEntities = ['Directorate', 'LeadUser', 'MeasurementUnit'];
    protected childrenEntities = ['Contributors($expand=ContributorUser)', 'Attributes($expand=AttributeType)'];

    constructor() {
        super(`/Metrics`);
    }

    public readAllForList(includeClosedMetrics?: boolean, directorateID?: number): Promise<IMetric[]> {
        return this.readAll(
            `?$select=ID,Title,TargetPerformanceLowerLimit,TargetPerformanceUpperLimit`
            + `&$orderby=Title`
            + `&$expand=EntityStatus($select=Title),Directorate($select=Title),LeadUser($select=Title),Contributors($select=ContributorUser;$expand=ContributorUser($select=Title)),Attributes($expand=AttributeType($select=Title))`
            + `&$filter=Directorate/CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosedMetrics ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
            + (directorateID ? ` and DirectorateID eq ${directorateID}` : '')
        );
    }

    public async readForView(metricID: number): Promise<IMetric> {
        return this.read(metricID, false, false, ['MeasurementUnit($select=Title)', 'Directorate($select=Title)', 'LeadUser($select=Title,EmailAddress)', 'Contributors($select=ContributorUser;$expand=ContributorUser($select=Title,EmailAddress))', 'Attributes($expand=AttributeType($select=Title))']);
    }

    public readAllForLookup(includeClosedEntities?: boolean, additionalFields?: string): Promise<IMetric[]> {
        return this.readAll(
            `?$select=ID,Title${additionalFields ? `,${additionalFields}` : ''}`
            + `&$orderby=Title&$filter=Directorate/CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosedEntities ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public readMyMetrics(): Promise<IMetric[]> {
        const username = encodeURIComponent(ContextService.Username());
        const filters = [
            `LeadUser/Username eq '${username}'`,
            `Contributors/any(c: c/ContributorUser/Username eq '${username}')`
        ];
        return this.readAll(`?$filter=EntityStatusID eq ${EntityStatus.Open} and (${filters.join(' or ')})`);
    }

    public readDirectorateMetrics(directoratetId: number): Promise<IMetric[]> {
        if (directoratetId) {
            return this.readAll(`?$expand=LeadUser&$filter=EntityStatusID eq ${EntityStatus.Open} and DirectorateID eq ${directoratetId}`);
        }
        return Promise.resolve([]);
    }

    public async readDirectorateOpenMetricsCount(directorateId: number): Promise<number> {
        if (directorateId) {
            return (await this.readAll(`?$select=ID&$filter=EntityStatusID eq ${EntityStatus.Open} and DirectorateID eq ${directorateId}`)).length;
        }
        return Promise.resolve(0);
    }

    public readDirectorateMetricsInPeriod(directoratetId: number, period: Period): Promise<IMetric[]> {
        if (directoratetId) {
            return this.readAll(`/GetMetricsDueInPeriod(DirectorateId=${directoratetId},Period=${period})?$expand=LeadUser,MeasurementUnit,Attributes($expand=AttributeType),Contributors`);
        }
        return Promise.resolve([]);
    }

    public entityChildren = (id: number): Promise<IEntityChildren[]> => {
        return this.entityChildrenSingle(id, 'Metric updates', 'MetricUpdates', false);
    }

    public async countMetricsForDirectorate(directorateID: number): Promise<number> {
        if (directorateID) {
            return (await this.readAll(`?$select=ID&$filter=EntityStatusID eq ${EntityStatus.Open} and DirectorateID eq ${directorateID}`))?.length;
        }
        return Promise.resolve(0);
    }
}