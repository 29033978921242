import * as React from 'react';
import * as types from '../../types';
import * as services from '../../services';
import { FilteredReport1List } from './FilteredReport1List';
import { IEntity } from '../../types';
import { IUpdatesListColumn, ColumnDisplayTypes } from '../../types/UpdatesListColumn';
import { CrLoadingOverlay } from '../cr/CrLoadingOverlay';
import { ConfirmDialog } from '../cr/ConfirmDialog';
import { IObjectWithKey, Selection } from '@fluentui/react/lib/DetailsList';
import styles from '../../styles/cr.module.scss';

export interface IReport1ListProps extends types.IBaseComponentProps {
    periodId: number | string;
    filterText?: string;
    onChangeFilterText: (value: string) => void;
}

export interface IReport1ListState<T> {
    SelectedEntity: number;
    SelectedEntityTitle: string;
    ShowForm: boolean;
    EnableCreatePdf?: boolean;
    EnableDeletePdf?: boolean;
    EnableDownloadPdf?: boolean;
    HideDeleteDialog: boolean;
    Entities: T[];
    Loading: boolean;
    ListFilterText?: string;
    InitDataLoaded: boolean;
}
export class Report1ListState<T> implements IReport1ListState<T> {
    public SelectedEntity = null;
    public SelectedEntityTitle: string = null;
    public ShowForm = false;
    public HideDeleteDialog = true;
    public EnableCreatePdf = false;
    public EnableDeletePdf = false;
    public EnableDownloadPdf = false;
    public Entities: T[] = [];
    public Loading = false;
    public ListFilterText = null;
    public InitDataLoaded = false;
}

export default class Report1List extends React.Component<IReport1ListProps, IReport1ListState<IEntity>> {
    private _selection: Selection;
    private goFormService: services.GoFormService = new services.GoFormService();
    private zFileService: services.ZFileService = new services.ZFileService();

    private listColumns: IUpdatesListColumn[] = [
        {
            key: 'ID',
            name: 'ID',
            fieldName: 'ID',
            minWidth: 1,
            isResizable: true,
            columnDisplayType: ColumnDisplayTypes.Hidden,
        },
        {
            key: 'Title',
            name: 'DGArea',
            fieldName: 'Title',
            minWidth: 100,
            maxWidth: 400,
            isResizable: true,
            headerClassName: styles.bold,
        },
        {
            key: 'PdfStatus',
            name: 'PDF Status',
            fieldName: 'PdfStatus',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            isMultiline: true,
            headerClassName: styles.bold,
        },
        {
            key: 'PdfName',
            name: 'PdfName',
            fieldName: 'PdfName',
            minWidth: 1,
            columnDisplayType: ColumnDisplayTypes.Hidden,
        },
        {
            key: 'OverviewStatus',
            name: 'Overview Status',
            fieldName: 'OverviewStatus',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },
        {
            key: 'SpecificAreaStatus',
            name: 'Specific Area Status',
            fieldName: 'SpecificAreaStatus',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },
        {
            key: 'SignOffStatus',
            name: 'Sign Off Status',
            fieldName: 'SignOffStatus',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },
    ];

    constructor(props: IReport1ListProps, state: IReport1ListState<IEntity>) {
        super(props);
        this.state = new Report1ListState<IEntity>();
        this._selection = new Selection({
            onSelectionChanged: () => {
                if (this._selection.getSelectedCount() === 1) {
                    const sel = this._selection.getSelection()[0];
                    const key = Number(sel.key);
                    const title: string = sel["Title"];
                    const pdfStatus: string = sel["PdfStatus"];

                    let pdfCreated: boolean = false;
                    if (pdfStatus.search("Cr") === 0)
                        pdfCreated = true;

                    this.setState({ SelectedEntity: key, SelectedEntityTitle: title, EnableCreatePdf: true, EnableDeletePdf: pdfCreated, EnableDownloadPdf: pdfCreated });
                }
                else {
                    this.setState({ SelectedEntity: null, SelectedEntityTitle: null, EnableCreatePdf: false, EnableDeletePdf: false, EnableDownloadPdf: false });
                }
            }
        });
    }

    public render(): React.ReactElement<IReport1ListProps> {
        return (
            <div className={`${styles.cr}`}>
                <div style={{ position: 'relative' }}>
                    <CrLoadingOverlay isLoading={this.state.Loading} />
                    {this.renderList()}
                    {this.state.ShowForm && this.renderForm()}

                    <ConfirmDialog hidden={this.state.HideDeleteDialog} title="Delete PDF" content={`Are you sure you want to delete ${this.getSelectedEntityName()} PDF?`} confirmButtonText="Delete PDF" handleConfirm={this.deletePdf} handleCancel={this.toggleDeletePdfConfirm} />
                </div>
            </div>
        );
    }

    private renderList() {
        const listColumns = this.getColumns();
        const listColumnsForData = this.getColumnsForData();
        let items: IObjectWithKey[] = this.state.Entities.map((e) => { return this.makeItem(e, listColumnsForData); });

        return (
            <FilteredReport1List
                columns={listColumns}
                items={items}
                filterText={this.props.filterText}
                onFilterChange={this.props.onChangeFilterText}
                selection={this._selection}
                onCreatePdf={this.handleCreatePdf}
                onDeletePdf={this.handleDeletePdf}
                onDownloadPdf={this.handleDownloadPdf}
                createPdfDisabled={!this.state.EnableCreatePdf}
                deletePdfDisabled={!this.state.EnableDeletePdf}
                downloadPdfDisabled={!this.state.EnableDownloadPdf}
            />
        );
    }

    private renderForm() {
        return null;
    }

    private makeItem = (e: IEntity, listColumns: IUpdatesListColumn[]): any => {
        let item: any = { key: e["ID"] };
        listColumns.map((c) => {
            let fieldContent: string = String(e[c.fieldName]);
            item = {
                [c.fieldName]: fieldContent,
                ...item
            };
        });
        return item;
    }

    private getColumns(): IUpdatesListColumn[] {
        let listColumns: IUpdatesListColumn[];
        listColumns = this.listColumns.filter(c => c.columnDisplayType !== ColumnDisplayTypes.Hidden);
        return listColumns;
    }

    private getColumnsForData(): IUpdatesListColumn[] {
        const listColumns: IUpdatesListColumn[] = this.listColumns;
        return listColumns;
    }

    private handleCreatePdf = (): void => {
        if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(null);
        if (this.state.SelectedEntity) {
            const goFormId: number = this.state.SelectedEntity;
            this.goFormService.createPDF(goFormId, '').then((res: string): void => {
                this.loadData();
            }, (err) => {
                if (this.props.errorHandling?.onError)
                    this.props.errorHandling?.onError(`Error creating PDF`, err.message);
            });
        }
    }
    private handleDeletePdf = (): void => {
        console.log('in del pdf');
    }

    private handleDownloadPdf = async (): Promise<void> => {
        let entity = this.state.Entities.filter((e) => { return e.ID === this.state.SelectedEntity; });
        if (entity[0]) {
            const pdfStatus: string = entity[0]["PdfStatus"];
            if (pdfStatus.search("Cr") === 0) {
                //status is Cr
                const pdfName: string = entity[0]["PdfName"];
                const fileUniqueID = entity[0]["FileUniqueID"];
                //download pdf
                try {
                    await this.zFileService.downloadFile(fileUniqueID, pdfName);
                } catch (error) {
                    console.error('Error downloading file', error);
                }
            }
            else {
                console.log("Output not created");
            }
        }
    }

    private getSelectedEntityName = (): string => {
        let entity = this.state.Entities.filter((e) => { return e.ID === this.state.SelectedEntity; });
        return entity[0] ? entity[0].Title : null;
    }

    private toggleDeletePdfConfirm = (): void => {
        this.setState({ HideDeleteDialog: !this.state.HideDeleteDialog });
    }

    private deletePdf = (): void => {
    }

    private loadData = (): void => {
        this.setState({ Loading: true });
        const read: Promise<IEntity[]> = this.goFormService.readAllReport1(this.props.periodId);
        read.then((entities: any): void => {
            this.setState({
                Loading: false, Entities: entities,
            });

        }, (err) => this.errorLoadingData(err));
    }

    private errorLoadingData = (err: any, entityName?: string): void => {
        this.setState({ Loading: false });
        if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading ${entityName || 'items'}`, err.message);
    }

    public componentDidMount(): void {
        this.loadData();
    }

    public componentDidUpdate(prevProps: IReport1ListProps): void {
        if (prevProps.periodId !== this.props.periodId) {
            this._selection.setAllSelected(false);
            this.loadData();
        }
    }
}
