import { Entity, IEntity } from "./Entity";
import { IUser } from "./User";
import { IApp03Task } from "./App03Task";

export interface IApp03TaskUser extends IEntity {
    UserID?: number;
    TaskID?: number;
    User?: IUser;
    App03Task?: IApp03Task;
}

export class App03TaskUser extends Entity implements IApp03TaskUser {
    public UserID = null;
    public TaskID = null;
}