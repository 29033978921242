import React from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import { MessageBar, MessageBarType, initializeIcons } from '@fluentui/react';
import { IUserPermissionService } from './services/UserPermissionService';

interface IUserPermsRouteProps extends RouteProps {
    userPermissions: IUserPermissionService;
}

export const DirectorateAdminRoute = ({ userPermissions: up, children, ...props }: IUserPermsRouteProps) =>
    <Route {...props} render={() => up.UserCanViewDirectorateEntities() ? children : <Redirect to='/not-authorised' />} />;

export const ProjectAdminRoute = ({ userPermissions: up, children, ...props }: IUserPermsRouteProps) =>
    <Route {...props} render={() => up.UserCanViewProjectEntities() ? children : <Redirect to='/not-authorised' />} />;
export const MilestoneAdminRoute = ({ userPermissions: up, children, ...props }: IUserPermsRouteProps) =>
    <Route {...props} render={() => up.UserCanViewDirectorateEntities() || up.UserCanViewProjectEntities() || up.UserCanViewPartnerOrganisationEntities() ? children : <Redirect to='/not-authorised' />} />;

export const AdminRoute = ({ userPermissions: up, children, ...props }: IUserPermsRouteProps) =>
    <Route {...props} render={() => up.UserIsSystemAdmin() ? children : <Redirect to='/not-authorised' />} />;

export const CustomSectionsAdminRoute = ({ userPermissions: up, children, ...props }: IUserPermsRouteProps) =>
    <Route {...props} render={() => up.UserIsCustomSectionsAdmin() ? children : <Redirect to='/not-authorised' />} />;

export const FinancialRiskAdminRoute = ({ userPermissions: up, children, ...props }: IUserPermsRouteProps) =>
    <Route {...props} render={() => up.UserIsFinancialRiskAdmin() ? children : <Redirect to='/not-authorised' />} />;

export const RiskAdminRoute = ({ userPermissions: up, children, ...props }: IUserPermsRouteProps) =>
    <Route {...props} render={() => up.UserCanViewRiskEntities() ? children : <Redirect to='/not-authorised' />} />;

const FinancialRiskUserRoute = ({ userPermissions: up, children, ...props }: IUserPermsRouteProps) =>
    <Route {...props} render={() => up.UserCanViewFinancialRiskEntities() ? children : <Redirect to='/not-authorised' />} />;

const PartnerOrganisationAdminRoute = ({ userPermissions: up, children, ...props }: IUserPermsRouteProps) =>
    <Route {...props} render={() => up.UserCanViewPartnerOrganisationEntities() ? children : <Redirect to='/not-authorised' />} />;

export const NotAuthorised = () =>
    <MessageBar messageBarType={MessageBarType.error}>Not authorised</MessageBar>;

export const NotFound = () =>
    <MessageBar messageBarType={MessageBarType.error}>Not found &apos;{useLocation().pathname}&apos;</MessageBar>;