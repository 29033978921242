import { EntityService } from './EntityService';
import { IProjectPhase } from '../types/ProjectPhase';
import { AppGlobals } from '../AppGlobals';

export class ProjectPhaseService extends EntityService<IProjectPhase> {
    constructor() {
        super(`/ProjectPhases`);
    }

    public readAllForLookup(additionalFields?: string): Promise<IProjectPhase[]> {
        return this.readAll(
            `?$select=ID,Title${additionalFields ? `,${additionalFields}` : ''}`
            + `&$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`
        );
    }
}