import * as React from 'react';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import * as types from '../../types';
import Report1List from '../../components/goReport/Report1List';
import * as services from '../../services';
import GenExport from '../../components/export/GenExport';
import { ICAPeriod, IEntity } from '../../types';
import { CrDropdown } from '../cr/CrDropdown';
import { CrLoadingOverlayWelcomeCat } from '../cr/CrLoadingOverlayWelcomeCat';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';

export interface IGoReportClsProps extends types.IBaseComponentProps {
}

export interface ILookupData {
    Periods: IEntity[];
    PeriodsOriginal: ICAPeriod[];
}

export class LookupData implements ILookupData {
    public Periods: ICAPeriod[] = [];
    public PeriodsOriginal: ICAPeriod[] = [];
}

export interface IGoReportState {
    Loading: boolean;
    LookupData: ILookupData;
    PeriodId: number;
    SelectedPeriodTxt: string;
    IsArchivedPeriod: boolean;
    Report1_ListFilterText: string;
}

export class GoReportState implements IGoReportState {
    public Loading = false;
    public LookupData = new LookupData();
    public PeriodId: number = 0;
    public SelectedPeriodTxt: string = "";
    public IsArchivedPeriod = false;
    public Report1_ListFilterText: string = null;
    public FilteredItems = [];
    constructor() {
    }
}

export default class GoReportCls extends React.Component<IGoReportClsProps, GoReportState> {
    private periodService: services.GoPeriodService = new services.GoPeriodService();
    constructor(props: IGoReportClsProps) {
        super(props);
        this.state = new GoReportState();
    }

    public render(): React.ReactElement<IGoReportClsProps> {
        return (
            <React.Fragment>
                <CrDropdown
                    placeholder="Select a Period"
                    style={{ marginTop: "10px", marginBottom: "20px" }}
                    label="Which period do you want to view?"
                    options={this.state.LookupData.Periods.map((p) => { return { key: p.ID, text: p.Title }; })}
                    onChange={(_, v) => this.changeDropdown(v, 'PeriodId')}
                    selectedKey={this.state.PeriodId}
                />
                <Pivot>
                    <PivotItem headerText="DG Areas">
                        {this.renderReport1()}
                    </PivotItem>
                    <PivotItem headerText="Export to Excel" itemKey="Export to Excel">
                        {this.renderGenExport()}
                    </PivotItem>
                </Pivot>
            </React.Fragment>
        );
    }

    private renderReport1(): React.ReactElement<types.IWebPartComponentProps> {
        return (
            <div>
                <CrLoadingOverlayWelcomeCat isLoading={this.state.Loading} />
                <div style={{ paddingTop: "10px" }}>
                    <Report1List
                        {...this.props}
                        errorHandling={this.props.errorHandling}
                        periodId={this.state.PeriodId}
                        filterText={this.state.Report1_ListFilterText}
                        onChangeFilterText={this.handleReport1_ChangeFilterText}
                    />
                </div>
            </div>
        );
    }

    private renderGenExport(): React.ReactElement<types.IWebPartComponentProps> {
        if (this.state.PeriodId > 0) {
            return (
                <div>
                    <div style={{ paddingTop: "10px" }}>
                        <GenExport
                            {...this.props}
                            errorHandling={this.props.errorHandling}
                            moduleName="Governance"
                            periodId={Number(this.state.PeriodId)}
                            periodTitle={this.state.SelectedPeriodTxt}
                        />
                    </div>
                </div>
            );
        }
        else
            return null;
    }

    private getSelectedPeriodText = (periodId: number, periodsOriginal: ICAPeriod[]): string => {
        let periodTxt: string = "";
        var pp = periodsOriginal.filter(p => p.ID === periodId);
        if (pp[0]) {
            periodTxt = pp[0]["Title"];
        }
        return periodTxt;
    }

    private loadPeriods = (): Promise<void | ICAPeriod[]> => {
        return this.periodService.readAll().then((pArr: ICAPeriod[]): ICAPeriod[] => {
            const pArrCopy = JSON.parse(JSON.stringify(pArr));
            //get the current period
            let currentPeriodId: number = 0;
            const currentPeriod = pArr.filter(p => p.PeriodStatus === "Current Period");
            if (currentPeriod && currentPeriod.length > 0) {
                currentPeriodId = currentPeriod[0].ID;
            }

            const selectedPeriodTxt: string = this.getSelectedPeriodText(currentPeriodId, pArrCopy);

            //show status like Qtr 2 2019 ( Current Period ) in Title
            for (let i = 0; i < pArr.length; i++) {
                let p: ICAPeriod = pArr[i];
                pArr[i].Title = `${p.Title} ( ${p.PeriodStatus} )`;
            }

            //check user permissions
            if (this.isSuperUser() === true) {
            }
            else {
                //dont show design periods
                pArr = pArr.filter(p => p.PeriodStatus !== "Design Period");
            }

            const xx = { ...this.state.LookupData, ['Periods']: pArr, ['PeriodsOriginal']: pArrCopy };
            this.setState({
                LookupData: xx,
                PeriodId: currentPeriodId,
                SelectedPeriodTxt: selectedPeriodTxt
            });

            return pArr;
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading Periods lookup data`, err.message); });
    }

    private loadLookups(): Promise<any> {
        return Promise.all([
            this.loadPeriods(),
        ]).then(() => {
            this.setState({ Loading: false })
        });
    }

    public componentDidMount(): void {
        this.setState({ Loading: true });
        this.loadLookups();
    }

    private isSuperUser(): boolean {
        return this.props.userPermissions.UserIsSystemAdmin()
            || this.props.userPermissions.UserIsGovernanceSuperUser();
    }

    private changeDropdown = (option: IDropdownOption, f: string, index?: number): void => {
        if (option.key !== this.state.PeriodId) {
            const pArrTemp: ICAPeriod[] = this.state.LookupData.Periods.filter(p => p.ID === option.key);
            let isArchivedPeriod: boolean = false;
            if (pArrTemp.length > 0) {
                if (pArrTemp[0].PeriodStatus === "Archived Period") {
                    isArchivedPeriod = true;
                }
            }

            const selectedPeriodTxt: string = this.getSelectedPeriodText(Number(option.key), this.state.LookupData.PeriodsOriginal);
            this.setState({ PeriodId: Number(option.key), IsArchivedPeriod: isArchivedPeriod, SelectedPeriodTxt: selectedPeriodTxt },
            );
        }
    }

    private handleReport1_ChangeFilterText = (value: string): void => {
        this.setState({ Report1_ListFilterText: value });
    }
}


