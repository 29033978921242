import { AppGlobals } from "../AppGlobals";


export class ContextService {
    // public static Username(spfxContext: WebPartContext): string {
    //     return this.alterUsername(spfxContext.pageContext.user.loginName);
    // }

    public static Username(): string {
        //return this.alterUsername('tas@swiftpro.com'); //this is also hard coded in UserService
        return this.alterUsername(AppGlobals.Username);
    }

    public static alterUsername(userName: string): string {
        return userName
            .replace('cirrus.', '')
            .replace("'", "''");
    }
}