import { IEntity } from "./Entity";
import { IUser } from "./User";
import { IAttribute } from "./Attribute";
import { IKeyWorkArea } from "./KeyWorkArea";
import { IWorkStream } from "./WorkStream";
import { IReportingEntityWithDeliveryDates, ReportingEntityWithDeliveryDates } from "./ReportingEntity";
import { IPartnerOrganisation } from "./PartnerOrganisation";
import { IMilestoneUpdate } from "./MilestoneUpdate";

export interface IMilestone extends IReportingEntityWithDeliveryDates {
    MilestoneCode: string;
    StartDate: Date | null;
    MilestoneTypeID: number | null;
    Attributes: IAttribute[];
    LeadUserID: number | null;
    RagOptionID: number | null;
    WorkStreamID: number | null;
    KeyWorkAreaID: number | null;
    PartnerOrganisationID: number | null;
    LeadUser?: IUser;
    MilestoneType?: IEntity;
    KeyWorkArea?: IKeyWorkArea;
    WorkStream?: IWorkStream;
    PartnerOrganisation?: IPartnerOrganisation;
    Description: string;
    MilestoneUpdates?: IMilestoneUpdate[];
    CustomerID?: number;
}

export class Milestone extends ReportingEntityWithDeliveryDates implements IMilestone {
    public CustomerID?: number = null;
    public MilestoneCode = '';
    public StartDate = null;
    public MilestoneTypeID = null;
    public Attributes = [];
    public LeadUserID = null;
    public RagOptionID = null;
    public WorkStreamID = null;
    public KeyWorkAreaID = null;
    public PartnerOrganisationID = null;
    public Description = '';
}