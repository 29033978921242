import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    EntityValidations, ISpecificEntityFormProps, IApp03WorkflowStage, IApp03Task, App03Task, IUser, App03TaskUser
} from '../../types';
import { DateService, LookupService } from '../../services';
import styles from '../../styles/cr.module.scss';
import { CrUserPicker } from '../cr/CrUserPicker';
import { CrTextField } from '../cr/CrTextField';
import { CrDropdown } from '../cr/CrDropdown';
import { EntityForm } from '../EntityForm';
import { DataContext } from '../DataContext';
import { CrDatePicker } from '../cr/CrDatePicker';
import { TaskPriority } from '../../refData/WorkflowType';
import { CrCheckbox } from '../cr/CrCheckbox';

class TaskFormValidations extends EntityValidations {
    public AssignedTo: string = null;
}

interface ITaskFormProps extends ISpecificEntityFormProps {
    workflowID: number;
    newTaskStageID: number;
}

export const TaskForm = (props: ITaskFormProps): React.ReactElement => {
    const { dataServices, loadLookupData: { entityStatuses, users: { all: allUsers } } } = useContext(DataContext);



    useMemo(() => entityStatuses(), [entityStatuses]);
    useMemo(() => allUsers(), [allUsers]);

    const [workflowStages, setWorkflowStages] = useState<IApp03WorkflowStage[]>([]);
    const [workflowUsers, setWorkflowUsers] = useState<IUser[]>([]);
    const [showArchived, setShowArchived] = useState<boolean>(true);

    useEffect(() => {
        const fetchStages = async () => {
            try {
                const stages = await dataServices.app03WorkflowStageService.readWorkflowStages(props.workflowID);
                setWorkflowStages(stages);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const fetchWorkflowUsers = async () => {
            try {
                const users = (await dataServices.app03WorkflowUserService.readWorkflowUsers(props.workflowID)).map(u => u.User);
                setWorkflowUsers(users);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchWorkflowUsers();
        fetchStages();
    }, [props.entityId]);


    const validateEntity = (task: IApp03Task): Promise<TaskFormValidations> => {
        const errors = new TaskFormValidations();

        if (task.Title === null || task.Title === '') {
            errors.Title = `Task name is required`;
            errors.Valid = false;
        }
        else {
            errors.Title = null;
        }
        if (task.App03TaskUsers === undefined || task.App03TaskUsers === null || task.App03TaskUsers.length < 1) {
            errors.AssignedTo = `At least one assignee is required`;
            errors.Valid = false;
        }
        else {
            errors.AssignedTo = null;
        }


        return Promise.resolve(errors);
    };


    const taskPriorityArray = Object.keys(TaskPriority)
        .filter(key => !isNaN(Number(TaskPriority[key])))
        .map(key => ({ ID: TaskPriority[key], Title: key }));



    return (
        <EntityForm<IApp03Task, TaskFormValidations>
            {...props}
            entityName='Task'
            renderFormFields={(changeHandlers, formState) => {
                const { FormData: task, ValidationErrors: errors } = formState;
                return (
                    <div>
                        <CrTextField
                            label="Task"
                            maxLength={300}
                            className={styles.formField}
                            required={true}
                            value={task.Title}
                            onChange={(ev, newValue) => changeHandlers.changeTextField(newValue, 'Title')}
                            errorMessage={errors.Title}
                        />

                        <CrTextField
                            label="External Ref"
                            maxLength={300}
                            className={styles.formField}
                            value={task.ExternalRef}
                            onChange={(ev, newValue) => changeHandlers.changeTextField(newValue, 'ExternalRef')}
                        />

                        <CrDropdown
                            label="Stage"
                            className={styles.formField}
                            options={LookupService.entitiesToSelectableOptions(workflowStages)}
                            selectedKey={task.WorkflowStageID}
                            onChange={(_, o) => changeHandlers.changeDropdown(o, 'WorkflowStageID')}
                        />

                        <CrDropdown
                            label="Priority"
                            className={styles.formField}
                            options={LookupService.entitiesToSelectableOptions(taskPriorityArray)}
                            selectedKey={task.TaskPriorityID}
                            onChange={(_, o) => changeHandlers.changeDropdown(o, 'TaskPriorityID')}
                        />

                        <CrTextField
                            label="Task details"
                            multiline
                            rows={6}
                            maxLength={10000}
                            className={styles.formField}
                            value={task.Details}
                            onChange={(ev, newValue) => changeHandlers.changeTextField(newValue, 'Details')}
                        />

                        <CrDatePicker
                            label="Start date"
                            className={styles.formField}
                            value={DateService.removeTimezoneOffset(task.StartDate)}
                            onSelectDate={date => changeHandlers.changeDatePicker(date, 'StartDate')}
                        />
                        <CrDatePicker
                            label="End date"
                            className={styles.formField}
                            value={DateService.removeTimezoneOffset(task.EndDate)}
                            minDate={DateService.removeTimezoneOffset(task.StartDate)}
                            onSelectDate={date => changeHandlers.changeDatePicker(date, 'EndDate')}
                        //errorMessage={errors.EndDate}
                        />

                        <CrUserPicker
                            label="Assigned to"
                            className={styles.formField}
                            users={workflowUsers}
                            itemLimit={10}
                            selectedUsers={task.App03TaskUsers?.map(u => u.UserID)}
                            onChange={v => changeHandlers.changeMultiUserPicker(v, 'App03TaskUsers', new App03TaskUser(), 'UserID')}
                            required={true}
                            errorMessage={errors.AssignedTo}
                        />

                        {props.entityId > 0 && showArchived === true && <CrCheckbox
                            className={`${styles.formField} ${styles.checkbox}`}
                            label="Move to archived"
                            checked={task.Archived}
                            onChange={(_, isChecked) => changeHandlers.changeCheckbox(isChecked, 'Archived')}
                        />}

                    </div>
                );
            }}
            loadEntity={id => dataServices.app03TaskService.read(id, true, true)}
            onAfterLoad={(task)=> {
                setShowArchived(task.Archived === true ? false : true);
            }}
            loadNewEntity={() => new App03Task(props.workflowID, props.newTaskStageID)}
            loadEntityValidations={() => new TaskFormValidations()}
            onValidateEntity={validateEntity}
            onCreate={t => dataServices.app03TaskService.create(t)}
            onUpdate={t => dataServices.app03TaskService.update(t.ID, t)}
            parentEntities={dataServices.app03TaskService.parentEntities}
            childEntities={[
                {
                    ObjectParentProperty: 'App03TaskUsers',
                    ParentIdProperty: 'TaskID',
                    ChildIdProperty: 'UserID',
                    ChildService: dataServices.app03TaskUserService
                },
            ]}
        />
    );
};
