import { EntityService } from './EntityService';
import { IEntity, IGIAAUpdate } from '../types';

export class GIAAUpdateService extends EntityService<IGIAAUpdate> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/GIAAUpdates`);
    }

    public readAllByRec(giaaRecommendationId: number): Promise<IEntity[]> {
        return this.readAll(`?giaaRecommendationId=${giaaRecommendationId}&dataForUpdatesList=`);
    }

    public readAllForUpdatesList(giaaRecommendationId: number): Promise<IGIAAUpdate[]> {
        return this.readAll(`?giaaRecommendationId=${giaaRecommendationId}&dataForUpdatesList=`);
    }

}