import React, { useContext, useMemo } from 'react';
import {
    CrUpdateFormState, IProgressUpdateValidations, ProgressUpdateValidations, IEntityProgressUpdateFormProps, IProgrammeUpdate, IProgramme, ProgrammeUpdate, Programme
} from '../../types';
import styles from '../../styles/cr.module.scss';
import { RagPicker } from '../cr/RagPicker';
import { RagWithComments } from '../cr/RagWithComments';
import { CrTextField } from '../cr/CrTextField';
import { ProgressUpdateForm } from '../ProgressUpdateForm';
import { EntityPeopleService, LookupService, NumberService, ValidationService } from '../../services';
import { DataContext } from '../DataContext';
import { CrLabel } from '../cr/CrLabel';
import { CrDropdown } from '../cr/CrDropdown';
import { CrNumberTextField } from '../cr/CrNumberTextField';

export class ProgrammeProgressUpdateFormValidations extends ProgressUpdateValidations {
    public WholeLifeCost: string = null;
    public WholeLifeBenefit: string = null;
    public NetPresentValue: string = null;
}

export class ProgrammeProgressUpdateFormState extends CrUpdateFormState<IProgrammeUpdate, ProgrammeProgressUpdateFormValidations, IProgramme> {
    constructor(programmeId: number, period: Date, parentEntity?: IProgramme, showForm?: boolean) {
        super(
            new ProgrammeUpdate(programmeId, period),
            parentEntity || new Programme(),
            new ProgrammeUpdate(),
            new ProgrammeProgressUpdateFormValidations(),
            showForm
        );
    }
}

export const ProgrammeProgressUpdateForm = (
    { entityId, entity, reportDates, ...otherProps }: IEntityProgressUpdateFormProps<IProgramme>
): React.ReactElement => {
    const { dataServices, lookupData, loadLookupData: { programmePhases, reportingFrequencies } } = useContext(DataContext);
    
    useMemo(() => programmePhases(), [programmePhases]);
    useMemo(() => reportingFrequencies(), [reportingFrequencies]);

    const validateProgrammeProgressUpdate = (pu: IProgrammeUpdate) => {
        const errors = new ProgrammeProgressUpdateFormValidations();

        if (pu.WholeLifeCost !== null && isNaN(Number(pu.WholeLifeCost))) {
            errors.WholeLifeCost = 'Whole life cost must be a number';
            errors.Valid = false;
        } else if (pu.WholeLifeCost !== null && !ValidationService.validSqlDecimal(Number(pu.WholeLifeCost))) {
            errors.WholeLifeCost = 'Whole life cost is too big';
            errors.Valid = false;
        } else errors.WholeLifeCost = null;

        if (pu.WholeLifeBenefit !== null && isNaN(Number(pu.WholeLifeBenefit))) {
            errors.WholeLifeBenefit = 'Whole life benefit must be a number';
            errors.Valid = false;
        } else if (pu.WholeLifeBenefit !== null && !ValidationService.validSqlDecimal(Number(pu.WholeLifeBenefit))) {
            errors.WholeLifeBenefit = 'Whole life benefit is too big';
            errors.Valid = false;
        } else errors.WholeLifeBenefit = null;

        if (pu.NetPresentValue !== null && isNaN(Number(pu.NetPresentValue))) {
            errors.NetPresentValue = 'Net present value must be a number';
            errors.Valid = false;
        } else if (pu.NetPresentValue !== null && !ValidationService.validSqlDecimal(Number(pu.NetPresentValue))) {
            errors.NetPresentValue = 'Net present value is too big';
            errors.Valid = false;
        } else errors.NetPresentValue = null;

        return Promise.resolve(errors);
    };

    return (
        <ProgressUpdateForm<IProgrammeUpdate, ProgrammeProgressUpdateFormValidations, IProgramme>
            {...otherProps}
            entityId={entityId}
            dueDate={() => reportDates?.Next}
            rag={du => du.OverallRagOptionID}
            loadPeople={async p => {
                const people = [];
                if (p) {
                    people.push(...await EntityPeopleService.GetProgrammeEntityPeople({
                        programmeService: dataServices.programmeService,
                        //userDirectorateService: dataServices.userDirectorateService,
                        programmeId: p.ID
                    }));
                }
                return people;
            }}
            renderFormFields={({ changeColor, changeTextField, changeDropdown }, { FormData: pu, LastSignedOffUpdate: so, ValidationErrors: errors }) => {
                return (
                    <div className={styles.grid}>
                        <div className={styles.gridRow}>
                            <div className={`${styles.gridCol} ${styles.sm12} ${styles.xl3}`}>
                                <CrLabel text="Current phase" singleLine={true} />
                                <CrDropdown
                                    className={styles.formField}
                                    options={LookupService.entitiesToSelectableOptions(lookupData.ProgrammePhases)}
                                    selectedKey={pu.ProgrammePhaseID}
                                    onChange={(_, o) => changeDropdown(o, 'ProgrammePhaseID')}
                                    history={so.ProgrammePhase && so.ProgrammePhase.Title}
                                />
                            </div>
                            <div className={`${styles.gridCol} ${styles.sm12} ${styles.xl3}`}>
                                <CrLabel text="Whole life cost" singleLine={true} />
                                <CrNumberTextField
                                    className={styles.formField}
                                    suffix="£m"
                                    maxLength={19}
                                    value={pu.WholeLifeCost}
                                    onChange={(ev, newValue) => changeTextField(newValue, 'WholeLifeCost')}
                                    history={NumberService.IsNullOrUndefined(so?.WholeLifeCost) ? null : Number(so?.WholeLifeCost)}
                                    errorMessage={errors.WholeLifeCost}
                                />
                            </div>
                            <div className={`${styles.gridCol} ${styles.sm12} ${styles.xl3}`}>
                                <CrLabel text="Whole life benefit" singleLine={true} />
                                <CrNumberTextField
                                    className={styles.formField}
                                    suffix="£m"
                                    maxLength={19}
                                    value={pu.WholeLifeBenefit}
                                    onChange={(ev, newValue) => changeTextField(newValue, 'WholeLifeBenefit')}
                                    history={NumberService.IsNullOrUndefined(so?.WholeLifeBenefit) ? null : Number(so?.WholeLifeBenefit)}
                                    errorMessage={errors.WholeLifeBenefit}
                                />
                            </div>
                            <div className={`${styles.gridCol} ${styles.sm12} ${styles.xl3}`}>
                                <CrLabel text="Net present value" singleLine={true} />
                                <CrNumberTextField
                                    className={styles.formField}
                                    suffix="£m"
                                    maxLength={19}
                                    value={pu.NetPresentValue}
                                    onChange={(ev, newValue) => changeTextField(newValue, 'NetPresentValue')}
                                    history={NumberService.IsNullOrUndefined(so?.NetPresentValue) ? null : Number(so?.NetPresentValue)}
                                    errorMessage={errors.NetPresentValue}
                                />
                            </div>
                        </div>
                        <div className={`${styles.gridCol} ${styles.sm12}`}>
                            <p className={styles.fontSize18}>Headlines</p>
                            <RagPicker
                                label="Delivery confidence"
                                className={styles.formField}
                                selectedRAG={pu.OverallRagOptionID}
                                onColorChanged={id => changeColor(id, 'OverallRagOptionID')}
                                history={so.OverallRagOptionID}
                            />
                            <CrTextField
                                label="Delivery confidence description"
                                className={styles.formField}
                                placeholder='Why has the director chosen the particular RAG rating? Summarise any major risks or issues (put detail in the individual key work area)'
                                multiline
                                rows={2}
                                maxLength={500}
                                charCounter={true}
                                value={pu.ProgressUpdate}
                                onChange={(ev, newValue) => changeTextField(newValue, 'ProgressUpdate')}
                                history={so.ProgressUpdate}
                            />
                            <CrTextField
                                label="Future actions"
                                className={styles.formField}
                                placeholder="What are you doing/expecting others to do? When is the RAG expected to change, and what would cause a change?"
                                multiline
                                rows={2}
                                maxLength={500}
                                charCounter={true}
                                value={pu.FutureActions}
                                onChange={(ev, newValue) => changeTextField(newValue, 'FutureActions')}
                                history={so.FutureActions}
                            />
                            <CrTextField
                                label="Escalations for senior leader action"
                                className={styles.formField}
                                placeholder="What support do you need from your Director General; the Performance, Finance and Risk Committee; the Executive Committee; or ministers?"
                                multiline
                                rows={1}
                                maxLength={500}
                                charCounter={true}
                                value={pu.Escalations}
                                onChange={(ev, newValue) => changeTextField(newValue, 'Escalations')}
                                history={so.Escalations}
                            />
                            <p className={styles.fontSize18}>Key Indicators</p>
                            <RagWithComments
                                label="Finance"
                                className={styles.formField}
                                commentsPlaceholder='What is your net position as agreed with your business partner and how does this affect delivery?'
                                commentsRows={1}
                                commentsMaxLength={500}
                                selectedColor={pu.FinanceRagOptionID}
                                commentValue={pu.FinanceComment}
                                onColorChanged={colorId => changeColor(colorId, 'FinanceRagOptionID')}
                                onCommentChanged={(ev, newValue) => changeTextField(newValue, 'FinanceComment')}
                                history={{ color: so.FinanceRagOptionID, comment: so.FinanceComment }}
                            />
                            <RagWithComments
                                label="People"
                                className={styles.formField}
                                commentsPlaceholder='Do you have enough staff or any skills concerns? How will this affect delivery?'
                                commentsRows={1}
                                commentsMaxLength={500}
                                selectedColor={pu.PeopleRagOptionID}
                                commentValue={pu.PeopleComment}
                                onColorChanged={colorId => changeColor(colorId, 'PeopleRagOptionID')}
                                onCommentChanged={(ev, newValue) => changeTextField(newValue, 'PeopleComment')}
                                history={{ color: so.PeopleRagOptionID, comment: so.PeopleComment }}
                            />
                            <RagWithComments
                                label="Milestones"
                                className={styles.formField}
                                commentsPlaceholder='Are your key deliverables likely to happen on time and how does this affect delivery?'
                                commentsRows={1}
                                commentsMaxLength={500}
                                selectedColor={pu.MilestonesRagOptionID}
                                commentValue={pu.MilestonesComment}
                                onColorChanged={colorId => changeColor(colorId, 'MilestonesRagOptionID')}
                                onCommentChanged={(ev, newValue) => changeTextField(newValue, 'MilestonesComment')}
                                history={{ color: so.MilestonesRagOptionID, comment: so.MilestonesComment }}
                            />
                            <RagWithComments
                                label="Benefits"
                                className={styles.formField}
                                commentsPlaceholder='Are the social or financial benefits in the business case likely to be met and how does this affect delivery?'
                                commentsRows={1}
                                commentsMaxLength={500}
                                selectedColor={pu.BenefitsRagOptionID}
                                commentValue={pu.BenefitsComment}
                                onColorChanged={colorId => changeColor(colorId, 'BenefitsRagOptionID')}
                                onCommentChanged={(ev, newValue) => changeTextField(newValue, 'BenefitsComment')}
                                history={{ color: so.BenefitsRagOptionID, comment: so.BenefitsComment }}
                            />
                        </div>
                    </div>
                );
            }}
            loadEntity={id => dataServices.programmeService.read(id, true, true)}
            loadEntityUpdate={id => dataServices.programmeUpdateService.read(id)}
            loadNewEntityUpdate={() => new ProgrammeUpdate(entityId)}
            loadLastSavedProgressUpdate={() => reportDates?.Next &&
                dataServices.programmeUpdateService.readLatestUpdateForPeriod(entityId, reportDates.Next)}
            loadLastSignedOffEntityUpdate={() => reportDates?.Previous &&
                dataServices.programmeUpdateService.readLastSignedOffUpdateForPeriod(entityId, reportDates.Previous)}
            onValidateUpdate={validateProgrammeProgressUpdate}
            onBeforeSave={pu => {
                delete pu.Programme;
                pu.WholeLifeCost = NumberService.ToNumberOrNull(pu.WholeLifeCost);
                pu.WholeLifeBenefit = NumberService.ToNumberOrNull(pu.WholeLifeBenefit);
                pu.NetPresentValue = NumberService.ToNumberOrNull(pu.NetPresentValue);
            }}
            onSaveUpdate={pu => dataServices.programmeUpdateService.create(pu)}
            onClearForm={(d, showForm) => new ProgrammeProgressUpdateFormState(entityId, reportDates?.Next, d, showForm)}
            reportDates={reportDates}
            entity={entity}
            reportingFrequencies={lookupData?.ReportingFrequencies}
        />
    );
};
