import React, { useContext, useMemo } from 'react';
import {
    CrUpdateFormState, IProgressUpdateValidations, ProgressUpdateValidations, IEntityProgressUpdateFormProps, IPortfolioUpdate, IPortfolio, PortfolioUpdate, Portfolio
} from '../../types';
import styles from '../../styles/cr.module.scss';
import { RagPicker } from '../cr/RagPicker';
import { RagWithComments } from '../cr/RagWithComments';
import { CrTextField } from '../cr/CrTextField';
import { ProgressUpdateForm } from '../ProgressUpdateForm';
import { EntityPeopleService } from '../../services';
import { DataContext } from '../DataContext';

export class PortfolioProgressUpdateFormState extends CrUpdateFormState<IPortfolioUpdate, IProgressUpdateValidations, IPortfolio> {
    constructor(portfolioId: number, period: Date, parentEntity?: IPortfolio, showForm?: boolean) {
        super(
            new PortfolioUpdate(portfolioId, period),
            parentEntity || new Portfolio(),
            new PortfolioUpdate(),
            new ProgressUpdateValidations(),
            showForm
        );
    }
}

export const PortfolioProgressUpdateForm = (
    { entityId, entity, reportDates, ...otherProps }: IEntityProgressUpdateFormProps<IPortfolio>
): React.ReactElement => {
    const { dataServices, lookupData, loadLookupData: { reportingFrequencies } } = useContext(DataContext);
    useMemo(() => reportingFrequencies(), [reportingFrequencies]);

    return (
        <ProgressUpdateForm<IPortfolioUpdate, IProgressUpdateValidations, IPortfolio>
            {...otherProps}
            entityId={entityId}
            dueDate={() => reportDates?.Next}
            rag={du => du.OverallRagOptionID}
            loadPeople={async p => {
                const people = [];
                if (p) {
                    people.push(...await EntityPeopleService.GetPortfolioEntityPeople({
                        portfolioService: dataServices.portfolioService,
                        //userDirectorateService: dataServices.userDirectorateService,
                        portfolioId: p.ID
                    }));
                }
                return people;
            }}
            renderFormFields={({ changeColor, changeTextField }, { FormData: pu, LastSignedOffUpdate: so }) => {
                return (
                    <div className={styles.cr}>
                        <p className={styles.fontSize18}>Headlines</p>
                        <RagPicker
                            label="Delivery confidence"
                            className={styles.formField}
                            selectedRAG={pu.OverallRagOptionID}
                            onColorChanged={id => changeColor(id, 'OverallRagOptionID')}
                            history={so.OverallRagOptionID}
                        />
                        <CrTextField
                            label="Delivery confidence description"
                            className={styles.formField}
                            placeholder='Why has the director chosen the particular RAG rating? Summarise any major risks or issues (put detail in the individual key work area)'
                            multiline
                            rows={2}
                            maxLength={500}
                            charCounter={true}
                            value={pu.ProgressUpdate}
                            onChange={(ev, newValue) => changeTextField(newValue, 'ProgressUpdate')}
                            history={so.ProgressUpdate}
                        />
                        <CrTextField
                            label="Future actions"
                            className={styles.formField}
                            placeholder="What are you doing/expecting others to do? When is the RAG expected to change, and what would cause a change?"
                            multiline
                            rows={2}
                            maxLength={500}
                            charCounter={true}
                            value={pu.FutureActions}
                            onChange={(ev, newValue) => changeTextField(newValue, 'FutureActions')}
                            history={so.FutureActions}
                        />
                        <CrTextField
                            label="Escalations for senior leader action"
                            className={styles.formField}
                            placeholder="What support do you need from your Director General; the Performance, Finance and Risk Committee; the Executive Committee; or ministers?"
                            multiline
                            rows={1}
                            maxLength={500}
                            charCounter={true}
                            value={pu.Escalations}
                            onChange={(ev, newValue) => changeTextField(newValue, 'Escalations')}
                            history={so.Escalations}
                        />
                        <p className={styles.fontSize18}>Key Indicators</p>
                        <RagWithComments
                            label="Finance"
                            className={styles.formField}
                            commentsPlaceholder='What is your net position as agreed with your business partner and how does this affect delivery?'
                            commentsRows={1}
                            commentsMaxLength={500}
                            selectedColor={pu.FinanceRagOptionID}
                            commentValue={pu.FinanceComment}
                            onColorChanged={colorId => changeColor(colorId, 'FinanceRagOptionID')}
                            onCommentChanged={(ev, newValue) => changeTextField(newValue, 'FinanceComment')}
                            history={{ color: so.FinanceRagOptionID, comment: so.FinanceComment }}
                        />
                        <RagWithComments
                            label="People"
                            className={styles.formField}
                            commentsPlaceholder='Do you have enough staff or any skills concerns? How will this affect delivery?'
                            commentsRows={1}
                            commentsMaxLength={500}
                            selectedColor={pu.PeopleRagOptionID}
                            commentValue={pu.PeopleComment}
                            onColorChanged={colorId => changeColor(colorId, 'PeopleRagOptionID')}
                            onCommentChanged={(ev, newValue) => changeTextField(newValue, 'PeopleComment')}
                            history={{ color: so.PeopleRagOptionID, comment: so.PeopleComment }}
                        />
                        <RagWithComments
                            label="Milestones"
                            className={styles.formField}
                            commentsPlaceholder='Are your key deliverables likely to happen on time and how does this affect delivery?'
                            commentsRows={1}
                            commentsMaxLength={500}
                            selectedColor={pu.MilestonesRagOptionID}
                            commentValue={pu.MilestonesComment}
                            onColorChanged={colorId => changeColor(colorId, 'MilestonesRagOptionID')}
                            onCommentChanged={(ev, newValue) => changeTextField(newValue, 'MilestonesComment')}
                            history={{ color: so.MilestonesRagOptionID, comment: so.MilestonesComment }}
                        />
                        <RagWithComments
                            label="Benefits"
                            className={styles.formField}
                            commentsPlaceholder='Are the social or financial benefits in the business case likely to be met and how does this affect delivery?'
                            commentsRows={1}
                            commentsMaxLength={500}
                            selectedColor={pu.BenefitsRagOptionID}
                            commentValue={pu.BenefitsComment}
                            onColorChanged={colorId => changeColor(colorId, 'BenefitsRagOptionID')}
                            onCommentChanged={(ev, newValue) => changeTextField(newValue, 'BenefitsComment')}
                            history={{ color: so.BenefitsRagOptionID, comment: so.BenefitsComment }}
                        />
                    </div>
                );
            }}
            loadEntity={id => dataServices.portfolioService.read(id, true, true)}
            loadEntityUpdate={id => dataServices.portfolioUpdateService.read(id)}
            loadNewEntityUpdate={() => new PortfolioUpdate(entityId)}
            loadLastSavedProgressUpdate={() => reportDates?.Next &&
                dataServices.portfolioUpdateService.readLatestUpdateForPeriod(entityId, reportDates.Next)}
            loadLastSignedOffEntityUpdate={() => reportDates?.Previous &&
                dataServices.portfolioUpdateService.readLastSignedOffUpdateForPeriod(entityId, reportDates.Previous)}
            onValidateUpdate={() => Promise.resolve({ Valid: true, RagOptionID: null, Comment: null })}
            onBeforeSave={pu => delete pu.Portfolio}
            onSaveUpdate={pu => dataServices.portfolioUpdateService.create(pu)}
            onClearForm={(d, showForm) => new PortfolioProgressUpdateFormState(entityId, reportDates?.Next, d, showForm)}
            reportDates={reportDates}
            entity={entity}
            reportingFrequencies={lookupData?.ReportingFrequencies}
        />
    );
};
