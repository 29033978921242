import { EntityWithStatusService } from './EntityWithStatusService';
import { IOrgLevel1, IEntityChildren } from '../types';
import { EntityStatus } from '../refData/EntityStatus';
import { AppGlobals } from '../AppGlobals';

export class OrgLevel1Service extends EntityWithStatusService<IOrgLevel1> {
    constructor() {
        super(`/OrgLevel1s`);
    }

    public readAllForList(includeClosedGroups?: boolean): Promise<IOrgLevel1[]> {
        return this.readAll(
            `?$select=ID,Title`
            + `&$orderby=Title`
            + `&$expand=DirectorGeneralUser($select=Title),EntityStatus($select=Title)&$filter=CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosedGroups ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public readAllForLookup(includeClosedEntities?: boolean, additionalFields?: string): Promise<IOrgLevel1[]> {
        return this.readAll(
            `?$select=ID,Title${additionalFields ? `,${additionalFields}` : ''}`
            + `&$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosedEntities ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public async entityChildren(id: number): Promise<IEntityChildren[]> {
        return this.entityChildrenSingle(id, 'Directorates', 'Directorates', true);
    }
    public async create(group: IOrgLevel1): Promise<IOrgLevel1> {
        group.CustomerID = AppGlobals.CustomerID;           
        return super.create(group);
    }

    public readAllForGoList(): Promise<IOrgLevel1[]> {
        //reads all DG Areas for GoUpdates picklist
        return this.readAll(`?currentUser=&openDirectorateGroups=&goAppList&$orderby=Title`);
    }
}