import { IUserDirectorate, IEntityChildren, ICorporateRisk, IEntityDescription1 } from '../types';
import { EntityStatus } from '../refData/EntityStatus';
import { RiskRegister } from '../refData/RiskRegister';
import { EntityWithStatusService } from './EntityWithStatusService';
import { ContextService } from './ContextService';
import { addDays, subMonths } from 'date-fns';
import { DateService } from './DateService';
import axios from 'axios';
import { AppGlobals } from '../AppGlobals';

export class CorporateRiskService extends EntityWithStatusService<ICorporateRisk> {
    public readonly parentEntities = ['RiskOwnerUser', 'Directorate($expand=OrgLevel1)'];
    protected childrenEntities = ['RiskRiskTypes', 'Contributors($expand=ContributorUser)', 'Attributes($expand=AttributeType)'];
    private readonly _open = EntityStatus.Open;
    private readonly _closed = EntityStatus.Closed;

    constructor() {
        super(`/CorporateRisks`);
    }

    public readAllForLookup(includeClosedEntities?: boolean, additionalFields?: string): Promise<ICorporateRisk[]> {
        return this.readAll(
            `?$select=ID,Title,RiskCode,RiskRegisterID,DirectorateID,IsProjectRisk,ProjectID${additionalFields ? `,${additionalFields}` : ''}`
            + `&$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosedEntities ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public readAllForList_ProjectRisks(includeClosedRisks?: boolean, projectID?: number): Promise<ICorporateRisk[]> {
        return this.readAll(
            `?$select=ID,RiskCode,Title,RiskRegisterID,ManagedByTypeID`
            + `&$orderby=Title`
            + `&$expand=Directorate($expand=OrgLevel1($select=Title);$select=Title),RiskOwnerUser($select=Title)`
            + `,OwnerL1($select=Title),OwnerL2($select=Title),OwnerL3($select=Title),ManagedByL2($select=Title),ManagedByL3($select=Title)`
            + `,ReportApproverUser($select=Title),RiskMitigationActions($select=ID),EntityStatus($select=Title),Contributors($select=ContributorUser;$expand=ContributorUser($select=Title))`
            + `&$filter=CustomerID eq ${AppGlobals.CustomerID} and IsProjectRisk eq true`
            + (includeClosedRisks ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
            + (projectID ? ` and ProjectID eq ${projectID}` : '')
        );
    }

    public readAllForList_RiskRegister(includeClosedRisks?: boolean): Promise<ICorporateRisk[]> {
        return this.readAll(
            `?$select=ID,RiskCode,Title,RiskRegisterID,ManagedByTypeID`
            + `&$orderby=Title`
            + `&$expand=Directorate($expand=OrgLevel1($select=Title);$select=Title),RiskOwnerUser($select=Title)`
            + `,OwnerL1($select=Title),OwnerL2($select=Title),OwnerL3($select=Title),ManagedByL2($select=Title),ManagedByL3($select=Title)`
            + `,ReportApproverUser($select=Title),RiskMitigationActions($select=ID),EntityStatus($select=Title),Contributors($select=ContributorUser;$expand=ContributorUser($select=Title))`
            + `&$filter=CustomerID eq ${AppGlobals.CustomerID} and IsProjectRisk ne true`
            + (includeClosedRisks ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public async create(risk: ICorporateRisk): Promise<ICorporateRisk> {
        risk.CustomerID = AppGlobals.CustomerID;
        return super.create(risk);
    }

    public async readForView(riskID: number): Promise<ICorporateRisk> {
        return this.read(
            riskID,
            false,
            false,
            [
                'OwnerL1($select=Title)',
                'OwnerL2($select=Title)',
                'OwnerL3($select=Title)',
                'ManagedByL2($select=Title)',
                'ManagedByL3($select=Title)',
                'RiskOwnerUser($select=Title,EmailAddress)',
                'ReportApproverUser($select=Title,EmailAddress)',
                'Contributors($select=ContributorUser;$expand=ContributorUser($select=Title,EmailAddress))',
                'Directorate($select=Title)',
                'Attributes($expand=AttributeType($select=Title))',
                'UnmitigatedRiskProbability($select=Title)',
                'UnmitigatedRiskImpactLevel($select=Title)',
                'TargetRiskProbability($select=Title)',
                'TargetRiskImpactLevel($select=Title)',
                'RiskRiskTypes($select=RiskType;$expand=RiskType($select=Title))'
            ]
        );
    }


    public readExpandAll(riskId: number): Promise<ICorporateRisk> {
        return this.read(riskId, false, true);
    }

    public readMyRisks(userDirectorates?: IUserDirectorate[]): Promise<ICorporateRisk[]> {
        const username = encodeURIComponent(ContextService.Username());
        let filters = [
            `RiskOwnerUser/Username eq '${username}'`,
            `ReportApproverUser/Username eq '${username}'`,
            `Contributors/any(c: c/ContributorUser/Username eq '${username}')`
        ];

        if (userDirectorates) {
            filters = filters.concat(userDirectorates.filter(ud => ud.IsRiskAdmin).map(ud => `DirectorateID eq ${ud.DirectorateID}`));
        }

        return this.readAll(
            `?$select=ID,Title,RiskCode,DirectorateID`
            + `&$expand=Directorate($select=ID),Attributes($expand=AttributeType)`
            + `&$orderby=RiskRegisterID,ID`
            + `&$filter=(${filters.join(' or ')}) and EntityStatusID eq ${EntityStatus.Open}`
        );
    }

    public readRegisterRisksForMonth(riskRegisterId: number, registerEntityId: number, period: Date): Promise<ICorporateRisk[]> {
        const monthStart = addDays(DateService.lastDateOfMonth(subMonths(period, 1)), 1).toISOString();
        const monthEnd = addDays(DateService.lastDateOfMonth(period), 1).toISOString();
        if (riskRegisterId === RiskRegister.Departmental)
            return this.readAll(
                `?$expand=Directorate($select=Title;$expand=OrgLevel1($select=Title)),Project($select=Title),UnmitigatedRiskImpactLevel,UnmitigatedRiskProbability,TargetRiskImpactLevel,`
                + `TargetRiskProbability,RiskRiskTypes,RiskOwnerUser,ReportApproverUser,Attributes($expand=AttributeType)`
                + `&$filter=CreatedDate lt ${monthEnd} and (EntityStatusID eq ${this._open} or (EntityStatusID eq ${this._closed} and EntityStatusDate gt ${monthStart})) and `
                + `RiskRegisterID eq ${RiskRegister.Departmental}`
                + `&$orderby=RiskRegisterID,IsProjectRisk,ID`);
        if (riskRegisterId === RiskRegister.Group)
            return this.readAll(
                `?$expand=Directorate($select=Title),Project($select=Title),UnmitigatedRiskImpactLevel,UnmitigatedRiskProbability,TargetRiskImpactLevel,`
                + `TargetRiskProbability,RiskRiskTypes,RiskOwnerUser,ReportApproverUser,Attributes($expand=AttributeType)`
                + `&$filter=CreatedDate lt ${monthEnd} and (EntityStatusID eq ${this._open} or (EntityStatusID eq ${this._closed} and EntityStatusDate gt ${monthStart})) and `
                + `RiskRegisterID ne ${RiskRegister.Directorate} and Directorate/GroupID eq ${registerEntityId}`
                + `&$orderby=RiskRegisterID,IsProjectRisk,ID`);
        if (riskRegisterId === RiskRegister.Directorate)
            return this.readAll(
                `?$expand=Project($select=Title),UnmitigatedRiskImpactLevel,UnmitigatedRiskProbability,TargetRiskImpactLevel,`
                + `TargetRiskProbability,RiskRiskTypes,RiskOwnerUser,ReportApproverUser,Attributes($expand=AttributeType)`
                + `&$filter=CreatedDate lt ${monthEnd} and (EntityStatusID eq ${this._open} or (EntityStatusID eq ${this._closed} and EntityStatusDate gt ${monthStart})) and `
                + `DirectorateID eq ${registerEntityId}`
                + `&$orderby=RiskRegisterID,IsProjectRisk,ID`);
        if (riskRegisterId === RiskRegister.Project) {
            return this.readAll(
                `?$expand=Project($select=Title),UnmitigatedRiskImpactLevel,UnmitigatedRiskProbability,TargetRiskImpactLevel,`
                + `TargetRiskProbability,RiskRiskTypes,RiskOwnerUser,ReportApproverUser,Attributes($expand=AttributeType)`
                + `&$filter=CreatedDate lt ${monthEnd} and (EntityStatusID eq ${this._open} or (EntityStatusID eq ${this._closed} and EntityStatusDate gt ${monthStart})) and `
                + `ProjectID eq ${registerEntityId} and IsProjectRisk eq true`
                + `&$orderby=RiskRegisterID,IsProjectRisk,ID`);
        }
        return Promise.resolve([]);
    }

    public readDraftReportRisks = (): Promise<ICorporateRisk[]> => {
        return this.readAll(`?$expand=Directorate($select=ID),Attributes($expand=AttributeType)&$orderby=RiskRegisterID,ID&$filter=CustomerID eq ${AppGlobals.CustomerID} and EntityStatusID eq ${this._open}`);
    }

    public async entityChildren(id: number): Promise<IEntityChildren[]> {
        const riskUrl = `${this.entityUrl}(${id})`;
        const risks = (await this.axiosGet(`${riskUrl}/ChildRisks?$select=ID&$top=10`)).data.value;
        const actions = (await this.axiosGet(`${riskUrl}/RiskMitigationActions?$select=ID&$top=10`)).data.value;
        const updates = (await this.axiosGet(`${riskUrl}/RiskUpdates?$select=ID&$top=10`)).data.value;
        const signOffs = (await this.axiosGet(`${riskUrl}/SignOffs?$select=ID&$top=10`)).data.value;
        return [
            { ChildType: 'Child risks', CanBeAdopted: true, ChildIDs: (await risks).map((r: { ID: any; }) => r.ID) },
            { ChildType: 'Risk mitigating actions', CanBeAdopted: true, ChildIDs: (await actions).map((r: { ID: any; }) => r.ID) },
            { ChildType: 'Risk updates', CanBeAdopted: false, ChildIDs: (await updates).map((r: { ID: any; }) => r.ID) },
            { ChildType: 'Reports', CanBeAdopted: false, ChildIDs: (await signOffs).map((s: { ID: any; }) => s.ID) }
        ];
    }

    public static sortRisksByCode = (risks: ICorporateRisk[]): ICorporateRisk[] => {
        return risks.sort((a, b) => {
            const reg = ((a.IsProjectRisk ? 4 : a.RiskRegisterID || 0) || 0) - ((b.IsProjectRisk ? 4 : b.RiskRegisterID || 0) || 0);
            return reg === 0 ? a.ID - b.ID : reg;
        });
    };


    public readRiskPeople = (riskId: number): Promise<ICorporateRisk> => {
        return this.read(riskId, false, false, ['RiskOwnerUser', 'ReportApproverUser', 'Contributors']);
    }

    public async countRisksForProject(projectID: number): Promise<number> {
        if (projectID) {
            return (await this.readAll(`?$select=ID&$filter=EntityStatusID eq ${EntityStatus.Open} and ProjectID eq ${projectID}`))?.length;
        }
        return Promise.resolve(0);
    }

    public async readTitlesForRiskProjectDirectorateAndGroup(entityPlural: string, entitySingular: string, riskID: number, orgL2Label: string, fromRiskRegister?: boolean): Promise<IEntityDescription1> {
        const entityDescription1: IEntityDescription1 = {
            Description1ForList: '',
            Description1ForForm: '',
            DirectorateID: null,
        };

        if (fromRiskRegister === true) {
            const res = await this.readAll(`?$filter=ID eq ${riskID}&$select=Title`);

            if (res.length > 0) {
                entityDescription1.Description1ForList = `${entityPlural} for risk <span style='font-style: italic'>${res[0].Title}</span>`;
                entityDescription1.Description1ForForm = `${entitySingular} for risk <span style='font-style: italic'>${res[0].Title}</span>`;
            }
        }
        else {
            const res = await this.readAll(`?$filter=ID eq ${riskID}&$expand=Project($select=Title;$expand=Directorate($select=Title;$expand=OrgLevel1($select=Title)))&$select=Title`);

            if (res.length > 0) {
                entityDescription1.Description1ForList = `${entityPlural} for risk <span style='font-style: italic'>${res[0].Title}</span> for project, ${res[0].Project.Title} in ${orgL2Label} ${res[0].Project.Directorate.Title}, ${res[0].Project.Directorate.OrgLevel1.Title}`;
                entityDescription1.Description1ForForm = `${entitySingular} for risk <span style='font-style: italic'>${res[0].Title}</span> for project, ${res[0].Project.Title} in ${orgL2Label} ${res[0].Project.Directorate.Title}, ${res[0].Project.Directorate.OrgLevel1.Title}`;
            }
        }


        return entityDescription1;
    }
}