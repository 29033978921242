import { IReportingEntity, ReportingEntity } from "./ReportingEntity";
import { IUser } from "./User";
import { IOrgLevel1 } from "./OrgLevel1";
import { IUserDirectorate } from "./UserDirectorate";
import { ICommitment } from "./Commitment";
import { IKeyWorkArea } from "./KeyWorkArea";
import { IMetric } from "./Metric";
import { IRisk } from "./Risk";
import { IDirectorateUpdate } from "./DirectorateUpdate";
import { IEntity } from "./Entity";
import { IPortfolio } from "./Portfolio";

export interface IDirectorate extends IReportingEntity {
    CustomerID: number | null;
    DirectorUserID: number | null;
    Objectives: string;
    GroupID: number | null;
    ReportApproverUserID: number | null;
    ReportingLeadUserID: number | null;
    DirectorateUpdates?: IDirectorateUpdate[];
    DirectorUser?: IUser;
    OrgLevel1?: IOrgLevel1;
    ReportApproverUser?: IUser;
    ReportingLeadUser?: IUser;
    UserDirectorates?: IUserDirectorate[];
    Commitments?: ICommitment[];
    KeyWorkAreas?: IKeyWorkArea[];
    Metrics?: IMetric[];
    Risks?: IRisk[];
    PortfolioManagedByL2s?: IPortfolio[];
    PortfolioOwnerL2s?: IPortfolio[];
}

export class Directorate extends ReportingEntity implements IDirectorate {
    public CustomerID = null;
    public DirectorUserID = null;
    public Objectives = '';
    public GroupID = null;
    public ReportApproverUserID = null;
    public ReportingLeadUserID = null;
}