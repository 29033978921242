import { format, formatDistanceToNow, parse } from 'date-fns';

export class DateService {

	public static ukDateFormat = 'ddMMMyyyy'; //'dd/MM/yyyy'; // For date-fns formatting
	public static ukShortDateFormat = 'ddMMMyy';
	public static ukLongDateFormat = 'd MMMM yyyy'; // For date-fns formatting
	public static ukTimeFormat = 'HH:mm'; // For date-fns formatting
	public static ukDateTimeFormat = 'dd/MM/yyyy HH:mm'; // For date-fns formatting
	public static isDateString = new RegExp(/^[0-9]{4}-[01][0-9]-[0-3][0-9]T[0-2][0-9]:[0-5][0-9]:[0-5][0-9](\.[0-9]{1,7})?[+-]?([01][0-9]:[0-5][0-9])?Z?$/);
	public static monthNameFormat = 'MMMM yyyy'; // For date-fns formatting
	public static timestamp(): number { return (new Date()).getTime(); }

	
	public static removeTimezoneOffset(date: Date | string): Date {
		if(date != null){
			return new Date(date);
		}
		return null;
	}
	public static parseUkDate(value: string): Date {
		return parse(value, DateService.ukDateFormat, new Date());
	}

	public static setLocaleDateToUTC(d: Date): Date {
		return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0));
	}

	public static dateToUkDate(d: Date): string {
		const date = this.removeTimezoneOffset(d);
		return date instanceof Date ? format(date, DateService.ukDateFormat) : '';
	}

	public static dateToUkShortDate(d: Date): string {
		return d instanceof Date ? format(d, DateService.ukShortDateFormat) : '';
	}

	public static dateToUkDateTime(d: Date): string {
		const date = this.removeTimezoneOffset(d);
		return date instanceof Date ? format(date, DateService.ukDateTimeFormat) : '';
	}

	public static dateToUkLongDate(d: Date): string {
		const date = this.removeTimezoneOffset(d);
		return date instanceof Date ? format(date, DateService.ukLongDateFormat) : '';
	}

	public static dateToUkTime(d: Date): string {
		return d instanceof Date ? format(d, DateService.ukTimeFormat) : '';
	}

	public static dateToMonthNameFormat(d: Date): string {
		return d instanceof Date ? format(d, DateService.monthNameFormat) : '';
	}

	public static lastDateOfMonth = (d: Date): Date => {
		if (d instanceof Date) {
			return new Date(Date.UTC(d.getUTCFullYear(), d.getMonth() + 1, 0));
		} else {
			throw new Error('Invalid input. Expected a Date object.');
		}
	};


	public static convertODataDates(dataObject: unknown): unknown {
		DateService.convertObjectDates(dataObject);
		return dataObject;
	}

	private static convertObjectDates(dataObject: unknown): void {
		if (dataObject) {
			const obj = dataObject as { [key: string]: unknown }; // Type assertion

			Object.keys(obj).forEach(key => {
				if (typeof obj[key] === 'object') {
					DateService.convertObjectDates(obj[key]);
				} else if (DateService.isDateString.test(obj[key] as string)) {
					obj[key] = new Date(obj[key] as string);
				}
			});
		}
	}


	public static relativeToNow = (d: Date): string => {
		let date: Date = null;
		if(d != null){
			date =  new Date(d);
			console.log('instance of date', date);
		}
		else{
			console.log('not an instance of date');
		}
		return date instanceof Date ? formatDistanceToNow(date) : ``;
	}

}