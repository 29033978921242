import { EntityUpdateService } from './EntityUpdateService';
import { IWorkStreamUpdate} from '../types';

export class WorkStreamUpdateService extends EntityUpdateService<IWorkStreamUpdate> {
    constructor() {
        super(`/WorkStreamUpdates`);
    }

    public async readLatestUpdateForPeriod(workStreamId: number, period: Date): Promise<IWorkStreamUpdate> {
        const ws = await this.readAll(
            `?$top=1`
            + `&$expand=UpdateUser`
            + `&$filter=WorkStreamID eq ${workStreamId} and UpdatePeriod eq ${period.toISOString()}`
            + `&$orderby=UpdateDate desc`
        );
    
        if (ws.length > 0) {
            return ws[0];
        }
    
        // Instead of returning null, handle the case when ws is empty by throwing an error or returning an empty object
        throw new Error('No update found for the given workStreamId and period.');
    }
    
    

    public async readLastSignedOffUpdateForPeriod(workStreamId: number, period: Date): Promise<IWorkStreamUpdate> {
        const ws = await this.readAll(
            `?$top=1`
            + `&$filter=WorkStreamID eq ${workStreamId} and UpdatePeriod eq ${period.toISOString()} and SignOffID ne null`
            + `&$orderby=SignOffID desc`
        );
    
        if (ws.length > 0) {
            return ws[0];
        }
    
        // Instead of returning null, handle the case when ws is empty by throwing an error or returning an empty object
        throw new Error('No signed-off update found for the given workStreamId and period.');
    }
    
    
}