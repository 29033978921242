import * as React from 'react';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import * as types from '../../types';
import * as services from '../../services';
import { IOrgRepColumn, ColumnDisplayTypes } from '../../types/OrgRepColumn';
import OrgGenReport2 from './OrgGenReport2';
import { ICAPeriod } from '../../types';
import { CrDropdown } from '../cr/CrDropdown';
import GenExport from '../export/GenExport';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import '../../styles/CustomFabric2.scss';

export interface ICaReportClsProps extends types.IBaseComponentProps {
}

export interface ILookupData {
    Periods: ICAPeriod[];
    PeriodsOriginal: ICAPeriod[];
}

export class LookupData implements ILookupData {
    public Periods: ICAPeriod[] = [];
    public PeriodsOriginal: ICAPeriod[] = [];
}

export interface IOrgReportState {
    LookupData: ILookupData;
    SelectedPeriod: number;
    SelectedPeriodTxt: string;
    SelectedPivotKey: string;
    DirectoratesFilter: string;
    DivisionsFilter: string;
    ThemeFilter_DivisionLst: string;
    DGAreaStackedBar: boolean;
    DirectoratesStackedBar: boolean;
    DivisionsStackedBar: boolean;
    ThemeStackedBar: boolean;
    DGAreaBreakdowns: boolean;
    DirectoratesBreakdowns: boolean;
    DivisionsBreakdowns: boolean;
    ThemeBreakdowns: boolean;
    DGAreaControls: boolean;
    DirectoratesControls: boolean;
    DivisionsControls: boolean;
    ThemeControls: boolean;
    DGAreaHighlightOnly: boolean;
    DirectoratesHighlightOnly: boolean;
    DivisionsHighlightOnly: boolean;
    ThemeHighlightOnly: boolean;
    DGAreaAssurances: boolean;
    DirectoratesAssurances: boolean;
    DivisionsAssurances: boolean;
    ThemeAssurances: boolean;
    DGAreaAssurance1: boolean;
    DirectoratesAssurance1: boolean;
    DivisionsAssurance1: boolean;
    ThemeAssurance1: boolean;
    DGAreaAssurance2: boolean;
    DirectoratesAssurance2: boolean;
    DivisionsAssurance2: boolean;
    ThemeAssurance2: boolean;
    DGAreaAssurance3: boolean;
    DirectoratesAssurance3: boolean;
    DivisionsAssurance3: boolean;
    ThemeAssurance3: boolean;
}
export class OrgReportState implements IOrgReportState {
    public LookupData = new LookupData();
    public SelectedPeriod: number = 0;
    public SelectedPeriodTxt: string = "";
    public SelectedPivotKey = "Browse DG Areas"; //default, 1st tab selected
    public DirectoratesFilter = "";
    public DivisionsFilter = "";
    public ThemeFilter_DivisionLst = "";
    public DGAreaStackedBar = false;
    public DirectoratesStackedBar = false;
    public DivisionsStackedBar = false;
    public ThemeStackedBar = false;
    public DGAreaBreakdowns = false;
    public DirectoratesBreakdowns: boolean;
    public DivisionsBreakdowns: boolean;
    public ThemeBreakdowns = false;
    public DGAreaControls = true;
    public DirectoratesControls = true;
    public DivisionsControls = true;
    public ThemeControls = true;
    public DGAreaHighlightOnly = false;
    public DirectoratesHighlightOnly = false;
    public DivisionsHighlightOnly = false;
    public ThemeHighlightOnly = false;
    public DGAreaAssurances = true;
    public DirectoratesAssurances = true;
    public DivisionsAssurances = true;
    public ThemeAssurances = true;
    public DGAreaAssurance1 = false;
    public DirectoratesAssurance1 = false;
    public DivisionsAssurance1 = false;
    public ThemeAssurance1 = false;
    public DGAreaAssurance2 = false;
    public DirectoratesAssurance2 = false;
    public DivisionsAssurance2 = false;
    public ThemeAssurance2 = false;
    public DGAreaAssurance3 = false;
    public DirectoratesAssurance3 = false;
    public DivisionsAssurance3 = false;
    public ThemeAssurance3 = false;

    constructor() {
    }
}

export default class CaReportCls extends React.Component<ICaReportClsProps, OrgReportState> {

    private readonly headerTxt_DGAreas: string = "Browse DG Areas";
    private readonly headerTxt_Directorates: string = "Browse Directorates";
    private readonly headerTxt_Divisions: string = "Browse Divisions";
    private readonly headerTxt_Theme: string = "Browse Theme";
    private readonly headerTxt_ExportRep: string = "Export to Excel";

    private periodService: services.CAPeriodService = new services.CAPeriodService();

    constructor(props: ICaReportClsProps) {
        super(props);
        this.state = new OrgReportState();
    }

    public render(): React.ReactElement<ICaReportClsProps> {
        return (
            <React.Fragment>
                <CrDropdown
                    label="Which period do you want to view?"
                    placeholder="Select a Period"
                    style={{ marginTop: "10px", marginBottom: "20px" }}
                    options={this.state.LookupData.Periods.map((p) => { return { key: p.ID, text: p.Title }; })}
                    onChanged={(v) => this.changePeriodsDropdown(v)}
                    selectedKey={this.state.SelectedPeriod}
                />
                <Pivot onLinkClick={this.handlePivotClick} selectedKey={`${this.state.SelectedPivotKey}`}>
                    <PivotItem headerText={this.headerTxt_DGAreas} itemKey={this.headerTxt_DGAreas}>
                        {this.renderDGAreasReport()}
                    </PivotItem>
                    <PivotItem headerText={this.headerTxt_Directorates} itemKey={this.headerTxt_Directorates}>
                        {this.renderDirectoratesReport()}
                    </PivotItem>
                    <PivotItem headerText={this.headerTxt_Divisions} itemKey={this.headerTxt_Divisions}>
                        {this.renderDivisionsReport()}
                    </PivotItem>
                    <PivotItem headerText={this.headerTxt_Theme} itemKey={this.headerTxt_Theme}>
                        {this.renderThemeReport()}
                    </PivotItem>
                    <PivotItem headerText={this.headerTxt_ExportRep} itemKey={this.headerTxt_ExportRep}>
                        {this.renderGenExport()}
                    </PivotItem>
                </Pivot>
            </React.Fragment>
        );
    }

    private renderGenExport(): React.ReactElement<types.IWebPartComponentProps> {
        if (this.state.SelectedPeriod > 0) {
            return (
                <div>
                    <div style={{ paddingTop: "10px" }}>
                        <GenExport
                            {...this.props}
                            errorHandling={this.props.errorHandling}
                            moduleName="ControlsAssurance"
                            periodId={Number(this.state.SelectedPeriod)}
                            periodTitle={this.state.SelectedPeriodTxt}
                        />
                    </div>
                </div>
            );
        }

        else
            return null;
    }

    private renderDGAreasReport() {
        const listColumns: IOrgRepColumn[] = [
            {
                key: 'Title',
                columnDisplayType: ColumnDisplayTypes.StackedBarOnAndOff,
                name: 'DG Area',
                fieldName: 'Title',
                minWidth: 250,
                maxWidth: 300,
                isResizable: true,
                headerClassName: "bold"
            },
            {
                key: 'TotalAUnsatisfactory',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalAUnsatisfactory',
                fieldName: 'TotalAUnsatisfactory',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalALimited',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalALimited',
                fieldName: 'TotalALimited',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalAModerate',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalAModerate',
                fieldName: 'TotalAModerate',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalASubstantial',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalASubstantial',
                fieldName: 'TotalASubstantial',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalANotApplicable',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalANotApplicable',
                fieldName: 'TotalANotApplicable',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalB1Unsatisfactory',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalB1Unsatisfactory',
                fieldName: 'TotalB1Unsatisfactory',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalB1Limited',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalB1Limited',
                fieldName: 'TotalB1Limited',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalB1Moderate',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalB1Moderate',
                fieldName: 'TotalB1Moderate',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalB1Substantial',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalB1Substantial',
                fieldName: 'TotalB1Substantial',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalB1NotApplicable',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalB1NotApplicable',
                fieldName: 'TotalB1NotApplicable',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'ControlsBar',
                columnDisplayType: ColumnDisplayTypes.StackedBarOn,
                name: 'Controls',
                fieldName: 'ControlsBar',
                minWidth: 245,
                maxWidth: 245,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'AssuranceBar',
                columnDisplayType: ColumnDisplayTypes.StackedBarOn,
                name: 'Assurance',
                fieldName: 'AssuranceBar',
                minWidth: 245,
                maxWidth: 245,
                isResizable: true,
                headerClassName: "bold",
            },
        ];

        return (
            this.state.LookupData.Periods && this.state.SelectedPeriod > 0 &&
            <React.Fragment>
                <OrgGenReport2
                    periodId={this.state.SelectedPeriod}
                    onItemTitleClick={this.handleItemTitleClick}
                    columns={listColumns}
                    {...this.props}
                    errorHandling={this.props.errorHandling}
                    entityService={new services.DGAreaStatService()}
                    entityNamePlural="DG Areas"
                    stackedBar={this.state.DGAreaStackedBar}
                    onChangeStackedBar={this.handleChangeStackedBar_DGAreas}
                />
            </React.Fragment>
        );
    }

    private renderDirectoratesReport() {
        const listColumns: IOrgRepColumn[] = [
            {
                key: 'DGTitle',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'DGTitle',
                fieldName: 'DGTitle',
                minWidth: 1,
            },
            {
                key: 'Title',
                columnDisplayType: ColumnDisplayTypes.StackedBarOnAndOff,
                name: 'Directorate',
                fieldName: 'Title',
                minWidth: 250,
                maxWidth: 300,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalAUnsatisfactory',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalAUnsatisfactory',
                fieldName: 'TotalAUnsatisfactory',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalALimited',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalALimited',
                fieldName: 'TotalALimited',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalAModerate',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalAModerate',
                fieldName: 'TotalAModerate',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalASubstantial',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalASubstantial',
                fieldName: 'TotalASubstantial',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalANotApplicable',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalANotApplicable',
                fieldName: 'TotalANotApplicable',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalB1Unsatisfactory',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalB1Unsatisfactory',
                fieldName: 'TotalB1Unsatisfactory',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalB1Limited',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalB1Limited',
                fieldName: 'TotalB1Limited',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalB1Moderate',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalB1Moderate',
                fieldName: 'TotalB1Moderate',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalB1Substantial',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalB1Substantial',
                fieldName: 'TotalB1Substantial',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalB1NotApplicable',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalB1NotApplicable',
                fieldName: 'TotalB1NotApplicable',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'ControlsBar',
                columnDisplayType: ColumnDisplayTypes.StackedBarOn,
                name: 'Controls',
                fieldName: 'ControlsBar',
                minWidth: 245,
                maxWidth: 245,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'AssuranceBar',
                columnDisplayType: ColumnDisplayTypes.StackedBarOn,
                name: 'Assurance',
                fieldName: 'AssuranceBar',
                minWidth: 245,
                maxWidth: 245,
                isResizable: true,
                headerClassName: "bold",
            },
        ];

        return (
            <React.Fragment>
                <OrgGenReport2
                    periodId={this.state.SelectedPeriod}
                    onItemTitleClick={this.handleItemTitleClick}
                    columns={listColumns}
                    {...this.props}
                    errorHandling={this.props.errorHandling}
                    entityService={new services.DirectorateStatService()}
                    entityNamePlural="Directorates"
                    filterText={this.state.DirectoratesFilter}
                    stackedBar={this.state.DirectoratesStackedBar}
                    onChangeStackedBar={this.handleChangeStackedBar_Directorates}
                />

            </React.Fragment>
        );
    }

    private renderDivisionsReport() {
        const listColumns: IOrgRepColumn[] = [
            {
                key: 'DGTitle',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'DGTitle',
                fieldName: 'DGTitle',
                minWidth: 1,
            },
            {
                key: 'DirTitle',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'DirTitle',
                fieldName: 'DirTitle',
                minWidth: 1,
            },
            {
                key: 'Title',
                columnDisplayType: ColumnDisplayTypes.StackedBarOnAndOff,
                name: 'Division',
                fieldName: 'Title',
                minWidth: 250,
                maxWidth: 300,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalAUnsatisfactory',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalAUnsatisfactory',
                fieldName: 'TotalAUnsatisfactory',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalALimited',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalALimited',
                fieldName: 'TotalALimited',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalAModerate',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalAModerate',
                fieldName: 'TotalAModerate',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalASubstantial',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalASubstantial',
                fieldName: 'TotalASubstantial',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalANotApplicable',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalANotApplicable',
                fieldName: 'TotalANotApplicable',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalB1Unsatisfactory',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalB1Unsatisfactory',
                fieldName: 'TotalB1Unsatisfactory',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalB1Limited',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalB1Limited',
                fieldName: 'TotalB1Limited',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalB1Moderate',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalB1Moderate',
                fieldName: 'TotalB1Moderate',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalB1Substantial',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalB1Substantial',
                fieldName: 'TotalB1Substantial',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalB1NotApplicable',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalB1NotApplicable',
                fieldName: 'TotalB1NotApplicable',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'ControlsBar',
                columnDisplayType: ColumnDisplayTypes.StackedBarOn,
                name: 'Controls',
                fieldName: 'ControlsBar',
                minWidth: 245,
                maxWidth: 245,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'AssuranceBar',
                columnDisplayType: ColumnDisplayTypes.StackedBarOn,
                name: 'Assurance',
                fieldName: 'AssuranceBar',
                minWidth: 245,
                maxWidth: 245,
                isResizable: true,
                headerClassName: "bold",
            },
        ];

        return (
            <React.Fragment>
                <OrgGenReport2
                    periodId={this.state.SelectedPeriod}
                    onItemTitleClick={this.handleItemTitleClick}
                    columns={listColumns}
                    {...this.props}
                    errorHandling={this.props.errorHandling}
                    entityService={new services.DivisionStatService()}
                    entityNamePlural="Divisions"
                    filterText={this.state.DivisionsFilter}
                    stackedBar={this.state.DivisionsStackedBar}
                    onChangeStackedBar={this.handleChangeStackedBar_Divisions}
                />

            </React.Fragment>
        );
    }

    private renderThemeReport() {

        const listColumns: IOrgRepColumn[] = [
            {
                key: 'Title',
                columnDisplayType: ColumnDisplayTypes.StackedBarOnAndOff,
                name: 'Theme',
                fieldName: 'Title',
                minWidth: 250,
                maxWidth: 300,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalAUnsatisfactory',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalAUnsatisfactory',
                fieldName: 'TotalAUnsatisfactory',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalALimited',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalALimited',
                fieldName: 'TotalALimited',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalAModerate',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalAModerate',
                fieldName: 'TotalAModerate',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalASubstantial',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalASubstantial',
                fieldName: 'TotalASubstantial',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalANotApplicable',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalANotApplicable',
                fieldName: 'TotalANotApplicable',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalB1Unsatisfactory',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalB1Unsatisfactory',
                fieldName: 'TotalB1Unsatisfactory',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalB1Limited',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalB1Limited',
                fieldName: 'TotalB1Limited',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalB1Moderate',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalB1Moderate',
                fieldName: 'TotalB1Moderate',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalB1Substantial',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalB1Substantial',
                fieldName: 'TotalB1Substantial',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'TotalB1NotApplicable',
                columnDisplayType: ColumnDisplayTypes.Hidden,
                name: 'TotalB1NotApplicable',
                fieldName: 'TotalB1NotApplicable',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'ControlsBar',
                columnDisplayType: ColumnDisplayTypes.StackedBarOn,
                name: 'Controls',
                fieldName: 'ControlsBar',
                minWidth: 245,
                maxWidth: 245,
                isResizable: true,
                headerClassName: "bold",
            },
            {
                key: 'AssuranceBar',
                columnDisplayType: ColumnDisplayTypes.StackedBarOn,
                name: 'Assurance',
                fieldName: 'AssuranceBar',
                minWidth: 245,
                maxWidth: 245,
                isResizable: true,
                headerClassName: "bold",
            },
        ];

        return (
            <React.Fragment>
                {/* <ThemeStatReport2
          periodId={this.state.SelectedPeriod}
          onItemTitleClick={this.handleItemTitleClick}
          columns={listColumns}
          {...this.props}
          onError={this.props.errorHandling?.onError}
          entityService={new services.ThemeStatService()}
          entityNamePlural="Themes"
          divisionLstFilter={this.state.ThemeFilter_DivisionLst}

          stackedBar={this.state.ThemeStackedBar}
          onChangeStackedBar={this.handleChangeStackedBar_Theme}


        /> */}

            </React.Fragment>
        );
    }

    private handlePivotClick = (item: PivotItem): void => {
        this.props.errorHandling.clearErrors();
        this.setState({ SelectedPivotKey: item.props.headerText, DirectoratesFilter: "", DivisionsFilter: "", ThemeFilter_DivisionLst: "" });
    }

    private handleItemTitleClick = (value: string, entityNamePlural: string): void => {
        if (entityNamePlural === "DG Areas") {
            this.setState({
                SelectedPivotKey: this.headerTxt_Directorates, DirectoratesFilter: value,
                DirectoratesBreakdowns: this.state.DGAreaBreakdowns,
                DirectoratesControls: this.state.DGAreaControls,
                DirectoratesHighlightOnly: this.state.DGAreaHighlightOnly,
                DirectoratesAssurances: this.state.DGAreaAssurances,
                DirectoratesAssurance1: this.state.DGAreaAssurance1,
                DirectoratesAssurance2: this.state.DGAreaAssurance2,
                DirectoratesAssurance3: this.state.DGAreaAssurance3,
            });
        }
        else if (entityNamePlural === "Directorates") {
            this.setState({
                SelectedPivotKey: this.headerTxt_Divisions, DivisionsFilter: value,
                DivisionsBreakdowns: this.state.DirectoratesBreakdowns,
                DivisionsControls: this.state.DirectoratesControls,
                DivisionsHighlightOnly: this.state.DirectoratesHighlightOnly,
                DivisionsAssurances: this.state.DirectoratesAssurances,
                DivisionsAssurance1: this.state.DirectoratesAssurance1,
                DivisionsAssurance2: this.state.DirectoratesAssurance2,
                DivisionsAssurance3: this.state.DirectoratesAssurance3,
            });
        }
        else if (entityNamePlural === "Divisions") {
            this.setState({
                SelectedPivotKey: this.headerTxt_Theme, ThemeFilter_DivisionLst: value,
                ThemeBreakdowns: this.state.DivisionsBreakdowns,
                ThemeControls: this.state.DivisionsControls,
                ThemeHighlightOnly: this.state.DivisionsHighlightOnly,
                ThemeAssurances: this.state.DivisionsAssurances,
                ThemeAssurance1: this.state.DivisionsAssurance1,
                ThemeAssurance2: this.state.DivisionsAssurance2,
                ThemeAssurance3: this.state.DivisionsAssurance3,
            });
        }
    }

    private handleChangeStackedBar_DGAreas = (value: boolean): void => {
        this.setState({ DGAreaStackedBar: value });
    }

    private handleChangeStackedBar_Directorates = (value: boolean): void => {
        this.setState({ DirectoratesStackedBar: value });
    }

    private handleChangeStackedBar_Divisions = (value: boolean): void => {
        this.setState({ DivisionsStackedBar: value });
    }

    private handleChangeStackedBar_Theme = (value: boolean): void => {
        this.setState({ ThemeStackedBar: value });
    }

    private changePeriodsDropdown = (option: IDropdownOption): void => {
        const selectedPeriodTxt: string = this.getSelectedPeriodText(Number(option.key), this.state.LookupData.PeriodsOriginal);
        this.setState({ SelectedPeriod: Number(option.key), SelectedPeriodTxt: selectedPeriodTxt });
    }

    private loadPeriods = (): Promise<void | ICAPeriod[]> => {
        return this.periodService.readAll().then((pArr: ICAPeriod[]): ICAPeriod[] => {
            const pArrCopy = JSON.parse(JSON.stringify(pArr));
            //get the current period
            let currentPeriodId: number = 0;
            const currentPeriod = pArr.filter(p => p.PeriodStatus === "Current Period");
            if (currentPeriod && currentPeriod.length > 0) {
                currentPeriodId = currentPeriod[0].ID;
            }

            const selectedPeriodTxt: string = this.getSelectedPeriodText(currentPeriodId, pArrCopy);

            //show status like Qtr 2 2019 ( Current Period ) in Title
            for (let i = 0; i < pArr.length; i++) {
                let p: ICAPeriod = pArr[i];
                pArr[i].Title = `${p.Title} ( ${p.PeriodStatus} )`;
            }

            //check user permissions
            if (this.superUserLoggedIn() === true) {
            }
            else {
                //dont show design periods
                pArr = pArr.filter(p => p.PeriodStatus !== "Design Period");
            }

            const xx = { ...this.state.LookupData, ['Periods']: pArr, ['PeriodsOriginal']: pArrCopy };
            this.setState({
                LookupData: xx,
                SelectedPeriod: currentPeriodId,
                SelectedPeriodTxt: selectedPeriodTxt
            }, () => { console.log("After set ", this.state.SelectedPeriod); });
            return pArr;
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading Periods lookup data`, err.message); });
    }

    private loadLookups(): Promise<any> {
        return Promise.all([
            this.loadPeriods(),
        ]);
    }

    public componentDidMount(): void {
        this.loadLookups();
    }

    private getSelectedPeriodText = (periodId: number, periodsOriginal: ICAPeriod[]): string => {
        let periodTxt: string = "";
        var pp = periodsOriginal.filter(p => p.ID === periodId);
        if (pp[0]) {
            periodTxt = pp[0]["Title"];
        }
        return periodTxt;
    }

    private superUserLoggedIn(): boolean {
        return this.props.userPermissions.UserIsSystemAdmin()
            || this.props.userPermissions.UserIsControlsAssuranceSuperUser();
    }
}
