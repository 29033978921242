import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from '../../styles/cr.module.scss';
import { IWithErrorHandlingProps, withErrorHandling } from '../withErrorHandling';
import { SignOffType } from '../../refData/SignOffType';
import { useParams } from 'react-router-dom';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { CrCommandBar } from '../cr/CrCommandBar';
import { DraftReportList } from '../draftReport/DraftReportList';

interface PortfolioUpdatesProps extends IWithErrorHandlingProps {
    lastDirectorateIdForChilds?: number;
    apiConnected: boolean;
    emphasiseProjectsWithAttribute: string;
    emphasisedProjectsHeaderText: string;
    otherProjectsHeaderText: string;
}

const PortfolioUpdates = (props: PortfolioUpdatesProps) => {

    const { portfolioIdForUpdates } = useParams<{ portfolioIdForUpdates: string }>();
    const history = useHistory();

    const commandBarItems: ICommandBarItemProps[] = [
        {
            key: 'portfolios',
            text: 'Portfolios',
            iconProps: { iconName: 'MiniExpandMirrored' },
            onClick: (ev, item) => {
                history.push('/performance-reporting/portfolios');
            },
        },
        {
            key: 'signoff',
            text: 'Review & Sign Off',
            iconProps: { iconName: 'MiniExpand' },
            onClick: (ev, item) => {
                history.push(`/performance-reporting/sign-off-for-portfolio/${portfolioIdForUpdates}`);
            },
        }
    ];


    return (

        <div className={`${styles.updatesContainer}`}>
            <CrCommandBar items={commandBarItems} />
            <div>
                <DraftReportList
                    defaultReportType={SignOffType.Portfolio}
                    defaultEntityId={Number(portfolioIdForUpdates)}
                    {...props}
                />
            </div>


        </div>

    );
};

export default withErrorHandling(PortfolioUpdates);