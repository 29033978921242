import { useCallback, useEffect, useState } from 'react';
import { IDataServices, IOrgConfig, IUserContext, UserContext } from '../types';
import { UserPermissionService } from '../services';
import { IUserPermissionService } from '../services/UserPermissionService';
import { IErrorHandling } from './withErrorHandling';
import { AppGlobals } from '../AppGlobals';

export interface IUseUserContextProps {
    userContext: IUserContext;
    userPermissions: IUserPermissionService;
    orbConfig: IOrgConfig;
}

export const useUserContext = (apiConnected: boolean, { userService, orgConfigService }: IDataServices, { onError }: IErrorHandling): IUseUserContextProps => {
    const [state, setState] = useState<{ UserContext: IUserContext, UserPermissions: UserPermissionService }>({
        UserContext: new UserContext(),
        UserPermissions: new UserPermissionService(new UserContext())
    });

    const orgConfigDefault: IOrgConfig = {
        CustomerID: AppGlobals.CustomerID,
        ID: 0,
        Title: '',
        OrgTypeLabel: '',
        OrgTypeLabelP: '',
        OrgTypeMgrLabel: '',
        OrgTypeMgrLabelP: '',
        OrgL1Label: 'Group',
        OrgL1LabelP: 'Groups',
        OrgMgrL1Lable: 'Director General (DG)',
        OrgMgrL1LabelP: 'Director Generals (DGs)',
        OrgL2Label: 'Directorate',
        OrgL2LabelP: 'Directorates',
        OrgMgrL2Lable: 'Director',
        OrgMgrL2LabelP: 'Directors',
        OrgL3Label: '',
        OrgL3LabelP: '',
        OrgMgrL3Lable: '',
        OrgMgrL3LabelP: ''
    }

    const [orgConfigState, setOrgConfig] = useState<IOrgConfig>(orgConfigDefault);
    const logError = useCallback(onError, [onError]);

    useEffect(() => {
        const loadOrgConfig = async (): Promise<void> => {
            try {
                const orgConfigs: IOrgConfig[] = await orgConfigService.readAll(`?$filter=CustomerID eq ${AppGlobals.CustomerID}`);
                if (orgConfigs.length > 0) {
                    const orgConfig: IOrgConfig = {
                        CustomerID: orgConfigs[0].CustomerID,
                        ID: orgConfigs[0].ID,
                        Title: orgConfigs[0].Title,
                        OrgTypeLabel: orgConfigs[0].OrgTypeLabel,
                        OrgTypeLabelP: orgConfigs[0].OrgTypeLabelP,
                        OrgTypeMgrLabel: orgConfigs[0].OrgTypeMgrLabel,
                        OrgTypeMgrLabelP: orgConfigs[0].OrgTypeMgrLabelP,
                        OrgL1Label: orgConfigs[0].OrgL1Label,
                        OrgL1LabelP: orgConfigs[0].OrgL1LabelP,
                        OrgMgrL1Lable: orgConfigs[0].OrgMgrL1Lable,
                        OrgMgrL1LabelP: orgConfigs[0].OrgMgrL1LabelP,
                        OrgL2Label: orgConfigs[0].OrgL2Label,
                        OrgL2LabelP: orgConfigs[0].OrgL2LabelP,
                        OrgMgrL2Lable: orgConfigs[0].OrgMgrL2Lable,
                        OrgMgrL2LabelP: orgConfigs[0].OrgMgrL2LabelP,
                        OrgL3Label: orgConfigs[0].OrgL3Label,
                        OrgL3LabelP: orgConfigs[0].OrgL3LabelP,
                        OrgMgrL3Lable: orgConfigs[0].OrgMgrL3Lable,
                        OrgMgrL3LabelP: orgConfigs[0].OrgMgrL3LabelP
                    }
                    setOrgConfig(orgConfig);
                }
                //console.log('after loadOrgConfig', orgConfigs);
            } catch (err) {
                const error = err as Error;
                logError(`Error loading orgconfig`, error.message);
            }
        };

        const loadUserPermissions = async (): Promise<void> => {
            try {
                console.log('in useUserContext - loadUserPermissions');
                const user = await userService?.readMyPermissions();
                console.log('after readMyPermissions', user);
                const uc: IUserContext = {
                    UserId: user?.ID ?? null,
                    Username: user?.Username ?? '',
                    UserTitle: user?.Title,
                    UserEntities: {
                        EntitiesTimestamp: new Date().getTime(),
                        UserRoles: user?.UserRoles ?? [],
                        UserGroups: user?.UserGroups ?? [],
                        UserDirectorates: user?.UserDirectorates ?? [],
                        UserProjects: user?.UserProjects ?? [],
                        UserPartnerOrganisations: user?.UserPartnerOrganisations ?? [],
                        FinancialRiskUserGroups: user?.FinancialRiskUserGroups ?? []
                    },
                    DirectorOf: user?.DirectorateDirectorUsers ?? [],
                    DirectorGeneralOf: user?.OrgLevel1DirectorGeneralUsers ?? [],
                    DeputyDirectorOf: user?.OrgLevel3DeputyDirectorUsers ?? [],
                    ApproverOfDirectorates: user?.DirectorateReportApproverUsers ?? [],
                    SROOf: user?.ProjectSeniorResponsibleOwnerUsers ?? [],
                    ApproverOfProjects: user?.ProjectReportApproverUsers ?? [],
                    RiskOwnerOf: [...user?.CorporateRiskRiskOwnerUsers ?? [], ...user?.FinancialRiskRiskOwnerUsers ?? []],
                    AlternativeApproverOfRisks: user?.CorporateRiskReportApproverUsers,
                    AlternativeApproverOfFinancialRisks: user?.FinancialRiskReportApproverUsers,
                    LeadPolicySponsorOfPartnerOrgs: user?.PartnerOrganisationLeadPolicySponsorUsers ?? [],
                    ReportAuthorOfPartnerOrgs: user?.PartnerOrganisationReportAuthorUsers ?? [],
                    ContributorTo: user?.ContributorContributorUsers ?? []
                };
                setState({ UserContext: uc, UserPermissions: new UserPermissionService(uc) });
            } catch (err) {
                const error = err as Error;
                logError(`Error loading user context`, error.message);
            }
        };

        if (apiConnected) {
            loadOrgConfig();
            loadUserPermissions();
        }
    }, [apiConnected, userService, logError]);

    return { userContext: state.UserContext, userPermissions: state.UserPermissions, orbConfig: orgConfigState };
};
