import { EntityUpdateService } from './EntityUpdateService';
import { IFinancialRiskUpdate } from '../types';
import { sub } from 'date-fns';
import { SignOffService } from './SignOffService';

export class FinancialRiskUpdateService extends EntityUpdateService<IFinancialRiskUpdate> {
    public parentEntities = [
        'UpdateUser',
        'RiskImpactLevel',
        'RiskProbability',
        'FinancialRisk($expand=OrgLevel1,Directorate,FinancialRiskMitigationActions,UnmitigatedRiskImpactLevel,UnmitigatedRiskProbability,RiskAppetite,RiskRiskTypes)',
        'RiskRegister'
    ];
    protected childrenEntities = [
        'RiskUpdateRiskMitigationActionUpdates'
    ];

    constructor() {
        super(`/FinancialRiskUpdates`);
    }

    public async readLatestUpdateForPeriod(riskId: number, period: Date): Promise<IFinancialRiskUpdate> {
        const ru = await this.readAll(
            `?$top=1`
            + `&$filter=RiskID eq ${riskId} and UpdatePeriod eq ${period.toISOString()}`
            + `&$orderby=UpdateDate desc`
            + `&$expand=UpdateUser($select=ID,Title),RiskImpactLevel,RiskProbability`
        );
        if (ru.length > 0)
            return ru[0];
        else
            throw new Error('No FinancialRiskUpdate found.');
    }

    public async readLatestUpdate(riskId: number): Promise<IFinancialRiskUpdate> {
        const ru = await this.readAll(
            `?$top=1`
            + `&$filter=RiskID eq ${riskId}`
            + `&$orderby=UpdatePeriod desc,UpdateDate desc`
        );
        if (ru.length > 0)
            return ru[0];
        else
            throw new Error('No FinancialRiskUpdate found.');
    }

    public async readLastSignedOffUpdateForPeriod(riskId: number, period: Date): Promise<IFinancialRiskUpdate> {
        const ru = await this.readAll(
            `?$top=1`
            + `&$expand=SignOff($expand=SignOffUser)`
            + `&$filter=RiskID eq ${riskId} and UpdatePeriod eq ${period.toISOString()} and SignOffID ne null`
            + `&$orderby=SignOffID desc`
        );
        if (ru.length > 0) {
            const riskUpdate = ru[0];
            if (riskUpdate.SignOff) {
                SignOffService.mapReportJsonToProperties(riskUpdate.SignOff);
                return riskUpdate;
            } else {
                throw new Error('No SignOff found for the FinancialRiskUpdate.');
            }
        } else {
            throw new Error('No FinancialRiskUpdate found.');
        }
    }


    public async readLatestSignedOffUpdate(riskId: number): Promise<IFinancialRiskUpdate> {
        const ru = await this.readAll(
            `?$top=1`
            + `&$filter=RiskID eq ${riskId}`
            + `&$orderby=UpdatePeriod desc,ID desc`
        );
        if (ru.length > 0)
            return ru[0];
        else
            throw new Error('No FinancialRiskUpdate found.');
    }

    public readAllLastSixMonths(): Promise<IFinancialRiskUpdate[]> {
        const sixMonthsAgo = sub(new Date(), { months: 6 });
        return this.readAll(
            `?$select=ID,Title,RiskCode,UpdatePeriod`
            + `&$filter=IsCurrent eq true and UpdatePeriod gt ${sixMonthsAgo.toISOString()}`
            + `&$orderby=UpdatePeriod desc,Title`
        );
    }

    public readCompleteRiskUpdate(id: number): Promise<IFinancialRiskUpdate> {
        return this.read(id, true, true);
    }
}