import React, { useEffect, useMemo, useState } from 'react';
import { IPanelHeaderRenderer, IPanelProps, Panel, PanelType } from '@fluentui/react/lib/Panel';
import { CrLoadingOverlay } from '../cr/CrLoadingOverlay';
import { ViewFormCommandBar } from '../cr/ViewFormCommandBar';
import { IApp03Task } from '../../types';
import styles from '../../styles/cr.module.scss';
import { getTaskPriorityString } from '../../refData/WorkflowType';
import { renderDate } from '../cr/ListRenderers';

interface TaskViewProps {
    entityName?: string;
    showForm?: boolean;
    entityId?: number;
    onClosed?: () => void;
    loadEntity: () => Promise<IApp03Task>;
}

export const TaskView: React.FC<TaskViewProps> = ({ showForm, entityName, entityId, loadEntity, onClosed }) => {
    const [formData, setFormData] = useState<IApp03Task>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const entity = await loadEntity();
                setFormData(entity);
                setLoading(false);
            } catch (error) {
                console.error('Error loading entity:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [loadEntity]);

    const TaskUsers = useMemo(() => {
        if (!formData) return '';

        const staffRoles = [
            {
                role: '',
                users: formData.App03TaskUsers && formData.App03TaskUsers.some(o => o.User != null)
                    ? formData.App03TaskUsers.map(o => `${o.User?.Title} (${o.User?.EmailAddress})`)
                    : [],
            },

        ];

        const formattedStaffRoles = staffRoles
            .filter(role => role.users.length > 0)
            .map(role => `<div style='display:flex'><div>${role.users.join(', ')}</div></div>`)
            .join('');

        return formattedStaffRoles;
    }, [formData]);



    const headerText = useMemo(() => {
        if (!formData) return entityName;

        return `${entityName} - ${formData.Title}`;
    }, [entityName, formData]);

    const formattedTaskDetails = useMemo(() => {
        if (!formData) return null;

        return formData.Details ? formData.Details.replace(/\n/g, '<br>') : null;
    }, [formData]);


    return (
        <Panel
            className={styles.cr}
            isOpen={showForm}
            headerText={headerText}
            type={PanelType.medium}
            hasCloseButton={false}
            onRenderHeader={(props: IPanelProps, defaultRender: IPanelHeaderRenderer) =>
                <div style={{ marginBottom: '20px' }} className={styles.panelHeaderText}>{defaultRender(props)}</div>
            }
            onRenderNavigation={() =>
                <ViewFormCommandBar onClose={onClosed} />
            }
        >
            <div className={styles.cr}>
                <CrLoadingOverlay isLoading={loading} opaque={true} />
                {formData && (
                    <div>
                        <div className={styles.viewFormFieldLabel}>Stage</div>
                        <div className={styles.viewFormFieldData}>{formData.WorkflowStage?.Title}</div>

                        <div className={styles.viewFormFieldLabel}>Priority</div>
                        <div className={styles.viewFormFieldData}>{getTaskPriorityString(formData.TaskPriorityID)}</div>

                        <div className={styles.viewFormFieldLabel}>Task Details</div>
                        <div className={styles.viewFormFieldData}>
                            {formattedTaskDetails ? (
                                <div dangerouslySetInnerHTML={{ __html: formattedTaskDetails }} />
                            ) : (
                                'N/A'
                            )}
                        </div>

                        <div className={styles.viewFormFieldLabel}>Assigned To</div>
                        <div className={styles.viewFormFieldData}>
                            {TaskUsers ? (
                                <div dangerouslySetInnerHTML={{ __html: TaskUsers }} />
                            ) : (
                                'N/A'
                            )}
                        </div>

                        <div className={styles.viewFormFieldLabel}>Start-End Dates</div>
                        <div className={styles.viewFormFieldData}>
                            {renderDate(formData.StartDate)}&nbsp;&nbsp;&nbsp;&nbsp;{renderDate(formData.EndDate)}
                        </div>

                        {/* <div className={styles.viewFormFieldLabel}>Archived</div>
                        <div className={styles.viewFormFieldData}>{formData.Archived === true ? 'Yes' : 'No'}</div> */}

                    </div>
                )}
            </div>
        </Panel>
    );
};