import { EntityService } from './EntityService';
import { IReportingFrequency } from '../types';
import { AppGlobals } from '../AppGlobals';

export class ReportingFrequencyService extends EntityService<IReportingFrequency> {
    constructor() {
        super(`/ReportingFrequencies`);
    }

    public readAllForLookup(): Promise<IReportingFrequency[]> {
        return this.readAll(`?$select=ID,Title,EarlyUpdateWarningDays&$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`);
    }

    public readAllForList(): Promise<IReportingFrequency[]> {
        return super.readAll(`?$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`);
    }
}
