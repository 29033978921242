import { EntityWithStatusService } from './EntityWithStatusService';
import { ContextService } from './ContextService';
import { IDirectorate, IEntityChildren, IEntityDescription1 } from '../types';
import { EntityStatus } from '../refData/EntityStatus';
import axios from 'axios';
import { AppGlobals } from '../AppGlobals';

export class DirectorateService extends EntityWithStatusService<IDirectorate> {
    public readonly parentEntities = ['DirectorUser'];
    protected childrenEntities = ['Contributors($expand=ContributorUser)', 'Attributes($expand=AttributeType)'];

    constructor() {
        super(`/Directorates`);
    }

    public readMyDirectorates(): Promise<IDirectorate[]> {
        const username = encodeURIComponent(ContextService.Username());
        const filters = [
            `DirectorUser/Username eq '${username}'`,
            `ReportApproverUser/Username eq '${username}'`,
            `ReportingLeadUser/Username eq '${username}'`,
            `Group/DirectorGeneralUser/Username eq '${username}'`,
            `Contributors/any(c: c/ContributorUser/Username eq '${username}')`
        ];
        return this.readAll(`?$filter=${filters.join(' or ')}&$orderby=Title`);
    }

    public readDgDirectorates(): Promise<IDirectorate[]> {
        return this.readAll(`?$filter=Group/DirectorGeneralUser/Username eq '${encodeURIComponent(ContextService.Username())}'&$expand=OrgLevel1`);
    }

    public readAllForLookup(includeClosedDirectorates?: boolean): Promise<IDirectorate[]> {
        return this.readAll(
            `?$select=ID,Title,GroupID`
            + `&$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosedDirectorates ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public readAllForList(includeClosedDirectorates?: boolean): Promise<IDirectorate[]> {
        return this.readAll(
            `?$select=ID,Title,Objectives`
            + `&$orderby=Title`
            + `&$expand=OrgLevel1($select=Title),DirectorUser($select=Title),EntityStatus($select=Title),ReportApproverUser($select=Title),ReportingLeadUser($select=Title),Contributors($expand=ContributorUser($select=Title))`
            + `&$filter=CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosedDirectorates ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public async readForView(directorateID: number): Promise<IDirectorate> {
        return this.read(directorateID, false, false, ['OrgLevel1($select=Title)', 'DirectorUser($select=Title,EmailAddress)', 'ReportApproverUser($select=Title,EmailAddress)', 'ReportingLeadUser($select=Title,EmailAddress)', 'Contributors($expand=ContributorUser($select=Title,EmailAddress))', 'Attributes($expand=AttributeType($select=Title))']);
    }

    public readDirectorateApprovers = (directorateId: number): Promise<IDirectorate> => {
        return this.read(directorateId, false, false, ['DirectorUser', 'ReportApproverUser', 'ReportingLeadUser']);
    }

    public readDraftReportDirectorates = (): Promise<IDirectorate[]> => {
        return this.readAll(`?$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID} and EntityStatusID eq ${EntityStatus.Open}`);
    }

    public async entityChildren(id: number): Promise<IEntityChildren[]> {
        const directorateUrl = `${this.entityUrl}(${id})`;
        const commitments = (await this.axiosGet(`${directorateUrl}/Commitments?$select=ID&$top=10`)).data.value;
        const directorateUpdates = (await this.axiosGet(`${directorateUrl}/DirectorateUpdates?$select=ID&$top=10`)).data.value;
        const keyWorkAreas = (await this.axiosGet(`${directorateUrl}/KeyWorkAreas?$select=ID&$top=10`)).data.value;
        const metrics = (await this.axiosGet(`${directorateUrl}/Metrics?$select=ID&$top=10`)).data.value;
        const partnerOrgs = (await this.axiosGet(`${directorateUrl}/PartnerOrganisations?$select=ID&$top=10`)).data.value;
        const projects = (await this.axiosGet(`${directorateUrl}/Projects?$select=ID&$top=10`)).data.value;
        const risks = (await this.axiosGet(`${directorateUrl}/CorporateRisks?$select=ID&$top=10`)).data.value;
        const signOffs = (await this.axiosGet(`${directorateUrl}/SignOffs?$select=ID&$top=10`)).data.value;
        const userDirectorates = (await this.axiosGet(`${directorateUrl}/UserDirectorates?$select=ID&$top=10`)).data.value;

        return [
            { ChildType: 'Commitments', CanBeAdopted: true, ChildIDs: (await commitments).map((c: { ID: any; }) => c.ID) },
            { ChildType: 'Directorate updates', CanBeAdopted: false, ChildIDs: (await directorateUpdates).map((d: { ID: any; }) => d.ID) },
            { ChildType: 'Key work areas', CanBeAdopted: true, ChildIDs: (await keyWorkAreas).map((k: { ID: any; }) => k.ID) },
            { ChildType: 'Metrics', CanBeAdopted: true, ChildIDs: (await metrics).map((m: { ID: any; }) => m.ID) },
            { ChildType: 'Partner organisations', CanBeAdopted: true, ChildIDs: (await partnerOrgs).map((p: { ID: any; }) => p.ID) },
            { ChildType: 'Projects', CanBeAdopted: true, ChildIDs: (await projects).map((p: { ID: any; }) => p.ID) },
            { ChildType: 'Reports', CanBeAdopted: false, ChildIDs: (await signOffs).map((s: { ID: any; }) => s.ID) },
            { ChildType: 'User directorates', CanBeAdopted: true, ChildIDs: (await userDirectorates).map((u: { ID: any; }) => u.ID) },
            { ChildType: 'Risks', CanBeAdopted: true, ChildIDs: (await risks).map((r: { ID: any; }) => r.ID) }
        ];
    }

    public async readTitlesForDirectorateAndGroup(entityPlural: string, entitySingular: string, orgLevel2Singular: string, directorateID: number): Promise<IEntityDescription1> {
        const entityDescription1: IEntityDescription1 = {
            Description1ForList: '',
            Description1ForForm: ''
        };
        const res = await this.readAll(`?$filter=ID eq ${directorateID}&$expand=OrgLevel1($select=Title)&$select=Title`);
        if (res.length > 0) {
            entityDescription1.Description1ForList = `${entityPlural} for ${orgLevel2Singular.toLowerCase()} <span style='font-style: italic'>${res[0].Title}</span>, ${res[0].OrgLevel1.Title}`;
            entityDescription1.Description1ForForm = `${entitySingular} for ${orgLevel2Singular.toLowerCase()} <span style='font-style: italic'>${res[0].Title}</span>, ${res[0].OrgLevel1.Title}`;
        }

        return entityDescription1;
    }

    public async readTitlesForDirectorateUsers(directorateID: number, orgLevel2: string): Promise<IEntityDescription1> {
        const entityDescription1: IEntityDescription1 = {
            Description1ForList: '',
            Description1ForForm: ''
        };
        const res = await this.readAll(`?$filter=ID eq ${directorateID}&$select=Title`);
        if (res.length > 0) {
            entityDescription1.Description1ForList = `Users with full access to ${orgLevel2} ${res[0].Title}`;
            entityDescription1.Description1ForForm = `Users with full access to ${orgLevel2} ${res[0].Title}`;
        }

        return entityDescription1;
    }

    public async create(directorate: IDirectorate): Promise<IDirectorate> {
        directorate.CustomerID = AppGlobals.CustomerID;
        return super.create(directorate);
    }
}