import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from '../../styles/cr.module.scss';
import { IWithErrorHandlingProps, withErrorHandling } from '../withErrorHandling';
import { SignOffType } from '../../refData/SignOffType';
import { useParams } from 'react-router-dom';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { CrCommandBar } from '../cr/CrCommandBar';
import { DraftReportList } from '../draftReport/DraftReportList';

interface ProjectUpdatesProps extends IWithErrorHandlingProps {
    lastDirectorateIdForChilds?: number;
    apiConnected: boolean;
    emphasiseProjectsWithAttribute: string;
    emphasisedProjectsHeaderText: string;
    otherProjectsHeaderText: string;
}


const ProjectUpdates = (props: ProjectUpdatesProps) => {

    const { projectIdForUpdates } = useParams<{ projectIdForUpdates: string }>();
    const history = useHistory();

    const commandBarItems: ICommandBarItemProps[] = [
        {
            key: 'projects',
            text: 'Projects',
            iconProps: { iconName: 'MiniExpandMirrored' },
            onClick: (ev, item) => {
                console.log('lastDirectorateIdForChilds', props.lastDirectorateIdForChilds);
                if (props.lastDirectorateIdForChilds != null) {
                    history.push(`/performance-reporting/projects-for-directorate/${props.lastDirectorateIdForChilds}`);
                }
                else {
                    history.push('/performance-reporting/projects');
                }
            },
        },
        {
            key: 'signoff',
            text: 'Review & Sign Off',
            iconProps: { iconName: 'MiniExpand' },
            onClick: (ev, item) => {
                history.push(`/performance-reporting/sign-off-for-project/${projectIdForUpdates}`);
            },
        }
    ];

    return (

        <div className={`${styles.updatesContainer}`}>
            <CrCommandBar items={commandBarItems} />
            <div>
                <DraftReportList
                    defaultReportType={SignOffType.Project}
                    defaultEntityId={Number(projectIdForUpdates)}
                    {...props}
                />
            </div>
        </div>

    );
};

export default withErrorHandling(ProjectUpdates);