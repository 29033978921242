import { EntityService } from './EntityService';
import { IApp03WorkflowStage } from '../types';

export class App03WorkflowStageService extends EntityService<IApp03WorkflowStage> {
    constructor() {
        super(`/App03WorkflowStages`);
    }

    public readWorkflowStages(workflowID: number): Promise<IApp03WorkflowStage[]> {
        return this.readAll(
            `?$select=ID,Title`
            + `&$orderby=DisplayOrder`
            + `&$filter=App03WorkflowID eq ${workflowID}`
        );
    }
}