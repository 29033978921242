import { EntityService } from './EntityService';
import { ContextService } from './ContextService';
import { IUserDirectorate } from '../types';
import { AppGlobals } from '../AppGlobals';

export class UserDirectorateService extends EntityService<IUserDirectorate> {
    constructor() {
        super(`/UserDirectorates`);
    }

    public readAllForList(directorateID?: number): Promise<IUserDirectorate[]> {
        return this.readAll(
            `?$orderby=User/Title&$expand=User,Directorate&$filter=User/CustomerID eq ${AppGlobals.CustomerID}`
            + (directorateID ? ` and DirectorateID eq ${directorateID}` : '')
        );
    }

    public readMyDirectorates(): Promise<IUserDirectorate[]> {
        return this.readAll(`?$filter=User/Username eq '${encodeURIComponent(ContextService.Username())}'`);
    }

    public readDirectorateAdmins(directorateId: number): Promise<IUserDirectorate[]> {
        return this.readAll(`?$filter=DirectorateID eq ${directorateId} and IsAdmin eq true&$expand=User`);
    }

    public readAllForLookup(): Promise<IUserDirectorate[]> {
        return this.readAll(`?$select=ID,Title,DirectorateID,UserID&$orderby=Title`);
    }

    public checkForDuplicates = (userId: number, directorateId: number): Promise<IUserDirectorate[]> => {
        return this.readAll(`?$filter=UserID eq ${userId} and DirectorateID eq ${directorateId}&$select=ID`);
    }
}