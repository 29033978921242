import React, { useContext } from 'react';
import { ErrorBoundary } from '../ErrorBoundary';
import { IWithErrorHandlingProps, withErrorHandling } from '../withErrorHandling';
import { IUseApiProps } from '../useApi';
import CaPropertiesCls from './CaPropertiesCls';
import { OrbUserContext } from '../OrbUserContext';


export interface ICaPropertiesProps extends IUseApiProps, IWithErrorHandlingProps {
}

const CaProperties = (props: ICaPropertiesProps) => {
    const { userPermissions } = useContext(OrbUserContext);
    return (
        <ErrorBoundary>
            <CaPropertiesCls
                userPermissions={userPermissions}
                {...props}
            />
        </ErrorBoundary>
    );
};

export default withErrorHandling(CaProperties);
