import { IEntity } from '../types';
import { EntityService } from './EntityService';

export class AccountService extends EntityService<IEntity> {
    private zedxApiURL: string = process.env.REACT_APP_ZedX_Non_Odata_API!;

    constructor() {
        super(`/Accounts`);
    }

    public async emailExistOnCoreUsers(emailAddress: string): Promise<boolean> {
        const endPoint: string = `${this.zedxApiURL}/Accounts/EmailExists`;
        const requestBody = { EmailAddress: emailAddress };
        const emailExist = await super.readAnyEndPointValueWithPost(endPoint, requestBody);
        return Boolean(emailExist);
    }

    public async changePassword(userID: number, currentPassword: string, newPassword: string): Promise<boolean> {
        const endPoint: string = `${this.zedxApiURL}/Accounts/ChangePassword`;
        const requestBody = {
            UserID: userID,
            CurrentPassword: currentPassword,
            NewPassword: newPassword,
        };
        const result = await super.readAnyEndPointValueWithPost(endPoint, requestBody);
        return Boolean(result);
    }
}