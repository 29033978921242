import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IPanelHeaderRenderer, IPanelProps, Panel, PanelType } from '@fluentui/react/lib/Panel';
import { CrLoadingOverlay } from '../cr/CrLoadingOverlay';
import { ViewFormCommandBar } from '../cr/ViewFormCommandBar';
import { IApp03Workflow } from '../../types';
import styles from '../../styles/cr.module.scss';
import { getWorkflowTypeString } from '../../refData/WorkflowType';

interface WorkflowViewProps {
    entityName?: string;
    showForm?: boolean;
    entityId?: number;
    onClosed?: () => void;
    loadEntity: (entityId: number) => Promise<IApp03Workflow>;
}

export const WorkflowView: React.FC<WorkflowViewProps> = ({ showForm, entityName, entityId, loadEntity, onClosed }) => {
    const [formData, setFormData] = useState<IApp03Workflow>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const entity = await loadEntity(entityId);
                setFormData(entity);
                setLoading(false);
            } catch (error) {
                console.error('Error loading entity:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [loadEntity]);

    const WorkflowOwners = useMemo(() => {
        if (!formData) return '';

        const staffRoles = [
            {
                role: 'Owners',
                users: formData.App03WorkflowUsers && formData.App03WorkflowUsers.some(o => o.User != null)
                    ? formData.App03WorkflowUsers.filter(u => u.IsAdmin === true).map(o => `${o.User?.Title} (${o.User?.EmailAddress})`)
                    : [],
            },
            {
                role: 'Staff',
                users: formData.App03WorkflowUsers && formData.App03WorkflowUsers.some(o => o.User != null)
                    ? formData.App03WorkflowUsers.filter(u => u.IsAdmin !== true).map(o => `${o.User?.Title} (${o.User?.EmailAddress})`)
                    : [],
            },
        ];

        const formattedStaffRoles = staffRoles
            .filter(role => role.users.length > 0)
            .map(role => `<div style='display:flex'><div style='font-weight:bold'>-&nbsp;</div><div>${role.role}: ${role.users.join(', ')}</div></div>`)
            .join('');

        return formattedStaffRoles;
    }, [formData]);



    const headerText = useMemo(() => {
        if (!formData) return entityName;

        return `${entityName} - ${formData.Title}`;
    }, [entityName, formData]);

    const formattedDescription = useMemo(() => {
        if (!formData) return null;

        return formData.Description ? formData.Description.replace(/\n/g, '<br>') : null;
    }, [formData]);


    return (
        <Panel
            className={styles.cr}
            isOpen={showForm}
            headerText={headerText}
            type={PanelType.medium}
            hasCloseButton={false}
            onRenderHeader={(props: IPanelProps, defaultRender: IPanelHeaderRenderer) =>
                <div style={{ marginBottom: '20px' }} className={styles.panelHeaderText}>{defaultRender(props)}</div>
            }
            onRenderNavigation={() =>
                <ViewFormCommandBar onClose={onClosed} />
            }
        >
            <div className={styles.cr}>
                <CrLoadingOverlay isLoading={loading} opaque={true} />
                {formData && (
                    <div>
                        <div className={styles.viewFormFieldLabel}>Type</div>
                        <div className={styles.viewFormFieldData}>{getWorkflowTypeString(formData.WorkflowTypeID)}</div>

                        <div className={styles.viewFormFieldLabel}>Description</div>
                        <div className={styles.viewFormFieldData}>
                            {formattedDescription ? (
                                <div dangerouslySetInnerHTML={{ __html: formattedDescription }} />
                            ) : (
                                'N/A'
                            )}
                        </div>

                        <div className={styles.viewFormFieldLabel}>All Users</div>
                        <div className={styles.viewFormFieldData}>
                            {WorkflowOwners ? (
                                <div dangerouslySetInnerHTML={{ __html: WorkflowOwners }} />
                            ) : (
                                'N/A'
                            )}
                        </div>

                        <div className={styles.viewFormFieldLabel}>New task default stage</div>
                        <div className={styles.viewFormFieldData}>{formData.App03WorkflowStages.filter(x => x.ID === formData.NewTaskDefaultStageID)?.[0]?.Title || 'N/A'}</div>

                    </div>
                )}
            </div>
        </Panel>
    );
};