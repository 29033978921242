import React from 'react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { CrCommandBar } from './CrCommandBar';

export interface IViewFormCommandBarProps {
    onClose?: () => void;
}

export const ViewFormCommandBar = ({ onClose }: IViewFormCommandBarProps): React.ReactElement => {

    const commandBarItems: ICommandBarItemProps[] = [
        { key: 'close', text: 'Close', iconProps: { iconName: 'Cancel' }, onClick: onClose },
    ];
    return <CrCommandBar items={commandBarItems} />;
};
