import React, { useContext } from 'react';
import { ErrorBoundary } from '../ErrorBoundary';
import { IWithErrorHandlingProps, withErrorHandling } from '../withErrorHandling';
import { IUseApiProps } from '../useApi';
import CaReportCls from './CaReportCls';
import { OrbUserContext } from '../OrbUserContext';

export interface ICaReportProps extends IUseApiProps, IWithErrorHandlingProps {
}

const CaReport = (props: ICaReportProps) => {
    const { userPermissions } = useContext(OrbUserContext);
    return (
        <ErrorBoundary>
            <CaReportCls
                userPermissions={userPermissions}
                {...props} />
        </ErrorBoundary>
    );
};

export default withErrorHandling(CaReport);
