import React, { useEffect, useMemo, useState } from 'react';
import { IPanelHeaderRenderer, IPanelProps, Panel, PanelType } from '@fluentui/react/lib/Panel';
import { CrLoadingOverlay } from '../cr/CrLoadingOverlay';
import { ViewFormCommandBar } from '../cr/ViewFormCommandBar';
import { ICorporateRiskMitigationAction } from '../../types';
import { renderDate } from '../cr/ListRenderers';
import styles from '../../styles/cr.module.scss';

interface ProjectRiskMitigationActionViewProps {
    entityName?: string;
    showForm?: boolean;
    entityId?: number;
    onClosed?: () => void;
    loadEntity: (entityId: number) => Promise<ICorporateRiskMitigationAction>;
}

export const ProjectRiskMitigationActionView: React.FC<ProjectRiskMitigationActionViewProps> = ({ showForm, entityName, entityId, loadEntity, onClosed }) => {
    const [formData, setFormData] = useState<ICorporateRiskMitigationAction>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const entity = await loadEntity(entityId);
                console.log(entity);
                setFormData(entity);
                setLoading(false);
            } catch (error) {
                console.error('Error loading entity:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [loadEntity]);

    const actionStaff = useMemo(() => {
        if (!formData) return '';

        const staffRoles = [
            { role: 'Mitigating action owner', users: formData.OwnerUser ? [`${formData.OwnerUser.Title} (${formData.OwnerUser.EmailAddress})`] : [] },
            {
                role: 'Contributors',
                users: formData.Contributors && formData.Contributors.some(c => c.ContributorUser != null)
                    ? formData.Contributors.map(c => `${c.ContributorUser?.Title} (${c.ContributorUser?.EmailAddress})`)
                    : [],
            },
        ];

        const formattedStaffRoles = staffRoles
            .filter(role => role.users.length > 0)
            .map(role => `<div style='display:flex'><div style='font-weight:bold'>-&nbsp;</div><div>${role.role}: ${role.users.join(', ')}</div></div>`)
            .join('');

        return formattedStaffRoles;
    }, [formData]);



    const headerText = useMemo(() => {
        if (!formData) return entityName;

        return `${entityName} - ${formData.Title}`;
    }, [entityName, formData]);

    const formattedDescription = useMemo(() => {
        if (!formData) return null;

        return formData.Description ? formData.Description.replace(/\n/g, '<br>') : null;
    }, [formData]);

    return (
        <Panel
            className={styles.cr}
            isOpen={showForm}
            headerText={headerText}
            type={PanelType.medium}
            hasCloseButton={false}
            onRenderHeader={(props: IPanelProps, defaultRender: IPanelHeaderRenderer) =>
                <div style={{ marginBottom: '20px' }} className={styles.panelHeaderText}>{defaultRender(props)}</div>
            }
            onRenderNavigation={() =>
                <ViewFormCommandBar onClose={onClosed} />
            }
        >
            <div className={styles.cr}>
                <CrLoadingOverlay isLoading={loading} opaque={true} />
                {formData && (
                    <div>
                        <div className={styles.viewFormFieldLabel}>Risk</div>
                        <div className={styles.viewFormFieldData}>{formData.Risk.Title}</div>

                        <div className={styles.viewFormFieldLabel}>Dependent risk</div>
                        <div className={styles.viewFormFieldData}>
                            {formData.CorporateRiskRiskMitigationActions && formData.CorporateRiskRiskMitigationActions.length > 0 ? (
                                formData.CorporateRiskRiskMitigationActions.map(rrma => rrma.Risk?.Title).join(', ')
                            ) : (
                                'N/A'
                            )}
                        </div>

                        <div className={styles.viewFormFieldLabel}>Staff</div>
                        <div className={styles.viewFormFieldData}>
                            {actionStaff ? (
                                <div dangerouslySetInnerHTML={{ __html: actionStaff }} />
                            ) : (
                                'N/A'
                            )}
                        </div>

                        <div className={styles.viewFormFieldLabel}>Is the action ongoing</div>
                        <div className={styles.viewFormFieldData}>{formData.ActionIsOngoing === true ? 'Yes' : 'No'}</div>

                        <div className={styles.viewFormFieldLabel}>Baseline delivery date</div>
                        <div className={styles.viewFormFieldData}>
                            {renderDate(formData.BaselineDate)}
                        </div>

                        <div className={styles.viewFormFieldLabel}>Description</div>
                        <div className={styles.viewFormFieldData}>
                            {formattedDescription ? (
                                <div dangerouslySetInnerHTML={{ __html: formattedDescription }} />
                            ) : (
                                'N/A'
                            )}
                        </div>

                    </div>
                )}
            </div>
        </Panel>
    );
};