import React from 'react';

interface MenuToggleProps {
    onToggleNavigation?: () => void;
}

export const MenuToggle: React.FC<MenuToggleProps> = ({ onToggleNavigation }) => {
    return (
        <div className="menu-toggle" onClick={onToggleNavigation}>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};
