import { EntityWithStatusService } from './EntityWithStatusService';
import { ContextService } from './ContextService';
import { IBenefit, IEntityChildren } from '../types';
import { EntityStatus } from '../refData/EntityStatus';
import { Period } from '../refData/Period';
import { AppGlobals } from '../AppGlobals';

export class BenefitService extends EntityWithStatusService<IBenefit> {
    public readonly parentEntities = ['Project', 'LeadUser', 'MeasurementUnit'];
    protected childrenEntities = ['Contributors($expand=ContributorUser)', 'Attributes($expand=AttributeType)'];

    constructor() {
        super(`/Benefits`);
    }

    public readMyBenefits(): Promise<IBenefit[]> {
        const username = encodeURIComponent(ContextService.Username());
        const filters = [
            `LeadUser/Username eq '${username}'`,
            `Contributors/any(c: c/ContributorUser/Username eq '${username}')`
        ];
        return this.readAllQuery(`$filter=EntityStatusID eq ${EntityStatus.Open} and (${filters.join(' or ')})`);
    }

    public readAllForList(includeClosedBenefits?: boolean, projectID?: number): Promise<IBenefit[]> {
        return this.readAll(
            `?$select=ID,Title,TargetPerformanceLowerLimit,TargetPerformanceUpperLimit`
            + `&$orderby=Title`
            + `&$expand=EntityStatus($select=Title),Project($select=Title),LeadUser($select=Title),Contributors($select=ContributorUser;$expand=ContributorUser($select=Title))&$filter=Project/CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosedBenefits ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
            + (projectID ? ` and ProjectID eq ${projectID}` : '')
        );
    }

    public async readForView(benefitID: number): Promise<IBenefit> {
        return this.read(benefitID, false, false, ['Project($select=Title)', 'LeadUser($select=Title,EmailAddress)', 'Contributors($select=ContributorUser;$expand=ContributorUser($select=Title,EmailAddress))', 'Attributes($expand=AttributeType($select=Title))', 'BenefitType($select=Title)', 'MeasurementUnit($select=Title)']);
    }

    public readAllForLookup(includeClosedEntities?: boolean, additionalFields?: string): Promise<IBenefit[]> {
        return this.readAll(
            `?$select=ID,Title${additionalFields ? `,${additionalFields}` : ''}`
            + `&$orderby=Title&$filter=Project/CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosedEntities ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public readProjectBenefitsInPeriod(projectId: number, period: Period): Promise<IBenefit[]> {
        if (projectId) {
            return this.readAll(
                `/GetBenefitsDueInPeriod(ProjectId=${projectId},Period=${period})`
                + `?$expand=LeadUser,MeasurementUnit,Attributes($expand=AttributeType),Contributors,BenefitType`
            );
        }
        return Promise.resolve([]);
    }

    public async entityChildren(id: number): Promise<IEntityChildren[]> {
        return this.entityChildrenSingle(id, 'Benefit updates', 'BenefitUpdates', false);
    }

    public async countBenefitsForProject(projectID: number): Promise<number> {
        if (projectID) {
            return (await this.readAll(`?$select=ID&$filter=EntityStatusID eq ${EntityStatus.Open} and ProjectID eq ${projectID}`))?.length;
        }
        return Promise.resolve(0);
    }
}