import { EntityService } from './EntityService';
import { IEntity } from '../types';

export class CAFormService extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/CAForms`);
    }

    public readFormUpdateStatus(periodId: number, formId: number): Promise<string> {
        return super.readString(`?getFormUpdateStatus=true&periodId=${periodId}&formId=${formId}`).then((result: string): string => {
            return result;
        });
    }
}