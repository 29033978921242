import { useCallback, useEffect, useState } from 'react';
import { IDataServices } from '../types/IDataServices';
import { IErrorHandling } from './withErrorHandling';

export interface IUseApiProps {
    apiConnected: boolean;
}

export const useApi = (dataServices: IDataServices, /*{ onError, onFirstAPIRequestError }: IErrorHandling*/): boolean => {

    const [apiConnected, setApiConnected] = useState(false);
    //const logError = useCallback(onError, [onError]);
    // const logError = useCallback(onError ?? ((errorMessage: string, errorDetail?: string) => {
    //     console.error(`Default Error Callback: ${errorMessage}`);
    // }), [onError]);
    
    //const logErrorOnConnect = useCallback(onFirstAPIRequestError, [onFirstAPIRequestError]);
    // const logErrorOnConnect = useCallback(onFirstAPIRequestError ?? ((errorType: string) => {
    //     console.error(`Default First API Request Error Callback: ${errorType}`);
    // }), [onFirstAPIRequestError]);

    useEffect(() => {
        console.log('in use effect - useApi');
        const firstRequestToAPI = async (): Promise<boolean> => {
            try {
                console.log('firstRequestToAPI before calling service');
                if(dataServices.healthCheckService){
                    console.log('healthCheckService is ok');
                }
                else{
                    console.log('healthCheckService is null');
                }

                const res: string = await dataServices.healthCheckService?.firstRequestToAPI() ?? "";
                console.log('firstRequestToAPI after calling service: ', res);

                if (res === 'ok') {
                    return true;
                }
                else {
                    //logErrorOnConnect?.(res, dataServices.tokenRefreshService);
                    return false;
                }
            } catch (err) {
                //logError?.(`Error connecting to API`, err.message);
                return false;
            }
        };

        const initialiseApiConnection = async () => {
            if (!apiConnected) {
                setApiConnected(await firstRequestToAPI());
            }
        };

        initialiseApiConnection();
    }, [apiConnected, dataServices.healthCheckService, /*dataServices.tokenRefreshService,*/ /*logErrorOnConnect*//*, logError*/]);

    return apiConnected;
};
