import { EntityService } from './EntityService';
import { IIAPActionDirectorate } from '../types';

export class IAPActionDirectorateService extends EntityService<IIAPActionDirectorate> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/IAPActionDirectorates`);
    }
}