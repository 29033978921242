import * as React from 'react';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import Section from './Section';
import NewCaseTab from './NewCaseTab';
import * as types from '../../types';
import * as services from '../../services';
import { CLCase, ICLCase, IClCaseCounts, ICLDefForm, ICLWorker, IUser, ICLHiringMember, IEntity } from '../../types';
import { CrLoadingOverlayWelcomeCat } from '../cr/CrLoadingOverlayWelcomeCat';
import { MessageDialog } from '../cr/MessageDialog';
import { OrbUserContext } from '../OrbUserContext';

export interface IClUpdatesClsProps extends types.IBaseComponentProps {

}
export interface ILookupData {
    CLGenders: IEntity[];
    CLStaffGrades: IEntity[];
    Directorates: IEntity[];
    CLProfessionalCats: IEntity[];
    CLWorkLocations: IEntity[];
    CLComFrameworks: IEntity[];

    CLIR35Scopes: IEntity[];
    CLSecurityClearances: IEntity[];
    CLDeclarationConflicts: IEntity[];
    PersonTitles: IEntity[];
}

export class LookupData implements ILookupData {
    public CLGenders: IEntity[] = [];
    public CLStaffGrades: IEntity[] = [];
    public Directorates: IEntity[] = [];
    public CLProfessionalCats: IEntity[] = [];
    public CLWorkLocations: IEntity[] = [];
    public CLComFrameworks: IEntity[] = [];
    public CLIR35Scopes: IEntity[] = [];
    public CLSecurityClearances: IEntity[] = [];
    public CLDeclarationConflicts: IEntity[] = [];
    public PersonTitles: IEntity[] = [];
}

export interface IClUpdatesState {
    Loading: boolean;
    LoadingCaseTabCoreData: boolean;
    LookupData: ILookupData;
    SelectedPivotKey: string;
    Section1_IsOpen: boolean;
    Section1_MainList_ListFilterText: string;
    Section2_IsOpen: boolean;
    Section2_MainList_ListFilterText: string;
    Section3_IsOpen: boolean;
    Section3_MainList_ListFilterText: string;
    Section_MainList_SelectedId: number;
    Section_MainList_SelectedCaseId: number;
    Section_MainList_SelectedStage: string;
    HistoricCase_WorkerId: number;
    HistoricCase_CaseId: number;
    HistoricCase_Stage: string;
    TotalBusinessCases: number;
    TotalEngagedCases: number;
    TotalArchivedCases: number;
    DefForm: ICLDefForm;
    Users: IUser[];
    SuperUsersAndViewers: IUser[];
    HideCantCreateExtensionMessage: boolean;
    ArchivedListRefreshCounter: number;
    FullControlFolderRoleId: number;
    CurrentUserPrincipalId: number;
}
export class ClUpdatesState implements IClUpdatesState {
    public Loading = false;
    public LoadingCaseTabCoreData = true;
    public LookupData = new LookupData();
    public SelectedPivotKey = "Manage Cases"; //default, 1st tab selected
    public Section1_IsOpen: boolean = false;
    public Section1_MainList_ListFilterText: string = null;
    public Section2_IsOpen: boolean = false;
    public Section2_MainList_ListFilterText: string = null;
    public Section3_IsOpen: boolean = false;
    public Section3_MainList_ListFilterText: string = null;
    public Section_MainList_SelectedId = null;
    public Section_MainList_SelectedCaseId = null;
    public Section_MainList_SelectedStage = null;
    public HistoricCase_WorkerId: number = null;
    public HistoricCase_CaseId: number = null;
    public HistoricCase_Stage: string = null;
    public TotalBusinessCases = null;
    public TotalEngagedCases = null;
    public TotalArchivedCases = null;
    public DefForm: ICLDefForm = null;
    public Users: IUser[] = [];
    public SuperUsersAndViewers: IUser[] = [];
    public HideCantCreateExtensionMessage = true;
    public ArchivedListRefreshCounter: number = 0;
    public FullControlFolderRoleId: number = 0;
    public CurrentUserPrincipalId: number = 0;

    constructor() {
    }
}

export default class ClUpdates extends React.Component<IClUpdatesClsProps, ClUpdatesState> {
    private clCaseService: services.CLCaseService = new services.CLCaseService();
    private clDefFormService: services.CLDefFormService = new services.CLDefFormService();
    private clGenderService: services.CLGenderService = new services.CLGenderService();
    private userService: services.UserService = new services.UserService();
    private clStaffGradeService: services.CLStaffGradeService = new services.CLStaffGradeService();
    private directorateService: services.DirectorateService = new services.DirectorateService();
    private clProfessionalCatService: services.CLProfessionalCatService = new services.CLProfessionalCatService();
    private clWorkLocationService: services.CLWorkLocationService = new services.CLWorkLocationService();
    private clComFrameworkService: services.CLComFrameworkService = new services.CLComFrameworkService();
    private clSecurityClearanceService: services.CLSecurityClearanceService = new services.CLSecurityClearanceService();
    private clDeclarationConflictService: services.CLDeclarationConflictService = new services.CLDeclarationConflictService();
    private personTitleService: services.PersonTitleService = new services.PersonTitleService();
    private clIR35ScopeService: services.CLIR35ScopeService = new services.CLIR35ScopeService();

    private readonly headerTxt_MainTab: string = "Manage Cases";
    private readonly headerTxt_NewCaseTab: string = "Case";
    private readonly headerTxt_HistoricCaseTab: string = "Extension Of";
    private newCaseSaveInProgress: boolean = false;

    static contextType = OrbUserContext;
    context!: React.ContextType<typeof OrbUserContext>;

    constructor(props: IClUpdatesClsProps) {
        super(props);
        this.state = new ClUpdatesState();
    }

    public render(): React.ReactElement<IClUpdatesClsProps> {
        return (
            <React.Fragment>
                <Pivot onLinkClick={this.handlePivotClick} selectedKey={`${this.state.SelectedPivotKey}`}>
                    <PivotItem headerText={this.headerTxt_MainTab} itemKey={this.headerTxt_MainTab}>
                        {this.renderMainTab()}
                    </PivotItem>
                    {this.renderNewCaseTab()}
                    {this.renderHistoricCaseTab()}
                </Pivot>
                <MessageDialog hidden={this.state.HideCantCreateExtensionMessage} title={null} /*title="Validation Successful"*/ content="Cannot create extension. This case already has an extension in the system." handleOk={() => { this.setState({ HideCantCreateExtensionMessage: true },); }} />
            </React.Fragment>

        );
    }

    private renderMainTab(): React.ReactElement<types.IWebPartComponentProps> {
        return (
            <div>
                <CrLoadingOverlayWelcomeCat isLoading={this.state.Loading} />
                <div style={{ paddingTop: "10px" }}>
                    {
                        <div>
                            <Section
                                sectionTitle="Business Cases"
                                caseType="BusinessCases"
                                sectionTotalCases={this.state.TotalBusinessCases}
                                onItemTitleClick={this.handleSection_MainListItemTitleClick}
                                section_IsOpen={this.state.Section1_IsOpen}
                                onSection_toggleOpen={this.handleSection1_toggleOpen}
                                listFilterText={this.state.Section1_MainList_ListFilterText}
                                onChangeFilterText={this.handleSection1_ChangeFilterText}
                                currentUserId={this.getCurrentUserId()}
                                superUserPermission={this.isSuperUser()}
                                onAfterArchived={this.handleAfterArchived}
                                {...this.props}
                            />
                            <Section
                                sectionTitle="Engaged"
                                caseType="Engaged"
                                sectionTotalCases={this.state.TotalEngagedCases}
                                onItemTitleClick={this.handleSection_MainListItemTitleClick}
                                section_IsOpen={this.state.Section2_IsOpen}
                                onSection_toggleOpen={this.handleSection2_toggleOpen}
                                listFilterText={this.state.Section2_MainList_ListFilterText}
                                onChangeFilterText={this.handleSection2_ChangeFilterText}
                                onMoveToLeaving={this.handleMoveToLeavingClick}
                                onCreateExtension={this.handleCreateExtensionClick}
                                currentUserId={this.getCurrentUserId()}
                                superUserPermission={this.isSuperUser()}
                                onAfterArchived={this.handleAfterArchived}
                                {...this.props}
                            />
                            <Section
                                sectionTitle="Archived"
                                caseType="Archived"
                                sectionTotalCases={this.state.TotalArchivedCases}
                                onItemTitleClick={this.handleSection_MainListItemTitleClick}
                                section_IsOpen={this.state.Section3_IsOpen}
                                onSection_toggleOpen={this.handleSection3_toggleOpen}
                                listFilterText={this.state.Section3_MainList_ListFilterText}
                                onChangeFilterText={this.handleSection3_ChangeFilterText}
                                currentUserId={this.getCurrentUserId()}
                                superUserPermission={this.isSuperUser()}
                                listRefreshCounter={this.state.ArchivedListRefreshCounter}
                                {...this.props}
                            />
                        </div>
                    }
                </div>
            </div>
        );
    }

    private renderNewCaseTab() {
        if (this.state.SelectedPivotKey === this.headerTxt_NewCaseTab || this.state.SelectedPivotKey === this.headerTxt_HistoricCaseTab) {
            return (
                <PivotItem headerText={this.headerTxt_NewCaseTab} itemKey={this.headerTxt_NewCaseTab}>
                    {this.renderNewCase()}
                </PivotItem>
            );
        }
        else
            return <React.Fragment></React.Fragment>;
    }

    private renderHistoricCaseTab() {
        if (this.state.SelectedPivotKey === this.headerTxt_HistoricCaseTab) {
            return (
                <PivotItem headerText={this.headerTxt_HistoricCaseTab} itemKey={this.headerTxt_HistoricCaseTab}>
                    {this.renderHistoricCase()}
                </PivotItem>
            );
        }
        else
            return <React.Fragment></React.Fragment>;
    }

    private renderNewCase(): React.ReactElement<types.IWebPartComponentProps> {
        return (
            <NewCaseTab
                onShowList={this.handleShowMainTab}
                clWorkerId={this.state.Section_MainList_SelectedId}
                clCaseId={this.state.Section_MainList_SelectedCaseId}
                stage={this.state.Section_MainList_SelectedStage}
                currentUserName={this.getCurrentUserName()}
                currentUserId={this.getCurrentUserId()}
                superUserPermission={this.isSuperUser()}
                viewerPermission={this.isViewerPermission()}
                defForm={this.state.DefForm}
                onShowHistoricCase={this.handleShowHistoricCaseClick}
                users={this.state.Users}
                clGenders={this.state.LookupData.CLGenders}
                clStaffGrades={this.state.LookupData.CLStaffGrades}
                directorates={this.state.LookupData.Directorates}
                clProfessionalCats={this.state.LookupData.CLProfessionalCats}
                clWorkLocations={this.state.LookupData.CLWorkLocations}
                clComFrameworks={this.state.LookupData.CLComFrameworks}
                clIR35Scopes={this.state.LookupData.CLIR35Scopes}
                clSecurityClearances={this.state.LookupData.CLSecurityClearances}
                clDeclarationConflicts={this.state.LookupData.CLDeclarationConflicts}
                personTitles={this.state.LookupData.PersonTitles}
                loadingCaseTabCoreData={this.state.LoadingCaseTabCoreData}
                superUsersAndViewers={this.state.SuperUsersAndViewers}
                fullControlFolderRoleId={this.state.FullControlFolderRoleId}
                currentUserPrincipalId={this.state.CurrentUserPrincipalId}
                {...this.props}
            />
        );
    }

    private renderHistoricCase(): React.ReactElement<types.IWebPartComponentProps> {
        return (
            <NewCaseTab
                onShowList={this.handleShowMainTab}
                clWorkerId={this.state.HistoricCase_WorkerId}
                clCaseId={this.state.HistoricCase_CaseId}
                stage={this.state.HistoricCase_Stage}
                currentUserName={this.getCurrentUserName()}
                currentUserId={this.getCurrentUserId()}
                superUserPermission={false}
                viewerPermission={true}
                historicCase={true}
                onShowCaseTab={this.handleShowCaseTab}
                defForm={this.state.DefForm}
                users={this.state.Users}
                clGenders={this.state.LookupData.CLGenders}
                clStaffGrades={this.state.LookupData.CLStaffGrades}
                directorates={this.state.LookupData.Directorates}
                clProfessionalCats={this.state.LookupData.CLProfessionalCats}
                clWorkLocations={this.state.LookupData.CLWorkLocations}
                clComFrameworks={this.state.LookupData.CLComFrameworks}
                clIR35Scopes={this.state.LookupData.CLIR35Scopes}
                clSecurityClearances={this.state.LookupData.CLSecurityClearances}
                clDeclarationConflicts={this.state.LookupData.CLDeclarationConflicts}
                personTitles={this.state.LookupData.PersonTitles}
                loadingCaseTabCoreData={this.state.LoadingCaseTabCoreData}
                superUsersAndViewers={this.state.SuperUsersAndViewers}
                fullControlFolderRoleId={this.state.FullControlFolderRoleId}
                currentUserPrincipalId={this.state.CurrentUserPrincipalId}
                {...this.props}
            />
        );
    }

    private loadLookups(): Promise<any> {
        return Promise.all([
            this.loadCaseCounts(),
            this.loadDefForm(),
            this.loadUsers(),
            this.loadAllSuperUsersAndViewers(),
        ]).then(() => {
            this.setState({ Loading: false },
                () => {
                    console.log('Loading done');
                }
            )
        });
    }

    private loadLookups2(): Promise<any> {
        return Promise.all([
            this.loadCLGenders(),
            this.loadCLStaffGrades(),
            this.loadDirectorates(),
            this.loadCLProfessionalCats(),
            this.loadCLWorkLocations(),
            this.loadCLComFrameworks(),
            this.loadCLIR35Scopes(),
            this.loadCLSecurityClearances(),
            this.loadPersonTitles(),
            this.loadCLDeclarationConflicts(),
        ]).then(() => {
            this.setState({ LoadingCaseTabCoreData: false },
                () => {
                    console.log('LoadingCaseTabCoreData done');
                }
            )
        });
    }

    public componentDidMount(): void {
        this.setState({ Loading: true });
        this.loadLookups();
        this.loadLookups2();
    }

    private loadCaseCounts = (): void => {
        this.clCaseService.getCaseCounts().then((x: IClCaseCounts) => {
            this.setState({
                TotalBusinessCases: x.TotalBusinessCases,
                TotalEngagedCases: x.TotalEngagedCases,
                TotalArchivedCases: x.TotalArchivedCases,
            });
        }, (err) => {
            if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading Case Counts`, err.message);
        });
    }

    private loadDefForm = (): Promise<void> => {
        let x = this.clDefFormService.readDefForm().then((dfArr: ICLDefForm[]): void => {
            if (dfArr.length > 0) {
                this.setState({ DefForm: dfArr[0] });
            }
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading df`, err.message); });
        return x;
    }

    private loadUsers = (): Promise<void> => {
        let x = this.userService.readAll().then((data: IUser[]): void => {
            this.setState({ Users: data });
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading Users`, err.message); });
        return x;
    }

    private loadCLGenders = (): Promise<void> => {
        return this.clGenderService.readAll().then((data: IEntity[]): void => {
            this.setState({ LookupData: this.cloneObject(this.state.LookupData, "CLGenders", data) });
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading CLGenders lookup data`, err.message); });
    }

    private loadCLStaffGrades = (): Promise<void> => {
        return this.clStaffGradeService.readAll().then((data: IEntity[]): void => {
            this.setState({ LookupData: this.cloneObject(this.state.LookupData, "CLStaffGrades", data) });
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading CLStaffGrades lookup data`, err.message); });

    }

    private loadDirectorates = (): Promise<void> => {
        return this.directorateService.readAll().then((data: IEntity[]): void => {
            this.setState({ LookupData: this.cloneObject(this.state.LookupData, "Directorates", data) });
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading Directorates lookup data`, err.message); });
    }

    private loadCLProfessionalCats = (): Promise<void> => {
        return this.clProfessionalCatService.readAll().then((data: IEntity[]): void => {
            this.setState({ LookupData: this.cloneObject(this.state.LookupData, "CLProfessionalCats", data) });
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading CLProfessionalCats lookup data`, err.message); });
    }

    private loadCLWorkLocations = (): Promise<void> => {
        return this.clWorkLocationService.readAll().then((data: IEntity[]): void => {
            this.setState({ LookupData: this.cloneObject(this.state.LookupData, "CLWorkLocations", data) });
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading CLWorkLocations lookup data`, err.message); });
    }

    private loadCLComFrameworks = (): Promise<void> => {
        return this.clComFrameworkService.readAll().then((data: IEntity[]): void => {
            this.setState({ LookupData: this.cloneObject(this.state.LookupData, "CLComFrameworks", data) });
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading CLWorkLocations lookup data`, err.message); });
    }

    private loadCLIR35Scopes = (): Promise<void> => {
        return this.clIR35ScopeService.readAll().then((data: IEntity[]): void => {
            this.setState({ LookupData: this.cloneObject(this.state.LookupData, "CLIR35Scopes", data) });
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading CLIR35Scopes lookup data`, err.message); });
    }

    private loadCLSecurityClearances = (): Promise<void> => {
        return this.clSecurityClearanceService.readAll().then((data: IEntity[]): void => {
            this.setState({ LookupData: this.cloneObject(this.state.LookupData, "CLSecurityClearances", data) });
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading CLSecurityClearances lookup data`, err.message); });
    }

    private loadCLDeclarationConflicts = (): Promise<void> => {
        return this.clDeclarationConflictService.readAll().then((data: IEntity[]): void => {
            this.setState({ LookupData: this.cloneObject(this.state.LookupData, "CLDeclarationConflicts", data) });
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading CLDeclarationConflicts lookup data`, err.message); });
    }

    private loadPersonTitles = (): Promise<void> => {
        return this.personTitleService.readAll().then((data: IEntity[]): void => {
            this.setState({ LookupData: this.cloneObject(this.state.LookupData, "PersonTitles", data) });
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading PersonTitles lookup data`, err.message); });
    }

    private loadAllSuperUsersAndViewers = (): Promise<void> => {
        //TODO
        //let x = this.userService.readAll_CL_SuperUsers_Viewers().then((data: IUser[]): void => {
        let x = this.userService.readAll().then((data: IUser[]): void => {
            this.setState({ SuperUsersAndViewers: data });
            //console.log('SuperUsersAndViewers', data);
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading super users`, err.message); });
        return x;
    }

    private getCurrentUserId = (): number => {
        const userContext = this.context;
        //console.log('userContext in private method', userContext);
        let userId: number = userContext.userContext.UserId;
        return userId;
    }

    private getCurrentUserName = (): string => {
        return this.context.userContext.UserTitle;
    }

    private isSuperUser(): boolean {
        return this.props.userPermissions.UserIsSystemAdmin()
            || this.props.userPermissions.UserIsCLSuperUser();
    }

    private isViewerPermission(): boolean {
        return this.props.userPermissions.UserIsCLViewer();
    }

    private handleShowMainTab = (refreshCounters?: boolean): void => {

        //TODO check following line to clearErrors
        //this.clearErrors();
        if (refreshCounters === true) {
            this.loadCaseCounts();
        }
        this.setState({ SelectedPivotKey: this.headerTxt_MainTab });
    }

    private handlePivotClick = (item: PivotItem): void => {
        //this.clearErrors();
        this.setState({ SelectedPivotKey: item.props.headerText });
    }

    private handleSection1_toggleOpen = (): void => {
        this.setState({ Section1_IsOpen: !this.state.Section1_IsOpen });
    }

    private handleSection2_toggleOpen = (): void => {
        this.setState({ Section2_IsOpen: !this.state.Section2_IsOpen });
    }

    private handleSection3_toggleOpen = (): void => {
        this.setState({ Section3_IsOpen: !this.state.Section3_IsOpen });
    }

    private handleSection1_ChangeFilterText = (value: string): void => {
        this.setState({ Section1_MainList_ListFilterText: value });
    }

    private handleSection2_ChangeFilterText = (value: string): void => {
        this.setState({ Section2_MainList_ListFilterText: value });
    }

    private handleSection3_ChangeFilterText = (value: string): void => {
        this.setState({ Section3_MainList_ListFilterText: value });
    }

    private handleSection_MainListItemTitleClick = (ID: number, title: string, filteredItems: any[]): void => {
        //ID from parameter is workerID
        if (ID === 0) {

            if (this.newCaseSaveInProgress === false) {
                this.newCaseSaveInProgress = true;

                let caseId: number = 0;
                const newCase = new CLCase("New Case");
                this.clCaseService.create(newCase).then((x: ICLCase): void => {
                    console.log('case created', x);
                    caseId = x.ID;
                    this.newCaseSaveInProgress = false;

                    this.setState({
                        SelectedPivotKey: this.headerTxt_NewCaseTab,
                        Section_MainList_SelectedId: 0, //worker id
                        Section_MainList_SelectedCaseId: caseId,
                        Section_MainList_SelectedStage: 'Draft',
                    });
                }, (err) => { this.newCaseSaveInProgress = false; });
            }
        }
        else {
            const caseF = (filteredItems.filter(x => x['ID'] === ID))[0];
            const caseId: number = Number(caseF['CaseId']);
            const stage: string = caseF['Stage'];
            this.setState({
                SelectedPivotKey: this.headerTxt_NewCaseTab,
                Section_MainList_SelectedId: ID,
                Section_MainList_SelectedCaseId: caseId,
                Section_MainList_SelectedStage: stage,
            });
        }
    }

    private handleMoveToLeavingClick = (ID: number, caseId: number): void => {
        this.setState({
            SelectedPivotKey: this.headerTxt_NewCaseTab,
            Section_MainList_SelectedId: ID,
            Section_MainList_SelectedCaseId: caseId,
            Section_MainList_SelectedStage: 'Leaving',
        });
    }

    private handleCreateExtensionClick = (ID: number, caseId: number): void => {
        this.clCaseService.createExtension(ID).then((x: ICLWorker) => {
            if (x.ID === 0) {
                this.setState({ HideCantCreateExtensionMessage: false });
                return;
            }
            this.setState({
                SelectedPivotKey: this.headerTxt_NewCaseTab,
                Section_MainList_SelectedId: x.ID, //workerId
                Section_MainList_SelectedCaseId: x['CLCaseId'], //case id
                Section_MainList_SelectedStage: 'Draft',
            });
        }, (err) => {
            if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error creating extension`, err.message);
        });
    }

    private handleShowHistoricCaseClick = (workerId: number, caseId: number, stage: string): void => {
        this.setState({
            SelectedPivotKey: this.headerTxt_HistoricCaseTab,
            HistoricCase_CaseId: caseId,
            HistoricCase_WorkerId: workerId,
            HistoricCase_Stage: stage,
        });
    }

    private handleShowCaseTab = (): void => {
        //this.clearErrors();
        this.setState({ SelectedPivotKey: this.headerTxt_NewCaseTab });
    }

    private handleAfterArchived = (): void => {
        this.loadCaseCounts();
        this.setState({ ArchivedListRefreshCounter: this.state.ArchivedListRefreshCounter + 1 });
    }

    private cloneObject(obj, changeProp?, changeValue?) {
        if (changeProp)
            return { ...obj, [changeProp]: changeValue };
        return { ...obj };
    }
}