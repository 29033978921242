import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from '../../styles/cr.module.scss';
import { IWithErrorHandlingProps, withErrorHandling } from '../withErrorHandling';
import { SignOffType } from '../../refData/SignOffType';
import { useParams } from 'react-router-dom';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { CrCommandBar } from '../cr/CrCommandBar';
import { DraftReportList } from '../draftReport/DraftReportList';

interface ProgrammeUpdatesProps extends IWithErrorHandlingProps {
    lastDirectorateIdForChilds?: number;
    apiConnected: boolean;
    emphasiseProjectsWithAttribute: string;
    emphasisedProjectsHeaderText: string;
    otherProjectsHeaderText: string;
}

const ProgrammeUpdates = (props: ProgrammeUpdatesProps) => {

    const { programmeIdForUpdates } = useParams<{ programmeIdForUpdates: string }>();
    const history = useHistory();

    const commandBarItems: ICommandBarItemProps[] = [
        {
            key: 'programmes',
            text: 'Programmes',
            iconProps: { iconName: 'MiniExpandMirrored' },
            onClick: (ev, item) => {
                history.push('/performance-reporting/programmes');
            },
        },
        {
            key: 'signoff',
            text: 'Sign Off',
            iconProps: { iconName: 'MiniExpand' },
            onClick: (ev, item) => {
                console.log('TODO');
            },
        }
    ];


    return (

        <div className={`${styles.updatesContainer}`}>
            <CrCommandBar items={commandBarItems} />
            <div>
                <DraftReportList
                    defaultReportType={SignOffType.Programme}
                    defaultEntityId={Number(programmeIdForUpdates)}
                    {...props}
                />
            </div>


        </div>

    );
};

export default withErrorHandling(ProgrammeUpdates);