import { IEntity } from "./Entity";
import { IUser } from "./User";
import { IRiskRiskType } from "./RiskRiskType";
import { IDirectorate } from "./Directorate";
import { IProject } from "./Project";
import { IReportingEntity, ReportingEntity } from "./ReportingEntity";
import { DateService } from "../services/DateService";
import { IOrgLevel1 } from "./OrgLevel1";
import { IRiskRiskMitigationAction } from "./RiskRiskMitigationAction";
import { IOrgLevel3 } from "./OrgLevel3";

export interface IRisk extends IReportingEntity {
    CustomerID: number | null;
    RiskCode: string;
    GroupID: number  | null;
    DirectorateID: number | null;
    ProjectID: number | null;
    RiskOwnerUserID: number | null;
    RiskRegisterID: number | null;
    InheritRegisterOwner?: boolean;
    OwnerL0ID: number | null;
    OwnerL1ID: number | null;
    OwnerL2ID: number | null;
    OwnerL3ID: number | null;
    InheritManagedBy?: boolean;
    ManagedByTypeID: number | null;
    ManagedByL2ID: number | null;
    ManagedByL3ID: number | null;
    ReportApproverUserID?: number | null;
    RiskEventDescription: string;
    RiskCauseDescription: string;
    RiskImpactDescription: string;
    UnmitigatedRiskProbabilityID: number | null;
    UnmitigatedRiskImpactLevelID: number | null;
    TargetRiskProbabilityID: number | null;
    TargetRiskImpactLevelID: number | null;
    RiskAppetiteID: number | null; // Obsolete, now calculated from other selections?
    RiskIsOngoing: boolean;
    RiskProximity: Date | null;
    IsProjectRisk: boolean | null;
    CreatedDate: Date | null;
    RiskRegisteredDate: Date;
    OrgLevel1?: IOrgLevel1 | null;
    Directorate?: IDirectorate | null;
    Project?: IProject | null;
    OwnerL1?: IOrgLevel1;
    OwnerL2?: IDirectorate;
    OwnerL3?: IOrgLevel3;
    ManagedByL2?: IDirectorate;
    ManagedByL3?: IOrgLevel3;
    RiskOwnerUser?: IUser | null;
    ReportApproverUser?: IUser;
    RiskRiskTypes?: IRiskRiskType[];
    UnmitigatedRiskProbability?: IEntity | null;
    UnmitigatedRiskImpactLevel?: IEntity | null;
    TargetRiskProbability?: IEntity | null;
    TargetRiskImpactLevel?: IEntity | null;
    RiskAppetite?: IEntity | null;
    LinkedRiskID?: number | null;
    ChildRisks?: IRisk[];
    RiskRiskMitigationActions?: IRiskRiskMitigationAction[];
}

export class Risk extends ReportingEntity implements IRisk {
    public CustomerID = null;
    public RiskCode = '';
    public GroupID = null;
    public DirectorateID = null;
    public ProjectID = null;
    public RiskOwnerUserID = null;
    public ReportApproverUserID = null;
    public RiskRegisterID: null | number = null;
    public InheritRegisterOwner = null;
    public OwnerL0ID: number | null = null;
    public OwnerL1ID: number | null = null;
    public OwnerL2ID: number | null = null;
    public OwnerL3ID: number | null = null;
    public InheritManagedBy = null;
    public ManagedByTypeID: number | null = null;
    public ManagedByL2ID: number | null = null;
    public ManagedByL3ID: number | null = null;
    public RiskEventDescription = '';
    public RiskCauseDescription = '';
    public RiskImpactDescription = '';
    public UnmitigatedRiskProbabilityID = null;
    public UnmitigatedRiskImpactLevelID = null;
    public TargetRiskProbabilityID = null;
    public TargetRiskImpactLevelID = null;
    public RiskAppetiteID = null;
    public RiskIsOngoing = false;
    public RiskProximity = null;
    public IsProjectRisk = null;
    public CreatedDate = null;
    public RiskRegisteredDate = DateService.setLocaleDateToUTC(new Date());

    public OrgLevel1 = null;
    public Directorate = null;
    public Project = null;
    public RiskOwnerUser = null;
    public RiskRiskTypes = [];
    public UnmitigatedRiskProbability = null;
    public UnmitigatedRiskImpactLevel = null;
    public TargetRiskProbability = null;
    public TargetRiskImpactLevel = null;
    public RiskAppetite = null;
    public LinkedRiskID = null;
    public ChildRisks = [];
    public EntityStatus = null;
    public Contributors = [];
}
