import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@fluentui/react/lib/Theme';
import { darkMenuTheme } from '../AppGlobals';
import { LoggedUserInfoForMenu } from './LoggedUserInfoForMenu';
import { Nav, INavLink } from '@fluentui/react/lib/Nav';
import { IEntity } from '../types';

interface IZedXMainMenu {
    onLinkClicked?: () => void;
    onLogout?: () => void;
    authType: string;
    menuData?: any[];
    lastFiveApps: IEntity[];
}

export const ZedXMainMenu = ({ onLogout, authType, onLinkClicked, menuData, lastFiveApps }: IZedXMainMenu): React.ReactElement => {

    const { pathname } = useLocation();
    const history = useHistory();
    const [navs, setNavs] = useState([]);

    // - Corporate Workflows
    // - Project Portfolios
    // - Risk Registers
    // - GIAA Actions
    // - NAO/PAC Tracker
    // - Contingent Labour
    // - Governance Statements
    // - Controls and Assurance
    // - Management Actions
    // - Agile Sprints
    // - Knowledge Hub
    // - Group Notifications

    useEffect(() => {

        const routerNav = (path: string): void => {
            history.push(path);
            if (onLinkClicked) onLinkClicked();
        };

        const NamePathToNavLink = ({ name, path }): INavLink => {
            return { key: path, name: name, url: `#${path}`, onClick: () => routerNav(path) };
        };

        const links: INavLink[] = [
            { name: 'Home', path: '/' },
        ].map(NamePathToNavLink);

        const allAppsLinks = [];
        const recentAppsLinks = [];

        const menuDataMap = new Map(menuData.map(item => [item.ID, item]));

        lastFiveApps.forEach((value) => {
            const menuItem = menuDataMap.get(value.ID);
            if (menuItem) {
                recentAppsLinks.push({
                    name: menuItem.MenuText,
                    key: `m_recent_${value.ID}`,
                    path: `${menuItem.AppLink}/welcome`,
                });
            }
        });

        menuData.forEach(m => {
            allAppsLinks.push({
                name: m.MenuText,
                key: `m_all_${m.ID}`,
                path: `${m.AppLink}/welcome`,
            });
        });

        links.push({
            key: 'recent_apps',
            name: 'Recent Apps',
            url: null,
            isExpanded: true,
            links: recentAppsLinks.map(NamePathToNavLink)
        });

        links.push({
            key: 'all_apps',
            name: 'All Apps',
            url: null,
            isExpanded: false,
            links: allAppsLinks.map(NamePathToNavLink)
        });

        links.push(NamePathToNavLink({ name: 'Account Admin', path: '/admin/welcome' }));

        if (authType == 'CustomJwt') {
            links.push(NamePathToNavLink({ name: 'Change Password', path: '/account/change-password' }));
        }
        links.push(
            {
                name: 'Logout',
                class: 'logout-link',
                key: 'logout',
                url: '',
                onClick: () => {
                    onLogout();
                }
            },
        );

        setNavs(links);

    }, [history, lastFiveApps, onLinkClicked]);

    const selectedKey: string = pathname === "/" ? "home" : pathname;
    return (
        <ThemeProvider theme={darkMenuTheme}>
            <LoggedUserInfoForMenu />
            <Nav
                groups={[{ links: navs }]}
                selectedKey={selectedKey}
            />
        </ThemeProvider>
    );
};

export function _onRenderNavLink(link: INavLink, totalPortfolios?: number) {

    const commonStyle = { paddingLeft: '10px' };
    const badgeStyle = {
        fontSize: '12px',
        backgroundColor: 'green',
        color: 'white',
        padding: '2px',
        paddingLeft: '4px',
        paddingRight: '22px',
        borderRadius: '4px'
    };

    if (link.key === '/performance-reporting/portfolios') {
        return (
            <span>
                <img width='8' height='8' src='https://placehold.co/10x10/99D9EA/99D9EA' />
                <span style={{ ...commonStyle, marginRight: '25px' }}>{link.name}</span>
                <span style={badgeStyle}>{totalPortfolios}</span>
            </span>
        );
    } else if (link.key === '/performance-reporting/programmes') {
        return (
            <span>
                <img width='8' height='8' src='https://placehold.co/10x10/99D9EA/99D9EA' />
                <span style={commonStyle}>{link.name}</span>
            </span>
        );
    } else if (link.key === '/performance-reporting/projects') {
        return (
            <span>
                <img width='8' height='8' src='https://placehold.co/10x10/99D9EA/99D9EA' />
                <span style={commonStyle}>{link.name}</span>
            </span>
        );
    }


    return <span>{link.name}</span>;
}
