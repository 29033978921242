import { Entity, IEntity } from "./Entity";
import { IApp03Workflow } from "./App03Workflow";
import { IApp03WorkflowStage } from "./App03WorkflowStage";
import { IApp03TaskUser } from "./App03TaskUser";
import { TaskPriority } from "../refData/WorkflowType";
import { getDate } from "date-fns/esm";


export interface IApp03Task extends IEntity {
    TaskRef?: string;
    ExternalRef?: string;
    WorkflowStageID?: number;
    WorkflowID?: number;
    Details?: string;
    TaskPriorityID?: number;
    Archived?: boolean;
    StartDate?: Date | null;
    EndDate?: Date | null;
    ProjectID?: number;
    UpdatePeriod?: Date | null;

    Workflow?: IApp03Workflow;
    WorkflowStage?: IApp03WorkflowStage
    App03TaskUsers?: IApp03TaskUser[];
}

export class App03Task extends Entity implements IApp03Task {
    public TaskRef = null;
    public ExternalRef = null;
    public WorkflowStageID = null;
    public WorkflowID = null;
    public Details = null;
    public TaskPriorityID = null;
    public Archived = null;
    public StartDate = null;
    public EndDate = null;
    public UpdatePeriod = null;
    public ProjectID = null;

    constructor(workflowID: number, defaultStageID: number) {
        super();
        this.WorkflowID = workflowID;
        this.WorkflowStageID = defaultStageID;
        this.TaskPriorityID = TaskPriority.Normal;
        this.StartDate = new Date();
    }

}