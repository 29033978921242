import { EntityService } from './EntityService';
import { ICLHiringMember } from '../types';

export class CLHiringMemberService extends EntityService<ICLHiringMember> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/CLHiringMembers`);
    }

}