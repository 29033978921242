import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest, msalConfig } from "./authConfig";
import { PrimaryButton } from "@fluentui/react";
import { AppGlobals } from "./AppGlobals";


export const MsSignIn: React.FC = () => {
  const { instance } = useMsal();

  useEffect(() => {
    // Call handleLogin("redirect") when the component is mounted
    handleLogin("redirect");
  }, []);

  const handleLogin = (loginType: "popup" | "redirect"): void => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).then((res) => {
        console.log('auth result', res);
        AppGlobals.Username = res.account.username;
      }).catch((e: any) => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e: any) => {
        console.log(e);
      });
    }
  };

  return (
    <div className="main">
      <div className="blue-banner" style={{ marginLeft: '0', paddingLeft: '0' }}>
        <div className="blue-banner-white-bold">ZedX Apps</div>
      </div>
      <div className="container">
        <div className="content" style={{ width: 'calc(100% - 38px)' }}>
          <div>
            <h2 style={{ color: 'rgb(112,146,190)', fontWeight: 'normal' }}>Welcome to ZedX Apps</h2>
            <div>
              Redirecting you for Microsoft authentication
              {/* To login to ZedX Apps, click on the button below. */}
              <br /><br />
            </div>
            {/* <div>
              <PrimaryButton text={'Login'} onClick={() => handleLogin("redirect")} />
            </div> */}
          </div>

        </div>
      </div>
    </div>
  );
};