import { EntityService } from './EntityService';
import { IGoDefForm } from '../types';

export class GoDefFormService extends EntityService<IGoDefForm> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/GoDefForms`);
    }

    public welcomeAccess(): Promise<string> {
        return super.readString(`?welcomeAccess=`).then((result: string): string => {
            return result;
        });
    }
}