import { IEntity } from "./Entity";

export interface IUserAppLaunchLog extends IEntity {
    AppID?: number;
}

export class UserAppLaunchLog implements IUserAppLaunchLog {
    public ID = 0;
    public Title = null;
    public AppID = null;

    constructor(appID: number) {
        this.AppID = appID;
    }
}
