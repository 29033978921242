import { IProgressUpdateWithDeliveryDates, ProgressUpdateWithDeliveryDates } from "./ProgressUpdate";
import { IPartnerOrganisationRiskMitigationAction } from "./PartnerOrganisationRiskMitigationAction";

export interface IPartnerOrganisationRiskMitigationActionUpdate extends IProgressUpdateWithDeliveryDates {
    PartnerOrganisationRiskMitigationActionID: number | null;
    SignOffID: number | null;
    PartnerOrganisationRiskMitigationAction?: IPartnerOrganisationRiskMitigationAction;
}

export class PartnerOrganisationRiskMitigationActionUpdate extends ProgressUpdateWithDeliveryDates implements IPartnerOrganisationRiskMitigationActionUpdate {
    public PartnerOrganisationRiskMitigationActionID: number | null = null;
    public SignOffID = null;

    constructor(riskMitigationActionId: number, period?: Date) {
        super(period);
        this.PartnerOrganisationRiskMitigationActionID = riskMitigationActionId;
    }
}