import { EntityWithStatusService } from './EntityWithStatusService';
import { ContextService } from './ContextService';
import { IDependency, IEntityChildren } from '../types';
import { EntityStatus } from '../refData/EntityStatus';
import { AppGlobals } from '../AppGlobals';

export class DependencyService extends EntityWithStatusService<IDependency> {
    public readonly parentEntities = ['Project', 'LeadUser'];
    protected childrenEntities = ['Contributors($expand=ContributorUser)', 'Attributes($expand=AttributeType)'];

    constructor() {
        super(`/Dependencies`);
    }

    public readMyDependencies(): Promise<IDependency[]> {
        const username = encodeURIComponent(ContextService.Username());
        const filters = [
            `LeadUser/Username eq '${username}'`,
            `Contributors/any(c: c/ContributorUser/Username eq '${username}')`
        ];
        return this.readAll(`?$filter=EntityStatusID eq ${EntityStatus.Open} and (${filters.join(' or ')})&$orderby=Project/Title,Title`);
    }

    public readAllForList(includeClosedDependency?: boolean, projectID?: number): Promise<IDependency[]> {
        return this.readAll(
            `?$select=ID,Title`
            + `&$orderby=Title`
            + `&$expand=EntityStatus($select=Title),Project($select=Title),LeadUser($select=Title),Contributors($select=ContributorUser;$expand=ContributorUser($select=Title))&$filter=Project/CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosedDependency ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
            + (projectID ? ` and ProjectID eq ${projectID}` : '')
        );
    }

    public async readForView(dependencyID: number): Promise<IDependency> {
        return this.read(dependencyID, false, false, ['Project($select=Title)', 'LeadUser($select=Title,EmailAddress)', 'Contributors($select=ContributorUser;$expand=ContributorUser($select=Title,EmailAddress))', 'Attributes($expand=AttributeType($select=Title))']);
    }

    public readAllForLookup(includeClosedEntities?: boolean, additionalFields?: string): Promise<IDependency[]> {
        return this.readAll(
            `?$select=ID,Title${additionalFields ? `,${additionalFields}` : ''}`
            + `&$orderby=Title&$filter=Project/CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosedEntities ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public readProjectDependencies(projectId: number): Promise<IDependency[]> {
        return this.readAll(`?$expand=LeadUser,Contributors&$filter=EntityStatusID eq ${EntityStatus.Open} and ProjectID eq ${projectId}&$orderby=Title`);
    }

    public async entityChildren(id: number): Promise<IEntityChildren[]> {
        return this.entityChildrenSingle(id, 'Dependency updates', 'DependencyUpdates', false);
    }

    public async countDependenciesForProject(projectID: number): Promise<number> {
        if (projectID) {
            return (await this.readAll(`?$select=ID&$filter=EntityStatusID eq ${EntityStatus.Open} and ProjectID eq ${projectID}`))?.length;
        }
        return Promise.resolve(0);
    }
}