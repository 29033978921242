import React, { useContext } from 'react';
import { ErrorBoundary } from '../ErrorBoundary';
import { IWithErrorHandlingProps, withErrorHandling } from '../withErrorHandling';
import { IUseApiProps } from '../useApi';
import ClUpdatesCls from './ClUpdatesCls';
import { OrbUserContext } from '../OrbUserContext';


export interface IClUpdatesProps extends IUseApiProps, IWithErrorHandlingProps {
}

const ClUpdates = (props: IClUpdatesProps) => {

    const { userPermissions } = useContext(OrbUserContext);
    return (
        <ErrorBoundary>
            <ClUpdatesCls
                userPermissions={userPermissions}
                {...props}
            />
        </ErrorBoundary>
    );
};

export default withErrorHandling(ClUpdates);
