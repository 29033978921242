import { EntityService } from './EntityService';
import { IEntityWithCustomer, IEntityChildren } from '../types';
import axios from 'axios';
import { AppGlobals } from '../AppGlobals';

export class MeasurementUnitService extends EntityService<IEntityWithCustomer> {
    constructor() {
        super(`/MeasurementUnits`);
    }

    public readAllForList(): Promise<IEntityWithCustomer[]> {
        return super.readAll(`?$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`);
    }

    public readAllForLookup(): Promise<IEntityWithCustomer[]> {
        return this.readAll(`?$select=ID,Title&$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`);
    }

    public async create(unit: IEntityWithCustomer): Promise<IEntityWithCustomer> {
        unit.CustomerID = AppGlobals.CustomerID;           
        return super.create(unit);
    }

    public async entityChildren(id: number): Promise<IEntityChildren[]> {
        const unitUrl = `${this.entityUrl}(${id})`;
        const benefits = (await this.axiosGet(`${unitUrl}/Benefits?$select=ID&$top=10`)).data.value;
        const metrics = (await this.axiosGet(`${unitUrl}/Metrics?$select=ID&$top=10`)).data.value;
        return [
            { ChildType: 'Benefits', CanBeAdopted: true, ChildIDs: (await benefits).map((b: { ID: any }) => b.ID) },
            { ChildType: 'Metrics', CanBeAdopted: true, ChildIDs: (await metrics).map((m: { ID: any }) => m.ID) }
        ];
    }
}