import { EntityService } from './EntityService';
import { IEntity } from '../types';

export class NAOUpdateFeedbackService extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/NAOUpdateFeedbacks`);
    }
    public readAllWithArgs(naoUpdateId: number): Promise<IEntity[]> {
        return this.readAll(`?$filter=NAOUpdateId eq ${naoUpdateId}&$expand=User,NAOUpdateFeedbackType`);
    }
}