import * as React from 'react';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import * as types from '../../types';
import Report1List from './Report1List';
import Report2List from './Report2List';
import GenExport from '../export/GenExport';
import { CrLoadingOverlayWelcomeCat } from '../cr/CrLoadingOverlayWelcomeCat';
import { OrbUserContext } from '../OrbUserContext';
import withMobileDetector from '../withMobileDetector';

export interface INaoReportClsProps extends types.IBaseComponentProps {
  isMobile: boolean;
}
export interface ILookupData {
}

export class LookupData implements ILookupData {
}

export interface INaoReportClsState {
  LookupData: ILookupData;
  Report1_ListFilterText: string;
  Report2_ListFilterText: string;
  Loading: boolean;
}
export class NaoReportClsState implements INaoReportClsState {
  public LookupData = new LookupData();
  public Report1_ListFilterText: string = null;
  public Report2_ListFilterText: string = null;
  public Loading = false;
  public FilteredItems = [];

  constructor() {
  }
}

export class NaoReportCls extends React.Component<INaoReportClsProps, NaoReportClsState> {
  constructor(props: INaoReportClsProps) {
    super(props);
    this.state = new NaoReportClsState();
  }

  static contextType = OrbUserContext;
  // Tell TypeScript the type of the context
  context!: React.ContextType<typeof OrbUserContext>;

  public render(): React.ReactElement<INaoReportClsProps> {
    //const _userContext = this.context;
    //console.log('_userContext', _userContext);

    let report1HeaderText = "Output PDF by DG Areas";
    let report2HeaderText = "Output PDF by Publication";
    let report3HeaderText = "Export to Excel";

    if (this.props.isMobile) {
      report1HeaderText = "By DG Areas";
      report2HeaderText = "By Publication";
      report3HeaderText = "Export";
    }

    return (
      <React.Fragment>
        <Pivot>
          <PivotItem headerText={report1HeaderText}>
            {this.renderReport1()}
          </PivotItem>
          <PivotItem headerText={report2HeaderText}>
            {this.renderReport2()}
          </PivotItem>
          <PivotItem headerText={report3HeaderText} itemKey="Export to Excel">
            {this.renderGenExport()}
          </PivotItem>
        </Pivot>
      </React.Fragment>
    );
  }

  private renderReport1(): React.ReactElement<types.IWebPartComponentProps> {
    return (
      <div>
        <CrLoadingOverlayWelcomeCat isLoading={this.state.Loading} />
        <div style={{ paddingTop: "10px" }}>
          <Report1List
            {...this.props}
            errorHandling={this.props.errorHandling}
            filterText={this.state.Report1_ListFilterText}
            onChangeFilterText={this.handleReport1_ChangeFilterText}
          />
        </div>
      </div>
    );
  }

  private renderReport2(): React.ReactElement<types.IWebPartComponentProps> {
    return (
      <div>
        <CrLoadingOverlayWelcomeCat isLoading={this.state.Loading} />
        <div style={{ paddingTop: "10px" }}>
          <Report2List
            {...this.props}
            errorHandling={this.props.errorHandling}
            filterText={this.state.Report2_ListFilterText}
            onChangeFilterText={this.handleReport2_ChangeFilterText}
          />
        </div>
      </div>
    );
  }

  private renderGenExport(): React.ReactElement<types.IWebPartComponentProps> {
    const isSuperUserPermission = this.isSuperUser();
    return (
      <div>
        <div style={{ paddingTop: "10px" }}>
          {(isSuperUserPermission === true) &&
            <GenExport
              {...this.props}
              errorHandling={this.props.errorHandling}
              moduleName="NAO"
            />
          }
          {
            (isSuperUserPermission === false) &&
            <div style={{ fontSize: '14px', color: 'navy', fontStyle: 'italic', paddingTop: '8px', paddingLeft: '5px' }}>
              Export to Excel function is only available to the Super User.
            </div>
          }
        </div>
      </div>
    );

  }

  private isSuperUser(): boolean {
    const userContext = this.context;
    console.log('userContext in private method', userContext);

    return this.props.userPermissions.UserIsSystemAdmin()
      || this.props.userPermissions.UserIsNAOTrackerSuperUser();

  }

  private handleReport1_ChangeFilterText = (value: string): void => {
    this.setState({ Report1_ListFilterText: value });
  }
  private handleReport2_ChangeFilterText = (value: string): void => {
    this.setState({ Report2_ListFilterText: value });
  }
}

export default withMobileDetector(NaoReportCls)


