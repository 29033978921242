import { EntityService } from './EntityService';
import { NAOAssignment } from '../types';

export class NAOAssignmentService extends EntityService<NAOAssignment> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/NAOAssignments`);
    }
}