import React, { useEffect, useMemo, useState } from 'react';
import { IPanelHeaderRenderer, IPanelProps, Panel, PanelType } from '@fluentui/react/lib/Panel';
import { CrLoadingOverlay } from '../cr/CrLoadingOverlay';
import { ViewFormCommandBar } from '../cr/ViewFormCommandBar';
import { IDependency } from '../../types';
import styles from '../../styles/cr.module.scss';
import { renderDate } from '../cr/ListRenderers';

interface DependencyViewProps {
    entityName?: string;
    showForm?: boolean;
    entityId?: number;
    onClosed?: () => void;
    loadEntity: (entityId: number) => Promise<IDependency>;
}

export const DependencyView: React.FC<DependencyViewProps> = ({ showForm, entityName, entityId, loadEntity, onClosed }) => {
    const [formData, setFormData] = useState<IDependency>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const entity = await loadEntity(entityId);
                setFormData(entity);
                setLoading(false);
            } catch (error) {
                console.error('Error loading entity:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [loadEntity]);

    const dependencyStaff = useMemo(() => {
        if (!formData) return '';

        const staffRoles = [
            { role: 'Lead', users: formData.LeadUser ? [`${formData.LeadUser.Title} (${formData.LeadUser.EmailAddress})`] : [] },
            {
                role: 'Contributors',
                users: formData.Contributors && formData.Contributors.some(c => c.ContributorUser != null)
                    ? formData.Contributors.map(dc => `${dc.ContributorUser?.Title} (${dc.ContributorUser?.EmailAddress})`)
                    : [],
            },
        ];

        const formattedStaffRoles = staffRoles
            .filter(role => role.users.length > 0)
            .map(role => `<div style='display:flex'><div style='font-weight:bold'>-&nbsp;</div><div>${role.role}: ${role.users.join(', ')}</div></div>`)
            .join('');

        return formattedStaffRoles;
    }, [formData]);


    const headerText = useMemo(() => {
        if (!formData) return entityName;

        return `${entityName} - ${formData.Title}`;
    }, [entityName, formData]);

    const formattedDescription = useMemo(() => {
        if (!formData) return null;

        return formData.Description ? formData.Description.replace(/\n/g, '<br>') : null;
    }, [formData]);

    return (
        <Panel
            className={styles.cr}
            isOpen={showForm}
            headerText={headerText}
            type={PanelType.medium}
            hasCloseButton={false}
            onRenderHeader={(props: IPanelProps, defaultRender: IPanelHeaderRenderer) =>
                <div style={{ marginBottom: '20px' }} className={styles.panelHeaderText}>{defaultRender(props)}</div>
            }
            onRenderNavigation={() =>
                <ViewFormCommandBar onClose={onClosed} />
            }
        >
            <div className={styles.cr}>
                <CrLoadingOverlay isLoading={loading} opaque={true} />
                {formData && (
                    <div>
                        <div className={styles.viewFormFieldLabel}>Name of third party</div>
                        <div className={styles.viewFormFieldData}>{formData.ThirdParty || 'N/A'}</div>

                        <div className={styles.viewFormFieldLabel}>Start-BaselineEnd Dates</div>
                        <div className={styles.viewFormFieldData}>
                            {renderDate(formData.StartDate)}&nbsp;&nbsp;&nbsp;&nbsp;{renderDate(formData.BaselineDate)}
                        </div>

                        <div className={styles.viewFormFieldLabel}>Staff</div>
                        <div className={styles.viewFormFieldData}>
                            {dependencyStaff ? (
                                <div dangerouslySetInnerHTML={{ __html: dependencyStaff }} />
                            ) : (
                                'N/A'
                            )}
                        </div>

                        <div className={styles.viewFormFieldLabel}>Attributes</div>
                        <div className={styles.viewFormFieldData}>
                            {formData.Attributes && formData.Attributes.length > 0 ? (
                                formData.Attributes.map(a => a.AttributeType?.Title).join(', ')
                            ) : (
                                'N/A'
                            )}
                        </div>

                        <div className={styles.viewFormFieldLabel}>Description</div>
                        <div className={styles.viewFormFieldData}>
                            {formattedDescription ? (
                                <div dangerouslySetInnerHTML={{ __html: formattedDescription }} />
                            ) : (
                                'N/A'
                            )}
                        </div>
                    </div>
                )}
            </div>
        </Panel>
    );
};