import * as React from 'react';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import * as types from '../../types';
import GenExport from '../../components/export/GenExport';

export interface IClReportClsProps extends types.IBaseComponentProps {
}

export interface ILookupData {
}

export class LookupData implements ILookupData {
}

export interface IClReportClsState {
    LookupData: ILookupData;
}

export class ClReportClsState implements IClReportClsState {
    public LookupData = new LookupData();
    public FilteredItems = [];
    constructor() {
    }
}

export default class ClReportCls extends React.Component<IClReportClsProps, ClReportClsState> {
    constructor(props: IClReportClsProps) {
        super(props);
        this.state = new ClReportClsState();
    }

    public render(): React.ReactElement<IClReportClsProps> {
        return (
            <React.Fragment>
                <Pivot>
                    <PivotItem headerText="Export to Excel" itemKey="Export to Excel">
                        {this.renderGenExport()}
                    </PivotItem>
                </Pivot>
            </React.Fragment>
        );
    }

    private renderGenExport(): React.ReactElement<types.IWebPartComponentProps> {
        if (1 + 1 === 2) {
            const isSuperUserPermission = this.isSuperUser();
            return (
                <div>
                    <div style={{ paddingTop: "10px" }}>
                        {(isSuperUserPermission === true) &&
                            <GenExport
                                {...this.props}
                                errorHandling={this.props.errorHandling}
                                moduleName="CL"
                            />
                        }
                        {
                            (isSuperUserPermission === false) &&
                            <div style={{ fontSize: '14px', color: 'navy', fontStyle: 'italic', paddingTop: '8px', paddingLeft: '5px' }}>
                                Export to Excel function is only available to the Super User.
                            </div>
                        }
                    </div>
                </div>
            );
        }
        else
            return null;
    }

    private isSuperUser(): boolean {
        return this.props.userPermissions.UserIsSystemAdmin()
            || this.props.userPermissions.UserIsCLSuperUser();
    }
}


