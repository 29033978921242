import { EntityService } from './EntityService';
import { IEntityWithTimerange } from '../types/EntityWithTimerange';

export class RiskProbabilityService extends EntityService<IEntityWithTimerange> {
    constructor() {
        super(`/RiskProbabilities`);
    }

    public readAllForLookup(): Promise<IEntityWithTimerange[]> {
        return this.readAll(`?$select=ID,Title,StartUpdatePeriod,EndUpdatePeriod&$orderby=ID desc`);
    }
}