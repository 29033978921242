import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CrLoadingOverlay } from '../cr/CrLoadingOverlay';
import { UserAppLaunchLog } from '../../types';
import styles from '../../styles/cr.module.scss';
import axios from 'axios';
import { DataContext } from '../DataContext';
import { AppIDs } from '../../AppGlobals';

interface GiaaWelcomeProps {
}

export interface IAppWelcomeText {
    CenteralHeading: string;
    CenteralBody: string;
    CustomerWelcomeHeading: string;
    CustomerWelcomeBody: string;
}

export const GiaaWelcome: React.FC<GiaaWelcomeProps> = ({ }) => {
    const { dataServices } = useContext(DataContext);
    const [formData, setFormData] = useState<IAppWelcomeText>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const zedxNetapiURL = process.env.REACT_APP_ZedX_Net_API!;
                const centeralWelcomeText = (await axios.get(`${zedxNetapiURL}/availableapps/GetAppWelcomeText/GIAA`)).data;
                const centeralWelcomeHeading = centeralWelcomeText.WelcomeHeading;
                const centeralWelcomeBody = centeralWelcomeText.WelcomeBody;

                let customerWelcomeHeading: string = '';
                let customerWelcomeBody: string = '';

                const giaaDefForm = await dataServices.gIAADefFormService.readAll();
                if (giaaDefForm.length > 0) {
                    customerWelcomeHeading = giaaDefForm[0].Title ?? '';
                    customerWelcomeBody = giaaDefForm[0].Details ?? '';
                }

                setFormData({
                    CenteralHeading: centeralWelcomeHeading,
                    CenteralBody: centeralWelcomeBody,
                    CustomerWelcomeHeading: customerWelcomeHeading,
                    CustomerWelcomeBody: customerWelcomeBody
                });

                dataServices.userAppLaunchLogService.create(new UserAppLaunchLog(AppIDs.GIAA));
                setLoading(false);
            } catch (error) {
                console.error('Error loading entity:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);





    // const headerText = useMemo(() => {
    //     if (!formData) return entityName;

    //     return `${entityName} - ${formData.Title}`;
    // }, [entityName, formData]);

    const formattedCenteralBody = useMemo(() => {
        if (!formData) return '';

        return formData.CenteralBody ? formData.CenteralBody.replace(/\n/g, '<br>') : '';
    }, [formData]);

    const formattedCustomerBody = useMemo(() => {
        if (!formData) return '';

        return formData.CustomerWelcomeBody ? formData.CustomerWelcomeBody.replace(/\n/g, '<br>') : '';
    }, [formData]);

    const formattedBody = useMemo(() => {
        if (!formData) return '';

        let b1: string = formData.CenteralBody ? formData.CenteralBody.replace(/\n/g, '<br>') : '';
        let b2: string = formData.CustomerWelcomeBody ? formData.CustomerWelcomeBody.replace(/\n/g, '<br>') : '';
        let b3 = '';
        if (b2.length > 0) {
            b3 = b1 + '<br/><br/>' + b2;
        }
        else {
            b3 = b1;
        }
        return b3;
    }, [formData]);

    return (

        <div className={styles.cr}>
            <CrLoadingOverlay isLoading={loading} opaque={true} />
            {formData && (
                <>
                    <h1 className={styles.welcomeL1Heading}>{formData.CenteralHeading}</h1>
                    <div>
                        <div className={styles.welcomeBody} dangerouslySetInnerHTML={{ __html: formattedCenteralBody }} />
                    </div>
                    <div>
                        {formData.CustomerWelcomeHeading?.length > 0 && <div style={{ fontWeight: 'bold', textDecoration: 'underline' }} className={styles.welcomeBody}>{formData.CustomerWelcomeHeading}</div>}
                    </div>
                    <div>
                        {formattedCustomerBody?.length > 0 && <div className={styles.welcomeBody} dangerouslySetInnerHTML={{ __html: formattedCustomerBody }} />}
                    </div>
                </>
            )}
        </div>

    );
};