import React, { useContext, useMemo, useState } from 'react';
import {
	IFinancialRisk,
	IFinancialRiskMitigationAction,
	ListDefaults,
	ICorporateRiskMitigationAction,
	ICorporateRisk
} from '../types';
import { EntityList } from './EntityList';
import { RiskForm } from './risk/RiskForm';
import { RiskMitigationActionForm } from './riskMitigationAction/RiskMitigationActionForm';
import { ErrorBoundary } from './ErrorBoundary';
import { IWithErrorHandlingProps, withErrorHandling } from './withErrorHandling';
import { IUseApiProps } from './useApi';
import { FinancialRiskForm } from './financialRisk/FinancialRiskForm';
import { DataContext } from './DataContext';
import { FinancialRiskMitigationActionForm } from './financialRisk/FinancialRiskMitigationActionForm';
import { OrbUserContext } from './OrbUserContext';
import { RiskView } from './risk/RiskView';
import { ProjectRiskMitigationActionView } from './riskMitigationAction/ProjectRiskMitigationActionView';
import { useHistory, useParams } from 'react-router-dom';

export interface IRiskReportingAdminProps extends IUseApiProps, IWithErrorHandlingProps {
	entity: string;
}

const RiskReportingAdmin = (props: IRiskReportingAdminProps): React.ReactElement => {
	const history = useHistory();
	const { entity } = props;
	const { dataServices, loadLookupData: { corporateRisks, financialRisks } } = useContext(DataContext);

	useMemo(() => corporateRisks(), [corporateRisks]);
	useMemo(() => financialRisks(), [financialRisks]);
	const { orbConfig } = useContext(OrbUserContext);
	const [riskMitigatingActionsLabelForSelectedRisk, setRiskMitigatingActionsLabelForSelectedRisk] = useState<string>("Risk mitigating actions");
	const { riskIdForMitigatingActions } = useParams<{ riskIdForMitigatingActions: string }>();

	const lcc = ListDefaults.columnWidths;
	return (
		<ErrorBoundary>
			{entity === 'Risks' &&
				<EntityList<ICorporateRisk>
					{...props}
					enableShowHideClosedEntities={true}
					entityName={{ Plural: 'Risks', Singular: 'Risk' }}
					preFarItemsOnSelection={(r) => {
						setRiskMitigatingActionsLabelForSelectedRisk(`Risk mitigating actions (${r?.RiskMitigationActions?.length ?? 0})`);
						return ([
							{
								key: 'Updates',
								name: 'Updates',
								iconProps: { iconName: 'MiniExpand' },
								onClick: (ev, item) => {
									//props.setLastProjectIdForChilds(p?.ID);
									history.push(`/risk-reporting/updates-for-risk/${r?.ID}`);
								},
							},
							{
								key: 'RiskMitigatingActions',
								name: riskMitigatingActionsLabelForSelectedRisk,
								iconProps: { iconName: 'MiniExpand' },
								onClick: (ev, item) => {
									history.push(`/risk-reporting/risk-mitigating-actions-for-risk/${r?.ID}`);
								},
							},
						]);
					}}
					columns={[
						{ key: '1', name: 'Risk ID', fieldName: 'RiskCode', minWidth: 70, maxWidth: 70, isResizable: true },
						{ key: '2', name: 'Risk name', fieldName: 'Name', minWidth: 100, maxWidth: 250, isResizable: true },
						{ key: '3', name: 'Register', fieldName: 'Register', minWidth: 100, maxWidth: 200, isResizable: true },
						{ key: '4', name: 'Managed By', fieldName: 'ManagedBy', minWidth: 100, maxWidth: 200, isResizable: true },
						{ key: '5', name: 'Staff', fieldName: 'RiskStaff', minWidth: 100, maxWidth: 250, isResizable: true, isCollapsible: true, isMultiline: true },
						{ key: '6', name: 'Status', fieldName: 'Status', minWidth: 50, isResizable: true }
					]}
					loadListItems={showClosedEntities => dataServices.corporateRiskService.readAllForList_RiskRegister(showClosedEntities)}
					mapEntitiesToListItems={risks => risks.map(r => {
						let owner = '';
						if (r.RiskRegisterID !== null) {
							switch (r.RiskRegisterID) {
								case 0:
									owner = `Dept`;
									break;
								case 1:
									owner = `${orbConfig.OrgL1Label} ${r.OwnerL1?.Title ?? ''}`;
									break;
								case 2:
									owner = `${orbConfig.OrgL2Label} ${r.OwnerL2?.Title ?? ''}`;
									break;
								case 3:
									owner = `${orbConfig.OrgL3Label} ${r.OwnerL3?.Title ?? ''}`;
									break;
								default:
									owner = '';
									break;
							}
						}

						let managedBy = '';
						if (r.ManagedByTypeID !== null) {
							switch (r.ManagedByTypeID) {
								case 2:
									managedBy = `${orbConfig.OrgL2Label} ${r.ManagedByL2?.Title ?? ''}`;
									break;
								case 3:
									managedBy = `${orbConfig.OrgL3Label} ${r.ManagedByL3?.Title ?? ''}`;
									break;
								default:
									managedBy = '';
									break;
							}
						}

						return {
							key: r.ID,
							Register: owner,
							ManagedBy: managedBy,
							RiskCode: r.RiskCode,
							Name: r.Title,
							Group: r.Directorate?.OrgLevel1?.Title,
							Directorate: r.Directorate?.Title,
							RiskStaff: [
								r.RiskOwnerUser ? `Owner - ${r.RiskOwnerUser.Title}` : null,
								r.ReportApproverUser ? `ARA - ${r.ReportApproverUser.Title}` : null,
								r.Contributors && r.Contributors.some(c => c.ContributorUser != null) ? `C - ${r.Contributors.map(pc => pc.ContributorUser?.Title).join(', ')}` : null,
							].filter(Boolean).join(', '),
							//Owner: r.RiskOwnerUser?.Title,
							//Contributors: r.Contributors?.map(c => c.ContributorUser?.Title).join(', '),
							//NumberOfActions: r.RiskMitigationActions?.length,
							Status: r.EntityStatus?.Title
						};
					})}
					entityForm={(showForm, entityId, onSaved, onCancelled) =>
						<RiskForm
							{...props}
							showForm={showForm}
							entityId={entityId}
							onSaved={onSaved}
							onCancelled={onCancelled}
						/>
					}
					viewForm={(showForm, entityId, onClosed) =>
						<RiskView
							{...props}
							showForm={showForm}
							entityId={entityId}
							entityName='Risk'
							loadEntity={riskId => dataServices.corporateRiskService.readForView(riskId)}
							onClosed={onClosed}
						/>
					}
					childEntityForm={(showForm, parentEntityId, onSaved, onCancelled) =>
						<RiskMitigationActionForm
							{...props}
							maxDependentRisks={1}
							showForm={showForm}
							onSaved={onSaved}
							onCancelled={onCancelled}
							defaultValues={[{ field: 'RiskID', value: parentEntityId }]}
						/>
					}
					onCheckDelete={id => dataServices.corporateRiskService.entityChildren(id)}
					onDelete={id => dataServices.corporateRiskService.delete(id)}
					onChange={() => corporateRisks(true)}
				/>
			}
			{entity === 'RiskMitigationActions' &&
				<EntityList<ICorporateRiskMitigationAction>
					{...props}
					enableShowHideClosedEntities={true}
					entityName={{ Plural: 'Risk mitigating actions', Singular: 'Risk mitigating action' }}
					entity={riskIdForMitigatingActions !== undefined ? "Actions for Risk" : "Risk mitigating actions"} //just to reload
					columns={[
						{ key: '0', name: 'Risk ID', fieldName: 'RiskCode', minWidth: 50, maxWidth: 70, isResizable: true },
						//{ key: '1', name: 'ID', fieldName: 'Code', minWidth: 20, maxWidth: 20, isResizable: true },
						{ key: '2', name: 'Risk mitigating action name', fieldName: 'Name', minWidth: 300, isResizable: true },
						{ key: '3', name: 'Register', fieldName: 'Register', minWidth: 200, isResizable: true },
						{ key: '4', name: 'Risk', fieldName: 'Risk', minWidth: 200, isResizable: true },
						//{ key: '4', name: 'Owner', fieldName: 'Owner', minWidth: lcc.user, isResizable: true },
						//{ key: '5', name: 'Contributors', fieldName: 'Contributors', minWidth: lcc.user, isResizable: true },
						{ key: '5', name: 'Staff', fieldName: 'ActionStaff', minWidth: 250, maxWidth: 350, isResizable: true, isMultiline: true, isCollapsible: true },
						{ key: '6', name: 'Status', fieldName: 'Status', minWidth: 50, isResizable: true }
					]}
					loadListItems={showClosedEntities => dataServices.corporateRiskMitigationActionService.readAllForList_RiskRegister(showClosedEntities, Number(riskIdForMitigatingActions))}
					mapEntitiesToListItems={riskMitigationActions => riskMitigationActions.map(a => {

						let owner = '';
						if (a.Risk?.RiskRegisterID !== null) {
							switch (a.Risk?.RiskRegisterID) {
								case 0:
									owner = `Dept`;
									break;
								case 1:
									owner = `${orbConfig.OrgL1Label} ${a.Risk?.OwnerL1?.Title ?? ''}`;
									break;
								case 2:
									owner = `${orbConfig.OrgL2Label} ${a.Risk?.OwnerL2?.Title ?? ''}`;
									break;
								case 3:
									owner = `${orbConfig.OrgL3Label} ${a.Risk?.OwnerL3?.Title ?? ''}`;
									break;
								default:
									owner = '';
									break;
							}
						}

						return {
							key: a.ID,
							RiskCode: a.Risk?.RiskCode,
							Code: a.RiskMitigationActionCode,
							Register: owner,
							Risk: a.Risk?.Title,
							Name: a.Title,
							//Owner: a.OwnerUser?.Title,
							//Contributors: a.Contributors?.map(c => c.ContributorUser?.Title).join(', '),
							ActionStaff: [
								a.OwnerUser ? `Owner - ${a.OwnerUser.Title}` : null,
								a.Contributors && a.Contributors.some(c => c.ContributorUser != null) ? `C - ${a.Contributors.map(c => c.ContributorUser?.Title).join(', ')}` : null,
							].filter(Boolean).join(', '),
							Status: a.EntityStatus?.Title
						};
					})}
					preFarItems={(p) => {
						if (riskIdForMitigatingActions !== undefined)
							return ([
								{
									key: 'Risks',
									name: 'Risks',
									iconProps: { iconName: 'MiniExpandMirrored' },
									onClick(ev, item) {
										history.push(`/risk-reporting/risks`);
									},
								},
							]);
						return null;
					}}
					showEntityDescription1={riskIdForMitigatingActions !== undefined ? true : false}
					loadEntityDescription1={() => {
						return dataServices.corporateRiskService.readTitlesForRiskProjectDirectorateAndGroup('Risks mitigating actions', 'Risks mitigating action', Number(riskIdForMitigatingActions), null, true);
					}}
					entityForm={(showForm, entityId, onSaved, onCancelled, entityDescription1) =>
						<RiskMitigationActionForm
							{...props}
							showEntityDescription1={riskIdForMitigatingActions !== undefined ? true : false}
							entityDescription1={entityDescription1}
							defaultValues={riskIdForMitigatingActions !== undefined ? [{ field: 'RiskID', value: Number(riskIdForMitigatingActions) }] : []}
							maxDependentRisks={1}
							showForm={showForm}
							entityId={entityId}
							onSaved={onSaved}
							onCancelled={onCancelled}
						/>
					}
					viewForm={(showForm, entityId, onClosed) =>
						<ProjectRiskMitigationActionView
							{...props}
							showForm={showForm}
							entityId={entityId}
							entityName='Risk mitigating action'
							loadEntity={actionId => dataServices.corporateRiskMitigationActionService.readForView(actionId)}
							onClosed={onClosed}
						/>
					}
					onCheckDelete={id => dataServices.corporateRiskMitigationActionService.entityChildren(id)}
					onDelete={id => dataServices.corporateRiskMitigationActionService.delete(id)}
				/>
			}
			{entity === 'FinancialRisks' &&
				<EntityList<IFinancialRisk>
					{...props}
					enableShowHideClosedEntities={true}
					entityName={{ Plural: 'Financial risks', Singular: 'Financial risk' }}
					addChild={{ Name: 'Risk mitigating action' }}
					columns={[
						{ key: '1', name: 'Risk ID', fieldName: 'RiskCode', minWidth: 70, maxWidth: 70, isResizable: true },
						{ key: '2', name: 'Risk name', fieldName: 'Name', minWidth: 250, isResizable: true },
						{ key: '4', name: 'Group', fieldName: 'Group', minWidth: 100, isResizable: true },
						{ key: '5', name: 'Directorate', fieldName: 'Directorate', minWidth: 100, isResizable: true },
						{ key: '6', name: 'Owner', fieldName: 'Owner', minWidth: lcc.user, isResizable: true },
						{ key: '7', name: 'Contributors', fieldName: 'Contributors', minWidth: lcc.user, isResizable: true },
						{ key: '8', name: 'No. of actions', fieldName: 'NumberOfActions', minWidth: 50, isResizable: true },
						{ key: '9', name: 'Status', fieldName: 'Status', minWidth: 50, isResizable: true }
					]}
					loadListItems={showClosedEntities => dataServices.financialRiskService.readAllForList(showClosedEntities)}
					mapEntitiesToListItems={risks => risks.map(r => (
						{
							key: r.ID,
							RiskCode: r.RiskCode,
							Name: r.Title,
							Group: r.OwnedByMultipleGroups ? `Central` : r.OrgLevel1?.Title,
							Directorate: r.OwnedByMultipleGroups ? `Central` : r.OwnedByDgOffice ? `DG Office` : r.Directorate?.Title,
							Owner: r.RiskOwnerUser?.Title,
							Contributors: r.Contributors?.map(c => c.ContributorUser?.Title).join(', '),
							NumberOfActions: r.FinancialRiskMitigationActions?.length,
							Status: r.EntityStatus?.Title
						}
					))}
					entityForm={(showForm, entityId, onSaved, onCancelled) =>
						<FinancialRiskForm
							{...props}
							showForm={showForm}
							entityId={entityId}
							onSaved={onSaved}
							onCancelled={onCancelled}
						/>
					}
					childEntityForm={(showForm, parentEntityId, onSaved, onCancelled) =>
						<FinancialRiskMitigationActionForm
							{...props}
							showForm={showForm}
							onSaved={onSaved}
							onCancelled={onCancelled}
							defaultValues={[{ field: 'RiskID', value: parentEntityId }]}
						/>
					}
					onCheckDelete={id => dataServices.financialRiskService.entityChildren(id)}
					onDelete={id => dataServices.financialRiskService.delete(id)}
					onChange={() => financialRisks(true)}
				/>
			}
			{entity === 'FinancialRiskMitigationActions' &&
				<EntityList<IFinancialRiskMitigationAction>
					{...props}
					enableShowHideClosedEntities={true}
					entityName={{ Plural: 'Financial risk mitigating actions', Singular: 'Financial risk mitigating action' }}
					columns={[
						{ key: '0', name: 'Risk ID', fieldName: 'RiskCode', minWidth: 50, maxWidth: 70, isResizable: true },
						{ key: '1', name: 'ID', fieldName: 'Code', minWidth: 20, maxWidth: 20, isResizable: true },
						{ key: '2', name: 'Risk mitigating action name', fieldName: 'Name', minWidth: 300, isResizable: true },
						{ key: '3', name: 'Risk', fieldName: 'Risk', minWidth: 200, isResizable: true },
						{ key: '4', name: 'Owner', fieldName: 'Owner', minWidth: lcc.user, isResizable: true },
						{ key: '5', name: 'Contributors', fieldName: 'Contributors', minWidth: lcc.user, isResizable: true },
						{ key: '6', name: 'Status', fieldName: 'Status', minWidth: 50, isResizable: true }
					]}
					loadListItems={showClosedEntities => dataServices.financialRiskMitigationActionService.readAllForList(showClosedEntities)}
					mapEntitiesToListItems={riskMitigationActions => riskMitigationActions.map(a => (
						{
							key: a.ID,
							RiskCode: a.FinancialRisk?.RiskCode,
							Code: a.RiskMitigationActionCode,
							Risk: a.FinancialRisk?.Title,
							Name: a.Title,
							Owner: a.OwnerUser?.Title,
							Contributors: a.Contributors?.map(c => c.ContributorUser?.Title).join(', '),
							Status: a.EntityStatus?.Title
						}
					))}
					entityForm={(showForm, entityId, onSaved, onCancelled) =>
						<FinancialRiskMitigationActionForm
							{...props}
							showForm={showForm}
							entityId={entityId}
							onSaved={onSaved}
							onCancelled={onCancelled}
						/>
					}
					onCheckDelete={id => dataServices.financialRiskMitigationActionService.entityChildren(id)}
					onDelete={id => dataServices.financialRiskMitigationActionService.delete(id)}
				/>
			}
		</ErrorBoundary>
	);
};

export default withErrorHandling(RiskReportingAdmin);
