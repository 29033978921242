import { EntityService } from './EntityService';
import { IEntity } from '../types';

export class CLGenderService extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/CLGenders`);
    }
}