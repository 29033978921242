import { EntityService } from './EntityService';
import { IEntity, IGoElementEvidence } from '../types';

export class GoElementEvidenceService extends EntityService<IGoElementEvidence> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/GoElementEvidences`);
    }

    public readAllByElement(goElementId: number): Promise<IEntity[]> {
        //ne null means not null, cause we only want to get completed uploaded files.
        return this.readAll(`?$orderby=ID&$expand=UploadedByUser&$filter=GoElementId eq ${goElementId} and Title ne null `);
    }
}