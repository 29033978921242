import { EntityService } from './EntityService';
import { IGoMiscFile } from '../types';

export class GoMiscFileService extends EntityService<IGoMiscFile> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/GoMiscFiles`);
    }

    public readAllExpandAll(): Promise<IGoMiscFile[]> {
        //ne null means not null, cause we only want to get completed uploaded files.
        return this.readAll(`?$orderby=ID&$expand=UploadedByUser&$filter=Title ne null`);
    }
}