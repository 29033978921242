import React, { useContext } from 'react';
import { ErrorBoundary } from '../ErrorBoundary';
import { IWithErrorHandlingProps, withErrorHandling } from '../withErrorHandling';
import { IUseApiProps } from '../useApi';
import NaoUpdatesCls from './NaoUpdatesCls';
import { OrbUserContext } from '../OrbUserContext';


export interface INaoUpdatesProps extends IUseApiProps, IWithErrorHandlingProps {
}

const NaoUpdates = (props: INaoUpdatesProps) => {
    const { userPermissions, userContext } = useContext(OrbUserContext);
    return (
        <ErrorBoundary>
            <NaoUpdatesCls
                userPermissions={userPermissions}
                userContext={userContext}
                {...props}
            />
        </ErrorBoundary>
    );

};

export default withErrorHandling(NaoUpdates);
