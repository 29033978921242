import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from '../../styles/cr.module.scss';
import { IWithErrorHandlingProps, withErrorHandling } from '../withErrorHandling';
import { SignOffType } from '../../refData/SignOffType';
import { useParams } from 'react-router-dom';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { CrCommandBar } from '../cr/CrCommandBar';
import { SignOffList } from '../signOff/SignOffList';
import { SignOffPageSettings } from './SignOffPageSettings';
import { Period } from '../../refData/Period';
import { DataContext } from '../DataContext';

interface ProjectSignOffProps extends IWithErrorHandlingProps {
    lastDirectorateIdForChilds?: number;
    apiConnected: boolean;
}


const ProjectSignOff = (props: ProjectSignOffProps) => {

    const { dataServices: { reportDueDatesService } } = useContext(DataContext);

    const { projectIdForUpdates } = useParams<{ projectIdForUpdates: string }>();
    const { requestWorkflowId } = useParams<{ requestWorkflowId: string }>();
    const { requestUpdatePeriod } = useParams<{ requestUpdatePeriod: string }>();

    const [updatePeriod, setUpdatePeriod] = useState<Date>(null);
    const [uniqueUpdatePeriods, setUniqueUpdatePeriods] = useState<Date[]>(null);
    const [showOptionsAndSettings, setShowOptionsAndSettings] = useState<boolean>(false);
    const [showPreviousUpdate, setShowPreviousUpdate] = useState<string>('false');
    const [selectedReportPeriod, setSelectedReportPeriod] = useState<Period>(Period.Current);

    const history = useHistory();

    useEffect(() => {
        setUpdatePeriod(new Date(requestUpdatePeriod));
    }, [requestUpdatePeriod]);

    useEffect(() => {
        const getUniqueDates = async () => {
            if (Number(projectIdForUpdates) > 0) {
                const uniqueDates = await reportDueDatesService.getProjectUpdateUniqueDates(Number(projectIdForUpdates));
                setUniqueUpdatePeriods(uniqueDates);
            }
        }
        getUniqueDates();

    }, [projectIdForUpdates]);

    const commandBarItems: ICommandBarItemProps[] = [];

    if (requestWorkflowId !== undefined) {
        commandBarItems.push({
            key: 'Tasks',
            text: 'Tasks',
            iconProps: { iconName: 'MiniExpandMirrored' },
            onClick: (ev, item) => {
                history.push(`/corporate-workflows/workflow-tasks/${requestWorkflowId}`);
            },
        });
    } else {
        commandBarItems.push({
            key: 'projects',
            text: 'Projects',
            iconProps: { iconName: 'MiniExpandMirrored' },
            onClick: (ev, item) => {
                console.log('lastDirectorateIdForChilds', props.lastDirectorateIdForChilds);
                if (props.lastDirectorateIdForChilds != null) {
                    history.push(`/performance-reporting/projects-for-directorate/${props.lastDirectorateIdForChilds}`);
                }
                else {
                    history.push('/performance-reporting/projects');
                }
            },
        });
    }

    commandBarItems.push({
        key: 'OptionsSettings',
        text: 'Options & Settings',
        iconProps: { iconName: 'MiniExpand' },
        onClick: (ev, item) => {
            console.log('options popup');
            setShowOptionsAndSettings(true);
        },
    });


    const handleChangeShowPreviousUpdate = (value: string) => {
        setShowPreviousUpdate(value);
    }

    const handleChangeSelectedPeriod = (period: Period) => {
        if (period === Period.Current || period === Period.Previous) {
            setUpdatePeriod(null);
        }
        setSelectedReportPeriod(period);

    }

    const handleChangeUpdatePeriod = (dateVal: Date) => {
        if (selectedReportPeriod === Period.Specific) {
            setUpdatePeriod(dateVal);
        }
        else {
            setUpdatePeriod(null);
        }
    }

    return (

        <div className={`${styles.cr} ${styles.updatesContainer}`}>
            <CrCommandBar items={commandBarItems} />
            <div>
                <SignOffList
                    defaultReportType={SignOffType.Project}
                    defaultEntityId={Number(projectIdForUpdates)}
                    defaultUpdatePeriod={updatePeriod}
                    showPreviousUpdate={showPreviousUpdate === 'true'}
                    reportPeriod={selectedReportPeriod}
                    {...props}
                />
            </div>

            <SignOffPageSettings
                showForm={showOptionsAndSettings}
                showPeriodSelection={requestWorkflowId === undefined}
                onClosed={() => setShowOptionsAndSettings(false)}
                showPreviousUpdate={showPreviousUpdate}
                onChangeShowPreviousUpdate={handleChangeShowPreviousUpdate}
                reportPeriod={selectedReportPeriod}
                onChangePeriod={handleChangeSelectedPeriod}
                uniqueUpdatePeriods={uniqueUpdatePeriods}
                updatePeriod={updatePeriod}
                onChangeUpdatePeriod={handleChangeUpdatePeriod}
            />

        </div>

    );
};

export default withErrorHandling(ProjectSignOff);