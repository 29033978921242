import React, { useContext } from 'react';
import { ErrorBoundary } from '../ErrorBoundary';
import { IWithErrorHandlingProps, withErrorHandling } from '../withErrorHandling';
import { IUseApiProps } from '../useApi';
import CaUpdatesCls from './CaUpdatesCls';
import { OrbUserContext } from '../OrbUserContext';

export interface ICaUpdatesProps extends IUseApiProps, IWithErrorHandlingProps {
}

const CaUpdates = (props: ICaUpdatesProps) => {
    const { userPermissions, userContext } = useContext(OrbUserContext);
    return (
        <ErrorBoundary>
            <CaUpdatesCls
                userPermissions={userPermissions}
                userContext={userContext}
                {...props}
            />
        </ErrorBoundary>
    );
};

export default withErrorHandling(CaUpdates);
