import { IReportingEntity, ReportingEntity } from "./ReportingEntity";
import { IDirectorate } from "./Directorate";
import { IUser } from "./User";
import { IWorkStream } from "./WorkStream";
import { IBenefit } from "./Benefit";
import { IDependency } from "./Dependency";
import { IProjectUpdate } from "./ProjectUpdate";
import { IPortfolio } from "./Portfolio";
import { IOrgLevel3 } from "./OrgLevel3";
import { IOrgLevel1 } from "./OrgLevel1";
import { IProjectStage } from "./ProjectStage";
import { IProgramme } from "./Programme";

export interface IProject extends IReportingEntity {
    CustomerID: number | null;
    DirectorateID: number | null;
    ParentProjectID: number | null;
    SeniorResponsibleOwnerUserID: number | null;
    ProjectManagerUserID: number | null;
    Objectives: string;
    StartDate: Date | null;
    EndDate: Date | null;
    ReportApproverUserID: number | null;
    ShowOnDirectorateReport: boolean;
    ReportingLeadUserID: number | null;
    CorporateProjectID: string;
    IntegrationID: string;
    IntegrationLastModified: Date | null;
    DataSource: string | null;
    ParentTypeID?: number;
    PortfolioID?: number;
    ProgrammeID?: number;
    OwnerTypeID?: number;
    OwnerL0ID?: number;
    OwnerL1ID?: number;
    OwnerL2ID?: number;
    OwnerL3ID?: number;
    ManagedByTypeID?: number;
    ManagedByL2ID?: number;
    ManagedByL3ID?: number;
    ProjectStageID?: number;
    ProjectStage?: IProjectStage;
    ManagedByL2?: IDirectorate
    ManagedByL3?: IOrgLevel3;
    OwnerL1?: IOrgLevel1;
    OwnerL2?: IDirectorate;
    OwnerL3?: IOrgLevel3;
    Portfolio?: IPortfolio;
    Programme?: IProgramme;
    Directorate?: IDirectorate; 
    SeniorResponsibleOwnerUser?: IUser;
    ProjectManagerUser?: IUser;
    ReportApproverUser?: IUser;
    ReportingLeadUser?: IUser;
    WorkStreams?: IWorkStream[];
    Benefits?: IBenefit[];
    Dependencies?: IDependency[];
    ProjectUpdates?: IProjectUpdate[];
}

export class Project extends ReportingEntity implements IProject {
    public CustomerID = null;
    public DirectorateID = null;
    public ParentProjectID = null;
    public SeniorResponsibleOwnerUserID = null;
    public ProjectManagerUserID = null;
    public Objectives = '';
    public StartDate = null;
    public EndDate = null;
    public ReportApproverUserID = null;
    public ShowOnDirectorateReport = false;
    public ReportingLeadUserID = null;
    public CorporateProjectID = '';
    public IntegrationID = '';
    public IntegrationLastModified = null;
    public DataSource = null;
    public ParentTypeID = null;
    public PortfolioID = null;
    public ProgrammeID = null;
    public OwnerTypeID = null;
    public OwnerL0ID = null;
    public OwnerL1ID = null;
    public OwnerL2ID = null;
    public OwnerL3ID = null;
    public ManagedByTypeID = null;
    public ManagedByL2ID = null;
    public ManagedByL3ID = null;
    public ProjectStageID = null;
}