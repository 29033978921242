import * as React from 'react';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import * as types from '../../types';
import * as services from '../../services';
import { IGenColumn, ColumnType, ColumnDisplayType } from '../../types/GenColumn';
import { ICAPeriod } from '../../types';
import PeriodList from '../period/PeriodList';
import EntityListCat from '../entity/EntityListCat';
import { CrDropdown } from '../cr/CrDropdown';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import withMobileDetector from '../withMobileDetector';
import { isMobile } from 'react-device-detect';

export interface ICaPropertiesClsProps extends types.IBaseComponentProps {
    isMobile: boolean;
}

export interface ILookupData {
    Periods: ICAPeriod[];
}
export class LookupData implements ILookupData {
    public Periods: ICAPeriod[] = [];
}

export interface ICaPropertiesState {
    LookupData: ILookupData;
    SelectedPeriodDefForms: string | number;
    IsArchivedPeriodDefForms: boolean;
    SelectedPeriodDefElementGroups: string | number;
    IsArchivedPeriodDefElementGroups: boolean;
    SelectedPeriodDefElements: string | number;
    IsArchivedPeriodDefElements: boolean;
}

export class CaPropertiesState implements ICaPropertiesState {
    public LookupData = new LookupData();
    public SelectedPeriodDefForms: string | number = 0;
    public IsArchivedPeriodDefForms = false;
    public SelectedPeriodDefElementGroups: string | number = 0;
    public IsArchivedPeriodDefElementGroups = false;
    public SelectedPeriodDefElements: string | number = 0;
    public IsArchivedPeriodDefElements = false;
    constructor() {
    }
}

class CaPropertiesCls extends React.Component<ICaPropertiesClsProps, CaPropertiesState> {
    private periodService: services.CAPeriodService = new services.CAPeriodService();
    constructor(props: ICaPropertiesClsProps) {
        super(props);
        this.state = new CaPropertiesState();
    }

    public render(): React.ReactElement<ICaPropertiesClsProps> {
        const { isMobile } = this.props;
        return (
            <Pivot onLinkClick={this.props.errorHandling.clearErrors}>
                <PivotItem headerText="Periods">
                    {this.renderCustomPeriodsList()}
                </PivotItem>
                <PivotItem headerText={isMobile ? "Form" : "Define Form"}>
                    {this.renderDefForm()}
                </PivotItem>
                <PivotItem headerText={isMobile ? "Groups" : "Define Element Groups"}>
                    {this.renderDefElementGroups()}
                </PivotItem>
                <PivotItem headerText={isMobile ? "Elements" : "Define Elements"}>
                    {this.renderDefElements()}
                </PivotItem>
                <PivotItem headerText={isMobile ? "Help" : "Define Help"}>
                    {this.renderDefineHelp()}
                </PivotItem>
            </Pivot>
        );
    }

    private renderCustomPeriodsList() {
        const listColumns: IGenColumn[] = [
            {
                key: '1',
                columnType: ColumnType.TextBox,
                name: 'Name',
                fieldName: 'Title',
                minWidth: isMobile ? 50 : 100,
                maxWidth: isMobile ? 60 : 200,
                isMultiline: true,
                isResizable: true,
                isRequired: true,
                fieldMaxLength: 50,
            },
            {
                key: '2',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.ListOnly,
                name: isMobile ? 'Status' : 'Period Status',
                fieldName: 'PeriodStatus',
                minWidth: isMobile ? 50 : 100,
                maxWidth: isMobile ? 60 : 200,
                isMultiline: true,
                isResizable: true,
                fieldMaxLength: 50,
            },
            {
                key: '3',
                columnType: ColumnType.DatePicker,
                name: isMobile ? 'Start' : 'Start Date',
                fieldName: 'PeriodStartDate',
                minWidth: isMobile ? 60 : 100,
                maxWidth: isMobile ? 70 : 150,
                isCollapsible: true,
                isResizable: true,
                isRequired: true,
                fieldMaxLength: 50,
            },
            {
                key: '4',
                columnType: ColumnType.DatePicker,
                name: isMobile ? 'End' : 'End Date',
                fieldName: 'PeriodEndDate',
                minWidth: isMobile ? 60 : 100,
                maxWidth: isMobile ? 70 : 150,
                isCollapsible: true,
                isResizable: true,
                isRequired: true,
                fieldMaxLength: 50,
            },
        ];

        return (
            <React.Fragment>
                <PeriodList
                    allowAdd={this.allowAdd_Periods()}
                    columns={listColumns}
                    {...this.props}
                    errorHandling={this.props.errorHandling}
                    entityService={new services.CAPeriodService()}
                    entityNamePlural="Periods"
                    entityNameSingular="Period"
                    childEntityNameApi=""
                    childEntityNamePlural=""
                    childEntityNameSingular=""
                />

            </React.Fragment>
        );
    }

    private renderDefineHelp() {

        const listColumns: IGenColumn[] = [
            {
                key: '1',
                columnType: ColumnType.TextBox,
                name: 'Name',
                fieldName: 'Title',
                minWidth: 300,
                isResizable: true,
                isRequired: true,
                fieldMaxLength: 50
            },
            {
                key: '2',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Help Text',
                fieldName: 'HelpText',
                minWidth: 300,
                isRequired: false,
                fieldMaxLength: 5000,
                numRows: 10
            },
        ];

        return (
            <React.Fragment>
                <EntityListCat
                    allowAdd={this.allowAdd_Periods()}
                    columns={listColumns}
                    {...this.props}
                    errorHandling={this.props.errorHandling}
                    entityService={new services.UserHelpService()}
                    entityNamePlural="Define Help"
                    entityNameSingular="Define Help"
                    childEntityNameApi=""
                    childEntityNamePlural=""
                    childEntityNameSingular=""
                />
            </React.Fragment>
        );
    }

    private renderDefForm() {

        const listColumns: IGenColumn[] = [
            {
                key: 'DefFormPeriod',
                fieldDisabled: true,
                fieldDefaultValue: this.state.SelectedPeriodDefForms,
                columnType: ColumnType.DropDown,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Period',
                fieldName: 'DefFormPeriod',
                idFieldName: 'PeriodId',
                isParent: true,
                parentEntityName: 'Period',
                parentColumnName: 'Title',
                parentService: new services.CAPeriodService(),
                minWidth: 150,
                isResizable: true,
                isRequired: true,
            },
            {
                key: '1',
                columnType: ColumnType.TextBox,
                name: 'Welcome Text Heading',
                fieldName: 'Title',
                minWidth: 300,
                isResizable: true,
                isMultiline: true,
                isRequired: true,
                fieldMaxLength: 100
            },
            {
                key: '2',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Welcome Text',
                fieldName: 'Details',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 5000,
                numRows: 10
            },
            {
                key: '3',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'DD Sign Off Title',
                fieldName: 'DDSignOffTitle',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 100
            },
            {
                key: '4',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'DD Sign Off Text',
                fieldName: 'DDSignOffText',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 4
            },
            {
                key: '5',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Director Sign Off Title',
                fieldName: 'DirSignOffTitle',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 100
            },
            {
                key: '6',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Director Sign Off Text',
                fieldName: 'DirSignOffText',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 4
            }
        ];

        const { LookupData: lookups } = this.state;
        return (
            lookups.Periods &&
            <React.Fragment>
                <CrDropdown
                    placeholder="Select a Period"
                    style={{ marginTop: "10px", marginRight: "24px" }}
                    options={lookups.Periods.map((p) => { return { key: p.ID, text: p.Title }; })}
                    onChange={(_, v) => this.changePeriodsDropdown_DefForms(v)}
                    selectedKey={this.state.SelectedPeriodDefForms}
                />
                <EntityListCat
                    entityReadAllWithArg1={this.state.SelectedPeriodDefForms}
                    allowAdd={this.allowAdd_DefForms()}
                    columns={listColumns}
                    {...this.props}
                    errorHandling={this.props.errorHandling}
                    entityService={new services.CADefFormService()}
                    entityNamePlural="Define Forms"
                    entityNameSingular="Define Form"
                    childEntityNameApi="DefElementGroups"
                    childEntityNamePlural="Define Element Groups"
                    childEntityNameSingular="Define Element Group"
                />
            </React.Fragment>
        );
    }

    private renderDefElementGroups() {

        const listColumns: IGenColumn[] = [
            {
                key: 'DefElementGroupPeriod',
                fieldDisabled: true,
                fieldDefaultValue: this.state.SelectedPeriodDefElementGroups,
                columnType: ColumnType.DropDown,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Period',
                fieldName: 'DefElementGroupPeriod',
                idFieldName: 'PeriodId',
                isParent: true,
                parentEntityName: 'Period',
                parentColumnName: 'Title',
                parentService: new services.CAPeriodService(),
                minWidth: 150,
                isResizable: true,
                isRequired: true,
            },
            {
                key: '1',
                columnType: ColumnType.TextBox,
                name: isMobile ? 'Group' : 'Element Group Title',
                fieldName: 'Title',
                minWidth: isMobile ? 80 : 300,
                maxWidth: isMobile ? 150 : 500,
                isMultiline: true,
                isResizable: true,
                isRequired: true,
                fieldMaxLength: 100
            },
            {
                key: '2',
                columnType: ColumnType.TextBox,
                name: isMobile ? 'Sequence' : 'Element Group Sequence',
                fieldName: 'Sequence',
                minWidth: isMobile ? 50 : 300,
                maxWidth: isMobile ? 100 : 300,
                isResizable: true,
                isRequired: true,
                fieldMaxLength: 100
            },
            {
                key: '3',
                columnType: ColumnType.DropDown,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Def Form',
                fieldName: 'DefForm',
                idFieldName: 'DefFormId',
                isParent: true,
                parentEntityName: 'DefForm',
                parentColumnName: 'Title',
                parentService: new services.CADefFormService(),
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 10
            }
        ];

        const { LookupData: lookups } = this.state;

        return (
            lookups.Periods &&
            <React.Fragment>
                <CrDropdown
                    placeholder="Select a Period"
                    style={{ marginTop: "10px", marginRight: "24px" }}
                    options={lookups.Periods.map((p) => { return { key: p.ID, text: p.Title }; })}
                    onChanged={(v) => this.changePeriodsDropdown_DefElementGroups(v)}
                    selectedKey={this.state.SelectedPeriodDefElementGroups}
                />
                <EntityListCat
                    allowAdd={this.allowAdd_DefElementGroups()}
                    entityReadAllWithArg1={this.state.SelectedPeriodDefElementGroups}
                    columns={listColumns}
                    {...this.props}
                    errorHandling={this.props.errorHandling}
                    entityService={new services.CADefElementGroupService()}
                    entityNamePlural="Define Element Groups"
                    entityNameSingular="Define Element Group"
                    childEntityNameApi="DefElements"
                    childEntityNamePlural="Define Elements"
                    childEntityNameSingular="Define Element"
                />
            </React.Fragment>
        );
    }

    private renderDefElements() {

        const listColumns: IGenColumn[] = [
            {
                key: 'DefElementPeriod',
                fieldDisabled: true,
                fieldDefaultValue: this.state.SelectedPeriodDefElements,
                columnType: ColumnType.DropDown,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Period',
                fieldName: 'DefElementPeriod',
                idFieldName: 'PeriodId',
                isParent: true,
                parentEntityName: 'Period',
                parentColumnName: 'Title',
                parentService: new services.CAPeriodService(),
                minWidth: 150,
                isResizable: true,
                isRequired: true,
            },
            {
                key: '1',
                columnType: ColumnType.TextBox,
                name: 'Element Title',
                fieldName: 'Title',
                minWidth: isMobile ? 200 : 300,
                maxWidth: isMobile ? 350 : 500,
                isMultiline: true,
                isResizable: true,
                isRequired: true,
                fieldMaxLength: 100
            },
            {
                key: '2',
                columnType: ColumnType.TextBox,
                name: 'Element Objective',
                fieldName: 'ElementObjective',
                minWidth: 300,
                maxWidth: 500,
                isMultiline: true,
                isResizable: true,
                isRequired: true,
                isCollapsible: true,
                fieldMaxLength: 5000,
                numRows: 3
            },
            {
                key: '45',
                columnType: ColumnType.DropDown,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Elements Group',
                fieldName: 'ElementsGroup',
                idFieldName: 'DefElementGroupId',
                isParent: true,
                parentEntityName: 'DefElementGroup',
                parentColumnName: 'Title',
                parentService: new services.CADefElementGroupService(),
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 10

            },
            {
                key: '3',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Title',
                fieldName: 'SectionATitle',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000

            },
            {
                key: '4',
                columnType: ColumnType.TagPicker,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Title Help',
                fieldName: 'SectionATitleHelp',
                idFieldName: 'SectionATitleHelpId',
                isParent: true,
                parentEntityName: 'UserHelp',
                parentColumnName: 'Title',
                parentService: new services.UserHelpService(),
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 10

            },
            {
                key: '5',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 1',
                fieldName: 'SectionAQuestion1',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2

            },
            {
                key: 'SectionAQ1ResponseDetails',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 1 Response Details',
                fieldName: 'SectionAQ1ResponseDetails',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2

            },
            {
                key: '6',
                columnType: ColumnType.TagPicker,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 1 Help',
                fieldName: 'SectionAQuestion1Help',
                idFieldName: 'SectionAQuestion1HelpId',
                isParent: true,
                parentEntityName: 'UserHelp',
                parentColumnName: 'Title',
                parentService: new services.UserHelpService(),
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000

            },
            {
                key: '7',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 2',
                fieldName: 'SectionAQuestion2',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2

            },
            {
                key: 'SectionAQ2ResponseDetails',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 2 Response Details',
                fieldName: 'SectionAQ2ResponseDetails',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2

            },
            {
                key: '8',
                columnType: ColumnType.TagPicker,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 2 Help',
                fieldName: 'SectionAQuestion2Help',
                idFieldName: 'SectionAQuestion2HelpId',
                isParent: true,
                parentEntityName: 'UserHelp',
                parentColumnName: 'Title',
                parentService: new services.UserHelpService(),
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000

            },
            {
                key: '9',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 3',
                fieldName: 'SectionAQuestion3',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2

            },
            {
                key: 'SectionAQ3ResponseDetails',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 3 Response Details',
                fieldName: 'SectionAQ3ResponseDetails',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2

            },
            {
                key: '10',
                columnType: ColumnType.TagPicker,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 3 Help',
                fieldName: 'SectionAQuestion3Help',
                idFieldName: 'SectionAQuestion3HelpId',
                isParent: true,
                parentEntityName: 'UserHelp',
                parentColumnName: 'Title',
                parentService: new services.UserHelpService(),
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000

            },
            {
                key: '11',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 4',
                fieldName: 'SectionAQuestion4',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2
            },
            {
                key: 'SectionAQ4ResponseDetails',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 4 Response Details',
                fieldName: 'SectionAQ4ResponseDetails',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2

            },
            {
                key: '12',
                columnType: ColumnType.TagPicker,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 4 Help',
                fieldName: 'SectionAQuestion4Help',
                idFieldName: 'SectionAQuestion4HelpId',
                isParent: true,
                parentEntityName: 'UserHelp',
                parentColumnName: 'Title',
                parentService: new services.UserHelpService(),
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000
            },
            {
                key: '13',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 5',
                fieldName: 'SectionAQuestion5',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2
            },
            {
                key: 'SectionAQ5ResponseDetails',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 5 Response Details',
                fieldName: 'SectionAQ5ResponseDetails',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2

            },
            {
                key: '14',
                columnType: ColumnType.TagPicker,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 5 Help',
                fieldName: 'SectionAQuestion5Help',
                idFieldName: 'SectionAQuestion5HelpId',
                isParent: true,
                parentEntityName: 'UserHelp',
                parentColumnName: 'Title',
                parentService: new services.UserHelpService(),
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000
            },
            {
                key: '15',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 6',
                fieldName: 'SectionAQuestion6',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2
            },
            {
                key: 'SectionAQ6ResponseDetails',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 6 Response Details',
                fieldName: 'SectionAQ6ResponseDetails',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2
            },
            {
                key: '16',
                columnType: ColumnType.TagPicker,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 6 Help',
                fieldName: 'SectionAQuestion6Help',
                idFieldName: 'SectionAQuestion6HelpId',
                isParent: true,
                parentEntityName: 'UserHelp',
                parentColumnName: 'Title',
                parentService: new services.UserHelpService(),
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000

            },
            {
                key: '17',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 7',
                fieldName: 'SectionAQuestion7',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2
            },
            {
                key: 'SectionAQ7ResponseDetails',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 7 Response Details',
                fieldName: 'SectionAQ7ResponseDetails',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2
            },
            {
                key: '18',
                columnType: ColumnType.TagPicker,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 7 Help',
                fieldName: 'SectionAQuestion7Help',
                idFieldName: 'SectionAQuestion8HelpId',
                isParent: true,
                parentEntityName: 'UserHelp',
                parentColumnName: 'Title',
                parentService: new services.UserHelpService(),
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000
            },
            {
                key: '19',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 8',
                fieldName: 'SectionAQuestion8',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2
            },
            {
                key: 'SectionAQ8ResponseDetails',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 8 Response Details',
                fieldName: 'SectionAQ8ResponseDetails',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2
            },
            {
                key: '20',
                columnType: ColumnType.TagPicker,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 8 Help',
                fieldName: 'SectionAQuestion8Help',
                idFieldName: 'SectionAQuestion8HelpId',
                isParent: true,
                parentEntityName: 'UserHelp',
                parentColumnName: 'Title',
                parentService: new services.UserHelpService(),
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000
            },
            {
                key: '21',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 9',
                fieldName: 'SectionAQuestion9',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2
            },
            {
                key: 'SectionAQ9ResponseDetails',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 9 Response Details',
                fieldName: 'SectionAQ9ResponseDetails',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2
            },
            {
                key: '22',
                columnType: ColumnType.TagPicker,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 9 Help',
                fieldName: 'SectionAQuestion9Help',
                idFieldName: 'SectionAQuestion9HelpId',
                isParent: true,
                parentEntityName: 'UserHelp',
                parentColumnName: 'Title',
                parentService: new services.UserHelpService(),
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000
            },
            {
                key: '23',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 10',
                fieldName: 'SectionAQuestion10',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2
            },
            {
                key: 'SectionAQ10ResponseDetails',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 10 Response Details',
                fieldName: 'SectionAQ10ResponseDetails',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2
            },
            {
                key: '24',
                columnType: ColumnType.TagPicker,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Question 10 Help',
                fieldName: 'SectionAQuestion10Help',
                idFieldName: 'SectionAQuestion10HelpId',
                isParent: true,
                parentEntityName: 'UserHelp',
                parentColumnName: 'Title',
                parentService: new services.UserHelpService(),
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000
            },
            {
                key: '25',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Other Question',
                fieldName: 'SectionAOtherQuestion',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2
            },
            {
                key: '26',
                columnType: ColumnType.TagPicker,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Other Question Help',
                fieldName: 'SectionAOtherQuestionHelp',
                idFieldName: 'SectionAOtherQuestionHelpId',
                isParent: true,
                parentEntityName: 'UserHelp',
                parentColumnName: 'Title',
                parentService: new services.UserHelpService(),
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000
            },
            {
                key: '27',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Faded note in Other Question Textbox',
                fieldName: 'SectionAOtherBoxText',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2
            },
            {
                key: '28',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Effectiveness Question',
                fieldName: 'SectionAEffectQuestion',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2
            },
            {
                key: '29',
                columnType: ColumnType.TagPicker,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Effectiveness Question Help',
                fieldName: 'SectionAEffectQuestionHelp',
                idFieldName: 'SectionAEffectQuestionHelpId',
                isParent: true,
                parentEntityName: 'UserHelp',
                parentColumnName: 'Title',
                parentService: new services.UserHelpService(),
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000
            },
            {
                key: '30',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section A: Effectiveness Details Question ',
                fieldName: 'SectionAEffectBoxText',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000,
                numRows: 2
            },
            {
                key: '31',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section B: Title',
                fieldName: 'SectionBTitle',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000
            },
            {
                key: '32',
                columnType: ColumnType.TagPicker,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section B: Title Help',
                fieldName: 'SectionBTitleHelp',
                idFieldName: 'SectionBTitleHelpId',
                isParent: true,
                parentEntityName: 'UserHelp',
                parentColumnName: 'Title',
                parentService: new services.UserHelpService(),
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 10
            },
            {
                key: '33',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section B: Question 1',
                fieldName: 'SectionBQuestion1',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000
            },
            {
                key: '34',
                columnType: ColumnType.TagPicker,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section B: Question 1 Help',
                fieldName: 'SectionBQuestion1Help',
                idFieldName: 'SectionBQuestion1HelpId',
                isParent: true,
                parentEntityName: 'UserHelp',
                parentColumnName: 'Title',
                parentService: new services.UserHelpService(),
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 10
            },
            {
                key: '35',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section B: Question above Details Box 1',
                fieldName: 'SectionBBoxText1',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000
            },
            {
                key: '36',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section B: Question above Effectiveness Choice 1',
                fieldName: 'SectionBEffect1',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000
            },
            {
                key: '37',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section B: Question 2',
                fieldName: 'SectionBQuestion2',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000
            },
            {
                key: '38',
                columnType: ColumnType.TagPicker,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section B: Question 2 Help',
                fieldName: 'SectionBQuestion2Help',
                idFieldName: 'SectionBQuestion2HelpId',
                isParent: true,
                parentEntityName: 'UserHelp',
                parentColumnName: 'Title',
                parentService: new services.UserHelpService(),
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 10
            },
            {
                key: '39',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section B: Question above Details Box 2',
                fieldName: 'SectionBBoxText2',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000
            },
            {
                key: '40',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section B: Question above Effectiveness Choice 2',
                fieldName: 'SectionBEffect2',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000
            },
            {
                key: '41',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section B: Question 3',
                fieldName: 'SectionBQuestion3',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000
            },
            {
                key: '42',
                columnType: ColumnType.TagPicker,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section B: Question 3 Help',
                fieldName: 'SectionBQuestion3Help',
                idFieldName: 'SectionBQuestion3HelpId',
                isParent: true,
                parentEntityName: 'UserHelp',
                parentColumnName: 'Title',
                parentService: new services.UserHelpService(),
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 10
            },
            {
                key: '43',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section B: Question above Details Box 3',
                fieldName: 'SectionBBoxText3',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000
            },
            {
                key: '44',
                columnType: ColumnType.TextBox,
                columnDisplayType: ColumnDisplayType.FormOnly,
                name: 'Section B: Question above Effectiveness Choice 3',
                fieldName: 'SectionBEffect3',
                minWidth: 300,
                isResizable: true,
                isRequired: false,
                fieldMaxLength: 1000
            }
        ];

        const { LookupData: lookups } = this.state;

        return (
            <React.Fragment>
                <CrDropdown
                    placeholder="Select a Period"
                    style={{ marginTop: "10px", marginRight: "24px" }}
                    options={lookups.Periods.map((p) => { return { key: p.ID, text: p.Title }; })}
                    onChanged={(v) => this.changePeriodsDropdown_DefElements(v)}
                    selectedKey={this.state.SelectedPeriodDefElements}
                />
                <EntityListCat
                    allowAdd={this.allowAdd_DefElements()}
                    entityReadAllWithArg1={this.state.SelectedPeriodDefElements}
                    columns={listColumns}
                    {...this.props}
                    errorHandling={this.props.errorHandling}
                    entityService={new services.CADefElementService()}
                    entityNamePlural="Define Elements"
                    entityNameSingular="Define Element"
                    childEntityNameApi="Elements"
                    childEntityNamePlural="Elements"
                    childEntityNameSingular="Element"
                />
            </React.Fragment>
        );
    }

    private superUserPermission(): boolean {
        return this.props.userPermissions.UserIsSystemAdmin()
            || this.props.userPermissions.UserIsControlsAssuranceSuperUser();
    }

    private allowAdd_Periods(): boolean {
        return this.superUserPermission();
    }

    private allowAdd_DefForms(): boolean {

        if (this.state.IsArchivedPeriodDefForms === false) {
            return this.superUserPermission();
        }
        return false;
    }

    private allowAdd_DefElementGroups(): boolean {

        if (this.state.IsArchivedPeriodDefElementGroups === false) {
            return this.superUserPermission();
        }
        return false;
    }

    private allowAdd_DefElements(): boolean {

        if (this.state.IsArchivedPeriodDefElements === false) {
            return this.superUserPermission();
        }
        return false;
    }

    private loadPeriods = (): Promise<void | ICAPeriod[]> => {
        return this.periodService.readAll().then((pArr: ICAPeriod[]): ICAPeriod[] => {
            //get the current period
            let currentPeriodId: number = 0;
            const currentPeriod = pArr.filter(p => p.PeriodStatus === "Current Period");
            if (currentPeriod && currentPeriod.length > 0) {
                currentPeriodId = currentPeriod[0].ID;
            }

            //show status like Qtr 2 2019 ( Current Period ) in Title
            for (let i = 0; i < pArr.length; i++) {
                let p: ICAPeriod = pArr[i];
                pArr[i].Title = `${p.Title} ( ${p.PeriodStatus} )`;
            }

            this.setState({
                LookupData: this.cloneObject(this.state.LookupData, 'Periods', pArr),
                SelectedPeriodDefForms: currentPeriodId,
                SelectedPeriodDefElementGroups: currentPeriodId,
                SelectedPeriodDefElements: currentPeriodId
            });
            return pArr;
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading Periods lookup data`, err.message); });
    }

    private loadLookups(): Promise<any> {

        return Promise.all([
            this.loadPeriods(),
        ]);
    }

    public componentDidMount(): void {
        //this.setState({ Loading: true } );
        this.loadLookups();
    }

    private changePeriodsDropdown_DefForms = (option: IDropdownOption): void => {
        const pArrTemp: ICAPeriod[] = this.state.LookupData.Periods.filter(p => p.ID === option.key);
        let isArchivedPeriod: boolean = false;
        if (pArrTemp.length > 0) {
            if (pArrTemp[0].PeriodStatus === "Archived Period") {
                isArchivedPeriod = true;
            }
        }
        this.setState({
            SelectedPeriodDefForms: option.key,
            IsArchivedPeriodDefForms: isArchivedPeriod
        });
    }

    private changePeriodsDropdown_DefElementGroups = (option: IDropdownOption): void => {

        const pArrTemp: ICAPeriod[] = this.state.LookupData.Periods.filter(p => p.ID === option.key);
        let isArchivedPeriod: boolean = false;
        if (pArrTemp.length > 0) {
            if (pArrTemp[0].PeriodStatus === "Archived Period") {
                isArchivedPeriod = true;
            }
        }
        this.setState({
            SelectedPeriodDefElementGroups: option.key,
            IsArchivedPeriodDefElementGroups: isArchivedPeriod
        });

    }

    private changePeriodsDropdown_DefElements = (option: IDropdownOption): void => {
        const pArrTemp: ICAPeriod[] = this.state.LookupData.Periods.filter(p => p.ID === option.key);
        let isArchivedPeriod: boolean = false;
        if (pArrTemp.length > 0) {
            if (pArrTemp[0].PeriodStatus === "Archived Period") {
                isArchivedPeriod = true;
            }
        }

        this.setState({
            SelectedPeriodDefElements: option.key,
            IsArchivedPeriodDefElements: isArchivedPeriod
        });
    }

    private cloneObject(obj, changeProp?, changeValue?) {
        if (changeProp)
            return { ...obj, [changeProp]: changeValue };
        return { ...obj };
    }
}

export default withMobileDetector(CaPropertiesCls);
