import React from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { CrTextField } from '../cr/CrTextField';
import { CrLabel } from './CrLabel';

export interface ICrMultiDropdownWithTextOption extends IDropdownOption {
    textRequired: boolean;
}
export interface ICrMultiDropdownWithTextValue {
    Key: number;
    Text: string | null;
}

export interface ICrMultiDropdownWithTextProps {
    label?: string;
    className?: string;
    options: ICrMultiDropdownWithTextOption[];
    selectedItems?: ICrMultiDropdownWithTextValue[];
    disabled?: boolean;
    textMaxLength?: number;
    onChange?: (value: ICrMultiDropdownWithTextValue[]) => void;
}

export const CrMultiDropdownWithText = ({ className, label, options, selectedItems, textMaxLength, onChange }: ICrMultiDropdownWithTextProps): React.ReactElement => {

    const _onDropdownChange = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption<any>,
        index?: number
    ): void => {
        if (!option) return;

        let updatedSelectedItems: ICrMultiDropdownWithTextValue[] = JSON.parse(JSON.stringify(selectedItems));
        if (option.selected) {
            updatedSelectedItems.push({ Key: option.key as number, Text: null });
        } else {
            updatedSelectedItems = updatedSelectedItems.filter(i => i.Key !== option.key);
        }
        if (onChange) onChange(updatedSelectedItems);
    };


    const _onTextFieldChanged = (value: string, key: string | number): void => {
        const v: ICrMultiDropdownWithTextValue[] = JSON.parse(JSON.stringify(selectedItems));
        v.forEach(i => {
            if (i.Key === key) i.Text = value;
        });
        if (onChange) onChange(v);
    };

    return (
        <div className={className}>
            <Dropdown
                onRenderLabel={() =>
                    label ? <CrLabel text={label} icon="Dropdown" /> : null
                }
                multiSelect
                options={options}
                selectedKeys={selectedItems ? selectedItems.map(i => i.Key) : undefined}
                onChange={_onDropdownChange}
            />
            {selectedItems && selectedItems.map(d => {
                const option = options.filter(o => o.key === d.Key)[0];
                return (
                    <CrTextField
                        key={d.Key}
                        style={{ marginLeft: '30px' }}
                        label={option?.text}
                        maxLength={textMaxLength}
                        value={d?.Text ?? ''}
                        onChange={(event, newValue) => {
                            if (newValue !== undefined) {
                                _onTextFieldChanged(newValue, d.Key);
                            }
                        }}
                    />

                );
            })}
        </div>
    );
};
