import * as React from 'react';
import * as types from '../../types';
import * as services from '../../services';
import { FilteredReport2List } from './FilteredReport2List';
import { IEntity } from '../../types';
import { IUpdatesListColumn, ColumnDisplayTypes } from '../../types/UpdatesListColumn';
import { CrLoadingOverlay } from '../cr/CrLoadingOverlay';
import { ConfirmDialog } from '../cr/ConfirmDialog';
import { IObjectWithKey, Selection } from '@fluentui/react/lib/DetailsList';
import styles from '../../styles/cr.module.scss';

export interface IReport2ListProps extends types.IBaseComponentProps {
    filterText?: string;
    onChangeFilterText: (value: string) => void;
}

export interface IReport2ListState<T> {
    SelectedEntity: number;
    SelectedEntityTitle: string;
    EnableCreatePdf?: boolean;
    EnableDeletePdf?: boolean;
    EnableDownloadPdf?: boolean;
    PDFStatus?: string;
    FileUniqueID?: string;
    SelectedEntityChildren: number;
    ShowForm: boolean;
    ShowManagePeriodForm: boolean;
    EnableEdit?: boolean;
    EnableDelete?: boolean;
    HideDeleteDialog: boolean;
    ShowChildForm: boolean;
    CurrentPage?: number;
    NextPageAvailable?: boolean;
    Entities: T[];
    Loading: boolean;
    ListFilterText?: string;
    InitDataLoaded: boolean;
}
export class Report2ListState<T> implements IReport2ListState<T> {
    public SelectedEntity = null;
    public SelectedEntityTitle: string = null;
    public EnableCreatePdf = false;
    public EnableDeletePdf = false;
    public EnableDownloadPdf = false;
    public PDFStatus = "";
    public FileUniqueID = "";
    public SelectedEntityChildren = null;
    public ShowForm = false;
    public ShowManagePeriodForm = false;
    public HideDeleteDialog = true;
    public EnableEdit = false;
    public EnableDelete = false;
    public ShowChildForm = false;
    public CurrentPage = 1;
    public NextPageAvailable = false;
    public Entities: T[] = [];
    public Loading = false;
    public ListFilterText = null;
    public InitDataLoaded = false;
}

export default class Report2List extends React.Component<IReport2ListProps, IReport2ListState<IEntity>> {
    private _selection: Selection;
    private naoPublicationService: services.NAOPublicationService = new services.NAOPublicationService();
    private naoOutput2Service: services.NAOOutput2Service = new services.NAOOutput2Service();
    private zFileService: services.ZFileService = new services.ZFileService();

    private listColumns: IUpdatesListColumn[] = [
        {
            key: 'ID',
            name: 'ID',
            fieldName: 'ID',
            minWidth: 1,
            isResizable: true,
            columnDisplayType: ColumnDisplayTypes.Hidden,
        },
        {
            key: 'Title',
            name: 'Title',
            fieldName: 'Title',
            minWidth: 100,
            maxWidth: 330,
            isResizable: true,
            isMultiline: true,
            headerClassName: styles.bold,
        },
        {
            key: 'DGArea',
            name: 'DGArea(s)',
            fieldName: 'DGArea',
            minWidth: 145,
            maxWidth: 145,
            isResizable: true,
            isMultiline: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },
        {
            key: 'Type',
            name: 'Type',
            fieldName: 'Type',
            minWidth: 90,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },

        {
            key: 'Year',
            name: 'Year',
            fieldName: 'Year',
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },

        {
            key: 'CompletePercent',
            name: 'Implemented',
            fieldName: 'CompletePercent',
            minWidth: 72,
            maxWidth: 72,
            isResizable: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },

        {
            key: 'AssignedTo',
            name: 'Assigned To',
            fieldName: 'AssignedTo',
            minWidth: 120,
            maxWidth: 120,
            isMultiline: true,
            isResizable: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },

        {
            key: 'PeriodEnd',
            name: 'Period End',
            fieldName: 'PeriodEnd',
            minWidth: 70,
            maxWidth: 70,
            isResizable: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },


        {
            key: 'CurrentPeriodId',
            name: 'CurrentPeriodId',
            fieldName: 'CurrentPeriodId',
            minWidth: 1,
            isResizable: true,
            columnDisplayType: ColumnDisplayTypes.Hidden,
        },
    ];

    constructor(props: IReport2ListProps, state: IReport2ListState<IEntity>) {
        super(props);
        this.state = new Report2ListState<IEntity>();
        this._selection = new Selection({
            onSelectionChanged: () => {

                this.manageEnableDisableCreatePDF();
            }
        });
    }

    public render(): React.ReactElement<IReport2ListProps> {
        return (
            <div className={`${styles.cr}`}>
                <div style={{ position: 'relative' }}>
                    <CrLoadingOverlay isLoading={this.state.Loading} />
                    {this.renderList()}
                    {this.renderStatus()}
                    <ConfirmDialog hidden={this.state.HideDeleteDialog} title={`Are you sure you want to delete ${this.getSelectedEntityName()}?`} content={`A deleted record cannot be un-deleted.`} confirmButtonText="Delete" handleConfirm={this.deleteRecord} handleCancel={this.toggleDeleteConfirm} />
                </div>
            </div>
        );
    }

    private renderList() {
        const listColumns = this.getColumns();
        const listColumnsForData = this.getColumnsForData();
        let items: IObjectWithKey[] = this.state.Entities.map((e) => { return this.makeItem(e, listColumnsForData); });

        return (
            <FilteredReport2List
                columns={listColumns}
                items={items}
                filterText={this.props.filterText}
                onFilterChange={this.props.onChangeFilterText}
                selection={this._selection}
                onCreatePdf={this.handleCreatePdf}
                onDeletePdf={this.handleDeletePdf}
                onDownloadPdf={this.handleDownloadPdf}
                createPdfDisabled={!this.state.EnableCreatePdf}
                deletePdfDisabled={!this.state.EnableDeletePdf}
                downloadPdfDisabled={!this.state.EnableDownloadPdf}
            />
        );
    }

    private renderStatus() {
        let statusMsg = this.state.PDFStatus;
        return (
            <div style={{ paddingTop: '25px' }}>
                <div>{statusMsg}</div>
                {statusMsg === "Working... Please Wait" &&
                    <div>
                        <span style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }} onClick={this.loadPDFStatus} >Click to Refresh Status</span>
                    </div>
                }
            </div>
        );
    }

    private makeItem = (e: IEntity, listColumns: IUpdatesListColumn[]): any => {
        let item: any = { key: e["ID"] };
        listColumns.map((c) => {
            let fieldContent: string = String(e[c.fieldName]);
            item = {
                [c.fieldName]: fieldContent,
                ...item
            };
        });
        return item;
    }

    private getColumns(): IUpdatesListColumn[] {
        let listColumns: IUpdatesListColumn[];
        listColumns = this.listColumns.filter(c => c.columnDisplayType !== ColumnDisplayTypes.Hidden);
        return listColumns;
    }

    private getColumnsForData(): IUpdatesListColumn[] {
        const listColumns: IUpdatesListColumn[] = this.listColumns;
        return listColumns;
    }

    private getSelectedEntityName = (): string => {
        let entity = this.state.Entities.filter((e) => { return e.ID === this.state.SelectedEntity; });
        return entity[0] ? entity[0].Title : null;
    }

    private toggleDeleteConfirm = (): void => {
        this.setState({ HideDeleteDialog: !this.state.HideDeleteDialog });
    }

    private deleteRecord = (): void => {

    }

    private manageEnableDisableCreatePDF = (): void => {
        if (this._selection.getSelectedCount() > 0) {
            if (this.state.PDFStatus.search("Working... Please Wait") === 0) {
                this.setState({ EnableCreatePdf: false });
            }
            else {
                this.setState({ EnableCreatePdf: true });
            }
        }
        else {
            this.setState({ EnableCreatePdf: false });
        }
    }

    private loadData = (): void => {
        this.setState({ Loading: true });
        const read: Promise<IEntity[]> = this.naoPublicationService.readAllWithFilters(0, false, false, false);
        read.then((entities: any): void => {
            this.setState({
                Loading: false, Entities: entities,
            });

        }, (err) => this.errorLoadingData(err));
    }

    private loadPDFStatus = (): void => {
        this.naoOutput2Service.getPDFStatus().then((res: any): void => {

            const pdfStatus: string = res['PdfStatus'];
            const fileUniqueID: string = res['FileUniqueID'];

            let pdfAvailable: boolean = false;
            if (pdfStatus.search("Last PDF generated by") === 0) {
                //pdf generated, allow user to download+delete
                pdfAvailable = true;
            }
            this.setState({
                PDFStatus: pdfStatus,
                FileUniqueID: fileUniqueID,
                EnableDownloadPdf: pdfAvailable,
                EnableDeletePdf: pdfAvailable,
            }, this.manageEnableDisableCreatePDF);


        }, (err) => {
            if (this.props.errorHandling?.onError)
                this.props.errorHandling?.onError(`Error creating PDF`, err.message);
        });
    }

    private errorLoadingData = (err: any, entityName?: string): void => {
        this.setState({ Loading: false });
        if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading ${entityName || 'items'}`, err.message);
    }
    public componentDidMount(): void {
        this.loadData();
        this.loadPDFStatus();
    }

    private handleCreatePdf = (filteredItems: any[]): void => {
        if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(null);

        const selection = this._selection.getSelection();
        let selectedIds: number[] = [];

        for (let sel of selection) {
            const sel_id: number = Number(sel["ID"]);
            selectedIds.push(sel_id);
        }

        const publicationIds: string = selectedIds.join(',');
        this.naoOutput2Service.createPDF(publicationIds).then((res: string): void => {
            this.setState({
                PDFStatus: res,
                EnableDownloadPdf: false,
                EnableDeletePdf: false,
                EnableCreatePdf: false,
            });

        }, (err) => {
            if (this.props.errorHandling?.onError)
                this.props.errorHandling?.onError(`Error creating PDF`, err.message);
        });
    }

    private handleDeletePdf = (): void => {
        if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(null);

        this.naoOutput2Service.deletePDFInfo().then((res: string): void => {
            this.loadPDFStatus();

        }, (err) => {
            if (this.props.errorHandling?.onError)
                this.props.errorHandling?.onError(`Error deleting PDF`, err.message);
        });
    }

    private handleDownloadPdf = async (): Promise<void> => {
        const pdfName: string = "NAO_Output_By_Publication.pdf";
        const fileUniqueID = this.state.FileUniqueID;
        //download pdf

        try {
            await this.zFileService.downloadFile(fileUniqueID, pdfName);
        } catch (error) {
            console.error('Error downloading file', error);
        }



    }
}





