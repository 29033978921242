import { IReportingEntity, ReportingEntity } from "./ReportingEntity";
import { IUser } from "./User";
import { IOrgLevel1 } from "./OrgLevel1";
import { IDirectorate } from "./Directorate";
import { IOrgLevel3 } from "./OrgLevel3";
import { IPortfolioUpdate } from "./PortfolioUpdate";

export interface IPortfolio extends IReportingEntity {
    CustomerID: number | null;
    ContainsValue?: string;
    ParentPortfolioID?: number;
    Purpose?: string;
    OwnerTypeID?: number;
    OwnerL0ID?: number;
    OwnerL1ID?: number;
    OwnerL2ID?: number;
    OwnerL3ID?: number;
    ManagedByTypeID?: number;
    ManagedByL2ID?: number;
    ManagedByL3ID?: number;
    PortfolioManagerUserID?: number;
    StartDate?: Date | null;
    EndDate?: Date | null;
    Objectives?: string;
    Stage?: string;
    ManagedByL2?: IDirectorate
    ManagedByL3?: IOrgLevel3;
    OwnerL1?: IOrgLevel1;
    OwnerL2?: IDirectorate;
    OwnerL3?: IOrgLevel3;
    ParentPortfolio?: IPortfolio
    PortfolioManagerUser?: IUser;
    PortfolioUpdates?: IPortfolioUpdate[];
}

export class Portfolio extends ReportingEntity implements IPortfolio {
    public CustomerID = null;
    public ContainsValue = null;
    public ParentPortfolioID = null;
    public Purpose = null;
    public OwnerTypeID = null;
    public OwnerL0ID = null;
    public OwnerL1ID = null;
    public OwnerL2ID = null;
    public OwnerL3ID = null;
    public ManagedByTypeID = null;
    public ManagedByL2ID = null;
    public ManagedByL3ID = null;
    public PortfolioManagerUserID = null;
    public StartDate = null;
    public EndDate = null;
    public Objectives = null;
    public Stage = 'Awaiting'
}