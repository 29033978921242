import { msalInstance } from ".";
import { loginRequest } from "./authConfig";
import { createTheme } from '@fluentui/react/lib/Styling';

export class AppGlobals {
    public static EntraClientId: string | null = null;
    public static CustomerID: number | null = null;
    public static Username: string | null = null;
    public static UserID: number | null = null;
    public static CustomerTitle: string | null = null;
    public static CustomerSectorTitle: string | null = null;
    public static CustomerRef: string | null = null;
    public static UserTitle: string | null = null;
    public static AuthType: string | null = null;
    public static CustomJwtToken: string | null = null;

    public static readonly ZedxNetSiteUrl: string = 'https://www.zedx.net';


    public static acquireAccessToken = async () => {

        if (this.AuthType === 'CustomJwt') {
            return this.CustomJwtToken;
            //return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJuYmYiOjE3MDE3NzYwNDAsImV4cCI6MTcwMjM4MDg0MCwiaWF0IjoxNzAxNzc2MDQwfQ.mtSoeMKKBnOqeA7WCwJUs-cKk8eEoGA1SyU0f47hUHc";
        }
        else {
            //const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
            const accounts = msalInstance.getAllAccounts();
            console.log('all accounts', accounts);

            // if (!activeAccount && accounts.length === 0) {
            //     /*
            //     * User is not signed in. Throw error or wait for user to login.
            //     * Do not attempt to log a user in outside of the context of MsalProvider
            //     */
            // }
            const request = {
                ...loginRequest,
                account: accounts[0]
            };

            const authResult = await msalInstance.acquireTokenSilent(request);
            //console.log('authResult', authResult);

            return authResult.accessToken;
        }
    };
}

export const darkMenuTheme = createTheme({
    palette: {
        themePrimary: '#ffffff',
        themeLighterAlt: '#f3f3f3',
        themeLighter: '#dcdcdc',
        themeLight: '#c8c8c8',
        themeTertiary: '#a6a6a6',
        themeSecondary: '#868686',
        themeDarkAlt: '#6e6e6e',
        themeDark: '#555555',
        themeDarker: '#3f3f3f',
        neutralLighterAlt: '#2b2b2b',
        neutralLighter: '#323232',
        neutralLight: '#3f3f3f',
        neutralQuaternaryAlt: '#484848',
        neutralQuaternary: '#4f4f4f',
        neutralTertiaryAlt: '#6d6d6d',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        black: '#ffffff',
        white: '#494949',
    },
});

export const AppIDs = {
    CORWF: 1,
    PROJPORT: 2,
    RISKREG: 3,
    GIAA: 4,
    NAO: 5,
    CL: 6,
    GO: 7,
    MA: 8,
    CA: 9,
    AGSPR: 10,
    KNHUB: 11,
    GRPNOTI: 12
};