import { EntityWithStatusService } from './EntityWithStatusService';
import { IEntityChildren } from '../types';
import { ICustomReportingEntity } from '../types/CustomReportingEntity';
import { ContextService } from './ContextService';
import { EntityStatus } from '../refData/EntityStatus';
import axios from 'axios';

export class ReportingEntityService extends EntityWithStatusService<ICustomReportingEntity> {
    public readonly parentEntities = ['Directorate', 'Project', 'LeadUser'];
    protected childrenEntities = ['Contributors($expand=ContributorUser)', 'Attributes($expand=AttributeType)'];

    constructor() {
        super(`/ReportingEntities`);
    }

    public async readMyEntities(): Promise<ICustomReportingEntity[]> {
        console.log('readMyEntities');
        // const username = encodeURIComponent(ContextService.Username());
        // const filters = [
        //     `LeadUser/Username eq '${username}'`,
        //     `Contributors/any(c: c/ContributorUser/Username eq '${username}')`
        // ];
        // const qry: string = `?$filter=EntityStatusID eq ${EntityStatus.Open} and (${filters.join(' or ')})`
        // + `&$expand=ReportingEntityType`
        // + `&$orderby=Directorate/Title,Title`;

        // console.log(qry);
        return Promise.resolve([]);
        //return this.readAll(qry);
    }

    public readAllForReportingEntityList(entityType: number, includeClosedEntities?: boolean): Promise<ICustomReportingEntity[]> {
        return this.readAll(
            `?$expand=Directorate,Project,PartnerOrganisation,LeadUser,Contributors($expand=ContributorUser),EntityStatus`
            + `&$filter=ReportingEntityTypeID eq ${entityType}${includeClosedEntities ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`}`
        );
    }

    public readAllForReportingEntityLookup(entityType: number, includeClosedEntities?: boolean): Promise<ICustomReportingEntity[]> {
        return this.readAll(
            `?$select=ID,Title`
            + `&$orderby=Title`
            + `&$filter=ReportingEntityTypeID eq ${entityType}${includeClosedEntities ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`}`
        );
    }

    public async entityChildren(id: number): Promise<IEntityChildren[]> {
        const entityUrl = `${this.entityUrl}(${id})`;
        const updates = (await this.axiosGet(`${entityUrl}/ReportingEntityUpdates?$select=ID&$top=10`)).data.value;
        return [
            { ChildType: 'Reporting updates', CanBeAdopted: false, ChildIDs: (await updates).map((w: { ID: any }) => w.ID) }
        ];
    }
}