import React, { useContext, useMemo } from 'react';
import {
    IPortfolio, IEntityFormValidations, EntityFormValidations,
    IEntityValidations, EntityValidations, ISpecificEntityFormProps, Portfolio, Contributor, Attribute
} from '../../types';
import { DateService, LookupService, ReportingCycleService } from '../../services';
import styles from '../../styles/cr.module.scss';
import { CrUserPicker } from '../cr/CrUserPicker';
import { CrTextField } from '../cr/CrTextField';
import { CrDropdown } from '../cr/CrDropdown';
import { EntityForm } from '../EntityForm';
import { DataContext } from '../DataContext';
import { IntegrationContext } from '../IntegrationContext';
import { OrbUserContext } from '../OrbUserContext';
import { CrChoiceGroup } from '../cr/CrChoiceGroup';
import { CrEntityPicker } from '../cr/CrEntityPicker';
import { CrDatePicker } from '../cr/CrDatePicker';
import { CrMultiDropdownWithText } from '../cr/CrMultiDropdownWithText';
import { error } from 'console';
import { CrReportingCyclePicker } from '../cr/CrReportingCyclePicker';

class PortfolioValidations extends EntityValidations {
    public DirectorateID: string = null;
    public ContainsValue: string = null;
    public Purpose: string = null;
    public OwnerL1: string = null;
    public OwnerL2: string = null;
    public OwnerL3: string = null;
    public ManagedByL2: string = null;
    public ManagedByL3: string = null;
    public ReportingCycle: string = null;
}

export const PortfolioForm = (props: ISpecificEntityFormProps): React.ReactElement => {
    const { userPermissions, orbConfig } = useContext(OrbUserContext);
    const { disableGroupManagement } = useContext(IntegrationContext);
    const { dataServices, lookupData, loadLookupData: { entityStatuses, groups, directorates, orgLevel3s, portfolios, attributeTypes, users: { all: allUsers } } } = useContext(DataContext);

    useMemo(() => entityStatuses(), [entityStatuses]);
    useMemo(() => portfolios(), [portfolios]);
    useMemo(() => allUsers(), [allUsers]);
    useMemo(() => groups(), [groups]);
    useMemo(() => directorates(), [directorates]);
    useMemo(() => orgLevel3s(), [orgLevel3s]);
    useMemo(() => attributeTypes(), [attributeTypes]);


    const validateEntity = (portfolio: IPortfolio): Promise<PortfolioValidations> => {
        const errors = new PortfolioValidations();

        if (portfolio.Title === null || portfolio.Title === '') {
            errors.Title = `Portfolio name is required`;
            errors.Valid = false;
        }
        else {
            errors.Title = null;
        }
        if (portfolio.ContainsValue === null || portfolio.ContainsValue === '') {
            errors.ContainsValue = `Portfolio Contains is required`;
            errors.Valid = false;
        }
        else {
            errors.ContainsValue = null;
        }
        if (portfolio.Purpose === null || portfolio.Purpose === '') {
            errors.Purpose = `Purpose is required`;
            errors.Valid = false;
        }
        else {
            errors.Purpose = null;
        }
        if (portfolio.OwnerTypeID === 1 && portfolio.OwnerL1ID === null) {
            errors.OwnerL1 = `Owner ${orbConfig.OrgL1Label} is required`;
            errors.Valid = false;
        }
        else {
            errors.OwnerL1 = null;
        }
        if (portfolio.OwnerTypeID === 2 && portfolio.OwnerL2ID === null) {
            errors.OwnerL2 = `Owner ${orbConfig.OrgL2Label} is required`;
            errors.Valid = false;
        }
        else {
            errors.OwnerL2 = null;
        }
        if (portfolio.OwnerTypeID === 3 && portfolio.OwnerL3ID === null) {
            errors.OwnerL3 = `Owner ${orbConfig.OrgL3Label} is required`;
            errors.Valid = false;
        }
        else {
            errors.OwnerL3 = null;
        }
        if (portfolio.ManagedByTypeID === 2 && portfolio.ManagedByL2ID === null) {
            errors.ManagedByL2 = `Managed By ${orbConfig.OrgL2Label} is required`;
            errors.Valid = false;
        }
        else {
            errors.ManagedByL2 = null;
        }
        if (portfolio.ManagedByTypeID === 3 && portfolio.ManagedByL3ID === null) {
            errors.ManagedByL3 = `Managed By ${orbConfig.OrgL3Label} is required`;
            errors.Valid = false;
        }
        else {
            errors.ManagedByL3 = null;
        }
        if (!ReportingCycleService.reportingCycleIsValid(portfolio)) {
            errors.ReportingCycle = 'Please select all values for the reporting cycle';
            errors.Valid = false;
        }
        else {
            errors.ReportingCycle = null;
        }

        return Promise.resolve(errors);
    };


    return (
        <EntityForm<IPortfolio, PortfolioValidations>
            {...props}
            entityName='Portfolio'
            renderFormFields={(changeHandlers, formState) => {
                const { FormData: portfolio, ValidationErrors: errors } = formState;
                return (
                    <div>
                        <CrTextField
                            label="Name"
                            maxLength={100}
                            className={styles.formField}
                            required={true}
                            value={portfolio.Title}
                            onChange={(ev, newValue) => changeHandlers.changeTextField(newValue, 'Title')}
                            errorMessage={errors.Title} />
                        <CrChoiceGroup
                            label="Contains"
                            required={true}
                            className={styles.formField}
                            options={[
                                { key: 'Portfolios', text: 'Portfolios' }, { key: 'Projects', text: 'Programmes & Projects' },
                            ]}
                            selectedKey={portfolio.ContainsValue}
                            onChange={(_, option) => changeHandlers.changeChoiceGroup(option, 'ContainsValue')}
                            errorMessage={errors.ContainsValue}
                        />
                        {portfolio.ContainsValue === 'Portfolios' &&
                            <CrEntityPicker
                                label="Parent"
                                //disabled={disableEdit}
                                required={false}
                                className={styles.formField}
                                entities={lookupData.Portfolios}
                                itemLimit={1}
                                selectedEntities={portfolio.ParentPortfolioID && [portfolio.ParentPortfolioID]}
                                onChange={v => changeHandlers.changeUserPicker(v, 'ParentPortfolioID')}
                            />}
                        <CrChoiceGroup
                            label="Purpose"
                            required={true}
                            className={styles.formField}
                            options={[
                                { key: 'Vision realisation', text: 'Vision realisation' }, { key: 'General Management', text: 'General Management' },
                            ]}
                            selectedKey={portfolio.Purpose}
                            onChange={(_, option) => changeHandlers.changeChoiceGroup(option, 'Purpose')}
                            errorMessage={errors.Purpose}
                        />

                        <CrReportingCyclePicker
                            label="Reports due:"
                            required={true}
                            className={styles.formField}
                            cycle={{ frequency: portfolio.ReportingFrequency, dueDay: portfolio.ReportingDueDay, startDate: portfolio.ReportingStartDate }}
                            onChange={cycle => changeHandlers.changeReportingCycle(cycle)}
                            errorMessage={errors.ReportingCycle}
                        />

                        <CrDropdown
                            label="Owner Type"
                            className={styles.formField}
                            options={LookupService.entitiesToSelectableOptions([
                                { ID: 0, Title: 'Dept' },
                                { ID: 1, Title: orbConfig.OrgL1Label },
                                { ID: 2, Title: orbConfig.OrgL2Label },
                                { ID: 3, Title: orbConfig.OrgL3Label },
                            ])}
                            selectedKey={portfolio.OwnerTypeID}
                            onChange={(_, o) => changeHandlers.changeDropdown(o, 'OwnerTypeID')}
                        />
                        {portfolio.OwnerTypeID === 1 &&
                            <CrDropdown
                                label={`Owner ${orbConfig.OrgL1Label}`}
                                className={styles.formField}
                                options={LookupService.entitiesToSelectableOptions(lookupData.Groups)}
                                selectedKey={portfolio.OwnerL1ID}
                                onChange={(_, option, index) => changeHandlers.changeDropdown(option, 'OwnerL1ID', index)}
                                errorMessage={errors.OwnerL1}
                            />
                        }
                        {portfolio.OwnerTypeID === 2 &&
                            <CrDropdown
                                label={`Owner ${orbConfig.OrgL2Label}`}
                                className={styles.formField}
                                options={LookupService.entitiesToSelectableOptions(lookupData.Directorates)}
                                selectedKey={portfolio.OwnerL2ID}
                                onChange={(_, option, index) => changeHandlers.changeDropdown(option, 'OwnerL2ID', index)}
                                errorMessage={errors.OwnerL2}
                            />
                        }
                        {portfolio.OwnerTypeID === 3 &&
                            <CrDropdown
                                label={`Owner ${orbConfig.OrgL3Label}`}
                                className={styles.formField}
                                options={LookupService.entitiesToSelectableOptions(lookupData.OrgLevel3s)}
                                selectedKey={portfolio.OwnerL3ID}
                                onChange={(_, option, index) => changeHandlers.changeDropdown(option, 'OwnerL3ID', index)}
                                errorMessage={errors.OwnerL3}
                            />
                        }
                        <CrDropdown
                            label="Managed By Type"
                            className={styles.formField}
                            options={LookupService.entitiesToSelectableOptions([
                                { ID: 2, Title: orbConfig.OrgL2Label },
                                { ID: 3, Title: orbConfig.OrgL3Label },
                            ])}
                            selectedKey={portfolio.ManagedByTypeID}
                            onChange={(_, o) => changeHandlers.changeDropdown(o, 'ManagedByTypeID')}
                        />
                        {portfolio.ManagedByTypeID === 2 &&
                            <CrDropdown
                                label={`Managed By ${orbConfig.OrgL2Label}`}
                                className={styles.formField}
                                options={LookupService.entitiesToSelectableOptions(lookupData.Directorates)}
                                selectedKey={portfolio.ManagedByL2ID}
                                onChange={(_, option, index) => changeHandlers.changeDropdown(option, 'ManagedByL2ID', index)}
                                errorMessage={errors.ManagedByL2}
                            />
                        }
                        {portfolio.ManagedByTypeID === 3 &&
                            <CrDropdown
                                label={`Managed By ${orbConfig.OrgL3Label}`}
                                className={styles.formField}
                                options={LookupService.entitiesToSelectableOptions(lookupData.OrgLevel3s)}
                                selectedKey={portfolio.ManagedByL3ID}
                                onChange={(_, option, index) => changeHandlers.changeDropdown(option, 'ManagedByL3ID', index)}
                                errorMessage={errors.ManagedByL3}
                            />
                        }
                        <CrUserPicker
                            label="Portfolio manager (PM)"
                            className={styles.formField}
                            users={lookupData.Users?.All}
                            selectedUsers={portfolio.PortfolioManagerUserID && [portfolio.PortfolioManagerUserID]}
                            onChange={v => changeHandlers.changeUserPicker(v, 'PortfolioManagerUserID')}
                        //{...suggestions}
                        />
                        <CrUserPicker
                            label="Contributors (C)"
                            className={styles.formField}
                            users={lookupData.Users?.All}
                            itemLimit={3}
                            selectedUsers={portfolio.Contributors?.map(c => c.ContributorUserID)}
                            onChange={v => changeHandlers.changeMultiUserPicker(v, 'Contributors', new Contributor(), 'ContributorUserID')}
                        //{...suggestions}
                        />
                        <CrTextField
                            label="Objectives"
                            multiline
                            rows={6}
                            maxLength={10000}
                            className={styles.formField}
                            value={portfolio.Objectives}
                            onChange={(ev, newValue) => changeHandlers.changeTextField(newValue, 'Objectives')}
                        />
                        {/* <CrDatePicker
                            label="Start date"
                            className={styles.formField}
                            value={DateService.removeTimezoneOffset(portfolio.StartDate)}
                            onSelectDate={date => changeHandlers.changeDatePicker(date, 'StartDate')}
                        />
                        <CrDatePicker
                            label="End date"
                            className={styles.formField}
                            value={DateService.removeTimezoneOffset(portfolio.EndDate)}
                            minDate={DateService.removeTimezoneOffset(portfolio.StartDate)}
                            onSelectDate={date => changeHandlers.changeDatePicker(date, 'EndDate')}
                        /> */}
                        
                        {/* <CrDropdown
                            label="Attributes"
                            className={styles.formField}
                            multiSelect
                            options={LookupService.entitiesToSelectableOptions2(lookupData.AttributeTypes)}
                            selectedKeys={portfolio.Attributes?.map(p => p.AttributeTypeID)}
                            onChange={(_, v) => changeHandlers.changeMultiDropdown(v, 'Attributes', new Attribute(), 'AttributeTypeID')}
                        /> */}
                        <CrMultiDropdownWithText
                            label="Attributes"
                            className={styles.formField}
                            textMaxLength={255}
                            options={lookupData.AttributeTypes.map(a => ({ key: a.ID, text: a.Title, textRequired: false }))}
                            selectedItems={LookupService.attributesToDropdownWithText(portfolio.Attributes)}
                            onChange={v => changeHandlers.changeMultiDropdownWithText(v, 'Attributes', new Attribute(), 'AttributeTypeID', 'AttributeValue')}
                        />
                        <CrDropdown
                            label="Status"
                            className={styles.formField}
                            disabled={disableGroupManagement}
                            options={LookupService.entitiesToSelectableOptions(lookupData.EntityStatuses)}
                            selectedKey={portfolio.EntityStatusID}
                            onChange={(_, o) => changeHandlers.changeStatusDropdown(o, 'EntityStatusID')}
                        />

                    </div>
                );
            }}
            loadEntity={id => dataServices.portfolioService.read(id, true, true)}
            loadNewEntity={() => new Portfolio()}
            loadEntityValidations={() => new PortfolioValidations()}
            onValidateEntity={validateEntity}
            onCreate={g => dataServices.portfolioService.create(g)}
            onUpdate={g => dataServices.portfolioService.update(g.ID, g)}
            parentEntities={dataServices.portfolioService.parentEntities}
            childEntities={[
                {
                    ObjectParentProperty: 'Contributors',
                    ParentIdProperty: 'PortfolioID',
                    ChildIdProperty: 'ContributorUserID',
                    ChildService: dataServices.contributorService
                },
                {
                    ObjectParentProperty: 'Attributes',
                    ParentIdProperty: 'PortfolioID',
                    ChildIdProperty: 'AttributeTypeID',
                    ChildService: dataServices.attributeService
                }
            ]}
        />
    );
};
