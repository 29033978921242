import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { INavLink, Nav } from '@fluentui/react';
import { IWithErrorHandlingProps } from './withErrorHandling';
import { OrbUserContext } from './OrbUserContext';
import { ThemeProvider } from '@fluentui/react/lib/Theme';
import { darkMenuTheme } from '../AppGlobals';
import { LoggedUserInfoForMenu } from './LoggedUserInfoForMenu';
import { DataContext } from './DataContext';
import { _onRenderNavLink } from './ZedXMainMenu';

interface IGroupNotificationsMenu extends IWithErrorHandlingProps {
    onLinkClicked?: () => void;
}

export const GroupNotificationsMenu = ({ errorHandling: { clearErrors }, onLinkClicked }: IGroupNotificationsMenu): React.ReactElement => {
    const { userPermissions: up, orbConfig } = useContext(OrbUserContext);
    const history = useHistory();
    const { pathname } = useLocation();
    const { dataServices } = useContext(DataContext);
    const [navs, setNavs] = useState([]);
    const resetErrors = useCallback(clearErrors, [clearErrors]);


    useEffect(() => {
        const routerNav = (path: string): void => {
            history.push(path);
            if (onLinkClicked) onLinkClicked();
        };

        const NamePathToNavLink = ({ name, path }): INavLink => {
            return { key: path, name: name, url: `#${path}`, onClick: () => routerNav(path) };
        };

        const links: INavLink[] = [
            { name: 'Home', path: '/' },
            { name: 'Welcome', path: '/group-notifications/welcome' },
            // { name: 'Updates', path: '/group-notifications/updates' },
            // { name: 'Outputs', path: '/group-notifications/outputs' },
            // { name: 'Properties', path: '/group-notifications/properties' },
        ].map(NamePathToNavLink);

        setNavs(links);
    }, [up, history, onLinkClicked, resetErrors]);

    return (
        <ThemeProvider theme={darkMenuTheme}>
            <LoggedUserInfoForMenu />
            <Nav
                selectedKey={pathname}
                groups={[{ links: navs }]}
            />
        </ThemeProvider>
    );
};
