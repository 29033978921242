import { IRiskMitigationActionUpdate } from '../types';
import { EntityUpdateService } from './EntityUpdateService';

export class FinancialRiskMitigationActionUpdateService extends EntityUpdateService<IRiskMitigationActionUpdate> {
    constructor() {
        super(`/FinancialRiskMitigationActionUpdates`);
    }

    public async readLatestUpdateForPeriod(riskMitigationActionId: number, period: Date): Promise<IRiskMitigationActionUpdate> {
        const au = await this.readAll(
            `?$top=1`
            + `&$filter=RiskMitigationActionID eq ${riskMitigationActionId} and UpdatePeriod eq ${period.toISOString()}`
            + `&$expand=UpdateUser($select=ID,Title)`
            + `&$orderby=UpdateDate desc`
        );
        if (au.length > 0)
            return au[0];
        else
            throw new Error('No FinancialRiskMitigationActionUpdates found.');
    }

    public async readLastSignedOffUpdateForPeriod(riskMitigationActionId: number, period: Date): Promise<IRiskMitigationActionUpdate> {
        const au = await this.readAll(
            `?$top=1`
            + `&$filter=RiskMitigationActionID eq ${riskMitigationActionId} and UpdatePeriod eq ${period.toISOString()}`
            + `&$orderby=UpdateDate desc`
        );
        if (au.length > 0)
            return au[0];
        else
            throw new Error('No FinancialRiskMitigationActionUpdates found.');
    }
}