import { EntityService } from './EntityService';
import { IEntity, IIAPActionUpdate } from '../types';

export class IAPActionUpdateService extends EntityService<IIAPActionUpdate> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/IAPActionUpdates`);
    }

    public readAllForList2(iapUpdateId: number): Promise<IEntity[]> {
        return this.readAll(`?iapUpdateId=${iapUpdateId}&dataForUpdatesList=`);
    }
}