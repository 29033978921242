import { EntityService } from './EntityService';
import { IEntityChildren } from '../types';
import { IRiskType } from '../types/RiskType';
import axios from 'axios';
import { AppGlobals } from '../AppGlobals';

export class RiskTypeService extends EntityService<IRiskType> {
    constructor() {
        super(`/RiskTypes`);
    }

    public readAllForLookup(): Promise<IRiskType[]> {
        return this.readAll(`?$expand=Threshold($expand=ThresholdAppetites)&$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`);
    }

    public readAllForList(): Promise<IRiskType[]> {
        return this.readAll(`?$expand=Threshold&$filter=CustomerID eq ${AppGlobals.CustomerID}`);
    }

    public async create(riskType: IRiskType): Promise<IRiskType> {
        riskType.CustomerID = AppGlobals.CustomerID;           
        return super.create(riskType);
    }

    public async entityChildren(id: number): Promise<IEntityChildren[]> {
        return [
            { ChildType: 'Risks', CanBeAdopted: true, ChildIDs: (await this.axiosGet(`${this.entityUrl}(${id})/RiskRiskTypes?$select=RiskID&$top=10`)).data.value.map((r: { [x: string]: any; }) => r['RiskID']) }
        ];
    }
}