import { EntityService } from './EntityService';
import { IEntity } from '../types';

export class ExportDefinationService extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/ExportDefinations`);
    }

    public readAllByModule(moduleName: string): Promise<IEntity[]> {
        return this.readAll(`?$filter=Module eq '${moduleName}'`);
    }

    public createExport(outputId: number, periodId:number, dgAreaId:number, periodTitle:string, dgAreaTitle:string, spSiteUrl): Promise<string> {
        
        return super.readString(`?key=${outputId}&periodId=${periodId}&dgAreaId=${dgAreaId}&periodTitle=${periodTitle}&dgAreaTitle=${dgAreaTitle}&createExport=&spSiteUrl=${spSiteUrl}`).then((result:string): string => {
            return result;
        });
    }

}