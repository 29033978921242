import { EntityService } from './EntityService';
import { IUserRole} from '../types';
import { AppGlobals } from '../AppGlobals';

export class UserRoleService extends EntityService<IUserRole> {
    constructor() {
        super(`/UserRoles`);
    }

    public readAllForList(): Promise<IUserRole[]> {
        return this.readAll(`?$orderby=User/Title&$expand=User,Role&$filter=User/CustomerID eq ${AppGlobals.CustomerID}`);
    }

    public checkForDuplicates = (userId: number, roleId: number): Promise<IUserRole[]> => {
        return this.readAll(`?$filter=UserID eq ${userId} and RoleID eq ${roleId}&$select=ID`);
    }
}