import React, { useContext } from 'react';
import { ErrorBoundary } from '../ErrorBoundary';
import { IWithErrorHandlingProps, withErrorHandling } from '../withErrorHandling';
import { IUseApiProps } from '../useApi';
import GoReportCls from './GoReportCls';
import { OrbUserContext } from '../OrbUserContext';

export interface IGoReportProps extends IUseApiProps, IWithErrorHandlingProps {
}

const GoReport = (props: IGoReportProps) => {
    const { userPermissions } = useContext(OrbUserContext);
    return (
        <ErrorBoundary>
            <GoReportCls
                userPermissions={userPermissions}
                {...props}
            />
        </ErrorBoundary>
    );
};

export default withErrorHandling(GoReport);
