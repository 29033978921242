import * as React from 'react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import styles from '../../styles/cr.module.scss';
import { CrCommandBar } from './CrCommandBar';

export interface IListCommandBarCatProps {
    onAdd: () => void;
    onEdit: () => void;
    onDelete: () => void;
    onFilterChange: (filterText: string) => void;
    editDisabled?: boolean;
    deleteDisabled?: boolean;
    onAddChild?: () => void;
    addChildName?: string;
    allowAdd?: boolean;
    className?: string;
}

export class ListCommandBarCat extends React.Component<IListCommandBarCatProps, any> {
    public render(): JSX.Element {
        const { props } = this;
        let commandBarItems: ICommandBarItemProps[];
        const farCommandBarItems: ICommandBarItemProps[] = [];

        if (this.props.allowAdd === true) {

            if (this.props.editDisabled && this.props.deleteDisabled) {

                commandBarItems = [
                    { key: '1', name: 'New', iconProps: { iconName: 'Add' }, onClick: this.props.onAdd }
                ];
            }
            else
                commandBarItems = [
                    { key: '1', name: 'Edit', iconProps: { iconName: 'Edit' }, onClick: this.props.onEdit, disabled: this.props.editDisabled },
                    { key: '2', name: 'Delete', iconProps: { iconName: 'Delete' }, onClick: this.props.onDelete, disabled: this.props.deleteDisabled }
                ];
        }

        if (props.onFilterChange) {
            farCommandBarItems.push(
                {
                    key: 'filter',
                    text: 'List filter',
                    inActive: true,
                    onRender: function renderFilter() {
                        return (
                            <div className={styles.crCommandBarContainer}>
                                <SearchBox placeholder="Filter items" onChange={(_, v) => props.onFilterChange(v)} className={styles.listFilterBox} />
                            </div>
                        );
                    }
                }
            );
        }

        return (
            <CrCommandBar
                items={commandBarItems}
                className={this.props.className}
                farItems={farCommandBarItems}
            />
        );
    }
}
