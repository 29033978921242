import { IEntity } from '../types';
import { EntityService } from './EntityService';

export class GIAAActionPriorityService extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/GIAAActionPriorities`);
    }
}