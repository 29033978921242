import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from '../../styles/cr.module.scss';
import { IWithErrorHandlingProps, withErrorHandling } from '../withErrorHandling';
import { SignOffType } from '../../refData/SignOffType';
import { useParams } from 'react-router-dom';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { CrCommandBar } from '../cr/CrCommandBar';
import { SignOffList } from '../signOff/SignOffList';

interface RiskSignOffProps extends IWithErrorHandlingProps {
    lastProjectIdForChilds?: number;
    apiConnected: boolean;
    emphasiseProjectsWithAttribute: string;
    emphasisedProjectsHeaderText: string;
    otherProjectsHeaderText: string;
}

const RiskSignOff = (props: RiskSignOffProps) => {

    const { riskIdForUpdates } = useParams<{ riskIdForUpdates: string }>();
    const history = useHistory();

    const commandBarItems: ICommandBarItemProps[] = [
        {
            key: 'risks',
            text: 'Risks',
            iconProps: { iconName: 'MiniExpandMirrored' },
            onClick: (ev, item) => {

                if (props.lastProjectIdForChilds != null) {
                    history.push(`/performance-reporting/risks-for-project/${props.lastProjectIdForChilds}`);
                }
                else {
                    //request was from risk register, so go back
                    history.push('/risk-reporting/risks');
                }
            },
        },
        {
            key: 'report_updates',
            text: 'Report Updates',
            iconProps: { iconName: 'MiniExpand' },
            onClick: (ev, item) => {
                history.push(`/performance-reporting/updates-for-risk/${riskIdForUpdates}`);
            },
        }
    ];


    return (

        <div className={`${styles.updatesContainer}`}>
            <CrCommandBar items={commandBarItems} />
            <div>
                <SignOffList
                    defaultReportType={SignOffType.Risk}
                    defaultEntityId={Number(riskIdForUpdates)}
                    {...props}
                />
            </div>


        </div>

    );
};

export default withErrorHandling(RiskSignOff);