import { EntityService } from './EntityService';
import { IEntityChildren } from '../types';
import { IThreshold } from '../types/Threshold';
import axios from 'axios';
import { AppGlobals } from '../AppGlobals';

export class ThresholdService extends EntityService<IThreshold> {
    constructor() {
        super(`/Thresholds`);
    }

    public readAllForLookup(): Promise<IThreshold[]> {
        return this.readAll(`?$expand=ThresholdAppetites&$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`);
    }

    public readAllForList(): Promise<IThreshold[]> {
        return super.readAll(`?$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`);
    }

    public async create(threshold: IThreshold): Promise<IThreshold> {
        threshold.CustomerID = AppGlobals.CustomerID;           
        return super.create(threshold);
    }

    public async entityChildren(id: number): Promise<IEntityChildren[]> {
        const thresholdUrl = `${this.entityUrl}(${id})`;
        const riskTypes = (await this.axiosGet(`${thresholdUrl}/RiskTypes?$select=ID&$top=10`)).data.value;
        const appetites = (await this.axiosGet(`${thresholdUrl}/ThresholdAppetites?$select=ID&$top=10`)).data.value;
        return [
            { ChildType: 'Risk types', CanBeAdopted: true, ChildIDs: (await riskTypes).map((r: { ID: any; }) => r.ID) },
            { ChildType: 'Threshold appetites', CanBeAdopted: true, ChildIDs: (await appetites).map((t: { ID: any; }) => t.ID) }
        ];
    }
}