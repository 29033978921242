import {
    IBenefit, ICommitment, ICorporateRisk, ICorporateRiskMitigationAction, ICustomReportingEntityType, IDependency, IDirectorate,
    IEntity,
    IFinancialRisk, IKeyWorkArea, IMetric, IMilestone, IPartnerOrganisation, IPartnerOrganisationRisk, IPartnerOrganisationRiskMitigationAction,
    IPortfolio, IProgramme,
    IProject, IRiskMitigationAction, ISignOff, ISignOffAndMetadata, ISignOffCorporateRiskDto, ISignOffDirectorateDto, ISignOffDto, ISignOffFinancialRiskDto,
    ISignOffPartnerOrganisationDto, ISignOffProjectDto, IWorkStream
} from '../types';
import { DateService } from './DateService';
import axios from 'axios';
import { EntityService } from './EntityService';
import { ISignOffPortfolioDto } from '../types/SignOffReportDto';
import { ISignOffProgrammeDto } from '../types/SignOffReportDto';

export interface IReportBuilderService {
    buildDirectorateReport: (directorateId: number, reportDate: Date) => Promise<ISignOffAndMetadata>;
    buildPortfolioReport: (portfolioId: number, reportDate: Date) => Promise<ISignOffAndMetadata>;
    buildProgrammeReport: (programmeId: number, reportDate: Date) => Promise<ISignOffAndMetadata>;
    buildProjectReport: (projectId: number, reportDate: Date) => Promise<ISignOffAndMetadata>;
    buildPartnerOrganisationReport: (partnerOrganisationId: number, reportDate: Date) => Promise<ISignOffAndMetadata>;
    buildRiskReport: (riskId: number, reportDate: Date) => Promise<ISignOffAndMetadata>;
    buildFinancialRiskReport: (riskId: number, reportDate: Date) => Promise<ISignOffAndMetadata>;
}

export class ReportBuilderService extends EntityService<IEntity> implements IReportBuilderService {
    private apiURL: string;

    constructor() {
        super('');
        const apiURL = process.env.REACT_APP_ZedX_API!;
        this.apiURL = apiURL;
    }

    public async buildDirectorateReport(directorateId: number, reportDate: Date): Promise<ISignOffAndMetadata> {
        const signOffDto: ISignOffDirectorateDto = (await this.axiosGet(`${this.apiURL}/BuildDirectorateReport(DirectorateID=${directorateId},ReportPeriod=${reportDate.toISOString()})`)).data;
        return ReportBuilderService.mapDirectorateDtoToSignOff(signOffDto);
    }

    public async buildProgrammeReport(programmeId: number, reportDate: Date): Promise<ISignOffAndMetadata> {
        const signOffDto: ISignOffProgrammeDto = (await this.axiosGet(`${this.apiURL}/BuildProgrammeReport(ProgrammeID=${programmeId},ReportPeriod=${reportDate.toISOString()})`)).data;
        return ReportBuilderService.mapProgrammeDtoToSignOff(signOffDto);
    }

    public async buildPortfolioReport(portfolioId: number, reportDate: Date): Promise<ISignOffAndMetadata> {
        const signOffDto: ISignOffPortfolioDto = (await this.axiosGet(`${this.apiURL}/BuildPortfolioReport(PortfolioID=${portfolioId},ReportPeriod=${reportDate.toISOString()})`)).data;
        return ReportBuilderService.mapPortfolioDtoToSignOff(signOffDto);
    }

    public async buildProjectReport(projectId: number, reportDate: Date): Promise<ISignOffAndMetadata> {
        const signOffDto: ISignOffProjectDto = (await this.axiosGet(`${this.apiURL}/BuildProjectReport(ProjectID=${projectId},ReportPeriod=${reportDate.toISOString()})`)).data;
        return ReportBuilderService.mapProjectDtoToSignOff(signOffDto);
    }

    public async buildPartnerOrganisationReport(partnerOrganisationId: number, reportDate: Date): Promise<ISignOffAndMetadata> {
        const signOffDto: ISignOffPartnerOrganisationDto = (await this.axiosGet(`${this.apiURL}/BuildPartnerOrganisationReport(PartnerOrganisationID=${partnerOrganisationId},ReportPeriod=${reportDate.toISOString()})`)).data;
        return ReportBuilderService.mapPartnerOrganisationDtoToSignOff(signOffDto);
    }

    public async buildRiskReport(riskId: number, reportDate: Date): Promise<ISignOffAndMetadata> {
        const signOffDto: ISignOffCorporateRiskDto = (await this.axiosGet(`${this.apiURL}/BuildRiskReport(RiskID=${riskId},ReportPeriod=${reportDate.toISOString()})`)).data;
        return ReportBuilderService.mapCorporateRiskDtoToSignOff(signOffDto);
    }

    public async buildFinancialRiskReport(riskId: number, reportDate: Date): Promise<ISignOffAndMetadata> {
        const signOffDto: ISignOffFinancialRiskDto = (await this.axiosGet(`${this.apiURL}/BuildFinancialRiskReport(RiskID=${riskId},ReportPeriod=${reportDate.toISOString()})`)).data;
        return ReportBuilderService.mapFinancialRiskDtoToSignOff(signOffDto);
    }

    private static getMetadataFromSignOffDto = (signOff: ISignOffDto) => ({
        LastApproved: signOff.LastApproved,
        LastApprovedBy: signOff.LastApprovedBy,
        ChangedSinceApproval: signOff.ChangedSinceApproval
    });

    public static mapDirectorateDtoToSignOff = (signOffDto: ISignOffDirectorateDto): ISignOffAndMetadata => {
        const signOff: Partial<ISignOff> = {};
        signOff.Commitments = DateService.convertODataDates(JSON.parse(signOffDto.Commitments)) as ICommitment[] || [];
        signOff.Directorate = DateService.convertODataDates(JSON.parse(signOffDto.Directorate)) as IDirectorate;
        signOff.KeyWorkAreas = DateService.convertODataDates(JSON.parse(signOffDto.KeyWorkAreas)) as IKeyWorkArea[] || [];
        signOff.Metrics = DateService.convertODataDates(JSON.parse(signOffDto.Metrics)) as IMetric[] || [];
        signOff.Milestones = DateService.convertODataDates(JSON.parse(signOffDto.Milestones)) as IMilestone[] || [];
        signOff.Projects = DateService.convertODataDates(JSON.parse(signOffDto.Projects)) as IProject[] || [];
        signOff.ReportingEntityTypes = DateService.convertODataDates(JSON.parse(signOffDto.ReportingEntityTypes)) as ICustomReportingEntityType[] || [];
        return { report: signOff, metadata: ReportBuilderService.getMetadataFromSignOffDto(signOffDto) };
    }

    public static mapPartnerOrganisationDtoToSignOff = (signOffDto: ISignOffPartnerOrganisationDto): ISignOffAndMetadata => {
        const signOff: Partial<ISignOff> = {};
        signOff.Milestones = DateService.convertODataDates(JSON.parse(signOffDto.Milestones)) as IMilestone[] || [];
        signOff.PartnerOrganisation = DateService.convertODataDates(JSON.parse(signOffDto.PartnerOrganisation)) as IPartnerOrganisation;
        signOff.PartnerOrganisationRisks = DateService.convertODataDates(JSON.parse(signOffDto.PartnerOrganisationRisks)) as IPartnerOrganisationRisk[] || [];
        signOff.PartnerOrganisationRiskMitigationActions =
            DateService.convertODataDates(JSON.parse(signOffDto.PartnerOrganisationRiskMitigationActions)) as IPartnerOrganisationRiskMitigationAction[] || [];
        signOff.ReportingEntityTypes = DateService.convertODataDates(JSON.parse(signOffDto.ReportingEntityTypes)) as ICustomReportingEntityType[] || [];
        return { report: signOff, metadata: ReportBuilderService.getMetadataFromSignOffDto(signOffDto) };
    }

    public static mapProjectDtoToSignOff = (signOffDto: ISignOffProjectDto): ISignOffAndMetadata => {
        const signOff: Partial<ISignOff> = {};
        signOff.Benefits = DateService.convertODataDates(JSON.parse(signOffDto.Benefits)) as IBenefit[] || [];
        signOff.Dependencies = DateService.convertODataDates(JSON.parse(signOffDto.Dependencies)) as IDependency[] || [];
        signOff.Milestones = DateService.convertODataDates(JSON.parse(signOffDto.Milestones)) as IMilestone[] || [];
        signOff.Project = DateService.convertODataDates(JSON.parse(signOffDto.Project)) as IProject;
        signOff.WorkStreams = DateService.convertODataDates(JSON.parse(signOffDto.WorkStreams)) as IWorkStream[] || [];
        signOff.Projects = DateService.convertODataDates(JSON.parse(signOffDto.Projects)) as IProject[] || [];
        signOff.ReportingEntityTypes = DateService.convertODataDates(JSON.parse(signOffDto.ReportingEntityTypes)) as ICustomReportingEntityType[] || [];
        return { report: signOff, metadata: ReportBuilderService.getMetadataFromSignOffDto(signOffDto) };
    }

    public static mapPortfolioDtoToSignOff = (signOffDto: ISignOffPortfolioDto): ISignOffAndMetadata => {
        const signOff: Partial<ISignOff> = {};
        signOff.Portfolio = DateService.convertODataDates(JSON.parse(signOffDto.Portfolio)) as IPortfolio;
        return { report: signOff, metadata: ReportBuilderService.getMetadataFromSignOffDto(signOffDto) };
    }

    public static mapProgrammeDtoToSignOff = (signOffDto: ISignOffProgrammeDto): ISignOffAndMetadata => {
        const signOff: Partial<ISignOff> = {};
        signOff.Programme = DateService.convertODataDates(JSON.parse(signOffDto.Programme)) as IProgramme;
        return { report: signOff, metadata: ReportBuilderService.getMetadataFromSignOffDto(signOffDto) };
    }

    public static mapCorporateRiskDtoToSignOff = (signOffDto: ISignOffCorporateRiskDto): ISignOffAndMetadata => {
        const signOff: Partial<ISignOff> = {};
        signOff.Risk = DateService.convertODataDates(JSON.parse(signOffDto.Risk)) as ICorporateRisk;
        signOff.RiskMitigationActions = DateService.convertODataDates(JSON.parse(signOffDto.RiskMitigationActions)) as ICorporateRiskMitigationAction[] || [];
        return { report: signOff, metadata: ReportBuilderService.getMetadataFromSignOffDto(signOffDto) };
    }

    public static mapFinancialRiskDtoToSignOff = (signOffDto: ISignOffFinancialRiskDto): ISignOffAndMetadata => {
        const signOff: Partial<ISignOff> = {};
        signOff.FinancialRisk = DateService.convertODataDates(JSON.parse(signOffDto.FinancialRisk)) as IFinancialRisk;
        signOff.FinancialRiskMitigationActions = DateService.convertODataDates(JSON.parse(signOffDto.FinancialRiskMitigationActions)) as IRiskMitigationAction[] || [];
        return { report: signOff, metadata: ReportBuilderService.getMetadataFromSignOffDto(signOffDto) };
    }
}
