import { EntityWithStatusService } from './EntityWithStatusService';
import { IPortfolio, IEntityChildren, IEntityDescription1 } from '../types';
import { EntityStatus } from '../refData/EntityStatus';
import { AppGlobals } from '../AppGlobals';

export class PortfolioService extends EntityWithStatusService<IPortfolio> {
    public readonly parentEntities = ['ManagedByL2', 'ManagedByL3'];
    protected childrenEntities = ['Contributors($expand=ContributorUser)', 'Attributes($expand=AttributeType)'];

    constructor() {
        super(`/Portfolios`);
    }

    // public readMyDirectorates(): Promise<IDirectorate[]> {
    //     const username = encodeURIComponent(ContextService.Username());
    //     const filters = [
    //         `DirectorUser/Username eq '${username}'`,
    //         `ReportApproverUser/Username eq '${username}'`,
    //         `ReportingLeadUser/Username eq '${username}'`,
    //         `Group/DirectorGeneralUser/Username eq '${username}'`,
    //         `Contributors/any(c: c/ContributorUser/Username eq '${username}')`
    //     ];
    //     return this.readAll(`?$filter=${filters.join(' or ')}&$orderby=Title`);
    // }

    // public readDgDirectorates(): Promise<IDirectorate[]> {
    //     return this.readAll(`?$filter=Group/DirectorGeneralUser/Username eq '${encodeURIComponent(ContextService.Username())}'&$expand=OrgLevel1`);
    // }

    public readAllForLookup(includeClosed?: boolean): Promise<IPortfolio[]> {
        return this.readAll(
            `?$select=ID,Title,ContainsValue`
            + `&$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosed ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public readAllForList(includeClosed?: boolean): Promise<IPortfolio[]> {
        return this.readAll(
            `?$select=ID,Title,Purpose,ContainsValue,OwnerTypeID,ManagedByTypeID,StartDate,EndDate,Stage`
            + `&$orderby=Title`
            + `&$expand=ParentPortfolio($select=Title),OwnerL1($select=Title),OwnerL2($select=Title),OwnerL3($select=Title),ManagedByL2($select=Title),ManagedByL3($select=Title),EntityStatus($select=Title),PortfolioManagerUser($select=Title),Contributors($expand=ContributorUser($select=Title))`
            + `,PortfolioUpdates($orderby=ID desc;$top=1;$select=ID,OverallRagOptionID,FinanceRagOptionID,PeopleRagOptionID,MilestonesRagOptionID,BenefitsRagOptionID)`
            + `&$filter=CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosed ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public async readForView(portfolioID: number): Promise<IPortfolio> {
        return this.read(
            portfolioID,
            false,
            false,
            [
                'ParentPortfolio($select=Title)',
                'OwnerL1($select=Title)',
                'OwnerL2($select=Title)',
                'OwnerL3($select=Title)',
                'ManagedByL2($select=Title)',
                'ManagedByL3($select=Title)',
                'PortfolioManagerUser($select=Title,EmailAddress)',
                'Contributors($expand=ContributorUser($select=Title,EmailAddress))',
                'Attributes($expand=AttributeType($select=Title))'
            ]
        );
    }

    public async readTitleForPortfolio(entityPlural: string, entitySingular: string, portfolioID: number): Promise<IEntityDescription1> {
        const entityDescription1: IEntityDescription1 = {
            Description1ForList: '',
            Description1ForForm: ''
        };
        const res = await this.readAll(`?$filter=ID eq ${portfolioID}&$select=Title`);
        if (res.length > 0) {
            entityDescription1.Description1ForList = `${entityPlural} for portfolio <span style='font-style: italic'>${res[0].Title}</span>`;
            entityDescription1.Description1ForForm = `${entitySingular} for portfolio <span style='font-style: italic'>${res[0].Title}</span>`;
        }

        return entityDescription1;
    }

    public readDraftReportPortfolios = (): Promise<IPortfolio[]> => {
        return this.readAll(`?$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID} and EntityStatusID eq ${EntityStatus.Open}`);
        // return this.readAll(
        //     `?$expand=Attributes($expand=AttributeType)`
        //     + `&$orderby=Title`
        //     + `&$filter=CustomerID eq ${AppGlobals.CustomerID} and EntityStatusID eq ${EntityStatus.Open}`
        // );
    }

    // public async readForView(directorateID: number): Promise<IDirectorate> {
    //     return this.read(directorateID, false, false, ['OrgLevel1($select=Title)', 'DirectorUser($select=Title,EmailAddress)', 'ReportApproverUser($select=Title,EmailAddress)', 'ReportingLeadUser($select=Title,EmailAddress)', 'Contributors($expand=ContributorUser($select=Title,EmailAddress))', 'Attributes($expand=AttributeType($select=Title))']);
    // }

    public readPortfolioApprovers = (portfolioId: number): Promise<IPortfolio> => {
        return this.read(portfolioId, false, false, ['PortfolioManagerUser'/*, 'ReportApproverUser', 'ReportingLeadUser'*/]);
    }


    public async entityChildren(id: number): Promise<IEntityChildren[]> {
        //TODO with actual children
        return [];
    }


    public async create(portfolio: IPortfolio): Promise<IPortfolio> {
        portfolio.CustomerID = AppGlobals.CustomerID;
        return super.create(portfolio);
    }

    public async countPortfolios(): Promise<number> {
        return (await this.readAll(`?$select=ID&$filter=EntityStatusID eq ${EntityStatus.Open}`))?.length;
    }
}