import { EntityService } from './EntityService';
import { IEntity, IUserAppLaunchLog } from '../types';

export class UserAppLaunchLogService extends EntityService<IUserAppLaunchLog> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/UserAppLaunchLogs`);
    }

    public getLastFive(): Promise<IEntity[]> {
        return this.readAll(`?getLastFive=`);
    }
}