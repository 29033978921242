import { EntityService } from './EntityService';
import { IEntity } from '../types';

export class AvailableExportService extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/AvailableExports`);
    }
    
    public readAllByModule(moduleName: string): Promise<IEntity[]> {
        return this.readAll(`?$filter=Module eq '${moduleName}'`);
    }
}