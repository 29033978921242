import { EntityService } from './EntityService';
import { IEntity, ICAElement } from '../types';

export class CAElementService extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/CAElements`);
    }

    public readElement(formId: number, defElementId: number): Promise<ICAElement[]> {
        return this.readAll(`?$filter=FormId eq ${formId} and DefElementId eq ${defElementId}`);
    }

    public readLastPeriodElement(periodId: number, teamId: number, formId: number, defElementId: number, defElementTitle: string): Promise<ICAElement> {
        return this.readEntity(`?periodId=${periodId}&teamId=${teamId}&formId=${formId}&defElementId=${defElementId}&defElementTitle=${defElementTitle}&getFromLastPeriod=`);
    }
}