import * as React from 'react';
import * as types from '../../types';
import * as services from '../../services';
import { CrTextField } from '../cr/CrTextField';
import { CrChoiceGroup } from '../cr/CrChoiceGroup';
import { CrDropdown } from '../cr/CrDropdown';
import { MessageDialog } from '../cr/MessageDialog';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import styles from '../../styles/cr.module.scss';
import { IEntity, ILinkLocalType, INAOUpdate, IUser, NAOUpdate } from '../../types';
import { IGenColumn, ColumnType, ColumnDisplayType } from '../../types/GenColumn';
import EvidenceList from './EV/EvidenceList';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { CrEntityPickerCat } from '../cr/CrEntityPickerCat';
import EntityListCat from '../entity/EntityListCat';
import { IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';
import { CrLoadingOverlay } from '../cr/CrLoadingOverlay';
import withMobileDetector from '../withMobileDetector';

export interface IPeriodUpdateTabProps extends types.IBaseComponentProps {
    filteredItems: any[];
    naoRecommendationId: any;
    naoPeriodId: any;
    onShowList: () => void;
    isViewOnly: boolean;
    isMobile: boolean;
}

export interface ILookupData {
    NAOUpdateStatusTypes: IEntity[];
    NAORecStatusTypes: IEntity[];
    Users: IUser[];
}

export class LookupData implements ILookupData {
    public NAOUpdateStatusTypes: IEntity[] = [];
    public NAORecStatusTypes: IEntity[] = [];
    public Users = null;
}

export interface IPeriodUpdateTabState {
    Loading: boolean;
    LookupData: ILookupData;
    FormData: INAOUpdate;
    RecInfo: INAOUpdate;
    Evidence_ListFilterText: string;
    NAORecommendationId: number;
    HideNoNextMessage: boolean;
    HideNextButton: boolean;
    ArrLinks: ILinkLocalType[];
    LastPeriodActions: string;
}

export class PeriodUpdateTabState implements IPeriodUpdateTabState {
    public Loading = false;
    public LookupData = new LookupData();
    public FormData;
    public RecInfo = null;
    public Evidence_ListFilterText: string = null;
    public NAORecommendationId: number = 0;
    public HideNoNextMessage: boolean = true;
    public HideNextButton: boolean = false;
    public ArrLinks: ILinkLocalType[] = [];
    public LastPeriodActions: string = "";

    constructor(naoPeriodId: number, naoRecommendationId: number) {
        this.FormData = new NAOUpdate(naoPeriodId, naoRecommendationId);

    }
}

class PeriodUpdateTab extends React.Component<IPeriodUpdateTabProps, IPeriodUpdateTabState> {

    private naoRecStatusTypeService: services.NAORecStatusTypeService = new services.NAORecStatusTypeService();
    private naoUpdateStatusTypeService: services.NAOUpdateStatusTypeService = new services.NAOUpdateStatusTypeService();
    private naoUpdateService: services.NAOUpdateService = new services.NAOUpdateService();
    private userService: services.UserService = new services.UserService();

    constructor(props: IPeriodUpdateTabProps, state: IPeriodUpdateTabState) {
        super(props);
        this.state = new PeriodUpdateTabState(props.naoPeriodId, props.naoRecommendationId);
    }

    public render(): React.ReactElement<IPeriodUpdateTabProps> {

        const loadingData: boolean = this.state.FormData?.ID > 0 ? false : true;

        return (
            <React.Fragment>
                {this.renderSectionTitle()}
                <div style={{ position: 'relative', minHeight: '400px' }}>
                    <CrLoadingOverlay isLoading={loadingData} />
                    {
                        this.state.FormData?.ID > 0 &&
                        <React.Fragment>
                            {this.renderInfoTable()}
                            {this.renderPeriodUpdateDetails()}
                            {this.renderFormButtons()}
                            {this.renderListsMainTitle()}
                            {this.renderEvidencesList()}
                            {this.renderFeedbacksList()}
                            {this.renderHistoricUpdatesList()}
                            {this.renderChangeLogs()}
                        </React.Fragment>
                    }
                </div>
                <MessageDialog hidden={this.state.HideNoNextMessage} title="Information" content="This is the last record in your list." handleOk={() => { this.setState({ HideNoNextMessage: true }); }} />
            </React.Fragment>
        );
    }

    private renderSectionTitle() {
        return (
            <React.Fragment>
                <h1 className={styles.headings2}>Period Update</h1>

            </React.Fragment>
        );
    }

    private renderInfoTable() {
        const recInfo = this.state.RecInfo;
        if (recInfo === null) return;

        const periodStartDate = new Date(String(recInfo["NAOPeriod"]["PeriodStartDate"]));
        const periodEndDate = new Date(String(recInfo["NAOPeriod"]["PeriodEndDate"]));
        const periodTitle: string = recInfo["NAOPeriod"]["Title"];
        const periodStartDateStr: string = services.DateService.dateToUkDate(periodStartDate);
        const periodEndDateStr: string = services.DateService.dateToUkDate(periodEndDate);
        let recDetails: string = recInfo["NAORecommendation"]["RecommendationDetails"];
        if (recDetails !== null)
            recDetails = recDetails?.split('\n').join('<br/>');
        else
            recDetails = "";

        let conclusion: string = recInfo["NAORecommendation"]["Conclusion"];
        if (conclusion !== null)
            conclusion = conclusion?.split('\n').join('<br/>');
        else
            conclusion = "";

        return (
            <React.Fragment>
                <div style={{ marginBottom: '20px', marginTop: '50px' }} className={styles.sectionATitle}>Recommendation Details</div>
                <div className={styles.infoTablePC}>
                    <table cellSpacing="0" cellPadding="10" className={styles.infoTable}>
                        <tbody>
                            <tr>
                                <td className={styles.infoTableLabelCol} style={{ borderTop: '1px solid rgb(166,166,166)', borderLeft: '1px solid rgb(166,166,166)', backgroundColor: 'rgb(229,229,229)' }}>
                                    Update Period
                                </td>
                                <td className={styles.infoTableValueCol} style={{ borderTop: '1px solid rgb(166,166,166)', borderLeft: '1px solid rgb(166,166,166)' }}>
                                    {`${periodTitle} ( ${periodStartDateStr} to ${periodEndDateStr} )`}
                                </td>
                                <td className={styles.infoTableLabelCol} style={{ borderTop: '1px solid rgb(166,166,166)', borderLeft: '1px solid rgb(166,166,166)', backgroundColor: 'rgb(229,229,229)' }}>
                                    Rec Ref
                                </td>
                                <td className={styles.infoTableValueCol} style={{ borderTop: '1px solid rgb(166,166,166)', borderLeft: '1px solid rgb(166,166,166)', borderRight: '1px solid rgb(166,166,166)' }}>
                                    {recInfo["NAORecommendation"]["Title"]}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid rgb(166,166,166)', borderLeft: '1px solid rgb(166,166,166)', backgroundColor: 'rgb(229,229,229)' }}>
                                    Report Conclusion
                                </td>
                                <td colSpan={3} style={{ borderTop: '1px solid rgb(166,166,166)', borderLeft: '1px solid rgb(166,166,166)', borderRight: '1px solid rgb(166,166,166)' }}>
                                    <div dangerouslySetInnerHTML={{ __html: conclusion }} ></div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid rgb(166,166,166)', borderLeft: '1px solid rgb(166,166,166)', borderBottom: '1px solid rgb(166,166,166)', backgroundColor: 'rgb(229,229,229)' }}>
                                    Recommendation
                                </td>
                                <td colSpan={3} style={{ borderTop: '1px solid rgb(166,166,166)', borderLeft: '1px solid rgb(166,166,166)', borderBottom: '1px solid rgb(166,166,166)', borderRight: '1px solid rgb(166,166,166)' }}>
                                    <div dangerouslySetInnerHTML={{ __html: recDetails }} ></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className={styles.infoTableMobile}>
                    <table cellSpacing="0" cellPadding="10" style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <td style={{ width: '110px', borderTop: '1px solid rgb(166,166,166)', borderLeft: '1px solid rgb(166,166,166)', backgroundColor: 'rgb(229,229,229)' }}>
                                    Update Period
                                </td>
                                <td style={{ borderTop: '1px solid rgb(166,166,166)', borderLeft: '1px solid rgb(166,166,166)', borderRight: '1px solid rgb(166,166,166)' }}>
                                    {`${periodTitle} ( ${periodStartDateStr} to ${periodEndDateStr} )`}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid rgb(166,166,166)', borderLeft: '1px solid rgb(166,166,166)', backgroundColor: 'rgb(229,229,229)' }}>
                                    Rec Ref
                                </td>
                                <td style={{ borderTop: '1px solid rgb(166,166,166)', borderLeft: '1px solid rgb(166,166,166)', borderRight: '1px solid rgb(166,166,166)' }}>
                                    {recInfo["NAORecommendation"]["Title"]}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid rgb(166,166,166)', borderLeft: '1px solid rgb(166,166,166)', backgroundColor: 'rgb(229,229,229)' }}>
                                    Report Conclusion
                                </td>
                                <td style={{ borderTop: '1px solid rgb(166,166,166)', borderLeft: '1px solid rgb(166,166,166)', borderRight: '1px solid rgb(166,166,166)' }}>
                                    <div dangerouslySetInnerHTML={{ __html: conclusion }} ></div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid rgb(166,166,166)', borderLeft: '1px solid rgb(166,166,166)', borderBottom: '1px solid rgb(166,166,166)', backgroundColor: 'rgb(229,229,229)' }}>
                                    Recommendation
                                </td>
                                <td style={{ borderTop: '1px solid rgb(166,166,166)', borderLeft: '1px solid rgb(166,166,166)', borderBottom: '1px solid rgb(166,166,166)', borderRight: '1px solid rgb(166,166,166)' }}>
                                    <div dangerouslySetInnerHTML={{ __html: recDetails }} ></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        );
    }

    private renderPeriodUpdateDetails() {
        const fd = this.state.FormData;

        return (
            <div>
                <div style={{ marginBottom: '20px', marginTop: '50px' }} className={styles.sectionATitle}>Period Update Details</div>
                <div style={{ width: '98%', marginTop: '10px', marginLeft: 'auto', marginRight: 'auto', paddingRight: '5px' }}>
                    <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                        We wish to provide an update for this period
                    </div>
                    <CrChoiceGroup
                        styles={{ flexContainer: { display: 'flex' } }}
                        style={{ marginBottom: '25px' }}
                        options={[
                            {
                                key: '1',
                                text: 'Yes\u00A0\u00A0\u00A0',
                            },
                            {
                                key: '0',
                                text: 'No'
                            },
                        ]}
                        selectedKey={fd.ProvideUpdate}
                        onChange={(ev, option) => this.changeChoiceGroup(ev, option, "ProvideUpdate")}
                    />

                    {fd.ProvideUpdate === '1' &&
                        <div className={styles.cr}>

                            <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                                Proposed Recommendation Status
                            </div>
                            <CrDropdown
                                style={{ width: '350px' }}
                                placeholder="Select an Option"
                                className={styles.formField}
                                options={this.state.LookupData.NAORecStatusTypes.map((p) => { return { key: p.ID, text: p.Title }; })}
                                selectedKey={fd.NAORecStatusTypeId}
                                onChange={(_, v) => this.changeDropdown(v, "NAORecStatusTypeId")}

                            />
                            <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                                Target Date
                            </div>
                            <div style={{ width: '350px' }}>

                                <CrTextField
                                    className={styles.formField}
                                    onChange={(_, v) => this.changeTextField(v, "TargetDate")}
                                    value={fd.TargetDate}
                                />
                            </div>
                            <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                                Actions Taken (Please mention by whom if not BEIS)
                            </div>
                            <CrTextField
                                className={styles.formField}
                                multiline
                                rows={6}
                                maxLength={6000}
                                charCounter={true}
                                onChange={(_, v) => this.changeTextField(v, "ActionsTaken")}
                                value={fd.ActionsTaken}
                            />
                            <div style={{ fontWeight: 'bold', fontStyle: 'italic', marginBottom: '5px' }}>
                                Actions Taken Previous Period:
                            </div>
                            <div style={{ fontStyle: 'italic', marginBottom: '30px' }}>
                                {this.state.LastPeriodActions}
                            </div>

                            {this.renderLinks()}
                            {this.renderApprovalSection()}

                            <div style={{ marginBottom: '15px', lineHeight: '1.5' }}>
                                <span style={{ fontWeight: 'bold' }}>Update Status:&nbsp;</span><span>{fd.LastSavedInfo}</span>
                            </div>
                        </div>
                    }

                </div>
            </div>
        );
    }



    public renderLinks() {

        return (
            <div>
                {this.props.isMobile !== true &&
                    <div className="form-container">
                        <div className="form-item">
                            <span>Link Text (ie. Treasury Minutes)</span>
                        </div>
                        <div className="form-item">
                            <span>Link URL (ie. http://bit.ly/hdydg)</span>
                        </div>
                        <div className="form-item-last">
                            <span>Add to Publication</span>
                        </div>
                    </div>
                }
                {this.state.ArrLinks.map((c, i) =>
                    this.renderLink(c, i)
                )}
                <div className={styles.formField}>
                    <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={this.addBlankLinkItem}>
                        Add fields For another link
                    </span>
                </div>
            </div>
        );
    }

    private renderLink(item: ILinkLocalType, index: number) {
        const isMobile = this.props.isMobile;

        return (
            <div key={`div_renderLink_${index}`} className="form-row">
                {isMobile && <div className="form-item">
                    <span>Link Text (ie. Treasury Minutes)</span>
                </div>}
                <div key={`divCol1_renderLink_${index}`} className="form-field">
                    <CrTextField key={`div_TextField1_${index}`} value={item.Description}
                        onChange={(_, v) => this.changeTextField_Link(v, index, "Description")}
                    />
                </div>

                {isMobile && <div className="form-item">
                    <span>Link URL (ie. http://bit.ly/hdydg)</span>
                </div>}
                <div key={`divCol2_renderLink_${index}`} className="form-field">
                    <CrTextField key={`div_TextField2_${index}`} value={item.URL}
                        onChange={(_, v) => this.changeTextField_Link(v, index, "URL")}
                    />
                </div>

                {isMobile && <div className="form-item-last">
                    <span>Add to Publication</span>
                </div>}
                <div key={`divCol3_renderLink_${index}`} className="form-field-last">
                    <CrChoiceGroup
                        styles={{ flexContainer: { display: 'flex' } }}
                        style={{ marginBottom: '20px' }}
                        options={[
                            {
                                key: 'False',
                                text: 'No\u00A0\u00A0\u00A0',
                            },
                            {
                                key: 'True',
                                text: 'Yes'
                            },
                        ]}
                        selectedKey={item.AddToPublication}
                        onChange={(ev, option) => this.changeChoiceGroup_Link(ev, option, index)}
                    />
                </div>
            </div>
        );
    }



    private renderApprovalSection() {

        const isMobile = this.props.isMobile;
        const users = this.state.LookupData.Users;
        if (users === null) return null;

        const drpOptions: IDropdownOption[] = [
            { key: 'Blank', text: '' },
            { key: 'Deputy Director', text: 'Deputy Director' },
            { key: 'Director', text: 'Director' },
            { key: 'Director General', text: 'Director General' },
            { key: 'Permanent Secretary', text: 'Permanent Secretary' },
        ];

        return (
            <div className={styles.formField}>
                {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                    <div className='two-fields-sidebyside-lbl'>
                        <span>Approved By</span>
                    </div>
                    <div className='two-fields-sidebyside-lbl'>
                        <span>Position</span>
                    </div>
                </div>}
                <div className='two-fields-sidebyside-r2'>
                    {isMobile && <div className="two-fields-sidebyside-lbl">
                        <span>Approved By</span>
                    </div>}
                    <div className='two-fields-sidebyside-field'>
                        <CrEntityPickerCat
                            displayForUser={true}
                            entities={this.state.LookupData.Users}
                            itemLimit={1}
                            selectedEntities={this.state.FormData.ApprovedById && [this.state.FormData.ApprovedById]}
                            onChange={(v) => this.changeUserPicker(v, 'ApprovedById')}
                        />
                    </div>
                    {isMobile && <div className="two-fields-sidebyside-lbl">
                        <span>Position</span>
                    </div>}
                    <div className='two-fields-sidebyside-field'>
                        <CrDropdown
                            placeholder="Select an Option"
                            options={drpOptions}
                            selectedKey={this.state.FormData.ApprovedByPosition}
                            onChange={(_, v) => this.changeDropdown(v, "ApprovedByPosition")}
                        />
                    </div>
                </div>
            </div>
        );
    }

    private renderFormButtons() {
        return (
            <div>
                {
                    (this.props.isViewOnly === false) &&
                    <React.Fragment>
                        {(this.state.HideNextButton === false) && <PrimaryButton text="Save &amp; Next" className={styles.formButton} style={{ marginRight: '5px' }}
                            onClick={() => this.saveData(true)}
                        />}

                        <PrimaryButton text="Save &amp; Close" className={styles.formButton} style={{ marginRight: '5px' }}
                            onClick={() => this.saveData(false)}
                        />
                        <DefaultButton text="Cancel" className={styles.formButton} style={{ marginRight: '5px' }}
                            onClick={this.props.onShowList}
                        />
                    </React.Fragment>
                }

                {(this.props.isViewOnly === true) &&
                    <div style={{ marginTop: '20px' }}>
                        {(this.state.HideNextButton === false) && <PrimaryButton text="Next" className={styles.formButton} style={{ marginRight: '5px' }}
                            onClick={() => this.showNext()}
                        />}

                        <DefaultButton text="Close" className={styles.formButton} style={{ marginRight: '5px' }}
                            onClick={this.props.onShowList}
                        />
                    </div>
                }
            </div>
        );
    }

    private renderListsMainTitle() {
        return (
            <div style={{ marginBottom: '20px', marginTop: '50px' }} className={styles.sectionATitle}>
                Evidence, Feedback, Previous Updates and Logs
            </div>
        );
    }

    private renderEvidencesList() {

        return (
            <React.Fragment>
                <div style={{ marginTop: '30px', fontWeight: "bold", marginBottom: '10px' }}>Evidence</div>
                <div style={{ minHeight: '120px' }}>
                    <EvidenceList
                        entityReadAllWithArg1={this.state.FormData.ID}
                        isViewOnly={false}
                        filterText={this.state.Evidence_ListFilterText}
                        onChangeFilterText={this.handleEvidence_ChangeFilterText}
                        {...this.props}
                        errorHandling={this.props.errorHandling}
                    />
                </div>

            </React.Fragment>
        );
    }

    private renderFeedbacksList() {
        const listColumns: IGenColumn[] = [
            {
                key: 'NAOUpdateId',
                columnDisplayType: ColumnDisplayType.FormOnly,
                fieldDisabled: true,
                fieldHiddenInForm: true,
                fieldDefaultValue: this.state.FormData.ID,
                columnType: ColumnType.TextBox,
                name: 'NAOUpdateId',
                fieldName: 'NAOUpdateId',
                minWidth: 1,
                isResizable: true,
                isRequired: true,
            },
            {
                key: 'NAOUpdateFeedbackTitle',
                columnType: ColumnType.DropDown,
                name: 'Comment Type',
                fieldName: 'NAOUpdateFeedbackTitle',
                idFieldName: 'NAOUpdateFeedbackTypeId',
                isParent: true,
                parentEntityName: 'NAOUpdateFeedbackType',
                parentColumnName: 'Title',
                parentService: new services.NAOUpdateFeedbackTypeService(),
                minWidth: 120,
                maxWidth: 120,
                isCollapsible: true,
                isResizable: true,
                isRequired: true,

            },
            {
                key: 'UserUsername',
                columnType: ColumnType.DisplayInListOnly,
                name: 'Comment By',
                fieldName: 'UserUsername',
                isParent: true,
                parentEntityName: 'User',
                parentColumnName: 'Title',
                minWidth: 80,
                maxWidth: 120,
                isResizable: true,
                isRequired: true,
                headerClassName: styles.bold,
            },
            {
                key: 'CommentDate',
                columnType: ColumnType.DatePicker,
                showDateAndTimeInList: true,
                columnDisplayType: ColumnDisplayType.ListOnly,
                name: 'Date/Time',
                fieldName: 'CommentDate',
                minWidth: 120,
                maxWidth: 120,
                isResizable: true,
                isCollapsible: true,
                fieldMaxLength: 100,
                headerClassName: styles.bold,
            },

            {
                key: 'Comment',
                columnType: ColumnType.TextBox,
                name: 'Comment',
                fieldName: 'Comment',
                minWidth: 200,
                isResizable: true,
                isRequired: true,
                fieldMaxLength: 10000,
                isMultiline: true,
                numRows: 5,
                headerClassName: styles.bold,
            },
        ];

        return (
            <React.Fragment>
                <div style={{ marginTop: '50px', fontWeight: "bold", marginBottom: '10px' }}>Feedback and General Comments</div>
                <div className={styles.cr} style={{ minHeight: '120px' }}>
                    <EntityListCat
                        entityReadAllWithArg1={this.state.FormData.ID}
                        allowAdd={true}
                        columns={listColumns}
                        {...this.props}
                        errorHandling={this.props.errorHandling}
                        entityService={new services.NAOUpdateFeedbackService()}
                        entityNamePlural="Comments"
                        entityNameSingular="Comment"
                        childEntityNameApi=""
                        childEntityNamePlural=""
                        childEntityNameSingular=""
                        zeroMarginTop={true}
                        hideTitleBelowCommandBar={true}
                    />
                </div>
                <div style={{ paddingTop: "10px", fontStyle: "italic" }}>
                    This area can be used to leave comments for other users.
                </div>

            </React.Fragment>
        );
    }

    private renderHistoricUpdatesList() {
        const listColumns: IGenColumn[] = [

            {
                key: 'NAOPeriodTitle',
                columnType: ColumnType.TextBox,
                name: 'Period Title',
                fieldName: 'NAOPeriodTitle',
                isParent: true,
                parentEntityName: 'NAOPeriod',
                parentColumnName: 'Title',
                minWidth: 80,
                maxWidth: 100,
                isResizable: true,
                isRequired: true,
                headerClassName: styles.bold,
            },
            {
                key: 'NAOPeriodStartDate',
                columnType: ColumnType.DatePicker,
                name: 'Period From',
                fieldName: 'NAOPeriodStartDate',
                isParent: true,
                parentEntityName: 'NAOPeriod',
                parentColumnName: 'PeriodStartDate',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isRequired: true,
                isCollapsible: true,
                headerClassName: styles.bold,
            },
            {
                key: 'NAOPeriodEndDate',
                columnType: ColumnType.DatePicker,
                name: 'Period To',
                fieldName: 'NAOPeriodEndDate',
                isParent: true,
                parentEntityName: 'NAOPeriod',
                parentColumnName: 'PeriodEndDate',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isRequired: true,
                isCollapsible: true,
                headerClassName: styles.bold,
            },
            {
                key: 'NAORecStatusTypeTitle',
                columnType: ColumnType.DisplayInListOnly,
                name: 'Proposed Status',
                fieldName: 'NAORecStatusTypeTitle',
                isParent: true,
                parentEntityName: 'NAORecStatusType',
                parentColumnName: 'Title',
                minWidth: 88,
                maxWidth: 88,
                isResizable: true,
                isRequired: true,
                isCollapsible: true,
                headerClassName: styles.bold,
            },
            {
                key: 'ActionsTaken',
                columnType: ColumnType.TextBox,
                name: 'Actions taken',
                fieldName: 'ActionsTaken',
                minWidth: 200,
                isResizable: true,
                isRequired: true,
                fieldMaxLength: 10000,
                isMultiline: true,
                numRows: 5,
                headerClassName: styles.bold,
            },
            {
                key: 'TargetDate',
                columnType: ColumnType.TextBox,
                name: 'Target Date',
                fieldName: 'TargetDate',
                minWidth: 100,
                isResizable: true,
                isRequired: true,
                isCollapsible: true,
                headerClassName: styles.bold,
            },
        ];

        return (
            <React.Fragment>
                <div style={{ marginTop: '50px', fontWeight: "bold", marginBottom: '10px' }}>Historic Updates</div>
                <div className={styles.cr} style={{ minHeight: '120px' }}>
                    <EntityListCat
                        entityReadAllWithArg1={this.state.NAORecommendationId}
                        entityReadAllWithArg2={this.props.naoPeriodId}
                        allowAdd={false}
                        columns={listColumns}
                        {...this.props}
                        errorHandling={this.props.errorHandling}
                        entityService={new services.NAOUpdateService()}
                        entityNamePlural="Updates"
                        entityNameSingular="Update"
                        childEntityNameApi=""
                        childEntityNamePlural=""
                        childEntityNameSingular=""
                        zeroMarginTop={true}
                        hideTitleBelowCommandBar={true}
                    />
                </div>
            </React.Fragment>
        );
    }

    private renderChangeLogs() {
        const fd = this.state.FormData;
        let changeLog = fd.UpdateChangeLog ? fd.UpdateChangeLog : "";
        let changeLogArr = changeLog?.split(',');
        let changeLogs = "";

        changeLogArr.reverse().forEach(log => {
            if (log?.length > 0) {
                changeLogs += `${log}<br />`;
            }
        });

        return (
            <React.Fragment>
                <div style={{ marginTop: "30px" }}>
                    <div style={{ fontWeight: 'bold' }}>Change Log:</div>
                    <div style={{ marginTop: "20px" }} dangerouslySetInnerHTML={{ __html: changeLogs }} />
                </div>
            </React.Fragment>
        );
    }

    private validateEntity = (): boolean => {
        return true;
    }
    private saveData = (showNext: boolean): void => {
        this.saveLinksToSingleValue(showNext);
    }
    private saveLinksToSingleValue = (showNext: boolean): void => {

        let singleStr: string = "";
        const arrLinks = this.state.ArrLinks;

        for (let i = 0; i < arrLinks?.length; i++) {
            let item: ILinkLocalType = arrLinks[i];
            if (item.Description.trim() === '' && item.URL.trim() === '') {
                //ignore this item
            }
            else {
                if (item.URL.trim() !== '') {
                    let description: string = item.Description !== '' ? item.Description : item.URL;
                    //use '<' for separator between description and url, And use '>' for next item separator
                    singleStr += `${description}<${item.URL.trim()}<${item.AddToPublication}>`;
                }
            }
        }

        //set single value in state
        const fd = { ...this.state.FormData };
        fd.FurtherLinks = singleStr;

        this.setState({ FormData: fd }, () => this.saveDataFinal(showNext));

    }
    private saveDataFinal = (showNext: boolean): void => {
        if (this.validateEntity()) {
            if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(null);
            let f: INAOUpdate = { ...this.state.FormData };
            this.naoUpdateService.create(f).then((): void => {
                if (this.props.errorHandling?.onError)
                    this.props.errorHandling?.onError(null);

                if (showNext === true) {
                    this.showNext();
                }
                else {
                    this.props.onShowList();
                }
            }, (err) => {
                if (this.props.errorHandling?.onError)
                    this.props.errorHandling?.onError(`Error saving update`, err.message);
            });
        }
    }

    private showNext = (): void => {
        const currentNAORecId: number = Number(this.state.NAORecommendationId);
        let currentIDFound: boolean = false;
        let nextNAORecID: number = 0;
        for (let i = 0; i < this.props.filteredItems?.length; i++) {
            let e: any = this.props.filteredItems[i];
            const id: number = Number(e["ID"]);

            if (id === currentNAORecId) {
                currentIDFound = true;
                continue;
            }
            if (currentIDFound === true) {
                nextNAORecID = id;
                break;
            }
        }

        if (nextNAORecID > 0) {
            this.setState({
                NAORecommendationId: nextNAORecID,
            }, () => this.loadUpdate(false));
        }
        else {

            //this condition will not run cause we are already hiding next buttons
            this.setState({
                HideNoNextMessage: false,
            });
        }
    }

    private loadNAORecStatusTypes = (): Promise<void | IEntity[]> => {
        return this.naoRecStatusTypeService.readAll(`?$orderby=ID`).then((data: IEntity[]): IEntity[] => {
            this.setState({ LookupData: this.cloneObject(this.state.LookupData, 'NAORecStatusTypes', data) });
            return data;
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading NAORecStatusTypes lookup data`, err.message); });
    }

    private loadNAOUpdateStatusTypes = (): Promise<void | IEntity[]> => {
        return this.naoUpdateStatusTypeService.readAll(`?$orderby=ID`).then((data: IEntity[]): IEntity[] => {
            this.setState({ LookupData: this.cloneObject(this.state.LookupData, 'NAOUpdateStatusTypes', data) });
            return data;
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading NAOUpdateStatusTypes lookup data`, err.message); });
    }

    private loadUpdate = (firstLoad: boolean): void => {

        this.naoUpdateService.readByPeriodAndRec(this.state.NAORecommendationId, this.props.naoPeriodId).then((u: INAOUpdate) => {
            const lastRecId_FilteredItems: number = Number(this.props.filteredItems[this.props.filteredItems?.length - 1]["ID"]);
            const recId_Current: number = Number(this.state.NAORecommendationId);
            let hideNextButton: boolean = false;
            if (recId_Current === lastRecId_FilteredItems) {
                hideNextButton = true;
            }


            /**************************************links************************************************************** */

            let arrLinks: ILinkLocalType[] = [];

            //unpack publication links from single value
            if (u.FurtherLinks !== null && u.FurtherLinks !== '') {
                let arr1 = u.FurtherLinks?.split('>');
                for (let i = 0; i < arr1?.length; i++) {

                    let itemStr: string = arr1[i];
                    if (itemStr.trim() === '') {
                        continue;
                    }
                    let arr2 = itemStr?.split('<');
                    let item: ILinkLocalType = { Description: '', URL: '' };
                    item.Description = arr2[0];
                    item.URL = arr2[1];
                    item.AddToPublication = 'False'; //always False on data load
                    arrLinks.push(item);
                }
            }

            /************************************links end***************************************************************** */

            this.setState({
                FormData: u,
                ArrLinks: arrLinks,
                HideNextButton: hideNextButton
            }, this.loadRecInfo);


        }, (err) => {
            if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading update`, err.message);
        });

        this.naoUpdateService.getLastPeriodActionsTaken(this.state.NAORecommendationId, this.props.naoPeriodId).then((res: string): void => {
            this.setState({
                LastPeriodActions: res,
            });

        }, (err) => {

        });
    }

    private loadRecInfo = (): void => {
        console.log('in loadRecInfo start', this.state.FormData);
        if (this.state.FormData.ID > 0) {
            this.addBlankLinkItem();
            this.naoUpdateService.getRecInfo(this.state.FormData.ID).then((u: INAOUpdate) => {
                console.log('Rec Info', u);
                this.setState({
                    RecInfo: u
                });
            }, (err) => {
                if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading rec info`, err.message);
            });
        }

    }

    private addBlankLinkItem = () => {
        const arrCopy = [...this.state.ArrLinks, { Description: '', URL: '', AddToPublication: 'False' }];
        this.setState({ ArrLinks: arrCopy });
    }

    private loadUsers = (): void => {
        this.userService.readAll().then((data: IUser[]): IUser[] => {
            this.setState({ LookupData: this.cloneObject(this.state.LookupData, "Users", data) });
            return data;
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading Users lookup data`, err.message); });
    }

    protected loadLookups(): Promise<any> {
        return Promise.all([
            this.loadNAORecStatusTypes(),
            this.loadNAOUpdateStatusTypes(),
            this.loadUsers(),
            this.loadUpdate(true),
        ]);
    }


    public componentDidMount(): void {
        this.setState({ Loading: true, NAORecommendationId: Number(this.props.naoRecommendationId) }, this.callBackFirstLoad
        );
    }
    private callBackFirstLoad = (): void => {
        let loadingPromises = [this.loadLookups()];
        Promise.all(loadingPromises).then(p => this.setState({ Loading: false })).catch(err => this.setState({ Loading: false }));
    }

    private handleEvidence_ChangeFilterText = (value: string): void => {
        this.setState({ Evidence_ListFilterText: value });
    }

    protected cloneObject(obj, changeProp?, changeValue?) {
        if (changeProp)
            return { ...obj, [changeProp]: changeValue };
        return { ...obj };
    }

    protected changeTextField = (value: string, f: string): void => {
        this.setState({ FormData: this.cloneObject(this.state.FormData, f, value)/*, FormIsDirty: true*/ });
    }
    private changeTextField_Link = (value: string, index: number, type: string): void => {
        const arrCopy = [...this.state.ArrLinks];
        const item: ILinkLocalType = arrCopy[index];
        if (type === "Description") {
            item.Description = value;
        }
        else {
            item.URL = value;
        }

        this.setState({ ArrLinks: arrCopy });
    }
    private changeChoiceGroup_Link = (ev, option: IChoiceGroupOption, index: number): void => {
        const selectedKey = option.key;
        const arrCopy = [...this.state.ArrLinks];
        const item: ILinkLocalType = arrCopy[index];
        item.AddToPublication = selectedKey;
        this.setState({ ArrLinks: arrCopy });
    }

    protected changeCheckbox = (value: boolean, f: string): void => {
        this.setState({ FormData: this.cloneObject(this.state.FormData, f, value)/*, FormIsDirty: true*/ });
    }

    private changeDropdown = (option: IDropdownOption, f: string, index?: number): void => {
        this.setState({ FormData: this.cloneObject(this.state.FormData, f, option.key), /*FormIsDirty: true*/ });
    }

    protected changeChoiceGroup = (ev, option: IChoiceGroupOption, f: string): void => {
        const selectedKey = option.key;
        this.setState({ FormData: this.cloneObject(this.state.FormData, f, selectedKey)/*, FormIsDirty: true*/ });

    }

    private changeUserPicker = (value: number[], f: string): void => {
        this.setState({ FormData: this.cloneObject(this.state.FormData, f, value?.length === 1 ? value[0] : null), });
    }

}

export default withMobileDetector(PeriodUpdateTab)