import { EntityService } from './EntityService';
import { GIAAImport, IGIAAImportInfo, IEntity } from '../types';

export class GIAAImportService extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/GIAAImports`);
    }

    public getImportInfo(): Promise<IGIAAImportInfo> {
        return this.readEntity(`?getInfo=true`);
    }
}