import { EntityService } from './EntityService';
import { ICLWorker } from '../types';

export class CLWorkerService extends EntityService<ICLWorker> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/CLWorkers`);
    }

    public createSDSPDF(clWorkerId: number): Promise<string> {
        return super.readString(`?clWorkerId=${clWorkerId}&createPdf=SDSPdf&spSiteUrl=`).then((result: string): string => {
            return result;
        });
    }

    public createCasePDF(clWorkerId: number): Promise<string> {
        return super.readString(`?clWorkerId=${clWorkerId}&createPdf=CasePdf&spSiteUrl=`).then((result: string): string => {
            return result;
        });
    }

    public archive(clWorkerId: number): Promise<string> {
        return super.readString(`?clWorkerId=${clWorkerId}&archive=true`).then((result: string): string => {
            return result;
        });
    }
}