import * as React from 'react';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import Section1Update from './Section1Update';
import Section2Update from './Section2Update';
import Section3Update from './Section3Update';
import Section4Update from './Section4Update';
import UpdateForm from './UpdateForm';
import * as types from '../../types';
import * as services from '../../services';
import { IEntity, IGoDefForm, GoForm, IGoForm, IOrgLevel1, IUserContext } from '../../types';
import { ICAPeriod } from '../../types/CAPeriod';
import { CrLoadingOverlayWelcomeCat } from '../cr/CrLoadingOverlayWelcomeCat';
import { CrDropdown } from '../cr/CrDropdown';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';

export interface IGoUpdatesClsProps extends types.IBaseComponentProps {
    userContext: IUserContext;
}

export interface ILookupData {
    GoDefForm: IGoDefForm;
    Periods: IEntity[];
    DGAreas: IOrgLevel1[];
}

export class LookupData implements ILookupData {
    public GoDefForm: IGoDefForm;
    public Periods: ICAPeriod[] = [];
    public DGAreas: IOrgLevel1[] = [];
}

export interface IGoUpdatesClsState {
    Loading: boolean;
    LookupData: ILookupData;
    PeriodId: string | number;
    DirectorateGroupId: string | number;
    IsArchivedPeriod: boolean;
    GoForm: IGoForm;
    SelectedPivotKey: string;
    Section2_IsOpen: boolean;
    Section2_IncompleteOnly: boolean;
    Section2_JustMine: boolean;
    Section2_ListFilterText: string;
    Section2_SelectedDefElementId: number;
    Section2_SelectedElementId: number;
    Section2_SelectedDefElementTitle: string;
    FilteredItems: any[];
}
export class GoUpdatesClsState implements IGoUpdatesClsState {
    public Loading = false;
    public LookupData = new LookupData();
    public PeriodId: string | number = 0;
    public IsArchivedPeriod = false;
    public DirectorateGroupId: string | number = 0;
    public GoForm: IGoForm = null;
    public SelectedPivotKey = "Governance-Updates"; //default, 1st tab selected
    public Section2_IsOpen: boolean = false;
    public Section2_IncompleteOnly = false;
    public Section2_JustMine = false;
    public Section2_ListFilterText: string = null;
    public Section2_SelectedDefElementId: number = 0;
    public Section2_SelectedElementId: number = 0;
    public Section2_SelectedDefElementTitle: string = null;
    public FilteredItems = [];
    constructor() {
    }
}

export default class GoUpdatesCls extends React.Component<IGoUpdatesClsProps, GoUpdatesClsState> {
    private goDefFormService: services.GoDefFormService = new services.GoDefFormService();
    private goFormService: services.GoFormService = new services.GoFormService();
    private periodService: services.GoPeriodService = new services.GoPeriodService();
    private deirectorateGroupService: services.OrgLevel1Service = new services.OrgLevel1Service();
    private readonly headerTxt_Updates: string = "Governance-Updates";
    private readonly headerTxt_UpdateForm: string = "Details";

    constructor(props: IGoUpdatesClsProps) {
        super(props);
        this.state = new GoUpdatesClsState();
    }

    public render(): React.ReactElement<IGoUpdatesClsProps> {
        return (
            <Pivot onLinkClick={this.handlePivotClick} selectedKey={`${this.state.SelectedPivotKey}`}>
                <PivotItem headerText={this.headerTxt_Updates} itemKey={this.headerTxt_Updates}>
                    {this.renderMyUpdates()}
                </PivotItem>
                {this.renderUpdateFormTab()}
            </Pivot>
        );
    }

    private renderMyUpdates(): React.ReactElement<types.IWebPartComponentProps> {
        const { LookupData: lookups } = this.state;

        return (
            <div>
                <CrLoadingOverlayWelcomeCat isLoading={this.state.Loading} />
                <div style={{ paddingTop: "10px" }}>
                    <CrDropdown
                        placeholder="Select an Option"
                        label="Which period do you want to view or report on?"
                        options={lookups.Periods.map((p) => { return { key: p.ID, text: p.Title }; })}
                        onChange={(_, v) => this.changeDropdown(v, 'PeriodId')}
                        selectedKey={this.state.PeriodId}
                    />
                    <CrDropdown
                        placeholder="Select an Option"
                        label="Which DGArea?"
                        options={lookups.DGAreas.map((d) => { return { key: d.ID, text: d.Title }; })}
                        onChange={(_, v) => this.changeDropdown(v, 'DirectorateGroupId')}
                        selectedKey={this.state.DirectorateGroupId}
                    />
                    <br />
                    {Number(this.state.PeriodId) > 0 && Number(this.state.DirectorateGroupId) > 0 && this.state.GoForm &&
                        <div>
                            <Section1Update
                                goDefForm={this.state.LookupData.GoDefForm}
                                goForm={this.state.GoForm}
                                isViewOnly={this.isViewOnlyGoForm()}
                                onSaved={this.readOrCreateGoFormInDb} //to refresh goForm in state
                                {...this.props}
                            />
                            <Section2Update
                                goDefForm={this.state.LookupData.GoDefForm}
                                goFormId={this.state.GoForm.ID}
                                section2CompletionStatus={this.state.GoForm.SpecificAreasCompletionStatus}
                                onItemTitleClick={this.handleSection2_ListItemTitleClick}
                                section2_IsOpen={this.state.Section2_IsOpen}
                                onSection2_toggleOpen={this.handleSection2_toggleOpen}
                                justMine={this.state.Section2_JustMine}
                                incompleteOnly={this.state.Section2_IncompleteOnly}
                                listFilterText={this.state.Section2_ListFilterText}
                                onChangeFilterText={this.handleSection2_ChangeFilterText}
                                onChangeIncompleteOnly={this.handleSection2_ChangeIncompleteOnly}
                                onChangeJustMine={this.handleSection2_ChangeJustMine}

                                {...this.props}
                            />
                            <Section3Update
                                goDefForm={this.state.LookupData.GoDefForm}
                                goForm={this.state.GoForm}
                                canSignOff={this.canSignOff()}
                                canUnSign={this.canUnSign()}
                                onSignOff={this.readOrCreateGoFormInDb} //to refresh goForm in state
                                {...this.props} />
                            <Section4Update {...this.props} />
                        </div>
                    }

                </div>
            </div>
        );
    }

    private renderUpdateFormTab() {
        if (this.state.SelectedPivotKey === this.headerTxt_UpdateForm) {
            return (
                <PivotItem headerText={this.headerTxt_UpdateForm} itemKey={this.headerTxt_UpdateForm}>
                    {this.renderUpdateForm()}
                </PivotItem>
            );
        }
        else
            return <React.Fragment></React.Fragment>;
    }
    private renderUpdateForm(): React.ReactElement<types.IWebPartComponentProps> {
        return (
            <UpdateForm
                filteredItems={this.state.FilteredItems}
                goElementId={this.state.Section2_SelectedElementId}
                isViewOnly={this.isViewOnlyGoForm()}
                onShowList={this.handleShowListSection2}
                {...this.props}
            />
        );
    }

    private loadDefForm = (): Promise<void | IGoDefForm> => {
        return this.goDefFormService.read(1).then((df: IGoDefForm): IGoDefForm => {
            this.setState({ LookupData: this.cloneObject(this.state.LookupData, 'GoDefForm', df) });
            return df;
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading DefForm lookup data`, err.message); });
    }

    private loadPeriods = (): Promise<void | ICAPeriod[]> => {
        return this.periodService.readAll().then((pArr: ICAPeriod[]): ICAPeriod[] => {
            //get the current period
            let currentPeriodId: number = 0;
            const currentPeriod = pArr.filter(p => p.PeriodStatus === "Current Period");
            if (currentPeriod && currentPeriod.length > 0) {
                currentPeriodId = currentPeriod[0].ID;
            }

            //show status like Qtr 2 2019 ( Current Period ) in Title
            for (let i = 0; i < pArr.length; i++) {
                let p: ICAPeriod = pArr[i];
                pArr[i].Title = `${p.Title} ( ${p.PeriodStatus} )`;
            }

            //check user permissions
            if (this.isSuperUser() === true) {
            }
            else {
                //dont show design periods
                pArr = pArr.filter(p => p.PeriodStatus !== "Design Period");
            }

            this.setState({
                LookupData: this.cloneObject(this.state.LookupData, 'Periods', pArr),
                PeriodId: currentPeriodId
            });
            return pArr;
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading Periods lookup data`, err.message); });
    }

    private loadDGAreas = (): Promise<void | IEntity[]> => {
        return this.deirectorateGroupService.readAllForGoList().then((data: IEntity[]): IEntity[] => {
            this.setState({ LookupData: this.cloneObject(this.state.LookupData, 'DGAreas', data) });
            return data;
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading Teams lookup data`, err.message); });
    }

    private loadLookups(): Promise<any> {
        return Promise.all([
            this.loadPeriods(),
            this.loadDGAreas(),
            this.loadDefForm(),
        ]).then(() => {
            this.setState({ Loading: false })
        });
    }

    public componentDidMount(): void {
        this.setState({ Loading: true });
        this.loadLookups();
    }

    private isSuperUser(): boolean {
        return this.props.userPermissions.UserIsSystemAdmin()
            || this.props.userPermissions.UserIsGovernanceSuperUser();
    }

    private isViewOnlyGoForm = (): boolean => {

        if (this.state.GoForm && this.state.GoForm.DGSignOffStatus === "Completed") {
            return true;
        }

        //ToDo commented out following
        //DirectorateGroup member check
        // let dgms = this.state.DirectorateGroupMembers;
        // for (let i = 0; i < dgms.length; i++) {
        //     let dgm: types.IDirectorateGroupMember = dgms[i];
        //     if (dgm.ViewOnly === true) {
        //         if (this.state.DirectorateGroupId === dgm.DirectorateGroupID) {
        //             return true;
        //         }

        //     }

        // }

        return false;

    }

    private canSignOff(): boolean {

        //Archived Period check - dont allow if period is archived
        if (this.state.IsArchivedPeriod === true)
            return false;

        if (this.props.userContext.DirectorGeneralOf.length > 0) {
            return true;
        }

        // //DirectorateGroup member check
        // let dgms = this.state.DirectorateGroupMembers;
        // for (let i = 0; i < dgms.length; i++) {
        //     let dgm: types.IDirectorateGroupMember = dgms[i];
        //     if (Number(this.state.DirectorateGroupId) === dgm.DirectorateGroupID) {
        //         if (dgm.ViewOnly === true) {
        //             return false;
        //         }
        //         else {
        //             //admin check
        //             if (dgm.IsAdmin === true) {
        //                 return true;
        //             }

        //         }
        //     }

        // }

        return false;
    }

    private canUnSign(): boolean {

        //Archived Period check - dont allow if period is archived
        if (this.state.IsArchivedPeriod === true)
            return false;

        if (this.props.userContext.DirectorGeneralOf.length > 0) {
            return true;
        }

        if (this.isSuperUser() === true)
            return true;

        //DirectorateGroup member check
        // let dgms = this.state.DirectorateGroupMembers;
        // for (let i = 0; i < dgms.length; i++) {
        //     let dgm: types.IDirectorateGroupMember = dgms[i];
        //     if (Number(this.state.DirectorateGroupId) === dgm.DirectorateGroupID) {
        //         if (dgm.ViewOnly === true) {
        //             return false;
        //         }
        //         else {
        //             //admin check
        //             if (dgm.IsAdmin === true) {
        //                 return true;
        //             }
        //         }
        //     }

        //}

        return false;
    }

    private changeDropdown = (option: IDropdownOption, f: string, index?: number): void => {
        if (f === "PeriodId") {
            if (option.key !== this.state.PeriodId) {
                const pArrTemp: ICAPeriod[] = this.state.LookupData.Periods.filter(p => p.ID === option.key);
                let isArchivedPeriod: boolean = false;
                if (pArrTemp.length > 0) {
                    if (pArrTemp[0].PeriodStatus === "Archived Period") {
                        isArchivedPeriod = true;
                    }
                }

                this.setState({ PeriodId: option.key, IsArchivedPeriod: isArchivedPeriod },
                    this.readOrCreateGoFormInDb
                );
            }
        }
        else {
            //f === "DirectorateGroupId"
            this.setState({ DirectorateGroupId: option.key },
                this.readOrCreateGoFormInDb
            );
        }
    }

    private readOrCreateGoFormInDb = (): void => {

        if (Number(this.state.PeriodId) > 0 && Number(this.state.DirectorateGroupId) > 0) {
            const goForm = new GoForm(Number(this.state.PeriodId), Number(this.state.DirectorateGroupId));
            goForm.Title = "_ADD_ONLY_IF_DOESNT_EXIST_"; //send this msg to api, so it doesnt do any change if goForm already exist in the db
            delete goForm.ID;
            delete goForm.SummaryRagRating;
            delete goForm.SummaryEvidenceStatement;
            delete goForm.SummaryCompletionStatus;
            delete goForm.SummaryMarkReadyForApproval;
            delete goForm.SpecificAreasCompletionStatus;
            delete goForm.DGSignOffStatus;
            delete goForm.DGSignOffUserId;
            delete goForm.DGSignOffDate;

            //following service only adds form in db if its needed
            this.goFormService.create(goForm).then((newForm: IGoForm): void => {
                //this.setState({ GoFormId: newForm.ID });
                this.setState({ GoForm: newForm });
            }, (err) => { });
        }
    }


    private handlePivotClick = (item: PivotItem): void => {
        //this.clearErrors();
        this.setState({ SelectedPivotKey: item.props.headerText });
    }

    private handleSection2_ListItemTitleClick = (ID: number, goElementId, title: string, filteredItems: any[]): void => {
        this.setState({
            SelectedPivotKey: this.headerTxt_UpdateForm,
            Section2_SelectedDefElementId: ID,
            Section2_SelectedElementId: goElementId,
            Section2_SelectedDefElementTitle: title,
            FilteredItems: filteredItems
        });
    }

    private handleSection2_toggleOpen = (): void => {
        this.setState({ Section2_IsOpen: !this.state.Section2_IsOpen });
    }

    private handleSection2_ChangeFilterText = (value: string): void => {
        this.setState({ Section2_ListFilterText: value });
    }

    private handleSection2_ChangeIncompleteOnly = (value: boolean): void => {
        this.setState({ Section2_IncompleteOnly: value });
    }

    private handleSection2_ChangeJustMine = (value: boolean): void => {
        this.setState({ Section2_JustMine: value });
    }

    private handleShowListSection2 = (): void => {
        //this.clearErrors();
        this.setState({ SelectedPivotKey: this.headerTxt_Updates }, this.readOrCreateGoFormInDb);
    }
    private cloneObject(obj, changeProp?, changeValue?) {
        if (changeProp)
            return { ...obj, [changeProp]: changeValue };
        return { ...obj };
    }
}