import { EntityService } from './EntityService';
import { ICADefElementGroup } from '../types';

export class CADefElementGroupService extends EntityService<ICADefElementGroup> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/CADefElementGroups`);
    }

    public readAllDefElementGroups(defFormId: number): Promise<ICADefElementGroup[]> {
        return this.readAll(`?$filter=DefFormId eq ${defFormId}&$orderby=Sequence`);
    }

    public readAllExpandAll(): Promise<ICADefElementGroup[]> {
        return this.readAll(`?$orderby=DefForm/Title&$expand=DefForm`);
    }
    public readAllWithArgs(periodId: number): Promise<ICADefElementGroup[]> {
        return this.readAll(`?$filter=PeriodId eq ${periodId}`);
    }

}