import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IApp03Workflow, ListDefaults } from '../../types';
import { EntityList } from '../EntityList';
import { ErrorBoundary } from '../ErrorBoundary';
import { IWithErrorHandlingProps, withErrorHandling } from '../withErrorHandling';
import { IUseApiProps } from '../useApi';
import { renderPortfolioIcons, renderWithBackgroundColor, renderWorkflowIcon } from '../cr/ListRenderers';
import { DataContext } from '../DataContext';
import useMobileDetector from '../useMobileDetector';
import { TaskPriority, getWorkflowTypeString } from '../../refData/WorkflowType';
import { WorkflowForm } from './WorkflowForm';
import { UserWorkflowForm } from './UserWorkflowForm';
import { OrbUserContext } from '../OrbUserContext';
import { WorkflowView } from './WorkflowView';

export interface IApp03WorkflowsAdminProps extends IUseApiProps, IWithErrorHandlingProps {
    entity: string;
}


const App03WorkflowsAdmin = (props: IApp03WorkflowsAdminProps) => {
    const { entity } = props;
    const { dataServices } = useContext(DataContext);
    const { userContext } = useContext(OrbUserContext);
    const isMobile = useMobileDetector();
    const history = useHistory();

    const { workflowIdForWorkflowUsers } = useParams<{ workflowIdForWorkflowUsers: string }>();
    const workflowNameWidths = isMobile ? { minWidth: 90, maxWidth: 150 } : { minWidth: 150, maxWidth: 250 };


    return (
        <ErrorBoundary>
            {entity === 'Workflows' &&
                <EntityList<IApp03Workflow>
                    {...props}
                    disableAdd={false}
                    enableShowHideClosedEntities={true}
                    entityName={{ Plural: 'Workflows', Singular: 'Workflow' }}
                    barColorBeforeTitle='#40A182'
                    additionalItemsOnSelection={(wf) => {
                        return ([
                            {
                                key: 'Access',
                                name: 'Access',
                                iconProps: { iconName: 'FabricUserFolder' },
                                onClick(ev, item) {
                                    history.push(`/corporate-workflows/workflow-users/${wf?.ID}`);
                                },
                            },
                            {
                                key: 'Tasks',
                                name: 'Tasks',
                                iconProps: { iconName: 'MiniExpand' },
                                onClick(ev, item) {
                                    history.push(`/corporate-workflows/workflow-tasks/${wf?.ID}`);
                                },
                            },
                        ]);
                    }}
                    columns={[
                        { key: '0', name: '', minWidth: 28, maxWidth: 28, isResizable: true, onRender: item => renderWorkflowIcon(item.WorkflowTypeID) },
                        { key: '1', name: 'Name', fieldName: 'Name', ...workflowNameWidths, isResizable: true },
                        { key: '2', name: 'Active Tasks', fieldName: 'ActiveTasks', minWidth: 80, maxWidth: 100, isCollapsible: true, isResizable: true },
                        { key: '3', name: 'Critical', fieldName: 'CriticalTasks', minWidth: 60, maxWidth: 60, isCollapsible: true, isResizable: true, onRender: item => renderWithBackgroundColor(item.CriticalTasks, 'red', 'white') },
                        { key: '4', name: 'Type', fieldName: 'Type', minWidth: 80, maxWidth: 100, isCollapsible: true, isResizable: true },
                        { key: '5', name: 'Owners', fieldName: 'Administrators', minWidth: 150, maxWidth: 200, isResizable: true, isCollapsible: true, isMultiline: true },
                    ]}
                    loadListItems={showClosedEntities => dataServices.app03WorkflowService.readAllForList(showClosedEntities)}
                    mapEntitiesToListItems={workflows => workflows.map(wf => {

                        const totalTasks: number = wf.App03Tasks?.length ?? 0;
                        const totalUserTasks: number = wf.App03Tasks?.filter(task => task.App03TaskUsers.some(user => user.UserID === userContext.UserId)).length ?? 0;


                        const totalCriticalTasks: number = wf.App03Tasks?.filter(t => t.TaskPriorityID === TaskPriority.Critical).length ?? 0;
                        const totalUserCriticalTasks: number = wf.App03Tasks?.filter(t => t.TaskPriorityID === TaskPriority.Critical && t.App03TaskUsers.some(user => user.UserID === userContext.UserId)).length ?? 0;

                        return {
                            key: wf.ID,
                            WorkflowTypeID: wf.WorkflowTypeID,
                            Name: wf.Title,
                            ActiveTasks: `${totalUserTasks}/${totalTasks}`, // '2/10',
                            CriticalTasks: `${totalUserCriticalTasks}/${totalCriticalTasks}`,
                            Type: getWorkflowTypeString(wf.WorkflowTypeID),
                            Administrators: [
                                wf.App03WorkflowUsers && wf.App03WorkflowUsers.some(a => a.User != null) ? `${wf.App03WorkflowUsers.map(pc => pc.User?.Title).join(', ')}` : null,
                            ].filter(Boolean).join(', '),
                        };
                    })}
                    entityForm={(showForm, entityId, onSaved, onCancelled, entityDescription1) =>
                        <WorkflowForm
                            {...props}
                            showForm={showForm}
                            entityId={entityId}
                            onSaved={onSaved}
                            onCancelled={onCancelled}
                        />
                    }
                    viewForm={(showForm, entityId, onClosed, entityDescription1) =>
                        <WorkflowView
                            {...props}
                            showForm={showForm}
                            entityId={entityId}
                            entityName='Workflow'
                            loadEntity={workflowID => dataServices.app03WorkflowService.readForView(workflowID)}
                            onClosed={onClosed}
                        />
                    }
                    onCheckDelete={id => dataServices.app03WorkflowService.entityChildren(id)}
                    onDelete={id => dataServices.app03WorkflowService.delete(id)}
                />
            }
            {entity === 'WorkflowUsers' &&
                <EntityList
                    {...props}
                    entityName={{ Plural: 'Workflow Users', Singular: 'Workflow User' }}
                    columns={[
                        { key: '1', name: 'User', fieldName: 'User', minWidth: 200, maxWidth: 300, isResizable: true },
                        { key: '3', name: 'Owner', fieldName: 'IsAdmin', minWidth: 150, isResizable: true, isCollapsible: true }
                    ]}
                    preFarItems={(wfu) => {
                        return ([
                            {
                                key: 'Workflows',
                                name: 'Workflows',
                                iconProps: { iconName: 'MiniExpandMirrored' },
                                onClick(ev, item) {
                                    history.push(`/corporate-workflows/workflows`);
                                },
                            },
                        ]);
                    }}
                    loadListItems={() => dataServices.app03WorkflowUserService.readAllForList(Number(workflowIdForWorkflowUsers))}
                    showEntityDescription1={true}
                    loadEntityDescription1={() => {
                        return dataServices.app03WorkflowService.readTitlesForWorkflowUsers(Number(workflowIdForWorkflowUsers));
                    }}
                    mapEntitiesToListItems={users => users.map(uw => (
                        {
                            key: uw.ID,
                            User: uw.User?.Title,
                            Workflow: uw.App03Workflow?.Title,
                            IsAdmin: uw.IsAdmin ? 'Yes' : 'No'
                        }
                    ))}
                    entityForm={(showForm, entityId, onSaved, onCancelled, entityDescription1) =>
                        <UserWorkflowForm
                            {...props}
                            showForm={showForm}
                            entityId={entityId}
                            showEntityDescription1={true}
                            entityDescription1={entityDescription1}
                            defaultValues={[{ field: 'App03WorkflowID', value: Number(workflowIdForWorkflowUsers) }]}
                            onSaved={onSaved}
                            onCancelled={onCancelled} />
                    }
                    onCheckDelete={() => Promise.resolve([])}
                    onDelete={id => dataServices.app03WorkflowUserService.delete(id)}
                />
            }

        </ErrorBoundary>
    );

};

export default withErrorHandling(App03WorkflowsAdmin);
