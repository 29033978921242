import { EntityWithStatusService } from './EntityWithStatusService';
import { IEntityChildren, IEntityDescription1, IProgramme } from '../types';
import { EntityStatus } from '../refData/EntityStatus';
import { AppGlobals } from '../AppGlobals';

export class ProgrammeService extends EntityWithStatusService<IProgramme> {
    public readonly parentEntities = ['ManagedByL2', 'ManagedByL3'];
    protected childrenEntities = ['Contributors($expand=ContributorUser)', 'Attributes($expand=AttributeType)'];

    constructor() {
        super(`/Programmes`);
    }

    public readAllForLookup(includeClosed?: boolean): Promise<IProgramme[]> {
        return this.readAll(
            `?$select=ID,Title`
            + `&$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosed ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public readAllForList(includeClosed?: boolean, portfolioID?: number): Promise<IProgramme[]> {
        return this.readAll(
            `?$select=ID,Title,OwnerTypeID,ManagedByTypeID,StartDate,EndDate`
            + `&$orderby=Title`
            + `&$expand=Portfolio($select=Title),OwnerL1($select=Title),OwnerL2($select=Title),OwnerL3($select=Title),ManagedByL2($select=Title),ManagedByL3($select=Title),EntityStatus($select=Title),ProgrammeManagerUser($select=Title),Contributors($expand=ContributorUser($select=Title)),ProgrammeStage($select=Title,StageColor)`
            + `,ProgrammeUpdates($orderby=ID desc;$top=1;$expand=ProgrammePhase($select=Title);$select=ID,OverallRagOptionID,FinanceRagOptionID,PeopleRagOptionID,MilestonesRagOptionID,BenefitsRagOptionID,ProgrammePhase)`
            + `&$filter=CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosed ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
            + (portfolioID ? ` and PortfolioID eq ${portfolioID}` : '')
        );
    }

    // public async readForView(portfolioID: number): Promise<IPortfolio> {
    //     return this.read(
    //         portfolioID,
    //         false,
    //         false,
    //         [
    //             'ParentPortfolio($select=Title)',
    //             'OwnerL1($select=Title)',
    //             'OwnerL2($select=Title)',
    //             'OwnerL3($select=Title)',
    //             'ManagedByL2($select=Title)',
    //             'ManagedByL3($select=Title)',
    //             'PortfolioManagerUser($select=Title,EmailAddress)',
    //             'Contributors($expand=ContributorUser($select=Title,EmailAddress))',
    //             'Attributes($expand=AttributeType($select=Title))'
    //         ]
    //     );
    // }

    // public async readTitleForPortfolio(entityPlural: string, entitySingular: string, portfolioID: number): Promise<IEntityDescription1> {
    //     const entityDescription1: IEntityDescription1 = {
    //         Description1ForList: '',
    //         Description1ForForm: ''
    //     };
    //     const res = await this.readAll(`?$filter=ID eq ${portfolioID}&$select=Title`);
    //     if (res.length > 0) {
    //         entityDescription1.Description1ForList = `${entityPlural} for portfolio <span style='font-style: italic'>${res[0].Title}</span>`;
    //         entityDescription1.Description1ForForm = `${entitySingular} for portfolio <span style='font-style: italic'>${res[0].Title}</span>`;
    //     }

    //     return entityDescription1;
    // }

    public readDraftReportProgrammes = (): Promise<IProgramme[]> => {
        return this.readAll(`?$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID} and EntityStatusID eq ${EntityStatus.Open}`);
        // return this.readAll(
        //     `?$expand=Attributes($expand=AttributeType)`
        //     + `&$orderby=Title`
        //     + `&$filter=CustomerID eq ${AppGlobals.CustomerID} and EntityStatusID eq ${EntityStatus.Open}`
        // );
    }


    public readProgrammeApprovers = (programmeId: number): Promise<IProgramme> => {
        return this.read(programmeId, false, false, ['ProgrammeManagerUser'/*, 'ReportApproverUser', 'ReportingLeadUser'*/]);
    }


    public async entityChildren(id: number): Promise<IEntityChildren[]> {
        //TODO with actual children
        return [];
    }


    public async create(programme: IProgramme): Promise<IProgramme> {
        programme.CustomerID = AppGlobals.CustomerID;
        return super.create(programme);
    }

    public async countProgrammes(): Promise<number> {
        return (await this.readAll(`?$select=ID&$filter=EntityStatusID eq ${EntityStatus.Open}`))?.length;
    }

    public async countProgrammesForPortfolio(portfolioID: number): Promise<number> {
        if (portfolioID) {
            return (await this.readAll(`?$select=ID&$filter=EntityStatusID eq ${EntityStatus.Open} and PortfolioID eq ${portfolioID}`))?.length;
        }
        return Promise.resolve(0);
    }

    public async readForView(programmeID: number): Promise<IProgramme> {
        return this.read(
            programmeID,
            false,
            false,
            [
                'Portfolio($select=Title)',
                'OwnerL1($select=Title)',
                'OwnerL2($select=Title)',
                'OwnerL3($select=Title)',
                'ManagedByL2($select=Title)',
                'ManagedByL3($select=Title)',
                'ProgrammeStage($select=Title)',
                'ProgrammeUpdates($orderby=ID desc;$top=1;$expand=ProgrammePhase($select=Title);$select=ProgrammePhase)',
                'ProgrammeManagerUser($select=Title,EmailAddress)',
                'Contributors($expand=ContributorUser($select=Title,EmailAddress))',
                'Attributes($expand=AttributeType($select=Title))'
            ]
        );
    }

    public async readTitleForProgramme(entityPlural: string, entitySingular: string, programmeID: number): Promise<IEntityDescription1> {
        const entityDescription1: IEntityDescription1 = {
            Description1ForList: '',
            Description1ForForm: ''
        };
        const res = await this.readAll(`?$filter=ID eq ${programmeID}&$select=Title`);
        if (res.length > 0) {
            entityDescription1.Description1ForList = `${entityPlural} for programme <span style='font-style: italic'>${res[0].Title}</span>`;
            entityDescription1.Description1ForForm = `${entitySingular} for programme <span style='font-style: italic'>${res[0].Title}</span>`;
        }

        return entityDescription1;
    }
}