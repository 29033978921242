import { EntityService } from './EntityService';
import { INAOPublicationDirectorate } from '../types';

export class NAOPublicationDirectorateService extends EntityService<INAOPublicationDirectorate> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/NAOPublicationDirectorates`);
    }
}