import { IEntityWithStatus, EntityWithStatus } from "./EntityWithStatus";
import { IEntity } from "./Entity";
import { IApp03WorkflowUser } from "./App03WorkflowUser";
import { IApp03Task } from "./App03Task";


export interface IApp03Workflow extends IEntityWithStatus {
    WorkflowTypeID?: number;
    Description?: string;
    NewTaskDefaultStageID?: number;
    RefColumnID?: number;
    RefPrefix?: string;
    CustomerID?: number;
    App03WorkflowUsers?: IApp03WorkflowUser[];
    App03WorkflowStages?: IEntity[];
    App03Tasks?: IApp03Task[];
}

export class App03Workflow extends EntityWithStatus implements IApp03Workflow {
    public WorkflowTypeID = 1; //default
    public Description = null;
    public NewTaskDefaultStageID = null;
    public RefColumnID = 1; //default
    public RefPrefix = 'G';
    public CustomerID = null;
}