import { EntityWithStatusService } from './EntityWithStatusService';
import { ContextService } from './ContextService';
import { IProject, IEntityChildren, IEntityDescription1 } from '../types';
import { EntityStatus } from '../refData/EntityStatus';
import { Period } from '../refData/Period';
import axios from 'axios';
import { AppGlobals } from '../AppGlobals';

export class ProjectService extends EntityWithStatusService<IProject> {
    public readonly parentEntities = ['SeniorResponsibleOwnerUser'];
    public readonly childrenEntities = ['Contributors($expand=ContributorUser)', 'Attributes($expand=AttributeType)'];

    constructor() {
        super(`/Projects`);
    }

    public readMyProjects(): Promise<IProject[]> {
        const username = encodeURIComponent(ContextService.Username());
        const filters = [
            `SeniorResponsibleOwnerUser/Username eq '${username}'`,
            `ReportApproverUser/Username eq '${username}'`,
            `ReportingLeadUser/Username eq '${username}'`,
            `ProjectManagerUser/Username eq '${username}'`,
            `Contributors/any(c: c/ContributorUser/Username eq '${username}')`
        ];
        return this.readAll(
            `?$expand=Attributes($expand=AttributeType)`
            + `&$filter=EntityStatusID eq ${EntityStatus.Open} and (${filters.join(' or ')})`
            + `&$orderby=Title`
        );
    }

    public readDgProjects(): Promise<IProject[]> {
        return this.readAll(`?$filter=Directorate/Group/DirectorGeneralUser/Username eq '${encodeURIComponent(ContextService.Username())}'`);
    }

    public readAllForLookup(includeClosedProjects?: boolean): Promise<IProject[]> {
        return this.readAll(
            `?$select=ID,Title,DirectorateID`
            + `&$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosedProjects ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public readAllForList(includeClosedProjects?: boolean, portfolioID?: number, programmeID?: number): Promise<IProject[]> {
        return this.readAll(
            `?$select=ID,Title,ParentTypeID,OwnerTypeID,ManagedByTypeID,Objectives,StartDate,EndDate,CorporateProjectID`
            + `&$orderby=Title`
            + `&$expand=Portfolio($select=Title,Purpose),Programme($select=Title;$expand=Portfolio($select=Purpose)),OwnerL1($select=Title),OwnerL2($select=Title),OwnerL3($select=Title),ManagedByL2($select=Title),ManagedByL3($select=Title),EntityStatus($select=Title),SeniorResponsibleOwnerUser($select=Title),ProjectManagerUser($select=Title)`
            + `,ReportApproverUser($select=Title),ReportingLeadUser($select=Title),Contributors($expand=ContributorUser($select=Title)),Attributes($expand=AttributeType($select=Title))`
            + `,ProjectStage($select=Title,StageColor),ProjectUpdates($orderby=ID desc;$top=1;$expand=ProjectPhase($select=Title);$select=ID,OverallRagOptionID,FinanceRagOptionID,PeopleRagOptionID,MilestonesRagOptionID,BenefitsRagOptionID,ProjectPhase)`
            + `&$filter=CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosedProjects ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
            + (portfolioID ? ` and PortfolioID eq ${portfolioID}` : '')
            + (programmeID ? ` and ProgrammeID eq ${programmeID}` : '')
        );
    }

    public async readForView(projectID: number): Promise<IProject> {
        return this.read(
            projectID,
            false,
            false,
            [
                'Portfolio($select=Title)',
                'Programme($select=Title;$expand=Portfolio($select=Purpose))',
                'OwnerL1($select=Title)',
                'OwnerL2($select=Title)',
                'OwnerL3($select=Title)',
                'ManagedByL2($select=Title)',
                'ManagedByL3($select=Title)',
                'ProjectStage($select=Title)',
                'ProjectUpdates($orderby=ID desc;$top=1;$expand=ProjectPhase($select=Title);$select=ProjectPhase)',
                'SeniorResponsibleOwnerUser($select=Title,EmailAddress)',
                'ProjectManagerUser($select=Title,EmailAddress)',
                'Directorate($select=Title)',
                'ReportApproverUser($select=Title,EmailAddress)',
                'ReportingLeadUser($select=Title,EmailAddress)',
                'Contributors($expand=ContributorUser($select=Title,EmailAddress))',
                'Attributes($expand=AttributeType($select=Title))'
            ]
        );
    }


    public readDirectorateProjects(directorateId: number, period: Period): Promise<IProject[]> {
        if (directorateId) {
            return this.readAll(
                `/GetProjectsDueInPeriod(DirectorateId=${directorateId},Period=${period})`
                + `?$expand=Attributes($expand=AttributeType),SeniorResponsibleOwnerUser($select=Title)`
            );
        }
        return Promise.resolve([]);
    }

    public getChildProjects(parentProjectId: number): Promise<IProject[]> {
        return this.readAll(
            `?$expand=Attributes($expand=AttributeType),SeniorResponsibleOwnerUser($select=Title)`
            + `&$filter=ParentProjectID eq ${parentProjectId}`
            + `&$orderby=Title`
        );
    }

    public readProjectApprovers = (projectId: number): Promise<IProject> => {
        return this.read(projectId, false, false, ['SeniorResponsibleOwnerUser', 'ReportApproverUser', 'ReportingLeadUser']);
    }

    public readDraftReportProjects = (): Promise<IProject[]> => {
        return this.readAll(
            `?$expand=Attributes($expand=AttributeType),SeniorResponsibleOwnerUser($select=Title)`
            + `&$orderby=Title`
            + `&$filter=CustomerID eq ${AppGlobals.CustomerID} and EntityStatusID eq ${EntityStatus.Open}`
        );
    }

    public readProjectByCorporateId = (corporateProjectID: string): Promise<IProject[]> => {
        return this.readAll(`?$select=ID&$filter=CorporateProjectID eq '${corporateProjectID}'`);
    }

    public async readDirectorateOpenProjectsCount(directorateId: number): Promise<number> {
        if (directorateId) {
            return (await this.readAll(`?$select=ID&$filter=EntityStatusID eq ${EntityStatus.Open} and DirectorateID eq ${directorateId} and ShowOnDirectorateReport eq true`))?.length;
        }
        return Promise.resolve(0);
    }

    public async entityChildren(id: number): Promise<IEntityChildren[]> {
        const projectUrl = `${this.entityUrl}(${id})`;
        const benefits = (await this.axiosGet(`${projectUrl}/Benefits?$select=ID&$top=10`)).data.value;
        const dependencies = (await this.axiosGet(`${projectUrl}/Dependencies?$select=ID&$top=10`)).data.value;
        const projects = (await this.axiosGet(`${projectUrl}/ChildProjects?$select=ID&$top=10`)).data.value;
        const updates = (await this.axiosGet(`${projectUrl}/ProjectUpdates?$select=ID&$top=10`)).data.value;
        const signOffs = (await this.axiosGet(`${projectUrl}/SignOffs?$select=ID&$top=10`)).data.value;
        const risks = (await this.axiosGet(`${projectUrl}/CorporateRisks?$select=ID&$top=10`)).data.value;
        const workStreams = (await this.axiosGet(`${projectUrl}/WorkStreams?$select=ID&$top=10`)).data.value;

        return [
            { ChildType: 'Benefits', CanBeAdopted: true, ChildIDs: (await benefits).map((b: { ID: any }) => b.ID) },
            { ChildType: 'Dependencies', CanBeAdopted: true, ChildIDs: (await dependencies).map((d: { ID: any }) => d.ID) },
            { ChildType: 'Projects', CanBeAdopted: true, ChildIDs: (await projects).map((p: { ID: any }) => p.ID) },
            { ChildType: 'Project updates', CanBeAdopted: false, ChildIDs: (await updates).map((p: { ID: any }) => p.ID) },
            { ChildType: 'Reports', CanBeAdopted: false, ChildIDs: (await signOffs).map((s: { ID: any }) => s.ID) },
            { ChildType: 'Risks', CanBeAdopted: true, ChildIDs: (await risks).map((r: { ID: any }) => r.ID) },
            { ChildType: 'Work streams', CanBeAdopted: true, ChildIDs: (await workStreams).map((w: { ID: any }) => w.ID) }
        ];
    }

    public async readTitlesForProjectDirectorateAndGroup(entityPlural: string, entitySingular: string, projectID: number, orgL2Label: string): Promise<IEntityDescription1> {
        const entityDescription1: IEntityDescription1 = {
            Description1ForList: '',
            Description1ForForm: '',
            DirectorateID: null,
        };
        const res = await this.readAll(`?$filter=ID eq ${projectID}&$expand=Directorate($select=Title;$expand=OrgLevel1($select=Title))&$select=Title,DirectorateID`);
        if (res.length > 0) {
            entityDescription1.Description1ForList = `${entityPlural} for project <span style='font-style: italic'>${res[0].Title}</span> in ${orgL2Label} ${res[0].Directorate.Title}, ${res[0].Directorate.OrgLevel1.Title}`;
            entityDescription1.Description1ForForm = `${entitySingular} for project <span style='font-style: italic'>${res[0].Title}</span> in ${orgL2Label} ${res[0].Directorate.Title}, ${res[0].Directorate.OrgLevel1.Title}`;
            entityDescription1.DirectorateID = res[0].DirectorateID;
        }

        return entityDescription1;
    }

    public async countProjectsForDirectorate(directorateID: number): Promise<number> {
        if (directorateID) {
            return (await this.readAll(`?$select=ID&$filter=EntityStatusID eq ${EntityStatus.Open} and DirectorateID eq ${directorateID}`))?.length;
        }
        return Promise.resolve(0);
    }

    public async countProjectsForPortfolio(portfolioID: number): Promise<number> {
        if (portfolioID) {
            return (await this.readAll(`?$select=ID&$filter=EntityStatusID eq ${EntityStatus.Open} and PortfolioID eq ${portfolioID}`))?.length;
        }
        return Promise.resolve(0);
    }

    public async countProjectsForProgramme(programmeID: number): Promise<number> {
        if (programmeID) {
            return (await this.readAll(`?$select=ID&$filter=EntityStatusID eq ${EntityStatus.Open} and ProgrammeID eq ${programmeID}`))?.length;
        }
        return Promise.resolve(0);
    }

    public async readTitlesForProjectUsers(projectID: number): Promise<IEntityDescription1> {
        const entityDescription1: IEntityDescription1 = {
            Description1ForList: '',
            Description1ForForm: ''
        };
        const res = await this.readAll(`?$filter=ID eq ${projectID}&$select=Title`);
        if (res.length > 0) {
            entityDescription1.Description1ForList = `Users with full access to Project ${res[0].Title}`;
            entityDescription1.Description1ForForm = `Users with full access to Project ${res[0].Title}`;
        }

        return entityDescription1;
    }

    public async create(project: IProject): Promise<IProject> {
        project.CustomerID = AppGlobals.CustomerID;
        return super.create(project);
    }

}