import React, { useContext, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import {
	IOrgLevel1,
	IDirectorate,
	IMilestone,
	IProject,
	IKeyWorkArea,
	IWorkStream,
	IMetric,
	IBenefit,
	ICommitment,
	IDependency,
	ListDefaults,
	IDataServices,
	ICorporateRisk,
	ICorporateRiskMitigationAction,
	IPortfolio,
	IProgramme
} from '../types';
import { EntityList } from './EntityList';
import { GroupForm } from './group/GroupForm';
import { DirectorateForm } from './directorate/DirectorateForm';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { MilestoneForm } from './milestone/MilestoneForm';
import { ProjectForm } from './project/ProjectForm';
import { KeyWorkAreaForm } from './keyWorkArea/KeyWorkAreaForm';
import { WorkStreamForm } from './workStream/WorkStreamForm';
import { MetricForm } from './metric/MetricForm';
import { BenefitForm } from './benefit/BenefitForm';
import { CommitmentForm } from './commitment/CommitmentForm';
import { DependencyForm } from './dependency/DependencyForm';
import { ErrorBoundary } from './ErrorBoundary';
import { IWithErrorHandlingProps, withErrorHandling } from './withErrorHandling';
import { IUseApiProps } from './useApi';
import { ReportingEntityList } from './reportingEntities/ReportingEntityList';
import { renderDate, renderDateRange, renderIndicators, renderPortfolioIcons, renderStage } from './cr/ListRenderers';
import { DataContext } from './DataContext';
import { IntegrationContext } from './IntegrationContext';
import { OrbUserContext } from './OrbUserContext';
import { useParams } from 'react-router-dom';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { UserDirectorateForm } from './user/UserDirectorateForm';
import { UserProjectForm } from './user/UserProjectForm';
import useMobileDetector from './useMobileDetector';
import { ProjectView } from './project/ProjectView';
import { DirectorateView } from './directorate/DirectorateView';
import { KeyWorkAreaView } from './keyWorkArea/KeyWorkAreaView';
import { MetricView } from './metric/MetricView';
import { CommitmentView } from './commitment/CommitmentView';
import { WorkStreamView } from './workStream/WorkStreamView';
import { DependencyView } from './dependency/DependencyView';
import { BenefitView } from './benefit/BenefitView';
import { MilestoneView } from './milestone/MilestoneView';
import { RiskForm } from './risk/RiskForm';
import { RiskMitigationActionForm } from './riskMitigationAction/RiskMitigationActionForm';
import { ProjectRiskForm } from './risk/ProjectRiskForm';
import { ProjectRiskMitigationActionForm } from './riskMitigationAction/ProjectRiskMitigationActionForm';
import { ProjectRiskView } from './risk/ProjectRiskView';
import { ProjectRiskMitigationActionView } from './riskMitigationAction/ProjectRiskMitigationActionView';
import { PortfolioForm } from './portfolio/PortfolioForm';
import { PortfolioView } from './portfolio/PortfolioView';
import { ProgrammeForm } from './programme/ProgrammeForm';
import { ProgrammeView } from './programme/ProgrammeView';

export interface IPerformanceReportingAdminProps extends IUseApiProps, IWithErrorHandlingProps {
	entity: string;
	lastPortfolioIdForChilds?: number | null;
	setLastPortfolioIdForChilds?: React.Dispatch<React.SetStateAction<number | null>>;
	lastProgrammeIdForChilds?: number | null;
	setLastProgrammeIdForChilds?: React.Dispatch<React.SetStateAction<number | null>>;
	lastProjectIdForChilds?: number | null;
	setLastProjectIdForChilds?: React.Dispatch<React.SetStateAction<number | null>>;
	lastDirectorateIdForChilds?: number | null;
	setLastDirectorateIdForChilds?: React.Dispatch<React.SetStateAction<number | null>>;
}


const PerformanceReportingAdmin = (props: IPerformanceReportingAdminProps) => {
	const { entity } = props;
	const { disableDirectorateManagement, disableGroupManagement, disableProjectManagement } = useContext(IntegrationContext);
	const { dataServices } = useContext(DataContext);
	const { orbConfig } = useContext(OrbUserContext);
	const isMobile = useMobileDetector();
	const { params } = useRouteMatch();
	const customReportingEntityTypeId = params?.['id'];
	const history = useHistory();
	const lcc = ListDefaults.columnWidths;

	const [projectsLabelForSelectedPortfolio, setProjectsLabelForSelectedPortfolio] = useState<string>("Projects");
	const [programmesLabelForSelectedPortfolio, setProgrammesLabelForSelectedPortfolio] = useState<string>("Programmes");
	const [projectsLabelForSelectedProgramme, setProjectsLabelForSelectedProgramme] = useState<string>("Projects");

	const [projectsLabelForSelectedDirectorate, setProjectsLabelForSelectedDirectorate] = useState<string>("Projects");
	const [workareasLabelForSelectedDirectorate, setWorkareasLabelForSelectedDirectorate] = useState<string>("WorkAreas");
	const [metricsLabelForSelectedDirectorate, setMetricsLabelForSelectedDirectorate] = useState<string>("Metrics");
	const [commitmentsLabelForSelectedDirectorate, setCommitmentsLabelForSelectedDirectorate] = useState<string>("Commitments");
	const [milestonesLabelForSelectedWorkArea, setMilestonesLabelForSelectedWorkArea] = useState<string>("Milestones");

	const [risksLabelForSelectedProject, setRisksLabelForSelectedProject] = useState<string>("Risks");
	const [workStreamsLabelForSelectedProject, setWorkStreamsLabelForSelectedProject] = useState<string>("Work streams");
	const [benefitsLabelForSelectedProject, setBenefitsLabelForSelectedProject] = useState<string>("Benefits");
	const [dependenciesLabelForSelectedProject, setDependenciesLabelForSelectedProject] = useState<string>("Dependencies");
	const [milestonesLabelForSelectedWorkStream, setMilestonesLabelForSelectedWorkStream] = useState<string>("Milestones");
	const [riskMitigatingActionsLabelForSelectedRisk, setRiskMitigatingActionsLabelForSelectedRisk] = useState<string>("Risk mitigating actions");

	const { portfolioIdForProgrammes } = useParams<{ portfolioIdForProgrammes: string }>();
	const { portfolioIdForProjects } = useParams<{ portfolioIdForProjects: string }>();
	const { programmeIdForProjects } = useParams<{ programmeIdForProjects: string }>();
	//const { directorateIdForProjects } = useParams<{ directorateIdForProjects: string }>();
	const { directorateIdForKeyWorkAreas } = useParams<{ directorateIdForKeyWorkAreas: string }>();
	const { directorateIdForMetrics } = useParams<{ directorateIdForMetrics: string }>();
	const { directorateIdForCommitments } = useParams<{ directorateIdForCommitments: string }>();
	const { workareaIdForMilestones } = useParams<{ workareaIdForMilestones: string }>();
	const { directorateIdForDirectorateUsers } = useParams<{ directorateIdForDirectorateUsers: string }>();
	const { projectIdForProjectUsers } = useParams<{ projectIdForProjectUsers: string }>();

	const { projectIdForRisks } = useParams<{ projectIdForRisks: string }>();
	const { riskIdForMitigatingActions } = useParams<{ riskIdForMitigatingActions: string }>();
	const { projectIdForWorkStream } = useParams<{ projectIdForWorkStream: string }>();
	const { projectIdForBenefits } = useParams<{ projectIdForBenefits: string }>();
	const { projectIdForDependencies } = useParams<{ projectIdForDependencies: string }>();
	const { workstreamIdForMilestones } = useParams<{ workstreamIdForMilestones: string }>();

	const userWidths = isMobile ? { minWidth: 100, maxWidth: 120 } : { minWidth: 150, maxWidth: 300 };
	const orgLevelNameWidths = isMobile ? { minWidth: 90, maxWidth: 170 } : { minWidth: 200, maxWidth: 350 };
	const portfoliolNameWidths = isMobile ? { minWidth: 90, maxWidth: 150 } : { minWidth: 90, maxWidth: 150 };

	// useEffect(() => {
	// 	console.log('Component Mounted');
	// 	// Your other code...

	// 	return () => {
	// 	  console.log('Component Unmounted');
	// 	};
	//   }, []);


	// useEffect(() => {
	// 	if(lastProjectIdForWorkStream !== null){
	// 		console.log('lastProjectIdForWorkStream', lastProjectIdForWorkStream);
	// 		history.push(`/performance-reporting/work-streams-for-project/${lastProjectIdForWorkStream}`);
	// 		//history.push(`/performance-reporting/milestones-for-work-stream/${ws?.ID}`);
	// 	}

	//  }, [lastProjectIdForWorkStream]);

	const getWorkStreamsColumns = (): IColumn[] => {
		if (projectIdForWorkStream) {
			return [
				{ key: '1', name: 'Work stream ID', fieldName: 'WorkStreamCode', minWidth: 50, maxWidth: 100, isResizable: true, isCollapsible: true },
				{ key: '2', name: 'Work stream name', fieldName: 'Name', minWidth: 200, maxWidth: 350, isResizable: true },
				{ key: '3', name: 'Staff', fieldName: 'WorkStreamStaff', minWidth: 250, maxWidth: 350, isResizable: true, isMultiline: true, isCollapsible: true },
				{ key: '4', name: 'Status', fieldName: 'Status', minWidth: 100, isResizable: true, isCollapsible: true }
			];
		}
		else {
			return [
				{ key: '1', name: 'Work stream ID', fieldName: 'WorkStreamCode', minWidth: 50, maxWidth: 100, isResizable: true },
				{ key: '2', name: 'Work stream name', fieldName: 'Name', minWidth: 200, maxWidth: 400, isResizable: true },
				{ key: '3', name: 'Project', fieldName: 'Project', minWidth: 150, isResizable: true },
				{ key: '4', name: 'Staff', fieldName: 'WorkStreamStaff', minWidth: 250, isResizable: true, isMultiline: true },
				{ key: '5', name: 'Status', fieldName: 'Status', minWidth: 100, isResizable: true }
			];
		}
	}

	const getBenefitsColumns = (): IColumn[] => {
		if (projectIdForBenefits) {
			return [
				{ key: '1', name: 'Name', fieldName: 'Name', minWidth: 200, maxWidth: 300, isResizable: true },
				{ key: '2', name: 'Lower limit', fieldName: 'TargetPerformanceLowerLimit', minWidth: 100, maxWidth: 100, isResizable: true, isCollapsible: true },
				{ key: '3', name: 'Upper limit', fieldName: 'TargetPerformanceUpperLimit', minWidth: 100, maxWidth: 100, isResizable: true, isCollapsible: true },
				{ key: '4', name: 'Staff', fieldName: 'BenefitStaff', minWidth: 250, maxWidth: 350, isResizable: true, isMultiline: true, isCollapsible: true },
				{ key: '5', name: 'Status', fieldName: 'Status', minWidth: 100, isResizable: true, isCollapsible: true }
			];
		}
		else {
			return [
				{ key: '1', name: 'Name', fieldName: 'Name', minWidth: 200, maxWidth: 400, isResizable: true },
				{ key: '2', name: 'Project', fieldName: 'Project', minWidth: 150, isResizable: true },
				{ key: '3', name: 'Target performance lower limit', fieldName: 'TargetPerformanceLowerLimit', minWidth: 100, isResizable: true },
				{ key: '4', name: 'Target performance upper limit', fieldName: 'TargetPerformanceUpperLimit', minWidth: 100, isResizable: true },
				{ key: '5', name: 'Staff', fieldName: 'BenefitStaff', minWidth: 250, isResizable: true, isMultiline: true },
				{ key: '6', name: 'Status', fieldName: 'Status', minWidth: 100, isResizable: true }
			];
		}
	}

	const getDependenciesColumns = (): IColumn[] => {
		if (projectIdForDependencies) {
			return [
				{ key: '1', name: 'Name', fieldName: 'Name', minWidth: 200, maxWidth: 350, isResizable: true },
				{ key: '2', name: 'Staff', fieldName: 'DependencyStaff', minWidth: 250, maxWidth: 350, isResizable: true, isMultiline: true, isCollapsible: true },
				{ key: '3', name: 'Status', fieldName: 'Status', minWidth: 100, isResizable: true, isCollapsible: true }
			];
		}
		else {
			return [
				{ key: '1', name: 'Name', fieldName: 'Name', minWidth: 200, maxWidth: 400, isResizable: true },
				{ key: '2', name: 'Project', fieldName: 'Project', minWidth: 150, isResizable: true },
				{ key: '3', name: 'Staff', fieldName: 'DependencyStaff', minWidth: 250, isResizable: true, isMultiline: true },
				{ key: '4', name: 'Status', fieldName: 'Status', minWidth: 100, isResizable: true }
			];
		}
	}

	const getMilestonesColumns = (): IColumn[] => {
		if (workstreamIdForMilestones || workareaIdForMilestones) {
			return [
				{ key: '1', name: 'Milestone ID', fieldName: 'MilestoneCode', minWidth: 100, maxWidth: 100, isResizable: true, isCollapsible: true },
				{ key: '2', name: 'Name', fieldName: 'Name', minWidth: 200, maxWidth: 300, isResizable: true },
				{ key: '5', name: 'Staff', fieldName: 'MilestoneStaff', minWidth: 200, maxWidth: 350, isResizable: true, isMultiline: true, isCollapsible: true },
				{ key: '6', name: 'Status', fieldName: 'Status', minWidth: 100, isResizable: true, isCollapsible: true }
			];
		}
		else {
			return [
				{ key: '1', name: 'Milestone ID', fieldName: 'MilestoneCode', minWidth: 100, maxWidth: 100, isResizable: true },
				{ key: '2', name: 'Name', fieldName: 'Name', minWidth: 200, maxWidth: 400, isResizable: true },
				{
					key: '3', name: `${orbConfig.OrgL2Label}/Project`, fieldName: 'ParentsParentEntity', minWidth: 200, isResizable: true,
					onRender: function renderParent(m) { return <TooltipHost content={m.MilestoneType}>{m.ParentsParentEntity}</TooltipHost>; }
				},
				{ key: '4', name: 'Key work area/Work stream', fieldName: 'ParentEntity', minWidth: 200, isResizable: true },
				{ key: '5', name: 'Staff', fieldName: 'MilestoneStaff', minWidth: 200, isResizable: true, isMultiline: true },
				{ key: '6', name: 'Status', fieldName: 'Status', minWidth: 100, isResizable: true }
			];
		}
	}

	const fetchChildCountsForSelectedPortfolio = (portfolioID: number): void => {
		if (portfolioID) {
			//call services to get total counts
			dataServices.projectService.countProjectsForPortfolio(portfolioID).then(c => {
				setProjectsLabelForSelectedPortfolio(`Projects (${c})`);
			});
			dataServices.programmeService.countProgrammesForPortfolio(portfolioID).then(c => {
				setProgrammesLabelForSelectedPortfolio(`Programmes (${c})`);
			});
		}
	}

	const fetchChildCountsForSelectedProgramme = (programmeID: number): void => {
		if (programmeID) {
			//call services to get total counts
			dataServices.projectService.countProjectsForProgramme(programmeID).then(c => {
				setProjectsLabelForSelectedProgramme(`Projects (${c})`);
			});
		}
	}

	const fetchChildCountsForSelectedDirectorate = (directorateID: number): void => {
		if (directorateID) {
			//call services to get total counts
			dataServices.projectService.countProjectsForDirectorate(directorateID).then(c => {
				setProjectsLabelForSelectedDirectorate(`Projects (${c})`);
			});
			dataServices.keyWorkAreaService.countWorkAreasForDirectorate(directorateID).then(c => {
				setWorkareasLabelForSelectedDirectorate(`WorkAreas (${c})`);
			});
			dataServices.metricService.countMetricsForDirectorate(directorateID).then(c => {
				setMetricsLabelForSelectedDirectorate(`Metrics (${c})`);
			});
			dataServices.metricService.countMetricsForDirectorate(directorateID).then(c => {
				setMetricsLabelForSelectedDirectorate(`Metrics (${c})`);
			});
			dataServices.commitmentService.countCommitmentsForDirectorate(directorateID).then(c => {
				setCommitmentsLabelForSelectedDirectorate(`Commitments (${c})`);
			});
		}
	}

	const fetchChildCountsForSelectedWorkArea = (workAreaID: number): void => {
		if (workAreaID) {
			//call services to get total counts
			dataServices.milestoneService.countMilestonesForWorkArea(workAreaID).then(c => {
				setMilestonesLabelForSelectedWorkArea(`Milestones (${c})`);
			});
		}
	}

	const fetchChildCountsForSelectedProject = (projectID: number): void => {
		if (projectID) {
			//call services to get total counts
			dataServices.corporateRiskService.countRisksForProject(projectID).then(c => {
				setRisksLabelForSelectedProject(`Risks (${c})`);
			});
			dataServices.workStreamService.countWorkStreamsForProject(projectID).then(c => {
				setWorkStreamsLabelForSelectedProject(`Work streams (${c})`);
			});
			dataServices.benefitService.countBenefitsForProject(projectID).then(c => {
				setBenefitsLabelForSelectedProject(`Benefits (${c})`);
			});
			dataServices.dependencyService.countDependenciesForProject(projectID).then(c => {
				setDependenciesLabelForSelectedProject(`Dependencies (${c})`);
			});
		}
	}

	const fetchChildCountsForSelectedWorkStream = (workStreamID: number): void => {
		if (workStreamID) {
			//call services to get total counts
			dataServices.milestoneService.countMilestonesForWorkStream(workStreamID).then(c => {
				setMilestonesLabelForSelectedWorkStream(`Milestones (${c})`);
			});
		}
	}

	const getPreFarItems = (keys: string[]) => {
		const config: Record<string, ICommandBarItemProps> = {
			Portfolios: {
				key: 'Portfolios',
				name: 'Portfolios',
				iconProps: { iconName: 'MiniExpandMirrored' },
				onClick() {
					props.setLastPortfolioIdForChilds(null);
					history.push('/performance-reporting/portfolios');
				},
			},
			Programmes: {
				key: 'Programmes',
				name: 'Programmes',
				iconProps: { iconName: 'MiniExpandMirrored' },
				onClick() {
					props.setLastProgrammeIdForChilds(null);
					console.log(props.lastPortfolioIdForChilds);
					if (props.lastPortfolioIdForChilds != null) {
						history.push(`/performance-reporting/programmes-for-portfolio/${props.lastPortfolioIdForChilds}`);
					}
					else {
						history.push('/performance-reporting/programmes');
					}
				},
			},
			Directorates: {
				key: 'Directorates',
				name: orbConfig.OrgL2LabelP,
				iconProps: { iconName: 'MiniExpandMirrored' },
				onClick() {
					props.setLastDirectorateIdForChilds(null);
					history.push('/performance-reporting/directorates');
				},
			},
			WorkAreas: {
				key: 'WorkAreas',
				name: 'WorkAreas',
				iconProps: { iconName: 'MiniExpandMirrored' },
				onClick() {
					console.log('lastDirectorateIdForChilds', props.lastDirectorateIdForChilds);
					history.push(`/performance-reporting/key-work-areas-for-directorate/${props.lastDirectorateIdForChilds}`);
				},
			},
			Projects: {
				key: 'Projects',
				name: 'Projects',
				iconProps: { iconName: 'MiniExpandMirrored' },
				onClick() {
					console.log('lastPortfolioIdForChilds', props.lastPortfolioIdForChilds);
					if (props.lastProgrammeIdForChilds != null) {
						history.push(`/performance-reporting/projects-for-programme/${props.lastProgrammeIdForChilds}`);
					}
					else if (props.lastPortfolioIdForChilds != null) {
						history.push(`/performance-reporting/projects-for-portfolio/${props.lastPortfolioIdForChilds}`);
					}
					else {
						history.push('/performance-reporting/projects');
					}

				},
			},
			WorkStreams: {
				key: 'WorkStreams',
				name: 'Work streams',
				iconProps: { iconName: 'MiniExpandMirrored' },
				onClick: (ev, item) => {
					console.log('lastProjectIdForChilds', props.lastProjectIdForChilds);
					history.push(`/performance-reporting/work-streams-for-project/${props.lastProjectIdForChilds}`);
				},
			},
			Risks: {
				key: 'Risks',
				name: 'Risks',
				iconProps: { iconName: 'MiniExpandMirrored' },
				onClick: (ev, item) => {
					console.log('lastProjectIdForChilds', props.lastProjectIdForChilds);
					history.push(`/performance-reporting/risks-for-project/${props.lastProjectIdForChilds}`);
				},
			},
			// Add more configurations as needed for other keys
		};

		const arr: ICommandBarItemProps[] = keys
			.filter((key) => config[key]) // Only include valid keys
			.map((key) => config[key]);

		return arr;
	};


	// const getPreFarItems = (keys: string[]) => {
	// 	let arr: ICommandBarItemProps[] = [];

	// 	keys.forEach((key) => {
	// 		if (key === 'Directorates') {
	// 			arr.push({
	// 				key: key,
	// 				name: 'Directorates',
	// 				iconProps: { iconName: 'MiniExpandMirrored' },
	// 				onClick() {
	// 					history.push('/performance-reporting/directorates');
	// 				},
	// 			});
	// 		}
	// 		if (key === 'Projects') {
	// 			arr.push({
	// 				key: key,
	// 				name: 'Projects',
	// 				iconProps: { iconName: 'MiniExpandMirrored' },
	// 				onClick() {
	// 					history.push('/performance-reporting/projects');
	// 				},
	// 			});
	// 		}

	// 		if (key === 'WorkStreams') {
	// 			arr.push({
	// 				key: key,
	// 				name: 'Work streams',
	// 				iconProps: { iconName: 'MiniExpandMirrored' },
	// 				onClick() {
	// 					// Your logic for 'WorkStreams'
	// 					// For example: history.push(`/performance-reporting/work-streams-for-project/${lastProjectIdForWorkStream}`);
	// 				},
	// 			});
	// 		}

	// 		// Add more conditions as needed for other keys
	// 	});

	// 	return arr;
	// };



	return (
		<ErrorBoundary>
			{entity === 'Portfolios' &&
				<EntityList<IPortfolio>
					{...props}
					disableAdd={false}
					enableShowHideClosedEntities={true}
					entityName={{ Plural: 'Portfolios', Singular: 'Portfolio' }}
					barColorBeforeTitle='#99D9EA'
					preFarItemsOnSelection={(p) => {
						const menuItems = [
							{
								key: 'Updates',
								name: 'Updates',
								iconProps: { iconName: 'MiniExpand' },
								onClick: (ev, item) => {
									//props.setLastProjectIdForChilds(p?.ID);
									history.push(`/performance-reporting/updates-for-portfolio/${p?.ID}`);
								},
							},
						];

						if (p?.ContainsValue === 'Projects') {
							fetchChildCountsForSelectedPortfolio(p?.ID);
							menuItems.push(
								{
									key: 'Programmes',
									name: programmesLabelForSelectedPortfolio,
									iconProps: { iconName: 'MiniExpand' },
									onClick(ev, item) {
										props.setLastPortfolioIdForChilds(p?.ID);
										history.push(`/performance-reporting/programmes-for-portfolio/${p?.ID}`);
									},
								},
								{
									key: 'Projects',
									name: projectsLabelForSelectedPortfolio,
									iconProps: { iconName: 'MiniExpand' },
									onClick(ev, item) {
										props.setLastPortfolioIdForChilds(p?.ID);
										history.push(`/performance-reporting/projects-for-portfolio/${p?.ID}`);
									},
								},
							);
						}

						return menuItems;
					}}
					columns={[
						{ key: '0', name: '', minWidth: 28, maxWidth: 28, isResizable: true, onRender: item => renderPortfolioIcons(item.Purpose, item.ContainsValue) },
						{ key: '1', name: 'Name', fieldName: 'Name', ...portfoliolNameWidths, isResizable: true },
						{ key: '2', name: 'Parent', fieldName: 'ParentPortfolio', minWidth: 80, maxWidth: 130, isCollapsible: true, isResizable: true },
						{ key: '3', name: 'Indicators', minWidth: 90, maxWidth: 110, isCollapsible: true, isResizable: true, onRender: item => renderIndicators(item.OverallRagOptionID, item.FinanceRagOptionID, item.PeopleRagOptionID, item.MilestonesRagOptionID, item.BenefitsRagOptionID) },
						{ key: '4', name: 'Managed By', fieldName: 'ManagedBy', minWidth: 100, maxWidth: 300, isCollapsible: true, isResizable: true, isMultiline: true },
						{ key: '5', name: 'Staff', fieldName: 'Staff', minWidth: 150, maxWidth: 200, isResizable: true, isCollapsible: true, isMultiline: true },
						// { key: '6', name: 'Stage', fieldName: 'Stage', minWidth: 60, maxWidth: 70, isCollapsible: true, isResizable: true, onRender: item => renderStage(item.Stage) },
						// { key: '7', name: 'Dates', minWidth: 100, isResizable: true, isCollapsible: true, onRender: item => renderDateRange(item.StartDate, item.EndDate) },
					]}
					loadListItems={showClosedEntities => dataServices.portfolioService.readAllForList(showClosedEntities)}
					mapEntitiesToListItems={portfolios => portfolios.map(p => {
						let owner = '';
						if (p.OwnerTypeID !== null) {
							switch (p.OwnerTypeID) {
								case 0:
									owner = `(Dept)`;
									break;
								case 1:
									owner = `(${orbConfig.OrgL1Label} ${p.OwnerL1.Title})`;
									break;
								case 2:
									owner = `(${orbConfig.OrgL2Label} ${p.OwnerL2.Title})`;
									break;
								case 3:
									owner = `(${orbConfig.OrgL3Label} ${p.OwnerL3.Title})`;
									break;
								default:
									owner = '';
									break;
							}

						}

						let managedBy = '';
						if (p.ManagedByTypeID !== null) {
							switch (p.ManagedByTypeID) {
								case 2:
									managedBy = `${orbConfig.OrgL2Label} ${p.ManagedByL2.Title} ${owner}`;
									break;
								case 3:
									managedBy = `${orbConfig.OrgL3Label} ${p.ManagedByL3.Title} ${owner}`;
									break;
								default:
									managedBy = '';
									break;
							}
						}
						const latestPortfolioUpdate = p.PortfolioUpdates.length > 0 ? p.PortfolioUpdates[0] : null;
						return {
							key: p.ID,
							Purpose: p.Purpose,
							ContainsValue: p.ContainsValue,
							Name: p.Title,
							ParentPortfolio: p.ParentPortfolio ? p.ParentPortfolio.Title : '-Root-',
							ManagedBy: managedBy,
							//Stage: p.Stage,
							//StartDate: p.StartDate,
							//StartDateValue: p.StartDate?.valueOf(),
							//EndDate: p.EndDate,
							//EndDateValue: p.EndDate?.valueOf(),
							Staff: [
								p.PortfolioManagerUser ? `PM - ${p.PortfolioManagerUser.Title}` : null,
								p.Contributors && p.Contributors.some(c => c.ContributorUser != null) ? `C - ${p.Contributors.map(c => c.ContributorUser?.Title).join(', ')}` : null,
							].filter(Boolean).join(', '),
							OverallRagOptionID: latestPortfolioUpdate?.OverallRagOptionID,
							FinanceRagOptionID: latestPortfolioUpdate?.FinanceRagOptionID,
							PeopleRagOptionID: latestPortfolioUpdate?.PeopleRagOptionID,
							MilestonesRagOptionID: latestPortfolioUpdate?.MilestonesRagOptionID,
							BenefitsRagOptionID: latestPortfolioUpdate?.BenefitsRagOptionID,
						};
					})}
					entityForm={(showForm, entityId, onSaved, onCancelled, entityDescription1) =>
						<PortfolioForm
							{...props}
							showForm={showForm}
							entityId={entityId}
							onSaved={onSaved}
							onCancelled={onCancelled}
						/>
					}

					viewForm={(showForm, entityId, onClosed) =>
						<PortfolioView
							{...props}
							showForm={showForm}
							entityId={entityId}
							entityName='Portfolio'
							loadEntity={portfolioId => dataServices.portfolioService.readForView(portfolioId)}
							onClosed={onClosed}
						/>
					}

					onCheckDelete={id => dataServices.portfolioService.entityChildren(id)}
					onDelete={id => dataServices.portfolioService.delete(id)}
				/>
			}
			{entity === 'Directorates' &&
				<EntityList<IDirectorate>
					{...props}
					disableAdd={true}
					disableDelete={() => true}
					enableShowHideClosedEntities={true}
					entityName={{ Plural: orbConfig.OrgL2LabelP, Singular: orbConfig.OrgL2Label }}
					additionalItemsOnSelection={(d) => {
						return ([
							{
								key: 'Access',
								name: 'Access',
								iconProps: { iconName: 'FabricUserFolder' },
								onClick(ev, item) {
									history.push(`/performance-reporting/directorate-users/${d?.ID}`);
								},
							},
						]);
					}}
					preFarItemsOnSelection={(d) => {
						fetchChildCountsForSelectedDirectorate(d?.ID);
						return ([
							{
								key: 'Projects',
								name: projectsLabelForSelectedDirectorate,
								iconProps: { iconName: 'MiniExpand' },
								onClick(ev, item) {
									props.setLastDirectorateIdForChilds(d?.ID);
									history.push(`/performance-reporting/projects-for-directorate/${d?.ID}`);
								},
							},
							{
								key: 'WorkAreas',
								name: workareasLabelForSelectedDirectorate,
								iconProps: { iconName: 'MiniExpand' },
								onClick() {
									props.setLastDirectorateIdForChilds(d?.ID);
									history.push(`/performance-reporting/key-work-areas-for-directorate/${d?.ID}`);
								},
							},
							{
								key: 'Metrics',
								name: metricsLabelForSelectedDirectorate,
								iconProps: { iconName: 'MiniExpand' },
								onClick(ev, item) {
									history.push(`/performance-reporting/metrics-for-directorate/${d?.ID}`);
								},
							},
							{
								key: 'Commitments',
								name: commitmentsLabelForSelectedDirectorate,
								iconProps: { iconName: 'MiniExpand' },
								onClick(ev, item) {
									history.push(`/performance-reporting/commitments-for-directorate/${d?.ID}`);
								},
							},
						]);
					}}
					columns={[
						{ key: '1', name: 'Name', fieldName: 'Name', ...orgLevelNameWidths, isResizable: true },
						{ key: '2', name: orbConfig.OrgL1Label, fieldName: 'Group', ...userWidths, isCollapsible: true, isResizable: true },
						{ key: '3', name: orbConfig.OrgMgrL2Lable, fieldName: 'Director', minWidth: lcc.user, maxWidth: 300, isCollapsible: true, isResizable: true },
						{ key: '4', name: 'Staff', fieldName: 'DirectorateStaff', minWidth: 300, isResizable: true, isCollapsible: true, isMultiline: true },
					]}
					loadListItems={showClosedEntities => dataServices.directorateService.readAllForList(showClosedEntities)}
					mapEntitiesToListItems={directorates => directorates.map(d => (
						{
							key: d.ID,
							Name: d.Title,
							Director: d.DirectorUser?.Title,
							AlternativeApprover: d.ReportApproverUser?.Title,
							Group: d.OrgLevel1?.Title,
							Status: d.EntityStatus?.Title,
							DirectorateStaff: [
								d.ReportApproverUser ? `ARA - ${d.ReportApproverUser.Title}` : null,
								d.ReportingLeadUser ? `RL - ${d.ReportingLeadUser.Title}` : null,
								d.Contributors && d.Contributors.some(c => c.ContributorUser != null) ? `HUC - ${d.Contributors.map(dc => dc.ContributorUser?.Title).join(', ')}` : null,
							].filter(Boolean).join(', '),
						}
					))}
					entityForm={(showForm, entityId, onSaved, onCancelled) =>
						<DirectorateForm
							{...props}
							entityName="Directorate"
							showForm={showForm}
							entityId={entityId}
							onSaved={onSaved}
							onCancelled={onCancelled}
						/>
					}
					viewForm={(showForm, entityId, onClosed) =>
						<DirectorateView
							{...props}
							showForm={showForm}
							entityId={entityId}
							entityName='Directorate'
							loadEntity={entityId => dataServices.directorateService.readForView(entityId)}
							onClosed={onClosed}
						/>
					}
					onCheckDelete={id => dataServices.directorateService.entityChildren(id)}
					onDelete={id => dataServices.directorateService.delete(id)}
				/>
			}
			{entity === 'Programmes' &&
				<EntityList<IProgramme>
					{...props}
					enableShowHideClosedEntities={true}
					entityName={{ Plural: 'Programmes', Singular: 'Programme' }}
					barColorBeforeTitle='#99D9EA'
					entity={portfolioIdForProgrammes !== undefined ? "Programmes for portfolio" : "Programmes"} //just to reload
					showEntityDescription1={portfolioIdForProgrammes !== undefined ? true : false}
					loadEntityDescription1={() => {
						return portfolioIdForProgrammes !== undefined ? dataServices.portfolioService.readTitleForPortfolio('Programmes', 'Programme', Number(portfolioIdForProgrammes)) : null;
					}}
					// additionalItemsOnSelection={(p) => {
					// 	return ([
					// 		{
					// 			key: 'Access',
					// 			name: 'Access',
					// 			iconProps: { iconName: 'FabricUserFolder' },
					// 			onClick(ev, item) {
					// 				history.push(`/performance-reporting/project-users/${p?.ID}`);
					// 			},
					// 		},
					// 	]);
					// }}
					preFarItems={(p) => portfolioIdForProgrammes !== undefined ? getPreFarItems(['Portfolios']) : null}
					preFarItemsOnSelection={(p) => {
						fetchChildCountsForSelectedProgramme(p?.ID);
						return ([
							{
								key: 'Updates',
								name: 'Updates',
								iconProps: { iconName: 'MiniExpand' },
								onClick: (ev, item) => {
									//props.setLastProjectIdForChilds(p?.ID);
									history.push(`/performance-reporting/updates-for-programme/${p?.ID}`);
								},
							},
							{
								key: 'Projects',
								name: projectsLabelForSelectedProgramme,
								iconProps: { iconName: 'MiniExpand' },
								onClick(ev, item) {
									props.setLastProgrammeIdForChilds(p?.ID);
									history.push(`/performance-reporting/projects-for-programme/${p?.ID}`);
								},
							},
						]);
					}}

					columns={[
						{ key: '0', name: '', minWidth: 14, maxWidth: 14, isResizable: true, onRender: item => renderPortfolioIcons(item.Purpose) },
						{ key: '1', name: 'Name', fieldName: 'Name', minWidth: 80, maxWidth: 180, isResizable: true },
						{ key: '3', name: 'Parent', fieldName: 'Parent', minWidth: 80, maxWidth: 100, isResizable: true, isCollapsible: true },
						{ key: '4', name: 'Indicators', minWidth: 90, maxWidth: 110, isCollapsible: true, isResizable: true, onRender: item => renderIndicators(item.OverallRagOptionID, item.FinanceRagOptionID, item.PeopleRagOptionID, item.MilestonesRagOptionID, item.BenefitsRagOptionID) },
						{ key: '5', name: 'Managed By', fieldName: 'ManagedBy', minWidth: 100, maxWidth: 250, isCollapsible: true, isResizable: true, isMultiline: true },
						{ key: '6', name: 'Staff', fieldName: 'ProgrammeStaff', minWidth: 100, maxWidth: 250, isResizable: true, isCollapsible: true, isMultiline: true },
						{ key: '7', name: 'Stage', fieldName: 'Stage', minWidth: 60, maxWidth: 70, isCollapsible: true, isResizable: true, onRender: item => renderStage(item.Stage, item.StageColor) },
						{ key: '8', name: 'Phase', fieldName: 'Phase', minWidth: 70, maxWidth: 80, isCollapsible: true, isResizable: true },
						{ key: '9', name: 'Dates', minWidth: 100, isResizable: true, isCollapsible: true, onRender: item => renderDateRange(item.StartDate, item.EndDate) },
					]}
					loadListItems={showClosedEntities => {
						if (portfolioIdForProgrammes !== undefined) {
							console.log('load data by portfolioID', portfolioIdForProgrammes, props.lastPortfolioIdForChilds);
						}
						else {
							props.setLastPortfolioIdForChilds(null);
							console.log('clear lastPortfolioIdForChilds');
						}
						return portfolioIdForProgrammes !== undefined ? dataServices.programmeService.readAllForList(showClosedEntities, Number(portfolioIdForProgrammes)) : dataServices.programmeService.readAllForList(showClosedEntities);
					}}
					mapEntitiesToListItems={programmes => programmes.map(p => {
						let owner = '';
						if (p.OwnerTypeID !== null) {
							switch (p.OwnerTypeID) {
								case 0:
									owner = `(Dept)`;
									break;
								case 1:
									owner = `(${orbConfig.OrgL1Label} ${p.OwnerL1.Title})`;
									break;
								case 2:
									owner = `(${orbConfig.OrgL2Label} ${p.OwnerL2.Title})`;
									break;
								case 3:
									owner = `(${orbConfig.OrgL3Label} ${p.OwnerL3.Title})`;
									break;
								default:
									owner = '';
									break;
							}

						}

						let managedBy = '';
						if (p.ManagedByTypeID !== null) {
							switch (p.ManagedByTypeID) {
								case 2:
									managedBy = `${orbConfig.OrgL2Label} ${p.ManagedByL2.Title} ${owner}`;
									break;
								case 3:
									managedBy = `${orbConfig.OrgL3Label} ${p.ManagedByL3.Title} ${owner}`;
									break;
								default:
									managedBy = '';
									break;
							}
						}
						const latestProgrammeUpdate = p.ProgrammeUpdates.length > 0 ? p.ProgrammeUpdates[0] : null;
						return {
							key: p.ID,
							Purpose: p.Portfolio?.Purpose,
							Name: p.Title,
							Parent: p.Portfolio?.Title,
							ManagedBy: managedBy,
							ProgrammeManagerUser: p.ProgrammeManagerUser?.Title,
							ProgrammeAttributes: p.Attributes?.map(pa => pa.AttributeType?.Title).join(', '),
							ProgrammeStaff: [
								p.ProgrammeManagerUser ? `PM - ${p.ProgrammeManagerUser.Title}` : null,
								p.Contributors && p.Contributors.some(c => c.ContributorUser != null) ? `C - ${p.Contributors.map(pc => pc.ContributorUser?.Title).join(', ')}` : null,
							].filter(Boolean).join(', '),

							StartDate: p.StartDate,
							StartDateValue: p.StartDate?.valueOf(),
							EndDate: p.EndDate,
							EndDateValue: p.EndDate?.valueOf(),
							Stage: p.ProgrammeStage?.Title,
							StageColor: p.ProgrammeStage?.StageColor,
							Phase: latestProgrammeUpdate?.ProgrammePhase?.Title,
							OverallRagOptionID: latestProgrammeUpdate?.OverallRagOptionID,
							FinanceRagOptionID: latestProgrammeUpdate?.FinanceRagOptionID,
							PeopleRagOptionID: latestProgrammeUpdate?.PeopleRagOptionID,
							MilestonesRagOptionID: latestProgrammeUpdate?.MilestonesRagOptionID,
							BenefitsRagOptionID: latestProgrammeUpdate?.BenefitsRagOptionID,
						};
					})}
					entityForm={(showForm, entityId, onSaved, onCancelled, entityDescription1) =>
						<ProgrammeForm
							{...props}
							showForm={showForm}
							entityId={entityId}
							showEntityDescription1={portfolioIdForProgrammes !== undefined ? true : false}
							entityDescription1={entityDescription1}
							defaultValues={[{ field: 'PortfolioID', value: Number(portfolioIdForProgrammes) }]}
							onSaved={onSaved}
							onCancelled={onCancelled}
						/>
					}
					viewForm={(showForm, entityId, onClosed, entityDescription1) =>
						<ProgrammeView
							{...props}
							showForm={showForm}
							entityId={entityId}
							entityName='Programme'
							loadEntity={programmeId => dataServices.programmeService.readForView(programmeId)}
							onClosed={onClosed}
						//showEntityDescription1={directorateIdForProjects !== undefined ? true : false}
						//entityDescription1={entityDescription1}
						/>
					}
					onCheckDelete={id => dataServices.programmeService.entityChildren(id)}
					onDelete={id => dataServices.programmeService.delete(id)}
				/>
			}
			{entity === 'Projects' &&
				<EntityList<IProject>
					{...props}
					enableShowHideClosedEntities={true}
					entityName={{ Plural: 'Projects', Singular: 'Project' }}
					barColorBeforeTitle='#99D9EA'
					entity={portfolioIdForProjects !== undefined ? "Projects for portfolio" : programmeIdForProjects !== undefined ? "Projects for programme" : "Projects"} //just to reload
					showEntityDescription1={(portfolioIdForProjects !== undefined || programmeIdForProjects !== undefined) ? true : false}
					loadEntityDescription1={() => {
						if (portfolioIdForProjects !== undefined) {
							return dataServices.portfolioService.readTitleForPortfolio('Projects', 'Project', Number(portfolioIdForProjects));
						}
						if (programmeIdForProjects !== undefined) {
							return dataServices.programmeService.readTitleForProgramme('Projects', 'Project', Number(programmeIdForProjects));
						}
						return null;
					}}

					additionalItemsOnSelection={(p) => {
						return ([
							{
								key: 'Access',
								name: 'Access',
								iconProps: { iconName: 'FabricUserFolder' },
								onClick(ev, item) {
									history.push(`/performance-reporting/project-users/${p?.ID}`);
								},
							},
						]);
					}}
					preFarItems={(p) => {
						if (portfolioIdForProjects !== undefined)
							return getPreFarItems(['Portfolios']);
						else if (programmeIdForProjects !== undefined)
							return getPreFarItems(['Programmes']);
						return null;
					}}
					preFarItemsOnSelection={(p) => {
						fetchChildCountsForSelectedProject(p?.ID);
						return ([
							{
								key: 'Updates',
								name: 'Updates',
								iconProps: { iconName: 'MiniExpand' },
								onClick: (ev, item) => {
									//props.setLastProjectIdForChilds(p?.ID);
									history.push(`/performance-reporting/sign-off-for-project/${p?.ID}`);
								},
							},
							{
								key: 'Risks',
								name: risksLabelForSelectedProject,
								iconProps: { iconName: 'MiniExpand' },
								onClick: (ev, item) => {
									props.setLastProjectIdForChilds(p?.ID);
									history.push(`/performance-reporting/risks-for-project/${p?.ID}`);
								},
							},
							{
								key: 'WorkStreams',
								name: workStreamsLabelForSelectedProject,
								iconProps: { iconName: 'MiniExpand' },
								onClick: (ev, item) => {
									props.setLastProjectIdForChilds(p?.ID);
									history.push(`/performance-reporting/work-streams-for-project/${p?.ID}`);
								},
							},
							{
								key: 'Benefits',
								name: benefitsLabelForSelectedProject,
								iconProps: { iconName: 'MiniExpand' },
								onClick() {
									history.push(`/performance-reporting/benefits-for-project/${p?.ID}`);
								},
							},
							{
								key: 'Dependencies',
								name: dependenciesLabelForSelectedProject,
								iconProps: { iconName: 'MiniExpand' },
								onClick(ev, item) {
									history.push(`/performance-reporting/dependencies-for-project/${p?.ID}`);
								},
							},
						]);
					}}

					columns={[
						{ key: '0', name: '', minWidth: 14, maxWidth: 14, isResizable: true, onRender: item => renderPortfolioIcons(item.Purpose) },
						{ key: '1', name: 'Project name', fieldName: 'Name', minWidth: 80, maxWidth: 180, isResizable: true },
						{ key: '3', name: 'Parent', fieldName: 'Parent', minWidth: 80, maxWidth: 100, isResizable: true, isCollapsible: true },
						{ key: '4', name: 'Indicators', minWidth: 90, maxWidth: 110, isCollapsible: true, isResizable: true, onRender: item => renderIndicators(item.OverallRagOptionID, item.FinanceRagOptionID, item.PeopleRagOptionID, item.MilestonesRagOptionID, item.BenefitsRagOptionID) },
						{ key: '5', name: 'Managed By', fieldName: 'ManagedBy', minWidth: 100, maxWidth: 250, isCollapsible: true, isResizable: true, isMultiline: true },
						{ key: '6', name: 'Staff', fieldName: 'ProjectStaff', minWidth: 100, maxWidth: 250, isResizable: true, isCollapsible: true, isMultiline: true },
						{ key: '7', name: 'Stage', fieldName: 'Stage', minWidth: 60, maxWidth: 70, isCollapsible: true, isResizable: true, onRender: item => renderStage(item.Stage, item.StageColor) },
						{ key: '8', name: 'Phase', fieldName: 'Phase', minWidth: 70, maxWidth: 80, isCollapsible: true, isResizable: true },
						{ key: '9', name: 'Dates', minWidth: 100, isResizable: true, isCollapsible: true, onRender: item => renderDateRange(item.StartDate, item.EndDate) },
					]}
					loadListItems={showClosedEntities => {
						if (portfolioIdForProjects !== undefined) {
							props.setLastProgrammeIdForChilds(null);
							return dataServices.projectService.readAllForList(showClosedEntities, Number(portfolioIdForProjects));
						}
						else if (programmeIdForProjects !== undefined) {
							return dataServices.projectService.readAllForList(showClosedEntities, undefined, Number(programmeIdForProjects));
						}
						else {
							props.setLastPortfolioIdForChilds(null);
							props.setLastProgrammeIdForChilds(null);
							return dataServices.projectService.readAllForList(showClosedEntities);
						}

					}}
					mapEntitiesToListItems={projects => projects.map(p => {
						let owner = '';
						if (p.OwnerTypeID !== null) {
							switch (p.OwnerTypeID) {
								case 0:
									owner = `(Dept)`;
									break;
								case 1:
									owner = `(${orbConfig.OrgL1Label} ${p.OwnerL1.Title})`;
									break;
								case 2:
									owner = `(${orbConfig.OrgL2Label} ${p.OwnerL2.Title})`;
									break;
								case 3:
									owner = `(${orbConfig.OrgL3Label} ${p.OwnerL3.Title})`;
									break;
								default:
									owner = '';
									break;
							}

						}

						let managedBy = '';
						if (p.ManagedByTypeID !== null) {
							switch (p.ManagedByTypeID) {
								case 2:
									managedBy = `${orbConfig.OrgL2Label} ${p.ManagedByL2.Title} ${owner}`;
									break;
								case 3:
									managedBy = `${orbConfig.OrgL3Label} ${p.ManagedByL3.Title} ${owner}`;
									break;
								default:
									managedBy = '';
									break;
							}
						}

						let parent = '';
						let purpose = '';
						if (p.ParentTypeID === 1) {
							parent = p.Portfolio?.Title;
							purpose = p.Portfolio?.Purpose;
						}
						else if (p.ParentTypeID === 2) {
							parent = p.Programme?.Title;
							purpose = p.Programme?.Portfolio?.Purpose;
						}


						const latestProjectUpdate = p.ProjectUpdates.length > 0 ? p.ProjectUpdates[0] : null;
						return {
							key: p.ID,
							Purpose: purpose,
							Name: p.Title,
							Parent: parent,
							ManagedBy: managedBy,
							CorporateProjectID: p.CorporateProjectID,
							SeniorResponsibleOwnerUser: p.SeniorResponsibleOwnerUser?.Title,
							ProjectManagerUser: p.ProjectManagerUser?.Title,
							AlternativeApproverUser: p.ReportApproverUser?.Title,
							ProjectAttributes: p.Attributes?.map(pa => pa.AttributeType?.Title).join(', '),
							ProjectStaff: [
								p.SeniorResponsibleOwnerUser ? `SRO - ${p.SeniorResponsibleOwnerUser.Title}` : null,
								p.ReportApproverUser ? `ARA - ${p.ReportApproverUser.Title}` : null,
								p.ProjectManagerUser ? `PM - ${p.ProjectManagerUser.Title}` : null,
								p.ReportingLeadUser ? `RL - ${p.ReportingLeadUser.Title}` : null,
								p.Contributors && p.Contributors.some(c => c.ContributorUser != null) ? `HUC - ${p.Contributors.map(pc => pc.ContributorUser?.Title).join(', ')}` : null,
							].filter(Boolean).join(', '),

							StartDate: p.StartDate,
							StartDateValue: p.StartDate?.valueOf(),
							EndDate: p.EndDate,
							EndDateValue: p.EndDate?.valueOf(),
							Stage: p.ProjectStage?.Title,
							StageColor: p.ProjectStage?.StageColor,
							Phase: latestProjectUpdate?.ProjectPhase?.Title,
							OverallRagOptionID: latestProjectUpdate?.OverallRagOptionID,
							FinanceRagOptionID: latestProjectUpdate?.FinanceRagOptionID,
							PeopleRagOptionID: latestProjectUpdate?.PeopleRagOptionID,
							MilestonesRagOptionID: latestProjectUpdate?.MilestonesRagOptionID,
							BenefitsRagOptionID: latestProjectUpdate?.BenefitsRagOptionID,
						};
					})}
					entityForm={(showForm, entityId, onSaved, onCancelled, entityDescription1) =>
						<ProjectForm
							{...props}
							showForm={showForm}
							entityId={entityId}
							showEntityDescription1={(portfolioIdForProjects !== undefined || programmeIdForProjects !== undefined) ? true : false}
							entityDescription1={entityDescription1}
							//defaultValues={[{ field: 'PortfolioID', value: Number(portfolioIdForProjects) }]}
							defaultValues={portfolioIdForProjects !== undefined ? [{ field: 'ParentTypeID', value: 1 }, { field: 'PortfolioID', value: Number(portfolioIdForProjects) }]
								: programmeIdForProjects !== undefined ? [{ field: 'ParentTypeID', value: 2 }, { field: 'ProgrammeID', value: Number(programmeIdForProjects) }]
									: []}

							onSaved={onSaved}
							onCancelled={onCancelled}
						/>
					}
					viewForm={(showForm, entityId, onClosed, entityDescription1) =>
						<ProjectView
							{...props}
							showForm={showForm}
							entityId={entityId}
							entityName='Project'
							loadEntity={projectId => dataServices.projectService.readForView(projectId)}
							onClosed={onClosed}
						//showEntityDescription1={directorateIdForProjects !== undefined ? true : false}
						//entityDescription1={entityDescription1}
						/>
					}
					onCheckDelete={id => dataServices.projectService.entityChildren(id)}
					onDelete={id => dataServices.projectService.delete(id)}
				/>
			}
			{entity === 'KeyWorkAreas' &&
				<EntityList<IKeyWorkArea>
					{...props}
					enableShowHideClosedEntities={true}
					entityName={{ Plural: 'Work areas', Singular: 'Work area' }}
					entity={directorateIdForKeyWorkAreas !== undefined ? "Key work areas for directorates" : "Key work areas"} //just to reload
					preFarItems={(kwa) => getPreFarItems(['Directorates'])}
					preFarItemsOnSelection={(kwa) => {
						fetchChildCountsForSelectedWorkArea(kwa?.ID);
						return ([
							{
								key: 'Milestones',
								name: milestonesLabelForSelectedWorkArea,
								iconProps: { iconName: 'MiniExpand' },
								onClick() {
									history.push(`/performance-reporting/milestones-for-work-area/${kwa?.ID}`);
								},
							},
						]);
					}}
					showEntityDescription1={directorateIdForKeyWorkAreas !== undefined ? true : false}
					loadEntityDescription1={() => {
						return directorateIdForKeyWorkAreas !== undefined ? dataServices.directorateService.readTitlesForDirectorateAndGroup('Work areas', 'Work area', orbConfig.OrgL2Label, Number(directorateIdForKeyWorkAreas)) : null;
					}}
					columns={[
						{ key: '1', name: 'Work area name', fieldName: 'Name', minWidth: 200, maxWidth: 400, isResizable: true },
						{ key: '2', name: 'Staff', fieldName: 'WorkAreaStaff', minWidth: 200, maxWidth: 350, isResizable: true, isCollapsible: true, isMultiline: true },
						{ key: '3', name: 'Attributes', fieldName: 'Attributes', minWidth: 200, maxWidth: 350, isResizable: true, isCollapsible: true, isMultiline: true },
						{ key: '4', name: 'Status', fieldName: 'Status', minWidth: 100, isResizable: true, isCollapsible: true }
					]}
					loadListItems={showClosedEntities => {
						return directorateIdForKeyWorkAreas !== undefined ? dataServices.keyWorkAreaService.readAllForList(showClosedEntities, Number(directorateIdForKeyWorkAreas)) : dataServices.keyWorkAreaService.readAllForList(showClosedEntities);
					}}
					mapEntitiesToListItems={keyWorkAreas => keyWorkAreas.map(kwa => (
						{
							key: kwa.ID,
							Directorate: kwa.Directorate?.Title,
							Name: kwa.Title,
							LeadUser: kwa.LeadUser?.Title,
							Contributors: kwa.Contributors?.map(c => c.ContributorUser?.Title).join(', '),
							Status: kwa.EntityStatus?.Title,
							Attributes: kwa.Attributes?.map(a => a.AttributeType?.Title).join(', '),
							WorkAreaStaff: [
								kwa.LeadUser ? `L - ${kwa.LeadUser.Title}` : null,
								kwa.Contributors && kwa.Contributors.some(c => c.ContributorUser != null) ? `C - ${kwa.Contributors.map(wc => wc.ContributorUser?.Title).join(', ')}` : null,
							].filter(Boolean).join(', '),
						}
					))}
					entityForm={(showForm: boolean, entityId: number, onSaved, onCancelled, entityDescription1) =>
						<KeyWorkAreaForm
							{...props}
							entityName="Key Work Area"
							showForm={showForm}
							showEntityDescription1={directorateIdForKeyWorkAreas !== undefined ? true : false}
							entityDescription1={entityDescription1}
							defaultValues={[{ field: 'DirectorateID', value: Number(directorateIdForKeyWorkAreas) }]}
							entityId={entityId}
							onSaved={onSaved}
							onCancelled={onCancelled}
						/>
					}
					viewForm={(showForm, entityId, onClosed) =>
						<KeyWorkAreaView
							{...props}
							showForm={showForm}
							entityId={entityId}
							entityName='Work Area'
							loadEntity={entityId => dataServices.keyWorkAreaService.readForView(entityId)}
							onClosed={onClosed}
						/>
					}
					onCheckDelete={id => dataServices.keyWorkAreaService.entityChildren(id)}
					onDelete={id => dataServices.keyWorkAreaService.delete(id)}
				/>
			}
			{entity === 'Risks' &&
				<EntityList<ICorporateRisk>
					{...props}
					enableShowHideClosedEntities={true}
					entityName={{ Plural: 'Risks', Singular: 'Risk' }}
					//addChild={{ Name: 'Risk mitigating action' }}
					preFarItems={(ws) => getPreFarItems(['Projects'])}
					preFarItemsOnSelection={(r) => {
						setRiskMitigatingActionsLabelForSelectedRisk(`Risk mitigating actions (${r?.RiskMitigationActions?.length ?? 0})`);
						return ([
							{
								key: 'Updates',
								name: 'Updates',
								iconProps: { iconName: 'MiniExpand' },
								onClick: (ev, item) => {
									//props.setLastProjectIdForChilds(p?.ID);
									history.push(`/performance-reporting/updates-for-risk/${r?.ID}`);
								},
							},
							{
								key: 'RiskMitigatingActions',
								name: riskMitigatingActionsLabelForSelectedRisk,
								iconProps: { iconName: 'MiniExpand' },
								onClick: (ev, item) => {
									history.push(`/performance-reporting/risk-mitigating-actions-for-risk/${r?.ID}`);
								},
							},
						]);
					}}
					columns={[
						{ key: '1', name: 'Risk ID', fieldName: 'RiskCode', minWidth: 40, maxWidth: 50, isResizable: true },
						{ key: '2', name: 'Risk name', fieldName: 'Name', minWidth: 150, maxWidth: 300, isResizable: true },
						{ key: '3', name: 'Staff', fieldName: 'RiskStaff', minWidth: 250, maxWidth: 350, isResizable: true, isMultiline: true, isCollapsible: true },
						{ key: '5', name: 'Actions', fieldName: 'NumberOfActions', minWidth: 50, maxWidth: 50, isResizable: true, isCollapsible: true },
						{ key: '6', name: 'Status', fieldName: 'Status', minWidth: 50, isResizable: true, isCollapsible: true }
					]}
					loadListItems={showClosedEntities => dataServices.corporateRiskService.readAllForList_ProjectRisks(showClosedEntities, Number(projectIdForRisks))}
					mapEntitiesToListItems={risks => risks.map(r => (
						{
							key: r.ID,
							Register: 'TODO',
							RiskCode: r.RiskCode,
							Name: r.Title,
							Group: r.Directorate?.OrgLevel1?.Title,
							Directorate: r.Directorate?.Title,
							RiskStaff: [
								r.RiskOwnerUser ? `Owner - ${r.RiskOwnerUser.Title}` : null,
								r.ReportApproverUser ? `ARA - ${r.ReportApproverUser.Title}` : null,
								r.Contributors && r.Contributors.some(c => c.ContributorUser != null) ? `C - ${r.Contributors.map(c => c.ContributorUser?.Title).join(', ')}` : null,
							].filter(Boolean).join(', '),
							NumberOfActions: r.RiskMitigationActions?.length,
							Status: r.EntityStatus?.Title
						}
					))}
					showEntityDescription1={true}
					loadEntityDescription1={() => {
						return dataServices.projectService.readTitlesForProjectDirectorateAndGroup('Risks', 'Risk', Number(projectIdForRisks), orbConfig.OrgL2Label);
					}}
					entityForm={(showForm, entityId, onSaved, onCancelled, entityDescription1, entityDescription1DirectorateID) =>
						<ProjectRiskForm
							{...props}
							showForm={showForm}
							entityId={entityId}
							showEntityDescription1={true}
							entityDescription1={entityDescription1}
							defaultValues={[/*{ field: 'RiskRegisterID', value: 3 },*/ { field: 'DirectorateID', value: entityDescription1DirectorateID }, { field: 'IsProjectRisk', value: true }, { field: 'ProjectID', value: Number(projectIdForRisks) }]}
							onSaved={onSaved}
							onCancelled={onCancelled}
						/>
					}
					viewForm={(showForm, entityId, onClosed) =>
						<ProjectRiskView
							{...props}
							showForm={showForm}
							entityId={entityId}
							entityName='Risk'
							loadEntity={riskId => dataServices.corporateRiskService.readForView(riskId)}
							onClosed={onClosed}
						/>
					}
					// childEntityForm={(showForm, parentEntityId, onSaved, onCancelled) =>
					// 	<RiskMitigationActionForm
					// 		{...props}
					// 		maxDependentRisks={1}
					// 		showForm={showForm}
					// 		onSaved={onSaved}
					// 		onCancelled={onCancelled}
					// 		defaultValues={[{ field: 'RiskID', value: parentEntityId }]}
					// 	/>
					// }
					onCheckDelete={id => dataServices.corporateRiskService.entityChildren(id)}
					onDelete={id => dataServices.corporateRiskService.delete(id)}
				//onChange={() => corporateRisks(true)}
				/>
			}
			{entity === 'RiskMitigationActions' &&
				<EntityList<ICorporateRiskMitigationAction>
					{...props}
					enableShowHideClosedEntities={true}
					entityName={{ Plural: 'Risk mitigating actions', Singular: 'Risk mitigating action' }}
					preFarItems={(a) => getPreFarItems(['Projects', 'Risks'])}
					columns={[
						{ key: '1', name: 'Risk mitigating action name', fieldName: 'Name', minWidth: 150, maxWidth: 300, isResizable: true },
						{ key: '2', name: 'Staff', fieldName: 'MitigatingActionStaff', minWidth: 250, maxWidth: 350, isResizable: true, isMultiline: true, isCollapsible: true },
						{ key: '3', name: 'Status', fieldName: 'Status', minWidth: 50, isResizable: true, isCollapsible: true }
					]}
					loadListItems={showClosedEntities => dataServices.corporateRiskMitigationActionService.readAllForList(showClosedEntities, Number(riskIdForMitigatingActions))}
					mapEntitiesToListItems={riskMitigationActions => riskMitigationActions.map(a => (
						{
							key: a.ID,
							RiskCode: a.Risk?.RiskCode,
							Code: a.RiskMitigationActionCode,
							Risk: a.Risk?.Title,
							Name: a.Title,
							MitigatingActionStaff: [
								a.OwnerUser ? `Owner - ${a.OwnerUser.Title}` : null,
								a.Contributors && a.Contributors.some(c => c.ContributorUser != null) ? `C - ${a.Contributors.map(c => c.ContributorUser?.Title).join(', ')}` : null,
							].filter(Boolean).join(', '),
							Status: a.EntityStatus?.Title
						}
					))}
					showEntityDescription1={true}
					loadEntityDescription1={() => {
						return dataServices.corporateRiskService.readTitlesForRiskProjectDirectorateAndGroup('Risks mitigating actions', 'Risks mitigating action', Number(riskIdForMitigatingActions), orbConfig.OrgL2Label);
					}}
					entityForm={(showForm, entityId, onSaved, onCancelled, entityDescription1) =>
						<ProjectRiskMitigationActionForm
							{...props}
							showEntityDescription1={true}
							entityDescription1={entityDescription1}
							maxDependentRisks={1}
							showForm={showForm}
							entityId={entityId}
							defaultValues={[{ field: 'RiskID', value: Number(riskIdForMitigatingActions) }]}
							onSaved={onSaved}
							onCancelled={onCancelled}
						/>
					}
					viewForm={(showForm, entityId, onClosed) =>
						<ProjectRiskMitigationActionView
							{...props}
							showForm={showForm}
							entityId={entityId}
							entityName='Risk mitigating action'
							loadEntity={actionId => dataServices.corporateRiskMitigationActionService.readForView(actionId)}
							onClosed={onClosed}
						/>
					}
					onCheckDelete={id => dataServices.corporateRiskMitigationActionService.entityChildren(id)}
					onDelete={id => dataServices.corporateRiskMitigationActionService.delete(id)}
				/>
			}
			{entity === 'WorkStreams' &&
				<EntityList<IWorkStream>
					{...props}
					enableShowHideClosedEntities={true}
					entityName={{ Plural: 'Work streams', Singular: 'Work stream' }}
					entity={projectIdForWorkStream !== undefined ? "Work stream for project" : "Work stream"} //just to reload
					preFarItems={(ws) => getPreFarItems(['Projects'])}
					preFarItemsOnSelection={(ws) => {
						fetchChildCountsForSelectedWorkStream(ws?.ID);
						return ([
							{
								key: 'Milestones',
								name: milestonesLabelForSelectedWorkStream,
								iconProps: { iconName: 'MiniExpand' },
								onClick() {
									history.push(`/performance-reporting/milestones-for-work-stream/${ws?.ID}`);
								},
							},
						]);
					}}
					columns={getWorkStreamsColumns()}
					loadListItems={showClosedEntities => {
						return projectIdForWorkStream !== undefined ? dataServices.workStreamService.readAllForList(showClosedEntities, Number(projectIdForWorkStream)) : dataServices.workStreamService.readAllForList(showClosedEntities);
					}}
					showEntityDescription1={projectIdForWorkStream !== undefined ? true : false}
					loadEntityDescription1={() => {
						return projectIdForWorkStream !== undefined ? dataServices.projectService.readTitlesForProjectDirectorateAndGroup('Work streams', 'Work stream', Number(projectIdForWorkStream), orbConfig.OrgL2Label) : null;
					}}

					mapEntitiesToListItems={workStreams => workStreams.map(ws => (
						{
							key: ws.ID,
							Project: ws.Project?.Title,
							WorkStreamCode: ws.WorkStreamCode,
							Name: ws.Title,
							WorkStreamStaff: [
								ws.LeadUser ? `L - ${ws.LeadUser.Title}` : null,
								ws.Contributors && ws.Contributors.some(c => c.ContributorUser != null) ? `C - ${ws.Contributors.map(wc => wc.ContributorUser?.Title).join(', ')}` : null,
							].filter(Boolean).join(', '),
							Status: ws.EntityStatus?.Title
						}
					))}
					entityForm={(showForm, entityId, onSaved, onCancelled, entityDescription1) =>
						<WorkStreamForm
							{...props}
							showForm={showForm}
							entityId={entityId}
							showEntityDescription1={projectIdForWorkStream !== undefined ? true : false}
							entityDescription1={entityDescription1}
							defaultValues={[{ field: 'ProjectID', value: Number(projectIdForWorkStream) }]}
							onSaved={onSaved}
							onCancelled={onCancelled} />
					}
					viewForm={(showForm, entityId, onClosed) =>
						<WorkStreamView
							{...props}
							showForm={showForm}
							entityId={entityId}
							entityName='Work stream'
							loadEntity={entityId => dataServices.workStreamService.readForView(entityId)}
							onClosed={onClosed}
						/>
					}
					onCheckDelete={id => dataServices.workStreamService.entityChildren(id)}
					onDelete={id => dataServices.workStreamService.delete(id)}
				/>
			}
			{entity === 'Metrics' &&
				<EntityList<IMetric>
					{...props}
					enableShowHideClosedEntities={true}
					entityName={{ Plural: 'Metrics', Singular: 'Metric' }}
					entity={directorateIdForMetrics !== undefined ? "Metrics for directorates" : "Metrics"} //just to reload
					preFarItems={(p) => getPreFarItems(['Directorates'])}
					showEntityDescription1={directorateIdForMetrics !== undefined ? true : false}
					loadEntityDescription1={() => {
						return directorateIdForMetrics !== undefined ? dataServices.directorateService.readTitlesForDirectorateAndGroup('Metrics', 'Metric', orbConfig.OrgL2Label, Number(directorateIdForMetrics)) : null;
					}}
					columns={[
						{ key: '1', name: 'Name', fieldName: 'Name', minWidth: 150, maxWidth: 250, isResizable: true },
						{ key: '2', name: 'Lower limit', fieldName: 'TargetPerformanceLowerLimit', minWidth: 100, maxWidth: 100, isResizable: true, isCollapsible: true },
						{ key: '3', name: 'Upper limit', fieldName: 'TargetPerformanceUpperLimit', minWidth: 100, maxWidth: 100, isResizable: true, isCollapsible: true },
						{ key: '4', name: 'Staff', fieldName: 'Staff', minWidth: 250, maxWidth: 350, isResizable: true, isMultiline: true, isCollapsible: true },
						{ key: '5', name: 'Attributes', fieldName: 'Attributes', minWidth: 200, maxWidth: 350, isResizable: true, isMultiline: true, isCollapsible: true },
						{ key: '6', name: 'Status', fieldName: 'Status', minWidth: 100, isResizable: true, isCollapsible: true }
					]}
					loadListItems={showClosedEntities => {
						return directorateIdForMetrics !== undefined ? dataServices.metricService.readAllForList(showClosedEntities, Number(directorateIdForMetrics)) : dataServices.metricService.readAllForList(showClosedEntities);
					}}
					mapEntitiesToListItems={metrics => metrics.map(m => (
						{
							key: m.ID,
							Name: m.Title,
							Directorate: m.Directorate?.Title,
							TargetPerformanceLowerLimit: m.TargetPerformanceLowerLimit || m.TargetPerformanceLowerLimit === 0 ? Number(m.TargetPerformanceLowerLimit) : null,
							TargetPerformanceUpperLimit: m.TargetPerformanceUpperLimit || m.TargetPerformanceUpperLimit === 0 ? Number(m.TargetPerformanceUpperLimit) : null,
							Attributes: m.Attributes?.map(a => a.AttributeType?.Title).join(', '),
							Staff: [
								m.LeadUser ? `L - ${m.LeadUser.Title}` : null,
								m.Contributors && m.Contributors.some(c => c.ContributorUser != null) ? `C - ${m.Contributors.map(c => c.ContributorUser?.Title).join(', ')}` : null,
							].filter(Boolean).join(', '),
							Status: m.EntityStatus?.Title
						}
					))}
					entityForm={(showForm, entityId, onSaved, onCancelled, entityDescription1) =>
						<MetricForm
							{...props}
							entityName="Metric"
							showForm={showForm}
							entityId={entityId}
							showEntityDescription1={directorateIdForMetrics !== undefined ? true : false}
							entityDescription1={entityDescription1}
							defaultValues={[{ field: 'DirectorateID', value: Number(directorateIdForMetrics) }]}
							onSaved={onSaved}
							onCancelled={onCancelled}
						/>
					}
					viewForm={(showForm, entityId, onClosed) =>
						<MetricView
							{...props}
							showForm={showForm}
							entityId={entityId}
							entityName='Metric'
							loadEntity={entityId => dataServices.metricService.readForView(entityId)}
							onClosed={onClosed}
						/>
					}
					onCheckDelete={id => dataServices.metricService.entityChildren(id)}
					onDelete={id => dataServices.metricService.delete(id)}
				/>
			}
			{entity === 'Milestones' &&
				<EntityList<IMilestone>
					{...props}
					enableShowHideClosedEntities={true}
					entityName={{ Plural: 'Milestones', Singular: 'Milestone' }}
					preFarItems={(m) => workstreamIdForMilestones !== undefined ? getPreFarItems(['Projects', 'WorkStreams']) : workareaIdForMilestones !== undefined ? getPreFarItems(['Directorates', 'WorkAreas']) : null}
					columns={getMilestonesColumns()}
					showEntityDescription1={(workstreamIdForMilestones !== undefined || workareaIdForMilestones !== undefined) ? true : false}
					loadEntityDescription1={() => {
						return (workstreamIdForMilestones !== undefined) ? dataServices.workStreamService.readTitlesForWorkStreamProjectDirectorateAndGroup('Milestones', 'Milestone', Number(workstreamIdForMilestones)) : (workareaIdForMilestones !== undefined) ? dataServices.keyWorkAreaService.readTitlesForWorkAreaDirectorateAndGroup('Milestones', 'Milestone', Number(workareaIdForMilestones)) : null;
					}}
					//loadListItems={showClosedEntities => dataServices.milestoneService.readAllDirectorateAndProjectMilestonesForList(showClosedEntities)}
					loadListItems={showClosedEntities => {
						return workstreamIdForMilestones !== undefined ? dataServices.milestoneService.readAllProjectMilestonesForList(showClosedEntities, Number(workstreamIdForMilestones)) : workareaIdForMilestones !== undefined ? dataServices.milestoneService.readAllDirectorateMilestonesForList(showClosedEntities, Number(workareaIdForMilestones)) : dataServices.milestoneService.readAllDirectorateAndProjectMilestonesForList(showClosedEntities);
					}}
					mapEntitiesToListItems={milestones => milestones.map(m => (
						{
							key: m.ID,
							ParentEntity: m.KeyWorkArea?.Title || m.WorkStream?.Title,
							ParentsParentEntity: m.KeyWorkArea?.Directorate?.Title || m.WorkStream?.Project?.Title,
							MilestoneType: m.MilestoneType?.Title,
							MilestoneCode: m.MilestoneCode,
							Name: m.Title,
							MilestoneStaff: [
								m.LeadUser ? `L - ${m.LeadUser.Title}` : null,
								m.Contributors && m.Contributors.some(x => x.IsReadOnly !== true) ? `C - ${m.Contributors.filter(x => x.IsReadOnly !== true).map(c => c.ContributorUser?.Title).join(', ')}` : null,
								m.Contributors && m.Contributors.some(x => x.IsReadOnly === true) ? `RO/C - ${m.Contributors.filter(x => x.IsReadOnly === true).map(c => c.ContributorUser?.Title).join(', ')}` : null,
							].filter(Boolean).join(', '),

							Status: m.EntityStatus?.Title
						}
					))}
					entityForm={(showForm, entityId, onSaved, onCancelled, entityDescription1) =>
						<MilestoneForm
							{...props}
							showForm={showForm}
							entityId={entityId}
							showEntityDescription1={(workstreamIdForMilestones !== undefined || workareaIdForMilestones !== undefined) ? true : false}
							entityDescription1={entityDescription1}
							defaultValues={workstreamIdForMilestones !== undefined ? [{ field: 'MilestoneTypeID', value: 2 /* 2 mean milestone type is Project */ }, { field: 'WorkStreamID', value: Number(workstreamIdForMilestones) }] : workareaIdForMilestones !== undefined ? [{ field: 'MilestoneTypeID', value: 1 /* 1 mean milestone type is Directorate */ }, { field: 'KeyWorkAreaID', value: Number(workareaIdForMilestones) }] : null}
							onSaved={onSaved}
							onCancelled={onCancelled} />
					}
					viewForm={(showForm, entityId, onClosed) =>
						<MilestoneView
							{...props}
							showForm={showForm}
							entityId={entityId}
							entityName='Milestone'
							loadEntity={entityId => dataServices.milestoneService.readForView(entityId)}
							onClosed={onClosed}
						/>
					}
					onCheckDelete={id => dataServices.milestoneService.entityChildren(id)}
					onDelete={id => dataServices.milestoneService.delete(id)}
				/>
			}
			{entity === 'Benefits' &&
				<EntityList<IBenefit>
					{...props}
					enableShowHideClosedEntities={true}
					entityName={{ Plural: 'Benefits', Singular: 'Benefit' }}
					entity={projectIdForBenefits !== undefined ? "Benefit for project" : "Benefit"} //just to reload
					columns={getBenefitsColumns()}
					preFarItems={(ws) => getPreFarItems(['Projects'])}
					loadListItems={showClosedEntities => {
						return projectIdForBenefits !== undefined ? dataServices.benefitService.readAllForList(showClosedEntities, Number(projectIdForBenefits)) : dataServices.benefitService.readAllForList(showClosedEntities);
					}}
					showEntityDescription1={projectIdForBenefits !== undefined ? true : false}
					loadEntityDescription1={() => {
						return projectIdForBenefits !== undefined ? dataServices.projectService.readTitlesForProjectDirectorateAndGroup('Benefits', 'Benefit', Number(projectIdForBenefits), orbConfig.OrgL2Label) : null;
					}}
					mapEntitiesToListItems={benefits => benefits.map(b => (
						{
							key: b.ID,
							Name: b.Title,
							Project: b.Project?.Title,
							TargetPerformanceLowerLimit: b.TargetPerformanceLowerLimit || b.TargetPerformanceLowerLimit === 0 ? Number(b.TargetPerformanceLowerLimit) : null,
							TargetPerformanceUpperLimit: b.TargetPerformanceUpperLimit || b.TargetPerformanceUpperLimit === 0 ? Number(b.TargetPerformanceUpperLimit) : null,
							BenefitStaff: [
								b.LeadUser ? `L - ${b.LeadUser.Title}` : null,
								b.Contributors && b.Contributors.some(c => c.ContributorUser != null) ? `C - ${b.Contributors.map(c => c.ContributorUser?.Title).join(', ')}` : null,
							].filter(Boolean).join(', '),
							Status: b.EntityStatus?.Title
						}
					))}
					entityForm={(showForm, entityId, onSaved, onCancelled, entityDescription1) =>
						<BenefitForm
							{...props}
							entityName="Benefit"
							showForm={showForm}
							entityId={entityId}
							showEntityDescription1={projectIdForBenefits !== undefined ? true : false}
							entityDescription1={entityDescription1}
							defaultValues={[{ field: 'ProjectID', value: Number(projectIdForBenefits) }]}
							onSaved={onSaved}
							onCancelled={onCancelled}
						/>
					}
					viewForm={(showForm, entityId, onClosed) =>
						<BenefitView
							{...props}
							showForm={showForm}
							entityId={entityId}
							entityName='Benefit'
							loadEntity={entityId => dataServices.benefitService.readForView(entityId)}
							onClosed={onClosed}
						/>
					}
					onCheckDelete={id => dataServices.benefitService.entityChildren(id)}
					onDelete={id => dataServices.benefitService.delete(id)}
				/>
			}
			{entity === 'Commitments' &&
				<EntityList<ICommitment>
					{...props}
					enableShowHideClosedEntities={true}
					entityName={{ Plural: 'Commitments', Singular: 'Commitment' }}
					entity={directorateIdForCommitments !== undefined ? "Commitments for directorates" : "Commitments"} //just to reload
					preFarItems={(p) => getPreFarItems(['Directorates'])}
					showEntityDescription1={directorateIdForCommitments !== undefined ? true : false}
					loadEntityDescription1={() => {
						return directorateIdForCommitments !== undefined ? dataServices.directorateService.readTitlesForDirectorateAndGroup('Commitments', 'Commitment', orbConfig.OrgL2Label, Number(directorateIdForCommitments)) : null;
					}}
					columns={[
						{ key: '1', name: 'Name', fieldName: 'Name', minWidth: 200, maxWidth: 300, isResizable: true },
						{ key: '2', name: 'Staff', fieldName: 'Staff', minWidth: 250, maxWidth: 350, isResizable: true, isMultiline: true, isCollapsible: true },
						{ key: '3', name: 'Attributes', fieldName: 'Attributes', minWidth: 200, maxWidth: 350, isResizable: true, isMultiline: true, isCollapsible: true },
						{ key: '4', name: 'Status', fieldName: 'Status', minWidth: 100, isResizable: true, isCollapsible: true }
					]}
					loadListItems={showClosedEntities => {
						return directorateIdForCommitments !== undefined ? dataServices.commitmentService.readAllForList(showClosedEntities, Number(directorateIdForCommitments)) : dataServices.commitmentService.readAllForList(showClosedEntities);
					}}
					mapEntitiesToListItems={commitments => commitments.map(c => (
						{
							key: c.ID,
							Directorate: c.Directorate?.Title,
							Name: c.Title,
							Attributes: c.Attributes?.map(a => a.AttributeType?.Title).join(', '),
							Staff: [
								c.LeadUser ? `L - ${c.LeadUser.Title}` : null,
								c.Contributors && c.Contributors.some(c => c.ContributorUser != null) ? `C - ${c.Contributors.map(c => c.ContributorUser?.Title).join(', ')}` : null,
							].filter(Boolean).join(', '),
							Status: c.EntityStatus?.Title
						}
					))}
					entityForm={(showForm, entityId, onSaved, onCancelled, entityDescription1) =>
						<CommitmentForm
							{...props}
							entityName="Commitment"
							showForm={showForm}
							entityId={entityId}
							showEntityDescription1={directorateIdForCommitments !== undefined ? true : false}
							entityDescription1={entityDescription1}
							defaultValues={[{ field: 'DirectorateID', value: Number(directorateIdForCommitments) }]}
							onSaved={onSaved}
							onCancelled={onCancelled}
						/>
					}
					viewForm={(showForm, entityId, onClosed) =>
						<CommitmentView
							{...props}
							showForm={showForm}
							entityId={entityId}
							entityName='Commitment'
							loadEntity={entityId => dataServices.commitmentService.readForView(entityId)}
							onClosed={onClosed}
						/>
					}
					onCheckDelete={id => dataServices.commitmentService.entityChildren(id)}
					onDelete={id => dataServices.commitmentService.delete(id)}
				/>
			}
			{entity === 'Dependencies' &&
				<EntityList<IDependency>
					{...props}
					enableShowHideClosedEntities={true}
					entityName={{ Plural: 'Dependencies', Singular: 'Dependency' }}
					entity={projectIdForDependencies !== undefined ? "Dependency for project" : "Dependency"} //just to reload
					columns={getDependenciesColumns()}
					preFarItems={(ws) => getPreFarItems(['Projects'])}
					loadListItems={showClosedEntities => {
						return projectIdForDependencies !== undefined ? dataServices.dependencyService.readAllForList(showClosedEntities, Number(projectIdForDependencies)) : dataServices.dependencyService.readAllForList(showClosedEntities);
					}}
					showEntityDescription1={projectIdForDependencies !== undefined ? true : false}
					loadEntityDescription1={() => {
						return projectIdForDependencies !== undefined ? dataServices.projectService.readTitlesForProjectDirectorateAndGroup('Dependencies', 'Dependency', Number(projectIdForDependencies), orbConfig.OrgL2Label) : null;
					}}
					mapEntitiesToListItems={dependencies => dependencies.map(d => (
						{
							key: d.ID,
							Project: d.Project?.Title,
							Name: d.Title,
							DependencyStaff: [
								d.LeadUser ? `L - ${d.LeadUser.Title}` : null,
								d.Contributors && d.Contributors.some(c => c.ContributorUser != null) ? `C - ${d.Contributors.map(dc => dc.ContributorUser?.Title).join(', ')}` : null,
							].filter(Boolean).join(', '),
							Status: d.EntityStatus?.Title
						}
					))}
					entityForm={(showForm, entityId, onSaved, onCancelled, entityDescription1) =>
						<DependencyForm
							{...props}
							showForm={showForm}
							entityId={entityId}
							showEntityDescription1={projectIdForDependencies !== undefined ? true : false}
							entityDescription1={entityDescription1}
							defaultValues={[{ field: 'ProjectID', value: Number(projectIdForDependencies) }]}
							onSaved={onSaved}
							onCancelled={onCancelled} />
					}
					viewForm={(showForm, entityId, onClosed) =>
						<DependencyView
							{...props}
							showForm={showForm}
							entityId={entityId}
							entityName='Dependency'
							loadEntity={entityId => dataServices.dependencyService.readForView(entityId)}
							onClosed={onClosed}
						/>
					}
					onCheckDelete={id => dataServices.dependencyService.entityChildren(id)}
					onDelete={id => dataServices.dependencyService.delete(id)}
				/>
			}
			{entity === 'DirectorateUsers' &&
				<EntityList
					{...props}
					entityName={{ Plural: `${orbConfig.OrgL2Label} Users`, Singular: `${orbConfig.OrgL2Label} User` }}
					columns={[
						{ key: '1', name: 'User', fieldName: 'User', minWidth: 200, maxWidth: 300, isResizable: true },
						{ key: '2', name: 'User is admin?', fieldName: 'IsAdmin', minWidth: 150, maxWidth: 200, isResizable: true, isCollapsible: true },
						{ key: '3', name: 'User is risk admin?', fieldName: 'IsRiskAdmin', minWidth: 150, isResizable: true, isCollapsible: true }
					]}
					preFarItems={(ud) => getPreFarItems(['Directorates'])}
					loadListItems={() => dataServices.userDirectorateService.readAllForList(Number(directorateIdForDirectorateUsers))}
					mapEntitiesToListItems={userDirectorates => userDirectorates.map(ud => (
						{
							key: ud.ID,
							User: ud.User?.Title,
							Directorate: ud.Directorate?.Title,
							IsAdmin: ud.IsAdmin ? 'Yes' : 'No',
							IsRiskAdmin: ud.IsRiskAdmin ? 'Yes' : 'No'
						}
					))}
					showEntityDescription1={true}
					loadEntityDescription1={() => {
						return dataServices.directorateService.readTitlesForDirectorateUsers(Number(directorateIdForDirectorateUsers), orbConfig.OrgL2Label);
					}}
					entityForm={(showForm, entityId, onSaved, onCancelled, entityDescription1) =>
						<UserDirectorateForm
							{...props}
							showForm={showForm}
							entityId={entityId}
							showEntityDescription1={true}
							entityDescription1={entityDescription1}
							defaultValues={[{ field: 'DirectorateID', value: Number(directorateIdForDirectorateUsers) }]}
							onSaved={onSaved}
							onCancelled={onCancelled} />
					}
					onCheckDelete={() => Promise.resolve([])}
					onDelete={id => dataServices.userDirectorateService.delete(id)}
				/>
			}
			{entity === 'ProjectUsers' &&
				<EntityList
					{...props}
					entityName={{ Plural: 'Project Users', Singular: 'Project User' }}
					columns={[
						{ key: '1', name: 'User', fieldName: 'User', minWidth: 200, maxWidth: 300, isResizable: true },
						{ key: '3', name: 'User is admin?', fieldName: 'IsAdmin', minWidth: 150, isResizable: true, isCollapsible: true }
					]}
					preFarItems={(ud) => getPreFarItems(['Projects'])}
					loadListItems={() => dataServices.userProjectService.readAllForList(Number(projectIdForProjectUsers))}
					showEntityDescription1={true}
					loadEntityDescription1={() => {
						return dataServices.projectService.readTitlesForProjectUsers(Number(projectIdForProjectUsers));
					}}
					mapEntitiesToListItems={userProjects => userProjects.map(up => (
						{
							key: up.ID,
							User: up.User?.Title,
							Project: up.Project?.Title,
							IsAdmin: up.IsAdmin ? 'Yes' : 'No'
						}
					))}
					entityForm={(showForm, entityId, onSaved, onCancelled, entityDescription1) =>
						<UserProjectForm
							{...props}
							showForm={showForm}
							entityId={entityId}
							showEntityDescription1={true}
							entityDescription1={entityDescription1}
							defaultValues={[{ field: 'ProjectID', value: Number(projectIdForProjectUsers) }]}
							onSaved={onSaved}
							onCancelled={onCancelled} />
					}
					onCheckDelete={() => Promise.resolve([])}
					onDelete={id => dataServices.userProjectService.delete(id)}
				/>
			}
			{entity === 'Custom' &&
				<ReportingEntityList
					{...props}
					reportingEntityTypeId={customReportingEntityTypeId}
				/>
			}
		</ErrorBoundary>
	);

};

export default withErrorHandling(PerformanceReportingAdmin);
