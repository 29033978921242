import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IPanelHeaderRenderer, IPanelProps, Panel, PanelType } from '@fluentui/react/lib/Panel';
import { CrLoadingOverlay } from '../cr/CrLoadingOverlay';
import { ViewFormCommandBar } from '../cr/ViewFormCommandBar';
import { IProject } from '../../types';
import { renderDate } from '../cr/ListRenderers';
import styles from '../../styles/cr.module.scss';
import { OrbUserContext } from '../OrbUserContext';

interface ProjectViewProps {
    entityName?: string;
    showForm?: boolean;
    entityId?: number;
    onClosed?: () => void;
    loadEntity: (entityId: number) => Promise<IProject>;
}

export const ProjectView: React.FC<ProjectViewProps> = ({ showForm, entityName, entityId, loadEntity, onClosed }) => {
    const [formData, setFormData] = useState<IProject>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const { orbConfig } = useContext(OrbUserContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const entity = await loadEntity(entityId);
                setFormData(entity);
                setLoading(false);
            } catch (error) {
                console.error('Error loading entity:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [loadEntity]);

    const projectStaff = useMemo(() => {
        if (!formData) return '';

        const staffRoles = [
            { role: 'Senior responsible owner', users: formData.SeniorResponsibleOwnerUser ? [`${formData.SeniorResponsibleOwnerUser.Title} (${formData.SeniorResponsibleOwnerUser.EmailAddress})`] : [] },
            { role: 'Alternative report approver', users: formData.ReportApproverUser ? [`${formData.ReportApproverUser.Title} (${formData.ReportApproverUser.EmailAddress})`] : [] },
            { role: 'Project manager', users: formData.ProjectManagerUser ? [`${formData.ProjectManagerUser.Title} (${formData.ProjectManagerUser.EmailAddress})`] : [] },
            { role: 'Reporting lead', users: formData.ReportingLeadUser ? [`${formData.ReportingLeadUser.Title} (${formData.ReportingLeadUser.EmailAddress})`] : [] },
            {
                role: 'Headline update contributors',
                users: formData.Contributors && formData.Contributors.some(c => c.ContributorUser != null)
                    ? formData.Contributors.map(pc => `${pc.ContributorUser?.Title} (${pc.ContributorUser?.EmailAddress})`)
                    : [],
            },
        ];

        const formattedStaffRoles = staffRoles
            .filter(role => role.users.length > 0)
            .map(role => `<div style='display:flex'><div style='font-weight:bold'>-&nbsp;</div><div>${role.role}: ${role.users.join(', ')}</div></div>`)
            .join('');

        return formattedStaffRoles;
    }, [formData]);



    const headerText = useMemo(() => {
        if (!formData) return entityName;

        return `${entityName} - ${formData.Title}`;
    }, [entityName, formData]);

    const formattedObjectives = useMemo(() => {
        if (!formData) return null;

        return formData.Objectives ? formData.Objectives.replace(/\n/g, '<br>') : null;
    }, [formData]);

    let owner: string = '';
    if (formData?.OwnerTypeID === 0)
        owner = 'Dept';
    else if (formData?.OwnerTypeID === 1)
        owner = `${orbConfig.OrgL1Label} - ${formData.OwnerL1?.Title}`;
    else if (formData?.OwnerTypeID === 2)
        owner = `${orbConfig.OrgL2Label} - ${formData.OwnerL2?.Title}`;
    else if (formData?.OwnerTypeID === 3)
        owner = `${orbConfig.OrgL3Label} - ${formData.OwnerL3?.Title}`;
    else
        owner = 'N/A';

    let managedBy: string = '';
    if (formData?.ManagedByTypeID === 2)
        managedBy = `${orbConfig.OrgL2Label} - ${formData.ManagedByL2?.Title}`;
    else if (formData?.ManagedByTypeID === 3)
        managedBy = `${orbConfig.OrgL3Label} - ${formData.ManagedByL3?.Title}`;
    else
        managedBy = 'N/A';

    const latestProjectUpdate = formData?.ProjectUpdates.length > 0 ? formData?.ProjectUpdates[0] : null;
    const projectPhase = latestProjectUpdate?.ProjectPhase?.Title || 'N/A';

    let parentType: string = '';
    let parent: string  = '';
    if (formData?.ParentTypeID === 1) {
        parentType = 'Portfolio';
        parent = formData.Portfolio?.Title;
    }
    else if (formData?.ParentTypeID === 2) {
        parentType = 'Programme';
        parent = formData.Programme?.Title;
    }

    return (
        <Panel
            className={styles.cr}
            isOpen={showForm}
            headerText={headerText}
            type={PanelType.medium}
            hasCloseButton={false}
            onRenderHeader={(props: IPanelProps, defaultRender: IPanelHeaderRenderer) =>
                <div style={{ marginBottom: '20px' }} className={styles.panelHeaderText}>{defaultRender(props)}</div>
            }
            onRenderNavigation={() =>
                <ViewFormCommandBar onClose={onClosed} />
            }
        >
            <div className={styles.cr}>
                <CrLoadingOverlay isLoading={loading} opaque={true} />
                {formData && (
                    <div>
                        <div className={styles.viewFormFieldLabel}>Project Ref</div>
                        <div className={styles.viewFormFieldData}>{formData.CorporateProjectID || 'N/A'}</div>

                        <div className={styles.viewFormFieldLabel}>Parent Type</div>
                        <div className={styles.viewFormFieldData}>{parentType}</div>

                        <div className={styles.viewFormFieldLabel}>Parent</div>
                        <div className={styles.viewFormFieldData}>{parent}</div>

                        <div className={styles.viewFormFieldLabel}>Owned By</div>
                        <div className={styles.viewFormFieldData}>{owner}</div>

                        <div className={styles.viewFormFieldLabel}>Managed By</div>
                        <div className={styles.viewFormFieldData}>{managedBy}</div>

                        <div className={styles.viewFormFieldLabel}>Staff</div>
                        <div className={styles.viewFormFieldData}>
                            {projectStaff ? (
                                <div dangerouslySetInnerHTML={{ __html: projectStaff }} />
                            ) : (
                                'N/A'
                            )}
                        </div>

                        <div className={styles.viewFormFieldLabel}>Stage</div>
                        <div className={styles.viewFormFieldData}>{formData?.ProjectStage?.Title || 'N/A'}</div>

                        <div className={styles.viewFormFieldLabel}>Phase</div>
                        <div className={styles.viewFormFieldData}>{projectPhase}</div>

                        <div className={styles.viewFormFieldLabel}>Start-End Dates</div>
                        <div className={styles.viewFormFieldData}>
                            {renderDate(formData.StartDate)}&nbsp;&nbsp;&nbsp;&nbsp;{renderDate(formData.EndDate)}
                        </div>

                        <div className={styles.viewFormFieldLabel}>Attributes</div>
                        <div className={styles.viewFormFieldData}>
                            {formData.Attributes && formData.Attributes.length > 0 ? (
                                formData.Attributes.map(a => a.AttributeType?.Title).join(', ')
                            ) : (
                                'N/A'
                            )}
                        </div>

                        <div className={styles.viewFormFieldLabel}>Objectives</div>
                        <div className={styles.viewFormFieldData}>
                            {formattedObjectives ? (
                                <div dangerouslySetInnerHTML={{ __html: formattedObjectives }} />
                            ) : (
                                'N/A'
                            )}
                        </div>
                    </div>
                )}
            </div>
        </Panel>
    );
};