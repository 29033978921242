import * as React from 'react';
import * as types from '../../types';
import * as services from '../../services';
import { FilteredAvailExportsList } from './FilteredAvailExportsList';
import { IEntity } from '../../types';
import { IObjectWithKey, Selection } from '@fluentui/react/lib/DetailsList';
import { IUpdatesListColumn, ColumnDisplayTypes } from '../../types/UpdatesListColumn';
import { CrLoadingOverlay } from '../cr/CrLoadingOverlay';
import { ConfirmDialog } from '../cr/ConfirmDialog';
import styles from '../../styles/cr.module.scss';


export interface IAvailExportsListProps extends types.IBaseComponentProps {
    moduleName: string;
    filterText?: string;
    onChangeFilterText: (value: string) => void;
    listRefreshNeededCounter: number;
}

export interface IAvailExportsListState<T> {
    SelectedEntity: number;
    SelectedEntityTitle: string;
    ShowForm: boolean;
    EnableDelete?: boolean;
    EnableDownload?: boolean;
    HideDeleteDialog: boolean;
    Entities: T[];
    Loading: boolean;
    ListFilterText?: string;
    InitDataLoaded: boolean;
}
export class AvailExportsListState<T> implements IAvailExportsListState<T> {
    public SelectedEntity = null;
    public SelectedEntityTitle: string = null;
    public ShowForm = false;
    public HideDeleteDialog = true;
    public EnableDelete = false;
    public EnableDownload = false;
    public Entities: T[] = [];
    public Loading = false;
    public ListFilterText = null;
    public InitDataLoaded = false;
}

export default class AvailExportsList extends React.Component<IAvailExportsListProps, IAvailExportsListState<IEntity>> {
    private _selection: Selection;
    private availableExportService: services.AvailableExportService = new services.AvailableExportService();
    private zFileService: services.ZFileService = new services.ZFileService();

    private listColumns: IUpdatesListColumn[] = [
        {
            key: 'ID',
            name: 'ID',
            fieldName: 'ID',
            minWidth: 1,
            isResizable: true,
            columnDisplayType: ColumnDisplayTypes.Hidden,
        },
        {
            key: 'Title',
            name: 'Title',
            fieldName: 'Title',
            minWidth: 100,
            maxWidth: 350,
            isResizable: true,
            isMultiline: true,
            headerClassName: styles.bold,
        },
        {
            key: 'OutputFileStatus',
            name: 'Export Status',
            fieldName: 'OutputFileStatus',
            minWidth: 120,
            maxWidth: 200,
            isResizable: true,
            isMultiline: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },
        {
            key: 'CreatedBy',
            name: 'Created By',
            fieldName: 'CreatedBy',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            isMultiline: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },
        {
            key: 'Parameters',
            name: 'Parameters',
            fieldName: 'Parameters',
            minWidth: 150,
            isResizable: true,
            isMultiline: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },
        {
            key: 'OutputFileName',
            name: 'File Name',
            fieldName: 'OutputFileName',
            minWidth: 1,
            columnDisplayType: ColumnDisplayTypes.Hidden,
        },
        {
            key: 'FileUniqueID',
            name: 'FileUniqueID',
            fieldName: 'FileUniqueID',
            minWidth: 1,
            columnDisplayType: ColumnDisplayTypes.Hidden,
        },
        {
            key: 'CreatedOn',
            name: 'CreatedOn',
            fieldName: 'CreatedOn',
            minWidth: 1,
            columnDisplayType: ColumnDisplayTypes.Hidden,
        },

    ];

    constructor(props: IAvailExportsListProps, state: IAvailExportsListState<IEntity>) {
        super(props);
        this.state = new AvailExportsListState<IEntity>();

        this._selection = new Selection({
            onSelectionChanged: () => {
                if (this._selection.getSelectedCount() === 1) {

                    const sel = this._selection.getSelection()[0];
                    console.log(sel);
                    const key = Number(sel.key);
                    const title: string = sel["Title"];
                    const outputStatus: string = sel["OutputFileStatus"];

                    let outputCreated: boolean = false;
                    if (outputStatus.search("Cr") === 0)
                        outputCreated = true;

                    let enableDelete: boolean = false;
                    if (outputStatus.search("Cr") === 0 || outputStatus.search("Err") === 0)
                        enableDelete = true;


                    this.setState({ SelectedEntity: key, SelectedEntityTitle: title, EnableDelete: enableDelete, EnableDownload: outputCreated });
                }
                else {
                    this.setState({ SelectedEntity: null, SelectedEntityTitle: null, EnableDelete: false, EnableDownload: false });
                }
            }
        });
    }

    public render(): React.ReactElement<IAvailExportsListProps> {

        return (
            <div className={`${styles.cr}`}>
                <div style={{ position: 'relative' }}>
                    <CrLoadingOverlay isLoading={this.state.Loading} />
                    {this.renderList()}
                    {this.state.ShowForm && this.renderForm()}

                    <ConfirmDialog hidden={this.state.HideDeleteDialog} title="Delete Export" content={`Are you sure you want to delete ${this.getSelectedEntityName()} export?`} confirmButtonText="Delete" handleConfirm={this.deleteOutput} handleCancel={this.toggleDeleteConfirm} />
                </div>
            </div>
        );
    }

    private renderList() {

        const listColumns = this.getColumns();
        const listColumnsForData = this.getColumnsForData();

        let items: IObjectWithKey[] = this.state.Entities.map((e) => { return this.makeItem(e, listColumnsForData); });

        return (
            <FilteredAvailExportsList
                columns={listColumns}
                items={items}
                filterText={this.props.filterText}
                onFilterChange={this.props.onChangeFilterText}
                selection={this._selection}
                onDelete={this.handleDelete}
                onDownload={this.handleDownload}
                deleteDisabled={!this.state.EnableDelete}
                downloadDisabled={!this.state.EnableDownload}
                onRefresh={this.handleRefresh}
            />
        );
    }

    private renderForm() {
        return null;
    }

    private makeItem = (e: IEntity, listColumns: IUpdatesListColumn[]): any => {

        let item: any = { key: e["ID"] };
        listColumns.map((c) => {
            let fieldContent: string = String(e[c.fieldName]);
            item = {
                [c.fieldName]: fieldContent,
                ...item
            };
        });
        return item;
    }

    private getColumns(): IUpdatesListColumn[] {

        let listColumns: IUpdatesListColumn[];
        listColumns = this.listColumns.filter(c => c.columnDisplayType !== ColumnDisplayTypes.Hidden);
        return listColumns;
    }

    private getColumnsForData(): IUpdatesListColumn[] {
        //separate method for data because we want to add Hidden Columns in the data, so hidden columns data can be filtered
        const listColumns: IUpdatesListColumn[] = this.listColumns;
        return listColumns;
    }

    private handleRefresh = (): void => {
        this.loadData();
    }

    private handleDelete = (): void => {

        if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(null);
        let entity = this.state.Entities.filter((e) => { return e.ID === this.state.SelectedEntity; });
        if (entity[0]) {
            const outputStatus: string = entity[0]["OutputFileStatus"];
            this.availableExportService.delete(this.state.SelectedEntity).then(() => {
                this.loadData();

                if (outputStatus.search("Cr") === 0) {
                    const outputFileName: string = entity[0]["OutputFileName"];
                }

            }, (err) => {
                if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Cannot delete this file. `, err.message);
            });
        }
    }

    private handleDownload = (): void => {
        let entity = this.state.Entities.filter((e) => { return e.ID === this.state.SelectedEntity; });
        if (entity[0]) {
            const outputStatus: string = entity[0]["OutputFileStatus"];
            if (outputStatus.search("Cr") === 0) {
                //status is Cr
                const outputFileName: string = entity[0]["OutputFileName"];
                const fileUniqueID: string = entity[0]["FileUniqueID"];

                this.DownloadFile(fileUniqueID, outputFileName);
            }
            else {
                console.log("Output not created");
            }
        }
    }

    private DownloadFile = async (fileUniqueID: string, fileName: string) => {
        try {
            await this.zFileService.downloadFile(fileUniqueID, fileName);
        } catch (error) {
            console.error('Error downloading file', error);
        }
    };


    private getSelectedEntityName = (): string => {
        let entity = this.state.Entities.filter((e) => { return e.ID === this.state.SelectedEntity; });
        return entity[0] ? entity[0].Title : null;
    }

    private toggleDeleteConfirm = (): void => {
        this.setState({ HideDeleteDialog: !this.state.HideDeleteDialog });
    }

    private deleteOutput = (): void => {

    }

    private loadData = (): void => {
        this.setState({ Loading: true });
        const read: Promise<IEntity[]> = this.availableExportService.readAllByModule(this.props.moduleName);
        read.then((entities: any): void => {
            this.setState({
                Loading: false, Entities: entities,
            });

        }, (err) => this.errorLoadingData(err));
    }
    private errorLoadingData = (err: any, entityName?: string): void => {
        this.setState({ Loading: false });
        if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading ${entityName || 'items'}`, err.message);
    }
    public componentDidMount(): void {
        this.loadData();
    }
    public componentDidUpdate(prevProps: IAvailExportsListProps): void {
        if (prevProps.listRefreshNeededCounter !== this.props.listRefreshNeededCounter) {
            this._selection.setAllSelected(false);
            this.loadData();
        }
    }
}
