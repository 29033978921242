import { EntityService } from './EntityService';
import { IUserHelp } from '../types';

export class UserHelpService extends EntityService<IUserHelp> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/UserHelps`);
    }
}