import { IAttributeType } from '../types';
import { IEntity } from './Entity';

export interface IAttribute extends IEntity {
    ID: number;
    AttributeTypeID: number | null;
    AttributeValue: string;
    BenefitID: number | null;
    CommitmentID: number | null;
    DependencyID: number | null;
    KeyWorkAreaID: number | null;
    MetricID: number | null;
    MilestoneID: number | null;
    WorkStreamID: number | null;
    AttributeType?: IAttributeType | null;
    RiskID: number | null;
    PartnerOrganisationRiskID: number | null;
    DirectorateID: number | null;
    ProjectID: number | null;
}

export class Attribute implements IAttribute {
    public ID = 0;
    public Title = null;
    public AttributeTypeID = null;
    public AttributeValue = '';
    public BenefitID = null;
    public CommitmentID = null;
    public DependencyID = null;
    public KeyWorkAreaID = null;
    public MetricID = null;
    public MilestoneID = null;
    public WorkStreamID = null;
    public AttributeType = null;
    public RiskID = null;
    public PartnerOrganisationRiskID = null;
    public DirectorateID = null;
    public ProjectID = null;
}