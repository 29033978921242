import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CrLoadingOverlay } from '../cr/CrLoadingOverlay';
import styles from '../../styles/cr.module.scss';
import axios from 'axios';
import { DataContext } from '../DataContext';
import { UserAppLaunchLog } from '../../types';
import { AppIDs } from '../../AppGlobals';

interface AgileSprintsWelcomeProps {
}

interface IAppWelcomeText {
    CenteralHeading: string;
    CenteralBody: string;
    CustomerWelcomeHeading: string;
    CustomerWelcomeBody: string;
}

export const AgileSprintsWelcome: React.FC<AgileSprintsWelcomeProps> = ({ }) => {
    const { dataServices } = useContext(DataContext);
    const [formData, setFormData] = useState<IAppWelcomeText>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const zedxNetapiURL = process.env.REACT_APP_ZedX_Net_API!;
                const zedxNetWelcomeTextApi: string = `${zedxNetapiURL}/availableapps/GetAppWelcomeText/AGSPR`;
                const centeralWelcomeText = (await axios.get(zedxNetWelcomeTextApi)).data;
                const centeralWelcomeHeading = centeralWelcomeText.WelcomeHeading;
                const centeralWelcomeBody = centeralWelcomeText.WelcomeBody;

                let customerWelcomeHeading: string = '';
                let customerWelcomeBody: string = '';

                setFormData({
                    CenteralHeading: centeralWelcomeHeading,
                    CenteralBody: centeralWelcomeBody,
                    CustomerWelcomeHeading: customerWelcomeHeading,
                    CustomerWelcomeBody: customerWelcomeBody
                });

                dataServices.userAppLaunchLogService.create(new UserAppLaunchLog(AppIDs.AGSPR));

                setLoading(false);
            } catch (error) {
                console.error('Error loading entity:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const formattedCenteralBody = useMemo(() => {
        if (!formData) return '';

        return formData.CenteralBody ? formData.CenteralBody.replace(/\n/g, '<br>') : '';
    }, [formData]);

    const formattedCustomerBody = useMemo(() => {
        if (!formData) return '';

        return formData.CustomerWelcomeBody ? formData.CustomerWelcomeBody.replace(/\n/g, '<br>') : '';
    }, [formData]);

    return (

        <div className={styles.cr}>
            <CrLoadingOverlay isLoading={loading} opaque={true} />
            {formData && (
                <>
                    <h1 className={styles.welcomeL1Heading}>{formData.CenteralHeading}</h1>
                    <div>
                        <div className={styles.welcomeBody} dangerouslySetInnerHTML={{ __html: formattedCenteralBody }} />
                    </div>
                    <div>
                        {formData.CustomerWelcomeHeading?.length > 0 && <div style={{ fontWeight: 'bold', textDecoration: 'underline' }} className={styles.welcomeBody}>{formData.CustomerWelcomeHeading}</div>}
                    </div>
                    <div>
                        {formattedCustomerBody?.length > 0 && <div className={styles.welcomeBody} dangerouslySetInnerHTML={{ __html: formattedCustomerBody }} />}
                    </div>
                </>
            )}
        </div>

    );
};