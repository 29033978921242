import { EntityService } from './EntityService';
import { AppGlobals } from '../AppGlobals';
import { IProgrammeStage } from '../types';

export class ProgrammeStageService extends EntityService<IProgrammeStage> {
    constructor() {
        super(`/ProgrammeStages`);
    }

    public readAllForLookup(additionalFields?: string): Promise<IProgrammeStage[]> {
        return this.readAll(
            `?$select=ID,Title${additionalFields ? `,${additionalFields}` : ''}`
            + `&$orderby=ID&$filter=CustomerID eq ${AppGlobals.CustomerID}`
        );
    }
}