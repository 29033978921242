import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { INavLink, Nav } from '@fluentui/react';
import { IWithErrorHandlingProps } from './withErrorHandling';
import { OrbUserContext } from './OrbUserContext';
import { ThemeProvider } from '@fluentui/react/lib/Theme';
import { darkMenuTheme } from '../AppGlobals';
import { LoggedUserInfoForMenu } from './LoggedUserInfoForMenu';
import { DataContext } from './DataContext';
import { _onRenderNavLink } from './ZedXMainMenu';

interface IManageProjectsMenu extends IWithErrorHandlingProps {
    onLinkClicked?: () => void;
}

export const ManageProjectsMenu = ({ errorHandling: { clearErrors }, onLinkClicked }: IManageProjectsMenu): React.ReactElement => {
    const { userPermissions: up, orbConfig } = useContext(OrbUserContext);
    const history = useHistory();
    const { pathname } = useLocation();
    const { dataServices } = useContext(DataContext);
    const [navs, setNavs] = useState([]);
    const [portfolioExpanded, setPortfolioExpanded] = useState(true);
    const [totalPortfolios, setTotalPortfolios] = useState(0);
    const resetErrors = useCallback(clearErrors, [clearErrors]);

    const fetchTotalPortfolios = () => {
        dataServices.portfolioService.countPortfolios().then(c => {
            setTotalPortfolios(c);            
        });
    };

    useEffect(() => {
        fetchTotalPortfolios();

        return () => {
            //console.log('Component Unmounted');
        };
    }, []);

    useEffect(() => {
        const routerNav = (path: string): void => {
            history.push(path);
            if (onLinkClicked) onLinkClicked();
        };

        const NamePathToNavLink = ({ name, path }): INavLink => {
            return { key: path, name: name, url: `#${path}`, onClick: () => routerNav(path) };
        };

        const links: INavLink[] = [
            { name: 'Home', path: '/' },
            { name: 'Welcome', path: '/performance-reporting/welcome' },
        ].map(NamePathToNavLink);

        const canViewProjects = up.UserCanViewProjectEntities();
        const canViewDirectorate = up.UserCanViewDirectorateEntities();

        if (up.UserIsSystemAdmin() || canViewProjects || canViewDirectorate) {
            links.push({
                key: 'Management',
                name: 'Management',
                url: null,
                isExpanded: portfolioExpanded,
                onClick: () => {
                    fetchTotalPortfolios();
                    setPortfolioExpanded(e => !e);
                },
                links: [
                    //up.UserCanViewDirectorateEntities() && { name: orbConfig.OrgL2LabelP, path: '/performance-reporting/directorates' },
                    up.UserCanViewDirectorateEntities() && { name: 'Portfolios', path: '/performance-reporting/portfolios' },
                    up.UserCanViewDirectorateEntities() && { name: 'Programmes', path: '/performance-reporting/programmes' },
                    up.UserCanViewProjectEntities() && { name: 'Projects', path: '/performance-reporting/projects' },

                ].filter(r => r).map(NamePathToNavLink)
            });
        }

        links.push(NamePathToNavLink({ name: 'Outputs', path: '' }));
        links.push(NamePathToNavLink({ name: 'Properties', path: '' }));

        setNavs(links);
    }, [up, portfolioExpanded, history, onLinkClicked, resetErrors]);

    return (
        <ThemeProvider theme={darkMenuTheme}>
            <LoggedUserInfoForMenu />
            <Nav
                selectedKey={pathname}
                groups={[{ links: navs }]}
                onRenderLink={(lnk) => _onRenderNavLink(lnk, totalPortfolios)}
            />
        </ThemeProvider>
    );
};
